import React, { useContext, useMemo, useState } from 'react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import PickUpSchedule from '../../AdditionalDetails/PickUpSchedule';
import { ProducerOrderProps } from '../../../producerOrder.interface';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import { OrderDetailsContext } from '../../../../../../context/OrderDetailsContext/order-details.context';
import OrderDetailsInvoice from '../../../../../../components/OrderDetailsInvoice/OrderDetailsInvoice';
import { PaymentNotesStatusesEnum } from '../../../../../../services/billing.service';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';

const PendingPayment: React.FC<ProducerOrderProps> = ({ selectedOrder }) => {
	const { producerInvoice } = useContext(OrderDetailsContext);
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const deliveryIncluded = useMemo(() => {
		return selectedOrder?.deliveryIncluded;
	}, [selectedOrder?.deliveryIncluded]);

	const currentStep = useMemo(() => {
		const baseStep = deliveryIncluded ? 4 : 5;

		if (producerInvoice?.status === PaymentNotesStatusesEnum.PENDING_INVOICE) {
			return baseStep;
		}

		if (producerInvoice?.status === PaymentNotesStatusesEnum.UPLOADED_INVOICE) {
			return baseStep + 1;
		}

		if (
			producerInvoice?.status === PaymentNotesStatusesEnum.INVOICE_CONFIRMED
		) {
			return baseStep + 2;
		}

		return baseStep;
	}, [producerInvoice, deliveryIncluded]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={currentStep}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{[
				PaymentNotesStatusesEnum.PENDING_INVOICE,
				PaymentNotesStatusesEnum.UPLOADED_INVOICE,
				PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
			].includes(producerInvoice?.status as PaymentNotesStatusesEnum) && (
				<div className="px-10 pt-7 pb-3.5">
					<OrderDetailsInvoice
						currentStatus={producerInvoice?.status!}
						target={OrderTarget.producer}
					/>
				</div>
			)}

			<div
				className={`px-10 ${
					[
						PaymentNotesStatusesEnum.PENDING_INVOICE,
						PaymentNotesStatusesEnum.UPLOADED_INVOICE,
						PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
					].includes(producerInvoice?.status as PaymentNotesStatusesEnum)
						? 'py-3.5'
						: 'pt-7 pb-3.5'
				}`}
			>
				{deliveryIncluded ? (
					<PickUpSchedule
						selectedOrder={selectedOrder}
						step={deliveryIncluded ? 4 : 5}
					/>
				) : (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				)}
			</div>

			<div className={`px-10 ${deliveryIncluded ? 'pb-7 pt-3.5' : 'py-3.5'}`}>
				{deliveryIncluded ? (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={selectedOrder?.trucks?.length === 1}
					/>
				)}
			</div>

			{!deliveryIncluded && (
				<div className="px-10 pb-7 pt-3.5">
					<PickUpSchedule
						selectedOrder={selectedOrder}
						step={deliveryIncluded ? 4 : 5}
					/>
				</div>
			)}
		</>
	);
};

export default PendingPayment;
