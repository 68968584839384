import { Column } from 'react-table';
import { TableData } from './ModalConfirmPayment.interface';

export const COLUMNS_PRODUCER: Column<TableData>[] = [
	{
		Header: 'Order',
		accessor: 'order',
	},
	{
		Header: 'Product',
		accessor: 'product',
	},
	{
		Header: 'Quantity',
		accessor: 'quantity',
	},
	{
		Header: 'Price',
		accessor: 'price',
	},
];

export const COLUMNS_CARRIER: Column<TableData>[] = [
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Order',
		accessor: 'order',
	},
	{
		Header: 'Product',
		accessor: 'product',
	},
	{
		Header: 'Distance',
		accessor: 'distance',
	},
	{
		Header: 'Quantity',
		accessor: 'quantity',
	},
	{
		Header: 'Price',
		accessor: 'price',
	},
];
