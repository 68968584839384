import { toast, ToastOptions } from 'react-toastify';

export const toastVariant = (
	text: string,
	isError: boolean = false,
	toastId?: string,
) => {
	const params: ToastOptions = {
		className: 'w-max text-center z-[2000]',
		position: 'top-center',
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: false,
		pauseOnHover: true,
		closeButton: false,
		draggable: true,
		progress: undefined,
		theme: 'colored',
		toastId,
	};
	if (isError) {
		return toast.error(text, params);
	}
	return toast.success(text, params);
};
