import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import { InContextTools } from '@tolgee/web/tools';
import { AuthContext } from './context';
import { User } from '../types/user';
import Api from '../axios/api';
import { UsersServices } from '../services/users.services';

require('dayjs/locale/pl');
const RedirectProvider = ({ children }: { children: ReactNode }) => {
	const { search } = useLocation();
	const [loading, setLoading] = useState(true);
	const { user, setUser } = useContext(AuthContext);

	const tolgee = Tolgee()
		.use(process.env.REACT_APP_TOLGEE_API_KEY ? InContextTools() : DevTools())
		.use(FormatSimple())
		.init({
			language: user?.isAdmin ? 'en' : localStorage.getItem('lang') || 'pl',
			availableLanguages: ['en', 'pl', 'ro', 'ru'],
			fallbackLanguage: 'en',
			apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
			apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
			staticData: {
				en: () => import('../i18n/en.json'),
				pl: () => import('../i18n/pl.json'),
				ro: () => import('../i18n/ro.json'),
				ru: () => import('../i18n/ru.json'),
			},
		});

	const navigate = useNavigate();
	const location = useLocation();

	const getUser = useCallback(
		async (token: string) => {
			try {
				const decodedUser: User = jwt(token);

				if (decodedUser?.company?.industries?.[0]) {
					try {
						const { data: profileData } = await UsersServices.getMyProfile(
							decodedUser.company.industries[0],
						);

						setUser({
							...decodedUser,
							profile: profileData?.profile,
						});
					} catch (error) {
						setUser(decodedUser);
					}
				} else {
					setUser(decodedUser);
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		},
		[setUser],
	);

	useEffect(() => {
		if (!user) return;
		if (location.pathname !== '/') return;

		if (user?.isAdmin) {
			navigate('/companies');
			return;
		}

		if (user?.company?.industries?.includes('customer')) {
			navigate('/store');
			return;
		}

		if (user?.company?.industries?.includes('transporter')) {
			navigate('/orders');
			return;
		}

		navigate('/stores');
	}, [location.pathname, navigate, user]);

	useEffect(() => {
		dayjs.locale(localStorage.getItem('lang') || 'pl');
		if (location.pathname === '/' && search) {
			const params = new URLSearchParams(search);
			const accessToken = params.get('access_token');
			const refreshToken = params.get('refresh_token');
			const hasOverduePayments = params.get('has_overdue_payments');
			let lang = params.get('lang');
			if (accessToken && refreshToken) {
				localStorage.setItem('access_token', accessToken);
				Api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
				localStorage.setItem('refresh_token', refreshToken);
				localStorage.setItem(
					'has_overdue_payments',
					hasOverduePayments || 'false',
				);
				const user: User = jwt(accessToken);
				if (user?.isAdmin) {
					lang = 'en';
				}
				localStorage.setItem('lang', lang || 'pl');
				dayjs.locale(lang || 'pl');
				localStorage.setItem(
					'terms_and_conditions',
					`${user?.company.termsAccepted || false}`,
				);
			}
		}
		const existToken = localStorage.getItem('access_token');
		if (existToken) {
			getUser(existToken).then(() => {});
		} else {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUser, search]);

	if (loading) {
		return <>Loading</>;
	}

	return (
		<>
			<TolgeeProvider
				tolgee={tolgee}
				fallback="Loading..." // loading fallback
			>
				{children}
			</TolgeeProvider>
		</>
	);
};

export default RedirectProvider;
