import React from 'react';
import { SypacIcon, SypacText } from '@sypac/component-library-react';
import { SortingItemInterface } from './Sorting.interface';
import Flag from 'react-world-flags';

export const SortingItem: React.FC<SortingItemInterface> = ({
	title,
	action,
	value,
	flag,
	isSelected,
}) => {
	const handleClick = () => {
		if (action) {
			action({ title, value });
		}
	};

	return (
		<div
			className={`flex gap-3 justify-center items-center py-[10px] px-3 bg-white border border-solid border-transparent rounded-lg cursor-pointer hover:bg-gray-10-opacity-50 hover:border-gray-10 ${
				isSelected ? 'bg-gray-10-opacity-50' : ''
			}`}
			onClick={handleClick}
		>
			{flag && (
				<Flag
					className="object-cover rounded z-10 border border-solid border-gray-10"
					code={flag}
					width={26}
					height={18}
				/>
			)}
			<SypacText variant="body-regular-medium" className="mr-auto">
				<p className="text-gray-80 leading-[19px]">{title}</p>
			</SypacText>
			{flag === 'PL' && (
				<SypacIcon
					iconName="Crown Minimalistic"
					className="text-yellow-400 z-[100]"
					size="custom"
					width="20px"
					height="20px"
				/>
			)}
		</div>
	);
};
