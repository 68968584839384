import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Flag from 'react-world-flags';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import {
	SypacButton,
	SypacDatepicker,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useGetCompanyById } from '../../../../hooks/use-get-company-by-id';
import useDebounce from '../../../../hooks/useDebounce';
import {
	ConfirmPaymentProps,
	TableData,
} from '../../../../components/ModalConfirmPayment/ModalConfirmPayment.interface';
import Close from '../../../../assets/Close';
import {
	COLUMNS_CARRIER,
	COLUMNS_PRODUCER,
} from '../../../../components/ModalConfirmPayment/columns.constants';
import { T, useTranslate } from '@tolgee/react';
import { OrderContext } from '../../../../context/OrderContext/order.context';
import InvoiceTable from '../../../../components/InvoiceTable/InvoiceTable';
import { formatDate } from '../../../../utils/time.util';
import { BillingService } from '../../../../services/billing.service';
import { FileType, PageProps } from '../../../../constants';
import { getFileType } from '../../../../utils/files.util';
import DocumentsViewer from '../../../../components/DocumentsViewer/DocumentsViewer';
import { OrderDetailsContext } from '../../../../context/OrderDetailsContext/order-details.context';
import Emitter, { EventType } from '../../../../services/events';
import { Document, Page } from 'react-pdf';
import { ModalPendingInvoiceConfirmationProps } from '../adminBilling.interface';

const ModalPendingInvoiceConfirmation: React.FC<
	ModalPendingInvoiceConfirmationProps
> = ({ isOpen, onClose, invoice, order, onSubmit, onReject }) => {
	const { t } = useTranslate();
	const { setAdditional } = useContext(OrderContext);
	const { actionFor } = useContext(OrderDetailsContext);
	const language = localStorage.getItem('lang') || 'en';
	const modalRef = useRef<HTMLDivElement>(null);

	const [displayAmount, setDisplayAmount] = useState<string>('');
	const [company] = useGetCompanyById({
		id: +invoice?.invoiceLines[0]?.assigneeUser?.company?.id!,
	});
	const [openDocView, setOpenDocView] = useState<boolean>(false);
	const [openDocUrl, setOpenDocUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();
	const [fileSizes, setFileSizes] = useState<Record<string, number>>({});

	const localFiles = useMemo(
		() =>
			invoice?.fullInvoiceUrls?.length
				? invoice.fullInvoiceUrls.map((url, index) => ({
						file: {
							lastModified: formatDate(invoice.updatedAt),
							name: `invoice_${index + 1}.pdf`,
							size: fileSizes[url] || 0,
							path: url.match(/\/dev\/[^?]+/)?.[0].substring(1),
						},
						mimeType: `${getFileType(url)?.toUpperCase()}`,
						name: `Invoice ${index + 1}`,
						size: fileSizes[url]
							? `${(fileSizes[url] / (1024 * 1024)).toFixed(2)} MB`
							: 'Unknown',
						fileUrl: url,
				  }))
				: [],
		[fileSizes, invoice?.fullInvoiceUrls, invoice?.updatedAt],
	);

	const tableData: TableData[] = useMemo(() => {
		return (
			invoice?.invoiceLines?.map((line) => ({
				key: line.orderId.toString(),
				order: line.orderId,
				product: line.description,
				quantity:
					actionFor === 'transporter'
						? order?.quantity.toString()
						: order?.trucks?.[0].loadedQuantity?.toString(),
				price: line.lineTotal?.toString()!,
				...(actionFor === 'transporter' && {
					description: 'Transportation',
					distance: `${order?.trucks?.[0].distance} ${line.unit}`,
				}),
			})) || []
		);
	}, [invoice, order, actionFor]);

	const formik = useFormik({
		initialValues: {
			date: '',
			amount: '',
			realAmount: invoice?.balanceDue.toString() || '',
		},
		initialTouched: {
			amount: true,
		},
		onSubmit: async (values) => {
			try {
				await BillingService.confirmInvoiceAdmin(actionFor, invoice.id);
				Emitter.emit(EventType.PAYMENT_NOTE_REFRESH);
				handleSubmit(values);
			} catch (e) {}
		},
		validationSchema: Yup.object({
			realAmount: Yup.string().required('RealAmount is required'),
			date: Yup.string().required('Please enter date.'),
			amount: invoice?.balanceDue
				? Yup.string()
						.oneOf(
							[Yup.ref('realAmount')],
							'Please enter an amount equal with the amount to pay.',
						)
						.required('Please enter a valid amount.')
				: Yup.string().required('Please enter a valid amount.'),
		}),
	});

	const errorAmount = useDebounce(
		formik.touched.amount && formik.errors.amount,
		500,
	);

	const closeModal = () => {
		formik.resetForm();
		onClose();
	};

	const handleSubmit = (values: ConfirmPaymentProps) => {
		formik.resetForm();
		onSubmit(values);
	};

	const setPrice = (value: string) => {
		setDisplayAmount(value);
		if (!invoice?.balanceDue) {
			formik.setFieldValue('realAmount', value.replace(/,/g, '.'), true);
		}
		formik.setFieldValue('amount', value.replace(/,/g, '.'), true);
	};

	const onCallback = (detail: string) => {
		formik.setFieldTouched('amount', false);
		formik.setFieldValue('date', dayjs(detail).toISOString());
	};

	const openFile = (url: string) => {
		setOpenDocUrl(url);
		setOpenDocView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	useEffect(() => {
		const fetchFileSizes = async () => {
			if (!invoice?.fullInvoiceUrls?.length) return;

			const sizePromises = invoice?.fullInvoiceUrls.map(async (url) => {
				try {
					const response = await fetch(url, { method: 'HEAD' });
					const contentLength = response.headers.get('Content-Length');
					return { url, size: contentLength ? parseInt(contentLength, 10) : 0 };
				} catch (error) {
					console.error('Error fetching file size:', error);
					return { url, size: 0 };
				}
			});

			const results = await Promise.all(sizePromises);
			const sizeMap = results.reduce((acc, { url, size }) => {
				acc[url] = size;
				return acc;
			}, {} as Record<string, number>);

			setFileSizes(sizeMap);
		};

		fetchFileSizes().then(() => {});
	}, [invoice?.fullInvoiceUrls]);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<>
			<div className="modal-overlay" onClick={closeModal}>
				<div
					className="w-[1657px] bg-white rounded-xl"
					onClick={(e) => e.stopPropagation()}
					ref={modalRef}
				>
					<form onSubmit={formik.handleSubmit} className="h-full">
						<div className="flex flex-col h-full">
							<header className="flex items-center justify-between p-3">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-90">
										<T keyName="modalPendingInvoiceConfirmation.pendingInvoiceConfirmation">
											Pending invoice confirmation
										</T>
									</p>
								</SypacText>
								<SypacButton variant="subTitle">
									<button
										type="button"
										className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
										onClick={closeModal}
									>
										<Close width="10" height="10" />
									</button>
								</SypacButton>
							</header>
							<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

							<section className="flex flex-col gap-6 p-3 pb-0">
								<SypacText variant="body-regular-medium">
									<p className="text-2xl text-gray-90">
										<T keyName="modalPendingInvoiceConfirmation.pleaseCheck">
											Please check the uploaded files carefully
										</T>
									</p>
								</SypacText>

								<div className="flex flex-col gap-3">
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-40">
											<T keyName="modalPendingInvoiceConfirmation.theProvidedData">
												The provided data must match the required criteria,
												including: dates, prices, weights, and company details.
												Ensure that all values correspond accurately before
												proceeding.
											</T>
										</p>
									</SypacText>
									<ul className="list-disc marker:text-gray-40 flex gap-[68px] m-0 pl-4">
										{['dates', 'prices', 'companyDetails', 'weights'].map(
											(item) => (
												<li key={item}>
													<SypacText variant="body-regular-medium">
														<p className="text-sm text-gray-40">
															<T
																keyName={`modalPendingInvoiceConfirmation.${item}`}
															>
																{item.charAt(0).toUpperCase() + item.slice(1)}
															</T>
														</p>
													</SypacText>
												</li>
											),
										)}
									</ul>
								</div>
							</section>

							<div className="max-h-[calc(100%-100px)] h-full">
								<div className="flex flex-col justify-between h-full">
									<div className="flex flex-col items-center justify-center p-3 pb-0">
										<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

										<div className="w-full flex">
											<section className="w-full h-[698px] flex flex-col gap-4 py-3 overflow-x-hidden overflow-y-scroll">
												{localFiles.map((file, index) => (
													<div
														key={file?.name || Math.random() + index}
														className="flex"
													>
														{[FileType.JPG, FileType.PNG].includes(
															getFileType(file.fileUrl) as FileType,
														) ? (
															<div
																style={{
																	backgroundImage: `url(${file.fileUrl})`,
																}}
																className="relative w-full h-[741px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
																onClick={() => openFile(file.fileUrl)}
																onMouseEnter={() =>
																	handleBoxToggle(file.fileUrl)
																}
																onMouseLeave={() => handleBoxToggle(undefined)}
																role="img"
															>
																{showBlur === file.fileUrl && (
																	<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
																		<SypacIcon
																			size="custom"
																			width="32px"
																			height="32px"
																			icon-name="Magnifer"
																			className="text-white"
																		/>
																	</div>
																)}
															</div>
														) : (
															<div
																className="relative w-full h-[741px] flex justify-center items-center border border-solid border-gray-10 bg-white rounded-lg overflow-hidden cursor-pointer"
																onClick={() => openFile(file.fileUrl)}
																onMouseEnter={() =>
																	handleBoxToggle(file.fileUrl)
																}
																onMouseLeave={() => handleBoxToggle(undefined)}
															>
																<Document
																	file={file.fileUrl}
																	renderMode="canvas"
																	className="w-[604px] flex justify-center items-center"
																>
																	{Array.from({ length: 1 }, (_, i) => (
																		<Page
																			key={`page_${i + 1}`}
																			pageNumber={i + 1}
																			{...PageProps}
																			children={null}
																		/>
																	))}
																</Document>
																{showBlur === file.fileUrl && (
																	<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
																		<SypacIcon
																			size="custom"
																			width="32px"
																			height="32px"
																			icon-name="Magnifer"
																			className="text-white"
																		/>
																	</div>
																)}
															</div>
														)}
													</div>
												))}
											</section>
											<div className="border-0 border-r border-solid border-gray-10 mr-3" />

											<aside className="w-full flex flex-col gap-3 py-3">
												<div className="w-full flex flex-col gap-5">
													<div className="flex gap-5">
														<div className="w-[58px] h-[58px] flex items-center justify-center rounded-10 bg-gray-10">
															<SypacText variant="heading-5">
																<p className="text-gray-90 mt-1">
																	{invoice?.invoiceLines[0]?.assigneeUser.company
																		?.name!.split(' ')
																		.map((name) => name[0])
																		.join('')
																		.toUpperCase()
																		.slice(0, 2)}
																</p>
															</SypacText>
														</div>

														<div className="flex flex-col gap-2.5">
															<SypacText variant="heading-5">
																<p className="text-gray-90">
																	{
																		invoice?.invoiceLines[0].assigneeUser
																			.company?.name
																	}
																</p>
															</SypacText>
															<div className="flex items-center gap-[10px]">
																<Flag
																	className="object-cover rounded z-10 border border-solid border-gray-10"
																	code={company?.countryCode.toUpperCase()}
																	width={22}
																	height={16}
																/>

																<SypacText variant="body-normal-medium">
																	<p className="text-sm text-gray-80 mt-[2px]">
																		{company?.registryAddress}
																	</p>
																</SypacText>
															</div>
														</div>
													</div>

													<div className="bg-alabaster rounded-10">
														<div className="flex flex-col justify-center dashed-box p-3 gap-5">
															<div>
																<SypacText variant="body-regular-small">
																	<p className="text-gray-80">
																		FAKTURA NR/Invoice no {invoice?.invoiceNr}
																	</p>
																</SypacText>
															</div>

															<>
																<div className="flex bg-white">
																	<InvoiceTable
																		columns={
																			actionFor === 'producer'
																				? COLUMNS_PRODUCER
																				: COLUMNS_CARRIER
																		}
																		data={tableData}
																	/>
																</div>
																<div className="text-end">
																	<SypacText variant="body-regular-small">
																		<p className="text-gray-80">
																			Amount to be paid:{' '}
																			<NumericFormat
																				type="text"
																				className="font-bold"
																				displayType="text"
																				thousandSeparator=" "
																				decimalSeparator="."
																				value={parseFloat(
																					invoice?.balanceDue?.toFixed(2) ||
																						'0',
																				)}
																				suffix={
																					invoice?.countryCode === 'MD'
																						? ' MDL'
																						: ' PLN'
																				}
																			/>
																		</p>
																	</SypacText>
																</div>
															</>
														</div>
													</div>
												</div>

												<div className="grid grid-cols-2 gap-3 w-full">
													<div className="flex bg-alabaster border border-dashed border-gray-10 rounded-10 p-[10px]">
														<SypacInput
															error={
																!!(formik.touched.date && formik.errors.date)
															}
															className="w-full"
														>
															<SypacText
																className="mb-1"
																variant="overline-normal-large"
															>
																<p>
																	<T keyName="modalPendingInvoiceConfirmation.paymentDueDate">
																		Payment due date
																	</T>{' '}
																	<span className="text-red">*</span>
																</p>
															</SypacText>

															<SypacDatepicker
																name="date"
																placeholder={t(
																	'modalPendingInvoiceConfirmation.enterDate',
																	'Enter date',
																)}
																min="1950-01-01"
																max={dayjs().toString()}
																value={
																	formik?.values.date ? formik?.values.date : ''
																}
																inputReadOnly={true}
																onCallback={({ detail }) => onCallback(detail)}
																locale={language}
															/>
															{formik.touched.date && formik.errors.date ? (
																<span className="bottom-helper text-red text-sm">
																	{formik.errors.date}
																</span>
															) : null}
														</SypacInput>
													</div>

													<div className="flex bg-alabaster border border-dashed border-gray-10 rounded-10 p-[10px]">
														<div className="w-full">
															<SypacText
																className="mb-1"
																variant="overline-normal-large"
															>
																<p>
																	<T keyName="modalPendingInvoiceConfirmation.price">
																		Price
																	</T>{' '}
																	<span className="text-red">*</span>
																</p>
															</SypacText>

															<div className="flex flex-col">
																<SypacInput
																	error={!!errorAmount}
																	className={`relative flex border border-solid transition rounded-lg ${
																		!!errorAmount
																			? 'border-red-orange'
																			: 'border-gray-10 focus-within:border-cornflower-blue hover:border-gray-30 focus-within:hover:border-cornflower-blue'
																	}`}
																>
																	<input
																		className="border-0 py-[10px] pl-3 rounded-lg placeholder:text-gray-22 outline-none"
																		type="text"
																		name="amount"
																		placeholder={t(
																			'modalPendingInvoiceConfirmation.enterPriceAmount',
																			'Enter price amount',
																		)}
																		value={displayAmount.replace(/\s/g, '')}
																		onChange={(event) => {
																			const cleanedValue =
																				event.target.value.replace(/\s/g, '');
																			setPrice(cleanedValue);
																		}}
																	/>

																	<div className="absolute right-0 flex h-[40px] px-[10px] justify-center items-center bg-white border-0 border-solid border-l-[1px] border-gray-10 rounded-r-lg">
																		<SypacText variant="overline-normal-large">
																			<p className="text-base text-gray-80 mt-[2px]">
																				{invoice?.countryCode === 'MD'
																					? 'MDL'
																					: 'PLN'}
																			</p>
																		</SypacText>
																	</div>
																</SypacInput>
																{errorAmount ? (
																	<span className="text-xs text-red-orange mt-[2px]">
																		<T keyName="selectProduct.errorPricePerUnit">
																			{formik.errors.amount}
																		</T>
																	</span>
																) : null}
															</div>
														</div>
													</div>
												</div>
											</aside>
										</div>
									</div>

									<footer className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 rounded-tl-10 rounded-tr-10 box-border">
										<SypacButton
											variant="secondary"
											size="small"
											className="w-full"
										>
											<button
												type="button"
												className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
												onClick={onReject}
											>
												<SypacText variant="body-regular-medium">
													<p>
														<T keyName="modalPendingInvoiceConfirmation.reject">
															Reject
														</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
										<SypacButton
											variant="secondary"
											size="small"
											className="w-full"
										>
											<button
												type="submit"
												className="w-full h-[44px] flex items-center justify-center border-0 bg-primary-violet rounded-lg hover:bg-primary-violet/80"
											>
												<SypacText variant="body-regular-medium">
													<p className="text-white">
														<T keyName="modalDeposit.confirm">Confirm</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
									</footer>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			{openDocView ? (
				<DocumentsViewer
					url={openDocUrl!}
					isPdf={
						getFileType(
							localFiles.find((file) => file.fileUrl === openDocUrl)?.fileUrl!,
						) === FileType.PDF
					}
					isOpen={openDocView}
					onClose={() => setOpenDocView(false)}
					canDownload={true}
				/>
			) : null}
		</>
	) : null;
};

export default ModalPendingInvoiceConfirmation;
