import React, { useMemo } from 'react';
import { SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import Wallet from '../../../assets/Wallet';
import { NoResultsProps } from '../interfaces/InvoiceLineTable.interface';

const NoResults: React.FC<NoResultsProps> = ({ currentTab }) => {
	const emptyText = useMemo(() => {
		const tabTextMap = {
			all: 'noResultFound',
			pending_invoice: 'noPendingInvoicesAtTheMoment',
			uploaded_invoice: 'noPendingInvoiceConfirmationsAtTheMoment',
			pending_invoice_confirmed: 'noPendingPaymentsAtTheMoment',
			overdue: 'noOrdersFlaggedForPaymentDisputes',
			paid: 'yourAccountHasNoPaidOrdersCurrently',
		};
		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab]);
	const emptySubText = useMemo(() => {
		const tabTextMap = {
			all: 'noResultFoundDescription',
			pending: 'noPendingPaymentsDescription',
		} as const;

		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab]);

	return (
		<div className="h-full flex flex-col gap-[60px] justify-center items-center">
			<Wallet />

			<div className="flex flex-col gap-6 items-center">
				<SypacText variant="body-regular-medium">
					<p className="text-xl leading-[26px] text-gray-80">
						<T keyName={`invoiceLinesTable.${emptyText}`}>{emptyText}</T>
					</p>
				</SypacText>
				{emptySubText ? (
					<SypacText variant="body-regular-medium">
						<p
							className="w-[340px] text-sm text-gray-40 text-center"
							style={{
								whiteSpace: 'break-spaces',
							}}
						>
							<T keyName={`invoiceLinesTable.${emptySubText}`}>
								{emptySubText}
							</T>
						</p>
					</SypacText>
				) : null}
			</div>
		</div>
	);
};

export default NoResults;
