import React, {
	useState,
	DragEvent,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import Modal from 'react-modal';
import { ModalUploadDocumentsProps } from './ModalUploadDocuments.interface';
import {
	SypacButton,
	SypacDropdown,
	SypacText,
} from '@sypac/component-library-react';
import CircleClose from '../../assets/CircleClose';
import CircleValid from '../../assets/CircleValid';
import PreviewDocument from '../../assets/PreviewDocument';
import ModalDeleteDocument from '../ModalDeleteDocument/ModalDeleteDocument';
import { FileInterface } from '../CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { T, useTranslate } from '@tolgee/react';
import LoadingBar from '../Loaders/LoadingBar';
import Close from '../../assets/Close';

export const DOCUMENT_TYPES = [
	{ value: 'Registration certificate', label: 'Registration certificate' },
	{ value: 'Power of attorney', label: 'Power of attorney' },
	{ value: 'Identification card', label: 'Identification card' },
	{ value: 'Passport', label: 'Passport' },
];

const ModalUploadDocuments: React.FC<ModalUploadDocumentsProps> = ({
	isOpen,
	onClose,
	onSave,
	files,
	docsLoading,
	saveLocalDocuments,
	updateType,
}) => {
	const { t } = useTranslate();
	const [type, setType] = useState<string>();
	const [isSave, setSave] = useState<boolean>(false);
	const [deleteIndex, setDeleteIndex] = useState<number>();
	const [openDelete, setDelete] = useState<boolean>(false);
	const [deletedFiles, setDeletedFiles] = useState<FileInterface[]>([]);
	const [localFiles, setLocalFiles] = useState<FileInterface[]>([]);

	const previousTypeRef = useRef<string | undefined>(undefined);

	const handleSave = () => {
		if (type !== previousTypeRef.current) {
			previousTypeRef.current = type;
			updateType(localFiles.map((r) => ({ ...r, type })));
		}
		saveLocalDocuments();
		setLocalFiles([]);
		setSave(false);
		setDeleteIndex(undefined);
		setType(undefined);
	};

	const handleClose = () => {
		setLocalFiles([]);
		setSave(false);
		setType(undefined);
		onClose();
	};

	const getDataFiles = async (files: File[]) => {
		const dataFiles = Array.from(files).map((file) => {
			const [name, mimeType = ''] = file.name.split('.');
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;
			return { type, name, mimeType: mimeType.toUpperCase(), size, file };
		});
		setLocalFiles(dataFiles);
		onSave(
			dataFiles.map((r) => ({ ...r, type })),
			deletedFiles,
		);
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFiles = Array.from(e.dataTransfer.files);
			getDataFiles(droppedFiles).then(() => {});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFiles = Array.from(e.target.files);
			getDataFiles(selectedFiles).then(() => {});
		}
	};

	const closeDeleteModal = () => {
		setDeleteIndex(undefined);
		setDelete(false);
	};

	const checkDeleteFile = (index: number) => {
		setDeleteIndex(index);
		setDelete(true);
	};

	const deleteFile = () => {
		const dataFiles = [...localFiles];
		const file = dataFiles.splice(deleteIndex!, 1)[0];
		setLocalFiles(dataFiles);
		if (!dataFiles.length) setSave(false);
		if (file?.id) setDeletedFiles([...deletedFiles, file]);
		setDeleteIndex(undefined);
		setDelete(false);
	};

	const handleDropdownChange = useCallback(
		(e: any) => setType(e?.detail?.value),
		[],
	);

	useEffect(() => {
		if (files?.length) {
			setType(files[0].type);
			setLocalFiles(files);
		}
		setSave(!!files?.length);
	}, [files]);

	useEffect(() => {
		if (!docsLoading) setSave(true);
	}, [docsLoading]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				className="modal-inside outline-none w-[358px]"
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={false}
			>
				<header className="flex justify-between items-center px-6 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="uploadDocuments.uploadCompanyDocuments">
								Upload company documents
							</T>
						</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</header>

				<section className="flex flex-col items-start justify-center upload-docs-top-border p-6 gap-4">
					<div className="w-full">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-80">
								<T keyName="uploadDocuments.selectFileType">Select file type</T>
							</p>
						</SypacText>
						<SypacDropdown
							name="type"
							placeholder={t(
								'uploadDocuments.enter',
								'Company registry certificate',
							)}
							callback={handleDropdownChange}
							className="w-full mt-1"
						>
							{DOCUMENT_TYPES.map((option) => {
								return (
									// @ts-ignore
									<sypac-dropdown-option
										key={option.value}
										value={option.value}
										label={option.label}
										selected={option.value === type}
									/>
								);
							})}
						</SypacDropdown>
					</div>

					<div className="w-full">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-80">
								{isSave
									? t('uploadDocuments.uploadedDocuments', 'Uploaded documents')
									: t('uploadDocuments.uploadDocument', 'Upload document')}
							</p>
						</SypacText>

						{isSave ? (
							<ul className="w-full p-0 m-0">
								{localFiles.map((file, index) => (
									<li key={file?.name || index} className="flex gap-4 mt-4">
										<PreviewDocument />
										<div>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">{file.name}</p>
											</SypacText>
											<div className="flex items-center mt-1">
												<SypacText variant="overline-regular-large">
													<p className="text-gray-20">{file.mimeType}</p>
												</SypacText>
												<span className="bg-gray-20 w-1 h-1 rounded-full mx-2" />
												<SypacText variant="overline-regular-large">
													<p className="text-gray-20">{file.size}</p>
												</SypacText>
											</div>
											<SypacText
												variant="overline-normal-large"
												className="mt-3 cursor-pointer"
												onClick={() => checkDeleteFile(index)}
											>
												<p className="text-red">
													<T keyName="uploadDocuments.deleteFile">
														Delete file
													</T>
												</p>
											</SypacText>
										</div>
									</li>
								))}
							</ul>
						) : docsLoading ? (
							<LoadingBar
								isLoading={docsLoading}
								className="flex items-center justify-center p-3"
							/>
						) : (
							<div
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}
								className="w-full h-[96px] border border-dashed border-gray-90 rounded-10 mt-4"
							>
								<input
									type="file"
									id="input-file-upload"
									className="hidden"
									multiple
									accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
									onChange={handleChange}
								/>
								<label
									htmlFor="input-file-upload"
									className="h-full flex flex-col justify-center items-center cursor-pointer"
								>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T keyName="uploadDocuments.dragAndDrop">
												Drag and drop or
											</T>{' '}
											<span className="text-cornflower-blue cursor-pointer">
												<T keyName="uploadDocuments.browseFile">Browse File</T>
											</span>
										</p>
									</SypacText>
									<SypacText variant="overline-regular-medium" className="mt-3">
										<p className="text-gray-40">
											<T keyName="uploadDocuments.noteSupportedFiles">
												Note: We support JPG, PDF or PNG files.
											</T>
										</p>
									</SypacText>
								</label>
							</div>
						)}
					</div>
				</section>

				{isSave && (
					<footer className="flex justify-center p-6 upload-docs-top-border">
						<div className="flex gap-x-4">
							<SypacButton variant="secondary" size="small">
								<button
									type="button"
									className="px-8 py-2.5 border-red flex items-center"
									onClick={handleClose}
								>
									<CircleClose />
									<SypacText variant="body-regular-medium" className="ml-3">
										<p className="text-red">
											<T keyName="uploadDocuments.cancel">Cancel</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton variant="secondary" size="small">
								<button
									type="button"
									className="px-8 py-2.5 border-mountain-meadow flex items-center"
									onClick={handleSave}
									disabled={!(type && localFiles.length)}
								>
									<CircleValid />
									<SypacText variant="body-regular-medium" className="ml-3">
										<p className="text-mountain-meadow">
											<T keyName="uploadDocuments.save">Save</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</footer>
				)}
			</Modal>
			<ModalDeleteDocument
				isOpen={openDelete}
				onClose={closeDeleteModal}
				onSubmit={deleteFile}
			/>
		</>
	);
};

export default ModalUploadDocuments;
