import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { useGetCategories } from '../../../hooks/use-get-categories';
import '../select-product.scss';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import QuantityPicker from '../../../components/QuantityPicker/QuantityPicker';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { COUNTERS } from '../constants';
import {
	ProductResponse,
	ProductService,
} from '../../../services/product.services';
import { T } from '@tolgee/react';
import ClientStepper from '../../../components/ClientStepper/ClientStepper';
import ClientOrderSummary from '../../../components/ClientOrderSummary/ClientOrderSummary';
import { OrdersService, Prices } from '../../../services/orders.services';
import { GeoService } from '../../../services/geo.services';
import ClientStoreBreadcrumbs from '../../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';
import { CompanyService } from '../../../services/company.services';
import { toastVariant } from '../../../components/ToastVariant/toastVariant';

const ClientSelectQuantity: React.FC = () => {
	const navigate = useNavigate();
	const { order, setOrder } = useContext(OrderContext);
	const { totalQuantityCounter, inputQuantity } = useContext(
		QuantityPickerContext,
	);
	const { groupId, categoryId, productId } = useParams();
	const [userCountry, setUserCountry] = useState<string>();
	const [categories] = useGetCategories({
		ids: [categoryId!],
		countryCode: userCountry,
	});
	const [groups] = useGetCategories({
		countryCode: userCountry,
	});
	const [products, setProducts] = useState<ProductResponse>({
		items: [],
		count: 0,
	});
	const [quantity, setQuantity] = useState(0);
	const [prices, setPrices] = useState<Prices>({
		productPriceForClient: 0,
		productPriceForClientWithVat: 0,
		transportPriceForClient: 0,
		transportPriceForClientWithVat: 0,
	});

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setUserCountry(
				data.countryCode === 'MD'
					? 'MDA'
					: data.countryCode === 'PL'
					? 'POL'
					: '',
			);
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const getProducts = useCallback(async () => {
		try {
			const resp = await ProductService.getList({
				status: 'all',
				categoryId,
				limit: 100,
				offset: 0,
				role: 'customer',
			});
			setProducts(resp.data);
		} catch (e) {}
	}, [categoryId]);

	useEffect(() => {
		getProducts().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId]);

	useEffect(() => {
		if (totalQuantityCounter?.length) {
			const result = totalQuantityCounter?.reduce(
				(total, item) => total + item.payload * item.quantity,
				0,
			);
			setQuantity(result);
		} else {
			setQuantity(0);
		}
	}, [totalQuantityCounter]);

	useEffect(() => {
		if (inputQuantity) {
			setQuantity(inputQuantity);
		}
	}, [inputQuantity]);

	const currentProduct = useMemo(() => {
		return products?.items?.find((item) => item.id === +productId!);
	}, [products, productId]);
	const currentCategory = useMemo(() => {
		return categories?.items?.find((item) => item.id === categoryId);
	}, [categories, categoryId]);
	const currentGroup = useMemo(() => {
		return groups?.items?.find((item) => item.id === groupId);
	}, [groups, groupId]);

	const getPrices = useCallback(async () => {
		try {
			if (!currentProduct?.id) {
				return;
			}
			let quantity = 0;
			let trucksNr = 1;
			if (totalQuantityCounter?.length) {
				quantity = totalQuantityCounter?.reduce(
					(total, item) => total + item.payload * item.quantity,
					0,
				);
				trucksNr = totalQuantityCounter?.reduce(
					(total, item) => total + item.quantity,
					0,
				);
			}
			if (inputQuantity) {
				quantity = inputQuantity;
			}
			let latDelivery = 0;
			let longDelivery = 0;
			if (order?.location?.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: order?.location?.locationId,
				});
				latDelivery = resp?.data?.Latitude;
				longDelivery = resp?.data?.Longitude;
			}
			const { data } = await OrdersService.calculatePrice({
				quantity,
				trucksNr,
				latPickup: currentProduct?.location?.coordinates?.length
					? currentProduct?.location?.coordinates[1]
					: 47.04014,
				longPickup: currentProduct?.location?.coordinates.length
					? currentProduct?.location?.coordinates[0]
					: 28.80058,
				latDelivery,
				longDelivery,
				productId: currentProduct?.id!,
			});
			setPrices(data);
		} catch (e) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentProduct?.id,
		inputQuantity,
		order?.location?.locationId,
		order?.product?.location?.coordinates,
		totalQuantityCounter,
	]);

	useEffect(() => {
		getPrices().then(() => {});
	}, [getPrices]);

	const next = () => {
		const newOrder = {
			...order,
			product: currentProduct,
			category: currentCategory,
			group: currentGroup,
		};
		setOrder({
			...newOrder,
		});
		navigate(
			`/store/groups/${groupId}/categories/${categoryId}/products/${productId}/date`,
		);
	};

	return (
		<div className="w-full flex flex-col gap-4 h-[calc(100vh-60px)] gap-x-2.5 overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			{/*<SearchBar
				placeholder={t(
					'clientSelectQuantity.searchPlacehoder',
					'Search Products',
				)}
			/>*/}

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center flex flex-col gap-4 pt-3.5 sm:pt-0 mb-1 xl-2xl:mb-4 px-3.5 sm:px-12 xl:ml-0">
				<ClientStoreBreadcrumbs
					currentCategory={currentCategory}
					currentGroup={currentGroup}
					currentProduct={currentProduct}
					categoryId={categoryId}
				/>
			</div>
			<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center xl-2xl:py-3 px-3.5 sm:px-12 xl:ml-0">
				<ClientStepper currentStep={1} />
			</div>
			<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

			<div className="sm:w-[calc(100vw-154px)] xl:w-[1133px] xl:self-center flex flex-col sm:flex-row gap-6 sm:justify-between mb-6 sm:mb-0 mt-2 xl-2xl:mt-3.5 sm:ml-12 sm:mr-7.5 xl:mx-0">
				<div className="w-[calc(100vw-26px)] sm:w-fit sm:max-w-[520px] flex-grow flex flex-col justify-between ml-3.5 sm:ml-0">
					<QuantityPicker
						counters={[...COUNTERS].sort((a, b) => b.payload - a.payload)}
						availableQuantity={currentProduct?.availableQuantity!}
						unlimited={currentProduct?.unlimited!}
					/>
					<nav className="hidden sm:flex gap-5 justify-center">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition"
								onClick={() => {
									navigate(-1);
								}}
							>
								<SypacIcon
									size="custom"
									width="10px"
									height="12px"
									icon-name="Alt Arrow Left"
								/>
								<SypacText variant="body-normal-medium">
									<p className="mt-[3px]">
										<T keyName="clientSelectQuantity.back">Back</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton className="w-full" variant="secondary" size="small">
							<button
								type="button"
								className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition"
								onClick={() => next()}
								disabled={quantity === 0}
							>
								<SypacText variant="body-normal-medium">
									<p className="mt-[3px]">
										<T keyName="clientSelectQuantity.next">Next</T>
									</p>
								</SypacText>
								<SypacIcon
									size="custom"
									width="10px"
									height="12px"
									icon-name="Alt Arrow Right"
								/>
							</button>
						</SypacButton>
					</nav>
				</div>

				<aside className="sm:w-[412px] xl:w-[460px] inline-table">
					<ClientOrderSummary
						currentProduct={currentProduct}
						prices={prices}
						quantity={quantity}
						step={1}
					/>
				</aside>

				<footer className="w-[calc(100vw-26px)] flex flex-col sm:hidden gap-5 justify-center ml-3.5">
					<SypacButton className="w-full" variant="secondary" size="small">
						<button
							type="button"
							className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition"
							onClick={() => next()}
							disabled={quantity === 0}
						>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.next">Next</T>
								</p>
							</SypacText>
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Right"
							/>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className="flex flex-row gap-2 px-6 py-[13px] rounded-lg w-full transition"
							onClick={() => {
								navigate(-1);
							}}
						>
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Left"
							/>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</footer>
			</div>
		</div>
	);
};

export default ClientSelectQuantity;
