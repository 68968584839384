import {
	Company,
	ProfileInterface,
	UsersInterface,
} from '../interfaces/Users.interface';
import API from '../axios/api';
import { PhoneInterface } from './company.services';
import Api from '../axios/api';

interface GetUsersResponse {
	data: {
		count: number;
		items: UsersInterface[];
	};
}

interface QueryParams {
	limit: number;
	skip: number;
	companyId?: string;
}

export interface AddUser {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	lang: string;
}

export interface UpdateProfile {
	firstName?: string;
	lastName?: string;
	email?: string;
	secondaryEmail?: string;
	phoneNumber?: PhoneInterface;
	secondaryPhoneNumber?: PhoneInterface;
	photoUrl?: string;
}

export interface MyProfile {
	company: Company;
	email: string;
	isAdmin: boolean;
	profile: ProfileInterface;
	uid: string;
}

export interface MyProfileResponse {
	data: MyProfile;
}

export class UsersServices {
	static getUsers(query?: QueryParams): Promise<GetUsersResponse> {
		return API.get('/v1/admin/users', {
			params: query,
		});
	}

	static changeUserStatus(action: string, uid: string): Promise<any> {
		return API.post(`/v1/admin/users/${uid}/${action}`);
	}

	static updateProfile(uid: string, body: UpdateProfile): Promise<void> {
		return API.patch(`/v1/admin/users/${uid}`, {
			...body,
		});
	}

	static getMyProfile(target: string): Promise<MyProfileResponse> {
		return Api.get(`/v1/${target}/users/my`);
	}

	static updateMyProfile(target: string, body: UpdateProfile): Promise<void> {
		return API.patch(`/v1/${target}/users/my`, {
			...body,
		});
	}

	static getCompanyUsers(target: string): Promise<{ data: UsersInterface[] }> {
		return Api.get(`/v1/${target}/users/company`);
	}

	static addNewUser(target: string, body: AddUser): Promise<void> {
		return Api.post(`/v1/${target}/users/add`, {
			...body,
		});
	}

	static editCompanyUser(
		target: string,
		id: string,
		body: UpdateProfile,
	): Promise<void> {
		return API.patch(`/v1/${target}/users/${id}`, {
			...body,
		});
	}

	static deleteCompanyUser(target: string, id: string): Promise<void> {
		return Api.delete(`/v1/${target}/users/${id}`);
	}
}
