import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import {
	PrintCodeProps,
	PrintCodeRef,
} from './ModalVerificationCode.interface';
import './modal-verification-code.css';
import { T } from '@tolgee/react';
import { useReactToPrint } from 'react-to-print';

const PrintCode = forwardRef<PrintCodeRef, PrintCodeProps>((props, ref) => {
	const { truck } = props;
	const printRef = useRef<HTMLDivElement | null>(null);
	const print = useReactToPrint({
		content: () => printRef.current,
		documentTitle: 'Truck code',
	});

	useImperativeHandle(ref, () => ({ print }), [print]);

	return (
		<div ref={printRef}>
			<main className="flex flex-col p-5">
				<div className="flex flex-col p-4 border border-solid border-gray-10 rounded-xl w-44">
					<div className="flex flex-row justify-center">
						<p className="text-gray-40 text-xs text-center font-medium m-0">
							<T keyName="modalViewQrCode.scan">
								Scan with your phone to login via the QR code.
							</T>
						</p>
					</div>
					<div className="mt-2 rounded-10 border border-solid border-gray-10">
						<div className="py-1 px-1 flex border-0 border-solid border-b border-gray-10 rounded-10 justify-center">
							<p className="text-gray-40 text-xs text-center font-medium m-0">
								<T keyName="modalViewQrCode.licensePlates">License plates:</T>{' '}
								{truck?.licensePlates}
							</p>
						</div>
						<div className="w-30 h-30">
							<img
								src={truck?.qrCodeUrl}
								alt={truck?.code}
								className="w-full object-cover"
							/>
						</div>
						<div className="py-1 px-1 flex border-0 border-solid border-t border-gray-10 rounded-10 justify-center">
							<p className="text-gray-90 text-base text-center font-medium m-0">
								<T keyName="modalViewQrCode.loginCode">Login code:</T>{' '}
								<span className="font-medium">{truck?.code}</span>
							</p>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
});

export default PrintCode;
