import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';

interface ClientInvoiceLinesStats {
	'all': string;
	'paid': string;
	'overdue': string;
	'pending': string;
	'pendingInvoice': string;
	'uploadedInvoice': string;
	'invoiceConfirmed': string;
}

interface Props {
	fromDate?: string;
	toDate?: string;
	target: string;
	refresh: number;
}

type UseGetInvoiceLinesStats = [
	ClientInvoiceLinesStats | undefined,
	Error | undefined,
	boolean,
];

export const useGetInvoiceLinesStats = ({
	target,
	toDate,
	fromDate,
	refresh = 0,
}: Props): UseGetInvoiceLinesStats => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/${target}/billings/stats-lines`, {
				params: {
					fromDate,
					toDate,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, target, toDate, fromDate, refresh]);

	return [data, error, isLoading];
};
