import React, { useState } from 'react';
import {
	SypacButton,
	SypacCheckbox,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import './modal-delete-product.css';
import { ModalDeleteProductProps } from './ModalDeleteProduct.interface';
import DeleteDocument from '../../assets/DeleteDocument';
import { T } from '@tolgee/react';

const ModalDeleteProduct = ({
	isOpen,
	onClose,
	onSubmit,
}: ModalDeleteProductProps) => {
	const [reason, setReason] = useState<string | undefined>();

	const handleSubmit = () => {
		onSubmit();
		onClose();
	};

	return isOpen ? (
		<div className="modal-overlay" onClick={() => onClose()}>
			<div
				className="modal-inside outline-none max-w-[475px]"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between px-6 py-4">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalDeleteProduct.confirmDelete">Confirm delete</T>
							</p>
						</SypacText>
						<SypacIcon
							iconName="Close Square"
							className="text-gray-10 ml-6 cursor-pointer"
							size="custom"
							width="32px"
							height="32px"
							onClick={onClose}
						/>
					</div>
					<div className="flex flex-row modal-delete-document-border px-6 py-6">
						<DeleteDocument />
						<div className="flex flex-col ml-10">
							<SypacText variant="heading-5">
								<p className="text-gray-80">
									<T keyName="modalDeleteProduct.deleteProduct">
										Delete product
									</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-small" className="mt-3">
								<p className="text-gray-40 text-left">
									<T keyName="modalDeleteProduct.sureDeleteProduct">
										Are you sure you want to delete this note?
									</T>
								</p>
							</SypacText>
						</div>
					</div>
					<div className="flex flex-col px-6 py-6">
						<div className="flex flex-row justify-start items-center">
							<SypacCheckbox size="md">
								<input
									className="cursor-pointer"
									name="first"
									id="first"
									checked={reason === 'first'}
									onChange={() => setReason('first')}
									type="radio"
								/>
							</SypacCheckbox>
							<label htmlFor="first" className="ml-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">Lorem ipsum dolor sit amet</p>
								</SypacText>
							</label>
						</div>

						<div className="flex flex-row justify-start items-center mt-7">
							<SypacCheckbox size="md">
								<input
									className="cursor-pointer"
									name="second"
									id="second"
									checked={reason === 'second'}
									onChange={() => setReason('second')}
									type="radio"
								/>
							</SypacCheckbox>
							<label htmlFor="second" className="ml-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">Consectetur adipiscing</p>
								</SypacText>
							</label>
						</div>

						<div className="flex flex-row justify-start items-center mt-7">
							<SypacCheckbox size="md">
								<input
									className="cursor-pointer"
									name="three"
									id="three"
									checked={reason === 'three'}
									onChange={() => setReason('three')}
									type="radio"
								/>
							</SypacCheckbox>
							<label htmlFor="three" className="ml-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">Elit, sed do</p>
								</SypacText>
							</label>
						</div>

						<div className="flex flex-row justify-start items-center mt-7">
							<SypacCheckbox size="md">
								<input
									className="cursor-pointer"
									name="four"
									id="four"
									checked={reason === 'four'}
									onChange={() => setReason('four')}
									type="radio"
								/>
							</SypacCheckbox>
							<label htmlFor="four" className="ml-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">Eiusmod tempor incididunt</p>
								</SypacText>
							</label>
						</div>

						<div className="flex flex-row justify-start items-center mt-7">
							<SypacCheckbox size="md">
								<input
									className="cursor-pointer"
									name="five"
									id="five"
									checked={reason === 'five'}
									onChange={() => setReason('five')}
									type="radio"
								/>
							</SypacCheckbox>
							<label htmlFor="five" className="ml-5">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">Other</p>
								</SypacText>
							</label>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-4 justify-center p-6">
						<SypacButton variant="secondary" size="small" onClick={onClose}>
							<button className="modal-delete-document-reject-button">
								<SypacText variant="body-regular-medium">
									<p className="text-red -tracking-[0.01em]">
										<T keyName="modalDeleteProduct.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" onClick={handleSubmit}>
							<button className="modal-delete-document-confirm-button">
								<SypacText variant="body-regular-medium">
									<p className="text-mountain-meadow -tracking-[0.01em]">
										<T keyName="modalDeleteProduct.confirm">Confirm</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default ModalDeleteProduct;
