import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import ReactInputVerificationCode from 'react-input-verification-code';
import {
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { ModalConfirmationCodeProps } from './ModalConfirmationCode.interface';
import './modal-confirmation-code.css';
import { toastVariant } from '../ToastVariant/toastVariant';
import { T, useTranslate } from '@tolgee/react';
import Close from '../../assets/Close';

const ModalConfirmationCode = ({
	isOpen,
	onClose,
	onSubmit,
}: ModalConfirmationCodeProps) => {
	const { t } = useTranslate();
	const [error, setError] = useState<boolean>(false);
	const [code, setCode] = useState<string>('');

	const inputRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			const inputElements = inputRef?.current?.querySelectorAll(
				'.ReactInputVerificationCode__item',
			);
			inputElements?.forEach((input, index) => {
				if (code[index] !== '0') {
					(input as HTMLElement).style.color = '#2F2F2F';
				} else {
					(input as HTMLElement).style.color = '#E8E8E8';
				}
			});
		}
	}, [code]);

	useEffect(() => {
		if (error) {
			toastVariant(
				t(
					'modalConfirmationCode.verificationCodeIncorrect',
					'Verification code incorrect. Double check your verification code and try again.',
				),
				true,
			);
		}
	}, [error, t]);

	useEffect(() => {
		if (code.length === 5) {
			setError(false);
		}
	}, [code]);

	const callSubmit = () => {
		if (code.length < 5) {
			setError(true);
			return;
		}
		setError(false);
		onSubmit && onSubmit();
	};

	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[563px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col">
				<div className="flex items-center justify-between px-3 py-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">Enter confirmation code</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>

				<div className="border-0 border-t border-solid border-gray-10" />

				<div className="flex flex-col items-center justify-center px-3 py-4 gap-4">
					<SypacText variant="body-regular-medium" className="self-start">
						<p className="text-gray-90">
							To complete the payment, enter the confirmation code in the field
							below.
						</p>
					</SypacText>

					<SypacBox className="flex flex-col items-center justify-center py-5 gap-6 bg-gray-10-opacity-50">
						<SypacText variant="heading-5">
							<p className="text-gray-80 font-semibold">Verification code</p>
						</SypacText>
						<div
							ref={inputRef}
							className={`confirmation-custom-styles ${
								error ? 'code-error' : ''
							}`}
						>
							<ReactInputVerificationCode
								value={code}
								length={5}
								autoFocus
								placeholder="0"
								onChange={setCode}
							/>
						</div>
					</SypacBox>

					<SypacText variant="body-regular-small">
						<p className="text-gray-40 text-left">
							The code consists of 5 digits and does not contain or spaces. Make
							sure to enter the code exactly as it was received.
						</p>
					</SypacText>
				</div>

				<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
					<SypacButton
						variant="secondary"
						size="small"
						onClick={onClose}
						className="w-full"
					>
						<button
							type="reset"
							className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalConfirmationCode.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>

					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className={`w-full h-[44px] flex items-center justify-center border-primary-violet rounded-lg hover:border-kimberly ${
								error ? 'opacity-50 pointer-events-none' : ''
							}`}
							onClick={callSubmit}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalConfirmationCode.nextStep">Next step</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ModalConfirmationCode;
