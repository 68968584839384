import React from 'react';
import { SypacTabs } from '@sypac/component-library-react';
import { ProductTab, ProductTabsProps } from './ProductTabs.interface';
import { TabsButton } from '../../../../../components/TabsButton/TabsButton';

const ProductTabs: React.FC<ProductTabsProps> = ({
	data,
	callback,
	activeTab,
	classNames,
}) => {
	return (
		<SypacTabs
			className={`flex w-fit p-[3px] gap-[4px] whitespace-nowrap ${classNames}`}
		>
			{data
				? data?.map(({ label, value, count }: ProductTab) => {
						return (
							<TabsButton
								key={value}
								label={label}
								buttonId={value!}
								onClick={() => callback(value ? value : label.toLowerCase())}
								isSelected={
									activeTab === value || activeTab === label.toLowerCase()
								}
								notification={+count}
								color="#E8E8E8"
								classNames={classNames}
							/>
						);
				  })
				: null}
		</SypacTabs>
	);
};

export default ProductTabs;
