import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	SypacAvatar,
	SypacBadge,
	SypacBox,
	SypacButton,
	SypacCheckbox,
	SypacDropdown,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';

import { WhatsUp } from '../../../../assets/WhatsUp';
import { Telegram } from '../../../../assets/Telegram';
import { Viber } from '../../../../assets/Viber';
import { CompanyContact, UserDetailsProps } from './UserDetails.interface';
import {
	colorsTarget,
	titlesTarget,
} from '../../../../constants/company-target.constants';
import { useGetCompanyById } from '../../../../hooks/use-get-company-by-id';
import { CompanyService } from '../../../../services/company.services';
import { T, useTranslate } from '@tolgee/react';
import LoadingSpinner from '../../../../assets/LoadingSpinner';

const ROLES = ['admin', 'transporter', 'producer', 'client'];

const UserDetails = ({
	companyId,
	role,
	onSubmit,
	onBack,
}: UserDetailsProps) => {
	const [company] = useGetCompanyById({
		id: companyId,
	});
	const { t } = useTranslate();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			firstName: company?.userOwner?.firstName,
			lastName: company?.userOwner?.lastName,
			role: role,
			primaryEmail: company?.userOwner?.email,
			secondaryEmail: company?.userOwner?.secondaryEmail,
			primaryPhoneNumber: company?.userOwner?.phoneNumber?.phoneNumber,
			secondaryPhoneNumber:
				company?.userOwner?.secondaryPhoneNumber?.phoneNumber,
			primaryIsViber: company?.userOwner?.phoneNumber?.isViber,
			primaryIsTelegram: company?.userOwner?.phoneNumber?.isTelegram,
			primaryIsWhatsUp: company?.userOwner?.phoneNumber?.isWhatsapp,
			secondaryIsViber: company?.userOwner?.secondaryPhoneNumber?.isViber,
			secondaryIsTelegram: company?.userOwner?.secondaryPhoneNumber?.isTelegram,
			secondaryIsWhatsUp: company?.userOwner?.secondaryPhoneNumber?.isWhatsapp,
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			setIsLoading(true);
			try {
				const userDetails: CompanyContact = {
					uid: company?.userOwner?.uid,
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.primaryEmail,
					secondaryEmail: values.secondaryEmail || undefined,
					phoneNumber: {
						phoneNumber: values.primaryPhoneNumber,
						isTelegram: values.primaryIsTelegram,
						isViber: values.primaryIsViber,
						isWhatsapp: values.primaryIsWhatsUp,
					},
					secondaryPhoneNumber: {
						phoneNumber: values.secondaryPhoneNumber,
						isTelegram: values.secondaryIsTelegram,
						isViber: values.secondaryIsViber,
						isWhatsapp: values.secondaryIsWhatsUp,
					},
				};

				await CompanyService.companyVerificationStep3(companyId, {
					...userDetails,
				});
				onSubmit();
			} catch (e) {
			} finally {
				setIsLoading(false);
			}
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required('First name number is required'),
			lastName: Yup.string().required('Last name is required'),
			primaryEmail: Yup.string()
				.email('Email address must be an email')
				.required('Email is required'),
			primaryPhoneNumber: Yup.string().required('Phone number is required'),
			secondaryEmail: Yup.string().email(
				'Secondary email address must be an email',
			),
		}),
	});

	return (
		<form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
			<div className="flex flex-col px-10 py-5 gap-6">
				<div className="flex justify-between items-center">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 -tracking-[0.01em]">
							<T keyName="userDetails.userDetails">User details:</T>
						</p>
					</SypacText>
				</div>
				<SypacBox
					hover={false}
					box-style="solid"
					box-color="primary"
					boxShadow="sm"
					className="p-2.5 bg-white box-border"
				>
					<div className="flex gap-5">
						<div className="flex gap-2.5 items-center">
							<SypacAvatar
								initials={
									formik.values?.firstName + ' ' + formik.values?.lastName
								}
								size="sm"
							>
								<img
									src=""
									alt={formik.values?.firstName + ' ' + formik.values?.lastName}
									className="w-full h-full object-cover rounded-full"
								/>
							</SypacAvatar>
							<SypacText variant="body-regular-small">
								<p className="text-gray-90">
									{formik.values?.firstName + ' ' + formik.values?.lastName}
								</p>
							</SypacText>
						</div>
						<div className="flex items-center">
							<SypacBadge color={colorsTarget[role]} size="large">
								<div className="px-4">
									<SypacText variant="overline-normal-large">
										<p>{titlesTarget[role]}</p>
									</SypacText>
								</div>
							</SypacBadge>
						</div>
					</div>
				</SypacBox>
				<div className="grid grid-cols-2 gap-4 company-information-editable-border-bottom pb-5">
					<SypacInput
						error={!!(formik.touched.firstName && formik.errors.firstName)}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="userDetails.firstName">First Name</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="firstName"
							type="text"
							placeholder={t('userDetails.enterFirstName', 'Enter First Name')}
							value={formik.values?.firstName}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik.touched.firstName && formik.errors.firstName ? (
							<span className="input-error">{formik.errors.firstName}</span>
						) : null}
					</SypacInput>
					<SypacInput
						error={!!(formik.touched.lastName && formik.errors.lastName)}
					>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="userDetails.lastName">Last Name</T>
						</label>
						<input
							className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
							name="lastName"
							type="text"
							placeholder={t('userDetails.enterLastName', 'Enter Last Name')}
							value={formik.values?.lastName}
							onChange={(event) => formik?.handleChange(event)}
						/>
						{formik.touched.lastName && formik.errors.lastName ? (
							<span className="input-error">{formik.errors.lastName}</span>
						) : null}
					</SypacInput>
					<SypacInput error={!!(formik.touched.role && formik.errors.role)}>
						<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
							<T keyName="userDetails.role">Role</T>
						</label>
						<SypacDropdown
							className="pointer-events-none"
							name="role"
							placeholder={t('userDetails.selectRole', 'Select role')}
							callback={(e) => formik.setFieldValue('role', e?.detail?.value)}
						>
							{ROLES?.map((item) => {
								return (
									// @ts-ignore
									<sypac-dropdown-option
										key={Math.random() + '-' + item}
										value={item}
										label={item.charAt(0).toUpperCase() + item.slice(1)}
										selected={item === role}
									/>
								);
							})}
						</SypacDropdown>
					</SypacInput>
				</div>
				<div className="flex flex-col gap-6">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 -tracking-[0.01em]">
							<T keyName="userDetails.contactInfoUser">Contact info (user)</T>
						</p>
					</SypacText>
					<div className="grid grid-cols-2 gap-x-4 gap-y-5">
						<SypacInput
							error={
								!!(formik.touched.primaryEmail && formik.errors.primaryEmail)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="userDetails.emailAddress">Email address</T>
							</label>
							<input
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="primaryEmail"
								type="text"
								placeholder={t(
									'userDetails.enterEmailAddress',
									'Enter Email address',
								)}
								value={formik.values?.primaryEmail}
								onChange={(event) => formik?.handleChange(event)}
							/>
							{formik.touched.primaryEmail && formik.errors.primaryEmail ? (
								<span className="input-error">
									{formik.errors.primaryEmail}
								</span>
							) : null}
						</SypacInput>
						<SypacInput
							error={
								!!(
									formik.touched.secondaryEmail && formik.errors.secondaryEmail
								)
							}
						>
							<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
								<T keyName="userDetails.secondaryEmailAddress">
									Secondary email address
								</T>
							</label>
							<input
								className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
								name="secondaryEmail"
								type="text"
								placeholder={t(
									'userDetails.enterSecondaryEmailAddress',
									'Enter Secondary email address',
								)}
								value={formik.values?.secondaryEmail}
								onChange={(event) => formik?.handleChange(event)}
							/>
							{formik.touched.secondaryEmail && formik.errors.secondaryEmail ? (
								<span className="input-error">
									{formik.errors.secondaryEmail}
								</span>
							) : null}
						</SypacInput>
						<div className="flex flex-col gap-4">
							<SypacInput
								error={
									!!(
										formik.touched.primaryPhoneNumber &&
										formik.errors.primaryPhoneNumber
									)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="userDetails.primaryPhoneNumber">
										Primary phone number
									</T>
								</label>
								<input
									className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
									name="primaryPhoneNumber"
									type="text"
									placeholder={t(
										'userDetails.enterPrimaryPhoneNumber',
										'Enter Primary Phone Number',
									)}
									value={formik.values?.primaryPhoneNumber}
									onChange={(event) => formik?.handleChange(event)}
								/>
								{formik.touched.primaryPhoneNumber &&
								formik.errors.primaryPhoneNumber ? (
									<span className="input-error">
										{formik.errors.primaryPhoneNumber}
									</span>
								) : null}
							</SypacInput>
							<div className="flex justify-end gap-[14px]">
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="primaryIsWhatsUp"
											checked={formik.values?.primaryIsWhatsUp}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<WhatsUp />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="primaryIsTelegram"
											checked={formik.values?.primaryIsTelegram}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Telegram />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="primaryIsViber"
											checked={formik.values?.primaryIsViber}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Viber />
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-4">
							<SypacInput
								error={
									!!(
										formik.touched.secondaryPhoneNumber &&
										formik.errors.secondaryPhoneNumber
									)
								}
							>
								<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em]">
									<T keyName="userDetails.secondaryPhoneNumber">
										Secondary phone number
									</T>
								</label>
								<input
									className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
									name="secondaryPhoneNumber"
									type="text"
									placeholder={t(
										'userDetails.enterSecondaryPhoneNumber',
										'Enter Secondary Phone Number',
									)}
									value={formik.values?.secondaryPhoneNumber}
									onChange={(event) => formik?.handleChange(event)}
								/>
							</SypacInput>
							<div className="flex justify-end gap-[14px]">
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="secondaryIsWhatsUp"
											checked={formik.values?.secondaryIsWhatsUp}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<WhatsUp />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="secondaryIsTelegram"
											checked={formik.values?.secondaryIsTelegram}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Telegram />
								</div>
								<div className="flex gap-1.5">
									<SypacCheckbox size="md">
										<input
											name="secondaryIsViber"
											checked={formik.values?.secondaryIsViber}
											onChange={(event) => formik?.handleChange(event)}
											type="checkbox"
										/>
									</SypacCheckbox>
									<Viber />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex justify-center w-full py-5 gap-6 border-0 border-t border-solid border-gray-10">
				<SypacButton variant="secondary" size="small">
					<button className="px-6 py-3" onClick={onBack}>
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="userDetails.back">Back</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
				<SypacButton variant="primary" size="small">
					<button className="px-6 py-3" type="submit">
						<SypacText
							variant="body-regular-medium"
							className="flex items-center gap-4"
						>
							<p>
								<T keyName="userDetails.nextStep">Next step</T>
							</p>
							{isLoading ? <LoadingSpinner /> : null}
						</SypacText>
					</button>
				</SypacButton>
			</div>
		</form>
	);
};

export default UserDetails;
