import React, { useCallback } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
	SypacButton,
	SypacDatepicker,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';

import { ModalFilterDateProps } from './ModalFilterDate.interface';
import './modal-filter-date.css';
import { T, useTranslate } from '@tolgee/react';

const ModalFilterDate = ({
	isOpen,
	onClose,
	onSuccess,
}: ModalFilterDateProps) => {
	const { t } = useTranslate();
	const language = localStorage.getItem('lang') || 'en';
	const formik = useFormik({
		initialValues: {
			dateFrom: '',
			dateTo: '',
		},
		onSubmit: (values) => {
			onSuccess(values.dateFrom, values.dateTo);
		},
	});

	const callback = useCallback(
		(field: string, selectedDate: string) => {
			formik.setFieldValue(field, dayjs(selectedDate).toISOString());
		},
		[formik],
	);

	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<form onSubmit={formik.handleSubmit}>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between p-5">
						<SypacText variant="heading-5">
							<p className="text-gray-80 font-semibold">
								<T keyName="modalFilterDate.filterByDate">Filter by date</T>
							</p>
						</SypacText>
						<SypacIcon
							iconName="Close Square"
							className="text-gray-10 ml-6 cursor-pointer"
							size="custom"
							width="32px"
							height="32px"
							onClick={() => {
								formik.resetForm();
								return onClose ? onClose() : null;
							}}
						/>
					</div>
					<div className="modal-filter-date-border p-5">
						<div className="grid grid-cols-2 gap-5">
							<SypacInput
								error={!!(formik.touched.dateFrom && formik.errors.dateFrom)}
							>
								<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
									<T keyName="modalFilterDate.dateFrom">Date from</T>
									<span className="text-red">*</span>
								</label>
								<SypacDatepicker
									name="dateFrom"
									placeholder={t('modalFilterDate.enterDate', 'Enter date')}
									min="1950-01-01"
									max={dayjs().toString()}
									value={formik?.values.dateFrom ? formik?.values.dateFrom : ''}
									onCallback={({ detail }) => callback('dateFrom', detail)}
									inputReadOnly={true}
									locale={language}
								/>
								{formik.touched.dateFrom && formik.errors.dateFrom ? (
									<span className="bottom-helper">
										{formik.errors.dateFrom}
									</span>
								) : null}
							</SypacInput>
							<SypacInput
								error={!!(formik.touched.dateTo && formik.errors.dateTo)}
							>
								<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
									<T keyName="modalFilterDate.dateTo">Date to</T>
									<span className="text-red">*</span>
								</label>
								<SypacDatepicker
									name="dateTo"
									placeholder={t('modalFilterDate.enterDate', 'Enter date')}
									min="1950-01-01"
									max={dayjs().toString()}
									value={formik?.values.dateTo ? formik?.values.dateTo : ''}
									onCallback={({ detail }) => callback('dateTo', detail)}
									inputReadOnly={true}
									locale={language}
								/>
								{formik.touched.dateTo && formik.errors.dateTo ? (
									<span className="bottom-helper">{formik.errors.dateTo}</span>
								) : null}
							</SypacInput>
						</div>
					</div>
					<div className="flex justify-center w-full py-5 gap-6">
						<SypacButton
							variant="secondary"
							size="small"
							onClick={() => {
								formik.resetForm();
								return onClose ? onClose() : null;
							}}
						>
							<button className="px-10 py-2.5 hover:border-gray-2" type="reset">
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="modalFilterDate.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small">
							<button
								className="px-15 py-2.5 border-primary-violet hover:border-kimberly"
								type="submit"
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="modalFilterDate.confirm">Confirm</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ModalFilterDate;
