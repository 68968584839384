import { useEffect, useState } from 'react';

import { ProductsResponse } from '../services/products.service';
import { useApiFacade } from './use-api-facade';

type UseGetProducts = [
	ProductsResponse | undefined,
	Error | undefined,
	boolean,
];

interface QueryParams {
	limit: number;
	offset: number;
	language?: string;
	search?: string;
	categoryId?: string;
	ids?: string[];
	coordinates?: number[];
	range?: number;
	sortBy?: string;
	sortDirection?: string;
	verifiedBy?: string;
	status?: 'pending' | 'approved' | 'rejected';
	companyId?: number;
	countryCode?: string;
}

export const useGetProducts = (
	status: string = 'all',
	categoryId: string | undefined,
	limit: number = 100,
	offset: number = 0,
	role = 'customer',
	countryCode: string,
	coordinates?: number[],
): UseGetProducts => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	const finishLoading = () => {
		setTimeout(() => {
			setIsLoading(false);
		}, 200);
	};

	useEffect(() => {
		if (!countryCode) {
			setData({ count: 0, items: [] });
			return;
		}
		// if (coordinates && coordinates.length === 0) {
		// 	setData({ count: 0, items: [] });
		// 	return;
		// }
		if (['all', 'approved'].indexOf(status) === -1) {
			setData({
				items: [],
				count: 0,
			});
			return;
		}
		let params: QueryParams = {
			limit,
			offset,
			language: localStorage.getItem('lang') || 'pl',
			countryCode,
		};
		if (categoryId) {
			params = {
				...params,
				categoryId,
			};
		}
		// if (coordinates?.length) {
		// 	params = {
		// 		...params,
		// 		coordinates,
		// 		range: 100,
		// 	};
		// }
		if (role === 'customer') {
			params = { ...params, sortBy: 'pricePerUnit', sortDirection: 'ASC' };
		}
		setIsLoading(true);
		facade.client
			.get(`/v1/${role}/products`, {
				params,
			})
			.then((response) => {
				setData(response.data);
				finishLoading();
			})
			.catch((err) => {
				setError(err);
				finishLoading();
			});
	}, [
		status,
		facade,
		limit,
		offset,
		role,
		categoryId,
		countryCode,
		coordinates,
	]);

	return [data, error, isLoading];
};
