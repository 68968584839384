import React from 'react';
import { DumpTruck } from '../../assets/DumpTruck';
import { Hooklifter } from '../../assets/Hooklifter';
import { SemiTrailer } from '../../assets/SemiTrailer';

interface TruckIconProps {
	type: string;
}

const TruckIcon: React.FC<TruckIconProps> = ({ type }) => {
	switch (type) {
		case 'aluminium':
			return <DumpTruck />;
		case 'steel':
			return <SemiTrailer />;
		case 'hooklifter':
			return <Hooklifter />;
		default:
			return null;
	}
};

export default TruckIcon;
