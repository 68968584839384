import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/context';
import TermsAndConditionsFirstStep from '../../components/TermsAndConditions/FirstdStep';
import TermsAndConditionsSecondStep from '../../components/TermsAndConditions/SecondStep';
import { AuthsService } from '../../services/auths.service';
import { memorizedRefreshToken } from '../../services/refreshToken';
import { toastVariant } from '../../components/ToastVariant/toastVariant';
import { useTranslate } from '@tolgee/react';

interface GuardProps {
	children: ReactElement<any, any>;
}

const TransporterGuardedRoute: React.FC<GuardProps> = ({ children }) => {
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);

	if (!user?.role) {
		window.location.replace(`${process.env.REACT_APP_LANDING_URL}/en/login`);
	}

	if (!user?.company?.industries?.includes('transporter')) {
		window.location.replace(`${process.env.REACT_APP_LANDING_URL}/en/login`);
	}

	useEffect(() => {
		const acceptTerms = localStorage.getItem('terms_and_conditions') === 'true';
		setAcceptedTerms(!acceptTerms);
	}, []);

	const nextStep = () => {
		setAcceptedTerms(false);
		setTimeout(() => {
			setAcceptTerms(true);
		}, 100);
	};

	const finish = async () => {
		try {
			await AuthsService.acceptTermsAndConditions();
			await memorizedRefreshToken();
			localStorage.setItem('terms_and_conditions', 'true');
			setAcceptTerms(false);
		} catch (e) {
			toastVariant(
				t(
					`pleaseTryAgainLater`,
					'Something went wrong, please try again later',
				),
				true,
			);
		}
	};

	return (
		<>
			{children}
			{acceptedTerms ? (
				<TermsAndConditionsFirstStep
					isOpen={acceptedTerms}
					nextStep={nextStep}
				/>
			) : null}
			{acceptTerms ? (
				<TermsAndConditionsSecondStep
					isOpen={acceptTerms}
					finish={finish}
					target="transporter"
				/>
			) : null}
		</>
	);
};

export default TransporterGuardedRoute;
