import { StylesConfig } from 'react-select';

export const colourErrorStyles: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		':focus': { borderColor: 'rgb(86, 130, 250)' },
		':hover': { borderColor: 'rgb(172, 176, 181)' },
		':focus-visible': { borderColor: 'rgb(86, 130, 250)' },
		':focus-within': { borderColor: 'rgb(86, 130, 250)' },
		boxShadow: 'none',
		borderColor: 'rgb(247, 72, 39)',
		padding: '0px 2px',
		height: '44px',
		borderRadius: '8px',
	}),
};

export const colourStyles: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		':focus': { borderColor: 'rgb(86, 130, 250)' },
		':hover': { borderColor: 'rgb(172, 176, 181)' },
		':focus-visible': { borderColor: 'rgb(86, 130, 250)' },
		':focus-within': { borderColor: 'rgb(86, 130, 250)' },
		boxShadow: 'none',
		borderColor: 'rgb(172, 176, 181)',
		padding: '7px 12px',
		maxHeight: '41px',
		borderRadius: '6px',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	valueContainer: (styles) => ({
		...styles,
		maxHeight: '24px',
		padding: 0,
	}),
	singleValue: (styles) => ({
		...styles,
		lineHeight: '20px',
		fontSize: '14px',
		color: 'rgb(34, 34, 52)',
	}),
	menu: (styles) => ({
		...styles,
		border: 'none',
		backgroundColor: '#ffffff',
		boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
		borderRadius: '8px',
	}),
	menuList: (styles) => ({
		...styles,
		position: 'relative',
		zIndex: 2000,
		width: '100%',
	}),
	placeholder: (styles) => ({
		...styles,
		color: '#d3d4d5',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '20px',
	}),
	indicatorsContainer: (styles) => ({
		...styles,
		padding: 0,
		'.dropdown': {
			'&__dropdown-indicator': {
				padding: 0,
			},
		},
	}),
	input: (styles) => ({
		...styles,
		padding: 0,
		margin: 0,
	}),
};
