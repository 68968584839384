import React from 'react';
import { SypacIcon, SypacText } from '@sypac/component-library-react';
import Flag from 'react-world-flags';
import { CountryBookTabsProps } from './CountryBookTabs.interface';
import { T } from '@tolgee/react';

const CountryBookTabs: React.FC<CountryBookTabsProps> = ({
	tabs,
	activeTab,
	onTabChange,
}) => (
	<nav className="flex justify-between border-0 border-solid border-b-[1px] border-gray-10 mt-1 xl:mt-0">
		<div className="flex h-[28px] w-[616px] bg-gray-1 rounded-t-[10px] ml-5 xl:ml-7.5">
			{Object.values(tabs).map(
				({ key, label, translatedLabel, flag }, index) => {
					const isActive = activeTab === key;
					const isFirst = index === 0;

					return (
						<button
							key={key}
							type="button"
							onClick={() => onTabChange(key)}
							className={`relative flex gap-3 items-center justify-center w-full border-0 p-0 m-0 cursor-pointer group ${
								isActive
									? 'bg-primary-violet rounded-t-md'
									: 'bg-transparent hover:bg-gray-10 hover:rounded-t-md'
							}`}
						>
							<div
								className={`absolute -top-1 left-0 w-full h-[9px] rounded-t-2xl ${
									isActive
										? 'bg-primary-violet opacity-100'
										: 'bg-gray-10 opacity-0 group-hover:opacity-100'
								}`}
							/>
							{!isFirst && (
								<div
									className={`absolute top-[7px] left-[0.5px] w-[1.75px] h-[14.5px] bg-gray-20 ${
										isActive ? 'opacity-0' : 'opacity-100 group-hover:opacity-0'
									}`}
								/>
							)}

							{flag && (
								<Flag
									className={`mb-[2px] object-cover rounded z-10 ${
										isActive
											? 'mb-[6px]'
											: 'border border-solid border-gray-10 group-hover:mb-[6px]'
									}`}
									code={flag}
									width={20}
									height={14}
								/>
							)}
							<SypacText variant="body-regular-medium">
								<p
									className={`${
										isActive
											? 'text-white mb-1'
											: 'text-gray-80 group-hover:mb-1'
									}`}
								>
									<T keyName={label}>{translatedLabel}</T>
								</p>
							</SypacText>
							{key === 'POL' && (
								<SypacIcon
									iconName="Crown Minimalistic"
									className={`text-yellow-400 z-[100] ${
										isActive ? 'mb-[6px]' : 'mb-[2px] group-hover:mb-[6px]'
									}`}
									size="custom"
									width="20px"
									height="20px"
								/>
							)}
						</button>
					);
				},
			)}
		</div>
	</nav>
);

export default CountryBookTabs;
