import React, { useState } from 'react';
import { SypacText } from '@sypac/component-library-react';
import { ChevronRightIcon } from '../../../../assets/ChevronRightIcon';
import {
	DaysSelectInterface,
	VariantInterface,
} from '../../ProductLocation.interface';
import WorkingTimeModal from '../../components/WorkingTimeModal';
import { T } from '@tolgee/react';
import { snakeToCamelCase } from '../../../../utils/string.util';

export const DaysSelect = ({
	daysMenu,
	pickupDays,
	currentDays,
	workHours,
	handleCheckedDays,
	isError,
}: DaysSelectInterface) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		item: VariantInterface,
		open = false,
	) => {
		event.preventDefault();
		if (open) {
			setOpenModal(!openModal);
		} else {
			handleCheckedDays(item);
		}
		setIsDropdownOpen(false);
	};

	const handleDataFromChild = (data: VariantInterface[]) => {
		handleCheckedDays({ title: 'Custom', variant: 'custom', submenu: data });
		setOpenModal(false);
	};

	return (
		<div className="relative">
			<div
				className={`flex bg-white border border-solid rounded-lg py-[11px] pl-3 pr-4 justify-between transition cursor-pointer ${
					isError
						? 'border-red-orange'
						: isDropdownOpen
						? 'border-cornflower-blue'
						: 'border-gray-10 hover:border-gray-30'
				}`}
				onClick={() => setIsDropdownOpen(!isDropdownOpen)}
			>
				<SypacText variant="body-normal-small">
					<p
						className={`${
							pickupDays[0].variant === 'days' ? 'text-gray-22' : ''
						}`}
					>
						{currentDays.length ? (
							<T
								keyName={`productLocation.${
									Array.isArray(currentDays)
										? snakeToCamelCase(currentDays?.[0]?.variant)
										: snakeToCamelCase(currentDays)
								}`}
							>
								{Array.isArray(currentDays)
									? currentDays?.[0]?.title
									: currentDays}
							</T>
						) : (
							<T keyName="newStoreCreate.selectWorkingDays">
								Select working days
							</T>
						)}
					</p>
				</SypacText>
				<span
					className={`flex my-auto transition ${
						isDropdownOpen ? 'transform rotate-90' : ''
					}`}
				>
					<ChevronRightIcon />
				</span>
			</div>

			{isDropdownOpen && (
				<div className="absolute top-[48px] w-full flex flex-col border border-solid border-gray-10 shadow-dropdown rounded-lg bg-white z-50">
					<ul className="flex flex-col w-full p-1 m-0 list-none box-border">
						{daysMenu.map((day: VariantInterface) => {
							return (
								<div key={day.variant}>
									<li className="w-full">
										<button
											type="button"
											className="w-full flex justify-between items-center border-none bg-transparent text-primary-dark-gray rounded-md p-3 cursor-pointer hover:bg-gray-10-opacity-50"
											onClick={(e) =>
												day.submenu
													? handleClick(e, day, true)
													: handleClick(e, day)
											}
										>
											<SypacText variant="body-normal-medium">
												<p>{day.title}</p>
											</SypacText>
										</button>
									</li>
								</div>
							);
						})}
					</ul>
				</div>
			)}

			<WorkingTimeModal
				isOpen={openModal}
				onClose={() => setOpenModal(false)}
				weekDays={daysMenu[2].submenu!}
				workHours={workHours[0]}
				selectedDays={pickupDays[0]}
				handleDataFromChild={handleDataFromChild}
			/>
		</div>
	);
};
