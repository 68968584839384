import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
	SypacBox,
	SypacButton,
	SypacCheckbox,
	SypacDatepicker,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import '../select-product.scss';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { T, useTranslate } from '@tolgee/react';
import ClientStepper from '../../../components/ClientStepper/ClientStepper';
import ClientOrderSummary from '../../../components/ClientOrderSummary/ClientOrderSummary';
import Clock from '../../../assets/Clock';
import { GeoService } from '../../../services/geo.services';
import { OrdersService, Prices } from '../../../services/orders.services';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import { COUNTERS } from '../constants';
import { ChevronDownIcon } from '../../../assets/ChevronDownIcon';
import ClientStoreBreadcrumbs from '../../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';
import { TruckStates, TruckTimes } from './clientSelectDate.interface';
import TruckIcon from '../../../components/TruckIcon/TruckIcon';

const deliveryTimeOptions = [
	{
		title: 'Full Time',
		value: 'full_time',
	},
	{
		title: 'From 09:00 to 17:00',
		value: 'from_9_to_5',
	},
	{
		title: 'From 09:00 to 13:00',
		value: 'from_9_to_1',
	},
	{
		title: 'From 13:00 to 17:00',
		value: 'from_1_to_5',
	},
];

const ClientSelectDate: React.FC = () => {
	const { t } = useTranslate();
	const { order, setOrder } = useContext(OrderContext);
	const { totalQuantityCounter, inputQuantity } = useContext(
		QuantityPickerContext,
	);
	const language = localStorage.getItem('lang') || 'en';
	const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

	const [quantity, setQuantity] = useState<number>(0);
	const [prices, setPrices] = useState<Prices>({
		productPriceForClient: 0,
		productPriceForClientWithVat: 0,
		transportPriceForClient: 0,
		transportPriceForClientWithVat: 0,
	});
	const [trucks, setTrucks] = useState<any[]>();
	const [isDropdownOpen, setIsDropdownOpen] = useState<TruckStates>({});
	const [deliveryTime, setDeliveryTime] = useState<TruckTimes>({});
	const [useForAllDeliveries, setUseForAllDeliveries] = useState<boolean>(true);
	const [showMore, setShowMore] = useState<boolean>(false);

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			trucks: trucks?.length
				? trucks.map((truck) => ({
						truckType: truck.truckType || undefined,
						quantity: truck.quantity || undefined,
						payload: truck.payload || undefined,
						deliveryFrom: truck.deliveryFrom || undefined,
						deliveryTo: truck.deliveryTo || undefined,
						deliveryTimeFrom:
							truck.deliveryTimeFrom ||
							dayjs().hour(9).minute(0).second(0).millisecond(0).toISOString(),
						deliveryTimeTo:
							truck.deliveryTimeTo ||
							dayjs().hour(17).minute(0).second(0).millisecond(0).toISOString(),
				  }))
				: [
						{
							truckType: undefined,
							quantity: undefined,
							payload: undefined,
							deliveryFrom: undefined,
							deliveryTo: undefined,
							deliveryTimeFrom: dayjs()
								.hour(9)
								.minute(0)
								.second(0)
								.millisecond(0)
								.toISOString(),
							deliveryTimeTo: dayjs()
								.hour(17)
								.minute(0)
								.second(0)
								.millisecond(0)
								.toISOString(),
						},
				  ],
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			const updatedTrucks = values.trucks.map((truck) => {
				const truckType =
					trucks?.find((t) => t.payload === truck.payload)?.car || '';
				return { ...truck, truckType, quantity: 1 };
			});

			setOrder({ ...order, trucks: updatedTrucks });
			navigate('/store/payment');
		},
		validationSchema: Yup.object().shape({
			trucks: Yup.array().of(
				Yup.object().shape({
					deliveryFrom: Yup.string().required('Required'),
					deliveryTo: Yup.string().required('Required'),
					deliveryTimeFrom: Yup.string().required('Required'),
					deliveryTimeTo: Yup.string().required('Required'),
				}),
			),
		}),
	});

	const callback = useCallback(
		(selectedDate: string, name: string, index: number) => {
			const newTrucks = [...formik.values.trucks];
			newTrucks[index] = {
				...newTrucks[index],
				[name === 'dateFrom' ? 'deliveryFrom' : 'deliveryTo']: selectedDate,
			};

			if (useForAllDeliveries) {
				newTrucks.forEach((_, idx) => {
					if (idx !== index) {
						newTrucks[idx] = {
							...newTrucks[idx],
							[name === 'dateFrom' ? 'deliveryFrom' : 'deliveryTo']:
								selectedDate,
						};
					}
				});
			}

			formik.setValues({
				...formik.values,
				trucks: newTrucks,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik],
	);

	const setDeliveryTimeForTruck = (index: number, value: string) => {
		setDeliveryTime((prev) => ({
			...prev,
			[index]: value,
		}));
		deliveryTimeCallback(value, index);
	};

	const deliveryTimeCallback = (value: string, index: number) => {
		const createTimeRange = (startHour: number, endHour: number) => ({
			deliveryTimeFrom: dayjs()
				.hour(startHour)
				.minute(0)
				.second(0)
				.millisecond(0)
				.toISOString(),
			deliveryTimeTo: dayjs()
				.hour(endHour)
				.minute(0)
				.second(0)
				.millisecond(0)
				.toISOString(),
		});
		const timeSettings: any = {
			full_time: {
				deliveryTimeFrom: dayjs().startOf('day').toISOString(),
				deliveryTimeTo: dayjs().endOf('day').toISOString(),
			},
			from_9_to_5: createTimeRange(9, 17),
			from_9_to_1: createTimeRange(9, 13),
			from_1_to_5: createTimeRange(13, 17),
		};
		const newTimes = timeSettings[value] || {};

		const updatedTrucks = formik.values.trucks.map((truck, idx) => {
			if (idx === index || useForAllDeliveries) {
				return {
					...truck,
					...newTimes,
				};
			}
			return truck;
		});

		formik.setValues({
			...formik.values,
			trucks: updatedTrucks,
		});

		setDeliveryTime((prev) => {
			if (useForAllDeliveries) {
				const newDeliveryTimes = {};
				updatedTrucks.forEach((_, idx) => {
					// @ts-ignore
					newDeliveryTimes[idx] = value;
				});
				return newDeliveryTimes;
			} else {
				return {
					...prev,
					[index]: value,
				};
			}
		});
	};

	const currentProduct = useMemo(() => order?.product, [order?.product]);
	const currentCategory = useMemo(() => order?.category, [order?.category]);
	const currentGroup = useMemo(() => order?.group, [order?.group]);

	const getPrices = useCallback(async () => {
		try {
			let quantity = 0;
			let trucksNr = 1;
			if (totalQuantityCounter?.length) {
				quantity = totalQuantityCounter?.reduce(
					(total, item) => total + item.payload * item.quantity,
					0,
				);
				trucksNr = totalQuantityCounter?.reduce(
					(total, item) => total + item.quantity,
					0,
				);
			}
			if (inputQuantity) {
				quantity = inputQuantity;
			}
			let latDelivery = 0;
			let longDelivery = 0;
			if (order?.location?.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: order?.location?.locationId,
				});
				latDelivery = resp?.data?.Latitude;
				longDelivery = resp?.data?.Longitude;
			}
			const { data } = await OrdersService.calculatePrice({
				quantity,
				trucksNr,
				latPickup: order?.product?.location?.coordinates?.length
					? order?.product?.location?.coordinates[1]
					: 47.04014,
				longPickup: order?.product?.location?.coordinates.length
					? order?.product?.location?.coordinates[0]
					: 28.80058,
				latDelivery,
				longDelivery,
				productId: currentProduct?.id!,
			});
			setPrices(data);
		} catch (e) {}
	}, [
		currentProduct?.id,
		inputQuantity,
		order?.location?.locationId,
		order?.product?.location?.coordinates,
		totalQuantityCounter,
	]);

	const toggleDropdown = (index: number) => {
		setIsDropdownOpen((prev) => ({
			...prev,
			[index]: !prev[index],
		}));
	};

	const handleUseForAllDeliveriesChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const isChecked = event.target.checked;
		setUseForAllDeliveries(isChecked);
		if (isChecked) {
			const firstTruck = formik.values.trucks[0];
			deliveryTimeCallback(firstTruck.deliveryTimeFrom, 0);
		}
	};

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	const renderDeliveryTime = (time: string) => {
		const timeMap: { [key: string]: string[] } = {
			'full_time': [t('clientSelectDate.full_time')],
			'from_9_to_5': ['09:00', '17:00'],
			'from_9_to_1': ['09:00', '13:00'],
			'from_1_to_5': ['13:00', '17:00'],
		};
		const times = timeMap[time] || ['09:00', '17:00'];

		return (
			<div className="flex gap-5">
				{times.map((t, index) => (
					<React.Fragment key={index}>
						<SypacText variant="body-regular-small">
							<p>{t}</p>
						</SypacText>
						{index < times.length - 1 && '•'}
					</React.Fragment>
				))}
			</div>
		);
	};

	const handleClickOutside = useCallback((event: MouseEvent) => {
		const refs = buttonRefs.current.filter(Boolean);

		const isOutsideRefs = refs.every(
			(ref) => !ref?.contains(event.target as Node),
		);

		if (isOutsideRefs) {
			setIsDropdownOpen({});
		}
	}, []);

	const truckToRender = showMore ? trucks : trucks?.slice(0, 1);

	useEffect(() => {
		if (totalQuantityCounter?.length) {
			const result = totalQuantityCounter?.reduce(
				(total, item) => total + item.payload * item.quantity,
				0,
			);
			let carId = 1;
			const cars = totalQuantityCounter.flatMap((counter) =>
				Array.from({ length: counter.quantity }, () => {
					const car = COUNTERS.find((r) => r.id === counter.type);
					return {
						...car,
						id: carId++,
					};
				}),
			);

			setQuantity(result);
			setTrucks(cars);
		} else {
			setQuantity(0);
		}
	}, [totalQuantityCounter]);

	useEffect(() => {
		getPrices().then(() => {});
	}, [getPrices]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [handleClickOutside]);

	return (
		<form
			onSubmit={formik.handleSubmit}
			className="w-full flex flex-col gap-4 h-[calc(100vh-60px)] gap-x-2.5 overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0"
		>
			{/*<SearchBar
				placeholder={t(
					'clientSelectDate.searchPlacehoder',
					'Search Products',
				)}
			/>*/}

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center flex flex-col gap-4 pt-3.5 sm:pt-0 mb-1 xl-2xl:mb-4 px-3.5 sm:px-12 xl:ml-0">
				<ClientStoreBreadcrumbs
					currentCategory={currentCategory}
					currentGroup={currentGroup}
					currentProduct={currentProduct}
					categoryId={currentCategory?.id}
				/>
			</div>
			<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

			<div className="w-[calc(100vw-26px)] sm:w-[1133px] xl:self-center xl-2xl:py-3 px-3.5 sm:px-12 xl:ml-0">
				<ClientStepper currentStep={2} />
			</div>
			<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

			<div className="sm:w-[calc(100vw-154px)] xl:w-[1133px] xl:self-center flex flex-col sm:flex-row gap-6 sm:justify-between mb-6 sm:mb-0 mt-2 xl-2xl:mt-3.5 sm:ml-12 sm:mr-7.5 xl:mx-0">
				<div className="sm:w-[407px] xl:w-[649px] flex flex-col justify-between sm:grow gap-6">
					<div className="flex flex-col gap-6">
						<div className="w-[calc(100vw-26px)] sm:w-full flex flex-col gap-4 [&_p]:m-0 ml-[14px] sm:ml-0">
							<div className="flex justify-between">
								<SypacText variant="body-normal-medium">
									<p className="text-base">
										{order?.deliveryIncluded !== false ? (
											<T keyName="clientSelectDate.deliveryDateTime">
												Delivery date & time
											</T>
										) : (
											<T keyName="clientSelectDate.pickUpDateTime">
												Pick up date & time
											</T>
										)}
									</p>
								</SypacText>

								{trucks?.length! > 1 && (
									<div className="flex items-center gap-3">
										<SypacCheckbox size="md">
											<input
												type="checkbox"
												className="cursor-pointer"
												checked={useForAllDeliveries}
												onChange={handleUseForAllDeliveriesChange}
											/>
										</SypacCheckbox>
										<SypacText
											variant="body-normal-medium"
											className="mt-[2px]"
										>
											<p className="text-[16px]">
												<T keyName="clientSelectDate.useForAll">
													Use for all deliveries
												</T>
											</p>
										</SypacText>
									</div>
								)}
							</div>

							<SypacText variant="body-normal-medium">
								<p className="text-xs text-gray-40">
									<T keyName="clientSelectDate.selectedDeliveryDateRange">
										Selected delivery date range may be modified automatically
										in cases where the delivery execution is impossible.
									</T>
								</p>
							</SypacText>
						</div>
						<div className="hidden xl:block border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

						<section
							className={`flex flex-col gap-6 ${
								showMore
									? 'sm:w-full xl:max-w-[662px] h-[378px] overflow-y-scroll overflow-x-hidden pr-2 sypac-scrollbar'
									: 'justify-center'
							}`}
						>
							{truckToRender?.map((truck, index) => (
								<SypacBox
									key={index}
									hover={false}
									className="bg-white transition hover:border-primary-violet"
								>
									<article className="p-3 flex flex-col gap-6.25">
										<header className="flex flex-col gap-4">
											<nav className="flex justify-between">
												<SypacText variant="body-regular-medium">
													<p>
														<T keyName="clientSelectDate.truck">Truck</T>{' '}
														{showMore ? index + 1 : 1}
													</p>
												</SypacText>
												<aside className="h-[22px] flex border border-solid border-gray-10 bg-[#f4f4f4] rounded-md items-center pr-[6px]">
													<span
														className={`${
															truck.car === 'hooklifter'
																? 'scale-[0.6]'
																: 'scale-[0.8]'
														}`}
													>
														<TruckIcon type={truck.car} />
													</span>
													<SypacText variant="body-regular-small">
														<p>
															{truck.car.charAt(0).toUpperCase() +
																truck.car.slice(1)}
														</p>
													</SypacText>
													<SypacText variant="body-regular-small">
														<p className="ml-[3px]">{truck.payload}t</p>
													</SypacText>
												</aside>
											</nav>

											<section className="grid grid-cols-1 lg:grid-cols-2 gap-6.25">
												<div className="w-full">
													<SypacInput
														error={
															!!(
																formik.touched?.trucks?.[index]?.deliveryFrom &&
																// @ts-ignore
																formik.errors?.trucks?.[index]?.deliveryFrom
															)
														}
													>
														<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
															<T keyName="clientSelectDate.dateFrom">
																Date from
															</T>
															<span className="text-red">*</span>
														</label>
														<SypacDatepicker
															name="dateFrom"
															className="dateFrom"
															placeholder={t(
																'clientSelectDate.enterDate',
																'Enter date',
															)}
															value={
																formik?.values.trucks?.[index]?.deliveryFrom ||
																''
															}
															min={dayjs().toString()}
															max={
																formik?.values.trucks?.[index]?.deliveryTo || ''
															}
															onCallback={({ detail }) =>
																callback(detail, 'dateFrom', index)
															}
															inputReadOnly={true}
															locale={language}
														/>
													</SypacInput>
												</div>
												<div className="w-full">
													<SypacInput
														error={
															!!(
																formik.touched?.trucks?.[index]?.deliveryTo &&
																// @ts-ignore
																formik.errors?.trucks?.[index]?.deliveryTo
															)
														}
													>
														<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
															<T keyName="clientSelectDate.dateTo">Date to</T>
															<span className="text-red">*</span>
														</label>
														<SypacDatepicker
															name="dateTo"
															min={
																formik?.values.trucks?.[index]?.deliveryFrom ||
																dayjs().toString()
															}
															className="dateTo"
															placeholder={t(
																'clientSelectDate.enterDate',
																'Enter date',
															)}
															value={
																formik?.values.trucks?.[index]?.deliveryTo || ''
															}
															onCallback={({ detail }) =>
																callback(detail, 'dateTo', index)
															}
															inputReadOnly={true}
															locale={language}
														/>
													</SypacInput>
												</div>
											</section>
										</header>

										<footer className="flex gap-3 items-center [&_p]:text-base">
											<SypacText variant="body-regular-small">
												<p className="text-gray-40">
													{order?.deliveryIncluded !== false ? (
														<T keyName="clientSelectDate.deliveryTime">
															Delivery time:
														</T>
													) : (
														<T keyName="clientSelectDate.pickUpTime">
															Pick up time:
														</T>
													)}
												</p>
											</SypacText>
											<Clock />

											{renderDeliveryTime(deliveryTime[index])}

											<SypacButton
												variant="secondary"
												size="small"
												className="-ml-[10px]"
											>
												<button
													type="button"
													className="relative border-none w-[20px] h-[20px]"
													onClick={() => toggleDropdown(index)}
													ref={(el) => (buttonRefs.current[index] = el)}
												>
													<SypacIcon
														iconName="Document Add"
														size="custom"
														width="38px"
														height="38px"
													/>
													{isDropdownOpen[index] && (
														<div
															className="flex flex-col fixed p-3 rounded-lg shadow-dropdown bg-white min-w-[145px] w-max z-30"
															style={{
																top:
																	buttonRefs.current[
																		index
																	]?.getBoundingClientRect().bottom! + 5,
																left: buttonRefs.current[
																	index
																]?.getBoundingClientRect().left,
															}}
														>
															{deliveryTimeOptions.map(({ title, value }) => (
																<div
																	key={value}
																	className="flex bg-white border-none pt-4 pb-[17px] px-5 rounded-md hover:bg-gray-10-opacity-50 no-underline cursor-pointer"
																	onClick={() =>
																		setDeliveryTimeForTruck(index, value)
																	}
																>
																	<SypacText
																		variant="body-regular-medium"
																		className="mr-auto"
																	>
																		<p className="text-gray-80 leading-[19px]">
																			<T keyName={`clientSelectDate.${value}`}>
																				{title}
																			</T>
																		</p>
																	</SypacText>
																</div>
															))}
														</div>
													)}
												</button>
											</SypacButton>
										</footer>
									</article>
								</SypacBox>
							))}
						</section>

						{trucks && trucks?.length > 1 && (
							<SypacButton
								variant="secondary"
								size="small"
								className="px-3 sm:p-0 w-full box-border"
							>
								<button
									type="button"
									className="flex gap-2 px-6 py-[13px] rounded-lg w-full h-[48px] transition"
									onClick={toggleShowMore}
								>
									<SypacText variant="body-regular-medium" className="mr-3">
										<p className="text-primary-violet">
											{showMore ? (
												<T keyName="clientSelectDate.showLessTrucks">
													Show less trucks
												</T>
											) : (
												<T keyName="clientSelectDate.showMoreTrucks">
													Show more trucks
												</T>
											)}
										</p>
									</SypacText>
									<span
										className={`flex my-auto transform mr-1 transition-all duration-300 ease-in-out ${
											showMore ? '-rotate-180' : ''
										}`}
									>
										<ChevronDownIcon />
									</span>
								</button>
							</SypacButton>
						)}
					</div>

					<div className="hidden sm:flex gap-5 justify-center">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="flex gap-2 px-6 py-[13px] rounded-lg w-full h-[48px] transition"
								onClick={() => {
									navigate(-1);
								}}
							>
								<SypacIcon
									size="custom"
									width="10px"
									height="12px"
									icon-name="Alt Arrow Left"
								/>
								<SypacText variant="body-normal-medium">
									<p className="mt-[3px]">
										<T keyName="clientSelectQuantity.back">Back</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="submit"
								className="flex gap-2 px-6 py-[13px] rounded-lg w-full h-[48px] transition"
								disabled={
									!formik.values.trucks.every(
										(truck) =>
											truck.deliveryFrom &&
											truck.deliveryTo &&
											truck.deliveryTimeFrom &&
											truck.deliveryTimeTo,
									)
								}
							>
								<SypacText variant="body-normal-medium">
									<p className="mt-[3px]">
										<T keyName="clientSelectQuantity.next">Next</T>
									</p>
								</SypacText>
								<SypacIcon
									size="custom"
									width="10px"
									height="12px"
									icon-name="Alt Arrow Right"
								/>
							</button>
						</SypacButton>
					</div>
				</div>

				<aside className="sm:w-[412px] xl:w-[460px] inline-table">
					<ClientOrderSummary
						currentProduct={currentProduct}
						prices={prices}
						quantity={quantity}
						step={2}
					/>
				</aside>

				<footer className="w-[calc(100vw-26px)] flex flex-col sm:hidden gap-5 justify-center ml-3.5">
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="submit"
							className="flex gap-2 px-6 py-[13px] rounded-lg w-full h-[48px] transition"
							disabled={
								!formik.values.trucks.every(
									(truck) =>
										truck.deliveryFrom &&
										truck.deliveryTo &&
										truck.deliveryTimeFrom &&
										truck.deliveryTimeTo,
								)
							}
						>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.next">Next</T>
								</p>
							</SypacText>
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Right"
							/>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className="flex gap-2 px-6 py-[13px] rounded-lg w-full h-[48px] transition"
							onClick={() => {
								navigate(-1);
							}}
						>
							<SypacIcon
								size="custom"
								width="10px"
								height="12px"
								icon-name="Alt Arrow Left"
							/>
							<SypacText variant="body-normal-medium">
								<p className="mt-[3px]">
									<T keyName="clientSelectQuantity.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</footer>
			</div>
		</form>
	);
};

export default ClientSelectDate;
