import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	SypacAvatar,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { Logout } from '../../assets/Logout';
import { AuthsService } from '../../services/auths.service';
import { AuthContext } from '../../context/context';
import { RenderIconByTarget } from '../RenderIconByTarget/RenderIconByTarget';
import { titlesTarget } from '../../constants/company-target.constants';
import { RadioCheked } from '../../assets/RadioCheked';
import { Radio } from '../../assets/Radio';
import {
	CompanyResponse,
	CompanyService,
} from '../../services/company.services';
import { toastVariant } from '../ToastVariant/toastVariant';
import Flag from 'react-world-flags';
import { ProfileModalProps } from './ProfileModal.interface';
import { useNavigate } from 'react-router-dom';

export const ProfileModal: React.FC<ProfileModalProps> = ({
	onClose,
	isLogoutOnly = false,
	viewTerms,
}) => {
	const { user } = useContext(AuthContext);
	const [myCompany, setMyCompany] = useState<CompanyResponse>();
	const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
	const [isLoadingCompany, setLoadingCompany] = useState<boolean>(false);
	const navigate = useNavigate();

	const userName = useMemo(() => {
		return user?.profile
			? `${user?.profile.firstName} ${user?.profile.lastName}`
			: user?.name;
	}, [user]);

	const shouldShowLogoutOnly = useMemo(() => {
		return (
			isLogoutOnly ||
			user?.company?.industries?.length === 1 ||
			!user?.company?.industries
		);
	}, [isLogoutOnly, user?.company?.industries]);

	const getMyCompanyCallback = useCallback(async () => {
		setLoadingCompany(true);
		try {
			const { data } = await CompanyService.getMeCompany();
			setMyCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		} finally {
			setLoadingCompany(false);
			setIsInitialLoading(false);
		}
	}, []);

	const logout = () => {
		AuthsService.logout();
	};

	const changeCompany = (companyType: string) => {
		let newUrl = '';
		if (companyType === 'transporter') {
			newUrl = window.location.origin.replace('producer', 'transporter');
		}
		if (companyType === 'producer') {
			newUrl = window.location.origin.replace('transporter', 'producer');
		}
		if (newUrl?.length) {
			const lang = localStorage.getItem('lang') || 'pl';
			const accessToken = localStorage.getItem('access_token');
			const refreshToken = localStorage.getItem('refresh_token');
			window.location.replace(
				`${newUrl}?access_token=${accessToken}&refresh_token=${refreshToken}&lang=${lang}`,
			);
		}
	};

	const handleViewTerms = () => {
		viewTerms && viewTerms();
	};

	const handleViewSettings = () => {
		onClose();
		navigate('/settings');
	};

	const getTarget = (host: string) => {
		if (host.includes('producer')) return 'producer';
		if (host.includes('transporter')) return 'transporter';
		return 'customer';
	};

	const getTargetColor = (host: string) => {
		switch (getTarget(host)) {
			case 'producer':
				return 'cornflower-blue';
			case 'transporter':
				return 'texas-rose';
			default:
				return 'primary-violet';
		}
	};

	useEffect(() => {
		if (!user?.isAdmin) {
			getMyCompanyCallback().then(() => {});
		} else {
			setIsInitialLoading(false);
		}
	}, [getMyCompanyCallback, user?.isAdmin]);

	return !isInitialLoading ? (
		<div className="w-[401px] h-fit bg-white border border-solid border-gray-10 rounded-10 outline-none">
			<div className="flex flex-col gap-2 box-border bg-alabaster border-0 border-solid border-b-[1px] border-gray-10 rounded-10 p-3">
				<>
					<div className="flex gap-6">
						<SypacAvatar initials={userName} size="sm" className="scale-[1.1]">
							{user?.profile?.photoUrl && (
								<img
									src={user?.profile.photoUrl || ''}
									alt={userName}
									className="w-full h-full object-cover rounded-full"
								/>
							)}
						</SypacAvatar>

						<SypacText variant="body-regular-medium">
							<p className="text-2xl text-gray-90">{userName}</p>
						</SypacText>
					</div>

					{!user?.isAdmin ? (
						<div className="flex flex-col gap-2 ml-[54px]">
							{isLoadingCompany ? (
								<div className="h-14 flex items-center">
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-50">
											Loading company information...
										</p>
									</SypacText>
								</div>
							) : (
								<>
									<div className="flex items-center gap-3">
										<SypacText variant="body-regular-medium">
											<p
												className={`text-xl text-${getTargetColor(
													window.location.host,
												)}`}
											>
												{myCompany?.name}
											</p>
										</SypacText>

										<div
											className={`w-[26px] h-[20px] flex justify-center items-center rounded bg-${getTargetColor(
												window.location.host,
											)}`}
										>
											<RenderIconByTarget
												target={getTarget(window.location.host)}
											/>
										</div>
									</div>

									<div className="flex items-center gap-3">
										<span className="mt-[2px]">
											<Flag
												className="object-cover border border-solid border-gray-10 rounded"
												code={myCompany?.countryCode}
												width={20}
												height={14}
											/>
										</span>
										<SypacText variant="body-regular-medium">
											<p className="text-sm align-middle text-gray-50">
												{myCompany?.registryAddress}
											</p>
										</SypacText>
									</div>
								</>
							)}
						</div>
					) : null}
				</>
			</div>

			{!shouldShowLogoutOnly ? (
				<>
					<div className="flex flex-col gap-3 w-full p-5 box-border">
						{user?.company.industries?.map((companyType) => {
							return (
								<button
									key={companyType}
									className={`flex items-center justify-between bg-transparent border border-solid transition hover:border-gray-40 rounded-lg px-3 py-2 ${
										!window.location.host.includes(companyType)
											? 'border-gray-10 cursor-pointer'
											: ''
									} ${
										window.location.host.includes(companyType)
											? 'border-gray-40 pointer-events-none'
											: ''
									}`}
									onClick={() => changeCompany(companyType)}
								>
									<div className="flex items-center gap-3">
										<div className="mt-1">
											{window.location.host.includes(companyType) ? (
												<RadioCheked />
											) : (
												<Radio />
											)}
										</div>

										<div
											className={`w-[26px] h-[20px] flex justify-center items-center rounded bg-${getTargetColor(
												companyType,
											)}`}
										>
											<RenderIconByTarget target={companyType} />
										</div>

										<SypacText variant="body-regular-medium">
											<p className="text-gray-80 -tracking-[0.01em] mt-[4px]">
												<T keyName={`profileModal.${companyType}`}>
													{titlesTarget[companyType]}
												</T>
											</p>
										</SypacText>
									</div>
								</button>
							);
						})}
					</div>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				</>
			) : null}

			{!user?.isAdmin ? (
				<div className="flex flex-col p-3 border-0 border-solid border-b-[1px] border-gray-10">
					<SypacButton variant="primary" size="small">
						<button
							type="button"
							className="flex gap-4 bg-transparent border-none -ml-[5px] group"
							onClick={handleViewTerms}
						>
							<SypacIcon
								iconName="Notebook"
								size="custom"
								width="32px"
								height="32px"
								className="text-gray-80 transition group-hover:text-gray-80/60"
							/>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 mt-1 transition group-hover:text-gray-80/60">
									<T keyName="profileModal.viewTermsAndConditions">
										View Terms and Conditions
									</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="primary" size="small">
						<button
							type="button"
							className="flex gap-4 bg-transparent border-none -ml-[5px] group"
							onClick={handleViewSettings}
						>
							<SypacIcon
								iconName="Settings"
								size="custom"
								width="32px"
								height="32px"
								className="text-gray-80 transition group-hover:text-gray-80/60"
							/>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 mt-1 transition group-hover:text-gray-80/60">
									<T keyName="profileModal.userSettings">User Settings</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			) : null}

			<div className="py-2.5">
				<SypacButton variant="primary" size="small">
					<button
						type="button"
						className="flex gap-5 bg-transparent border-none group"
						onClick={logout}
					>
						<span className="scale-[0.7] ml-[6px]">
							<Logout color="#F44A77" hoverEffect={true} />
						</span>

						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 transition group-hover:text-gray-80/60">
								<T keyName="producerRouter.logOut">Log out</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
			</div>
		</div>
	) : null;
};
