import React, { useContext, useMemo, useState } from 'react';
import { OrderInterface } from '../../../../../components/OrdersTable/interfaces/Order.interface';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacBadge,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import StatusBadge from '../../../../../components/StatusBadge/StatusBadge';
import { Calendar } from '../../../../../assets/Calendar';
import dayjs from 'dayjs';
import Invoice from '../../../../../assets/Invoice';
import { PaymentTypeAction } from '../../../../../components/PaymentsTable/interfaces/PaymentStatus.interface';
import { InvoiceInterface } from '../../../../../components/PaymentsTable/interfaces/Payment.interface';
import DocumentsViewer from '../../../../../components/DocumentsViewer/DocumentsViewer';
import { AuthContext } from '../../../../../context/context';
import { PaymentMethod } from '../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { calculateRemainingDays } from '../../../../../utils/time.util';
import { PaymentStatusColor } from '../../../../../components/TableStatus/TableStatus.constants';
import { SypacGrLoader } from '../../../../../assets/SypacGrLoader';
import { OrderDetailsContext } from '../../../../../context/OrderDetailsContext/order-details.context';
import { PaymentNotesStatusesEnum } from '../../../../../services/billing.service';
import { InfoRowProps } from '../../../../../components/BaseOrderDetails/BaseOrderDetails.interface';

interface PaymentTermsProps {
	selectedOrder: OrderInterface | undefined;
	forSeller: boolean;
}

const PaymentTerms: React.FC<PaymentTermsProps> = ({ forSeller }) => {
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const { producerInvoice, carrierInvoice } = useContext(OrderDetailsContext);
	const [openView, setOpenView] = useState<boolean>(false);
	const [invoiceUrl, setInvoiceUrl] = useState<string | undefined>(undefined);

	const orderInvoice = useMemo(
		() => (forSeller ? producerInvoice : carrierInvoice),
		[carrierInvoice, forSeller, producerInvoice],
	);

	const downloadInvoice = async (invoice: InvoiceInterface) => {
		try {
			if (!invoice.notesUrl) {
				return;
			}

			const companyName = user?.company.name;
			const invoiceSort =
				companyName
					?.split(' ')
					?.map((r) => r[0])
					?.join('')
					?.toUpperCase() || 'FV';
			const response = await fetch(invoice.notesUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			const fileName = invoice.invoiceNr
				? `${invoice.notesUrl}.pdf`
				: `${invoiceSort}${dayjs(invoice.createdAt).format('DD.MM.YYYY')}${
						invoice.id
				  }.pdf`;
			link.setAttribute('download', fileName);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} catch (e) {}
	};

	const viewInvoice = (url?: string) => {
		setInvoiceUrl(url);
		setOpenView(true);
	};

	const actionClick = (type: string, payment: InvoiceInterface) => {
		switch (type) {
			case PaymentTypeAction.download:
				return downloadInvoice(payment);
			case PaymentTypeAction.view_invoice:
				return viewInvoice(payment?.notesUrl);
		}
	};

	const InfoRow = ({
		label,
		value,
		isBadge,
		badgeContent,
		className,
	}: InfoRowProps) => (
		<div
			className={`grid grid-cols-[220px,auto] items-start px-5 ${className}`}
		>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			{isBadge ? (
				badgeContent
			) : (
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{value}</p>
				</SypacText>
			)}
		</div>
	);

	const daysBetweenDates = (
		termDateString: string,
		issueDateString: string,
	): number =>
		Math.ceil(
			Math.abs(
				new Date(termDateString).getTime() -
					new Date(issueDateString).getTime(),
			) /
				(1000 * 3600 * 24),
		);

	return (
		<>
			<div className="bg-white border border-solid border-gray-10 rounded-xl">
				<SypacText variant="body-regular-medium" className="px-5 py-4">
					<p className="text-base text-gray-60">
						{forSeller ? (
							<T keyName="paymentTerms.sellerPaymentTerms">
								Seller payment terms
							</T>
						) : (
							<T keyName="paymentTerms.carrierPaymentTerms">
								Carrier payment terms
							</T>
						)}
					</p>
				</SypacText>

				{orderInvoice ? (
					<div className="flex flex-col gap-3 mb-3">
						<InfoRow
							label={t('baseOrderDetails.paymentTerms', 'Payment terms:')}
							value={
								<div className="flex gap-3 items-center whitespace-nowrap">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{orderInvoice?.paymentMethod === PaymentMethod.bank ? (
												<T keyName="baseOrderDetails.bankWire">Bank Wire</T>
											) : (
												<T keyName="baseOrderDetails.card">Card</T>
											)}
										</p>
									</SypacText>
									<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{daysBetweenDates(
												orderInvoice?.createdAt!,
												orderInvoice?.dueDate!,
											)}{' '}
											<T keyName="baseOrderDetails.paymentDays">Days</T>
										</p>
									</SypacText>
								</div>
							}
						/>

						<>
							{!orderInvoice ? (
								<div className="w-full flex flex-col gap-6 my-6 px-5">
									<SypacGrLoader />

									<SypacText variant="body-regular-medium">
										<p className="text-2xl text-shark">
											<T keyName="paymentTerms.invoiceGenerationInProgress">
												Invoice Generation in Progress
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											<T keyName="paymentTerms.pleaseWaitGeneration">
												Please wait. Invoice generation might take a while.
											</T>
										</p>
									</SypacText>
								</div>
							) : (
								<>
									<InfoRow
										label={t('paymentTerms.invoiceStatus', 'Invoice status:')}
										isBadge={true}
										badgeContent={
											<div className="flex items-center gap-3">
												{calculateRemainingDays(orderInvoice?.dueDate!) > 0 ? (
													<>
														<StatusBadge
															status={orderInvoice?.status!}
															isInvoiceStatus={true}
														/>
														{[
															'pending',
															PaymentNotesStatusesEnum.PENDING_INVOICE,
															PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
															PaymentNotesStatusesEnum.UPLOADED_INVOICE,
														].includes(orderInvoice?.status) ? (
															<>
																<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
																	<SypacText variant="body-regular-medium">
																		<p className="text-xs truncate">
																			{calculateRemainingDays(
																				orderInvoice.dueDate,
																			)}{' '}
																			<T keyName="baseOrderDetails.paymentDays">
																				Days
																			</T>
																		</p>
																	</SypacText>
																</span>
																<SypacText variant="body-regular-medium">
																	<p className="text-sm text-gray-40">
																		<T keyName="paymentTerms.remainingToPay">
																			Remaining to pay
																		</T>
																	</p>
																</SypacText>
															</>
														) : null}
													</>
												) : (
													<>
														<SypacBadge
															color={PaymentStatusColor['overdue']}
															size="large"
															className="h-[24px]"
														>
															<div className="flex gap-1 items-center text-red w-fit justify-center cursor-auto">
																<SypacIcon
																	iconName="Danger Filled"
																	size="custom"
																	width="12px"
																	height="11px"
																/>
																<SypacText variant="overline-regular-large">
																	<p className="text-white">
																		<T keyName="paymentStatus.overdue">
																			Overdue
																		</T>
																	</p>
																</SypacText>
															</div>
														</SypacBadge>
														<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
															<SypacText variant="body-regular-medium">
																<p className="text-xs truncate">
																	{Math.abs(
																		calculateRemainingDays(
																			orderInvoice?.dueDate!,
																		),
																	)}{' '}
																	<T keyName="baseOrderDetails.paymentDays">
																		Days
																	</T>
																</p>
															</SypacText>
														</span>
														<SypacText variant="body-regular-medium">
															<p className="text-sm text-gray-40 truncate">
																<T keyName="paymentTerms.sinceOverdue">
																	Since overdue
																</T>
															</p>
														</SypacText>
													</>
												)}
											</div>
										}
									/>

									<InfoRow
										label={t('paymentTerms.issued', 'Issued:')}
										value={
											<div className="flex items-center gap-3 ml-[5px]">
												<span className="p-0 m-0 flex scale-[1.1]">
													<Calendar />
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">
														{dayjs(orderInvoice?.createdAt).format(
															'DD.MM.YYYY',
														)}
													</p>
												</SypacText>
											</div>
										}
									/>
								</>
							)}

							<div className="flex gap-5 px-5 pb-1">
								<SypacButton
									variant="secondary"
									size="large"
									className="w-full"
								>
									<button
										type="button"
										className="w-full h-[42px] transition"
										onClick={() =>
											actionClick(PaymentTypeAction.download, orderInvoice!)
										}
									>
										<span className="p-0 m-0 flex scale-[1.1]">
											<Invoice />
										</span>
										<SypacText variant="body-normal-medium">
											<p>
												<T keyName="paymentsWithdrawHistory.downloadInvoice">
													Download invoice
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton
									variant="secondary"
									size="large"
									className="w-full"
								>
									<button
										className="w-full h-[42px] transition"
										onClick={() =>
											actionClick(PaymentTypeAction.view_invoice, orderInvoice!)
										}
									>
										<SypacIcon
											icon-name="Eye"
											className="text-gray"
											size="custom"
											width="32px"
											height="32px"
										/>
										<SypacText variant="body-normal-medium">
											<p className="-ml-[6px]">
												<T keyName="paymentsWithdrawHistory.viewInvoice">
													View invoice
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						</>
					</div>
				) : (
					<div className="w-full flex flex-col gap-6 my-6 px-5">
						<SypacGrLoader />

						<SypacText variant="body-regular-medium">
							<p className="text-2xl text-shark">
								<T keyName="paymentTerms.invoiceGenerationInProgress">
									Invoice Generation in Progress
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								<T keyName="paymentTerms.pleaseWaitGeneration">
									Please wait. Invoice generation might take a while.
								</T>
							</p>
						</SypacText>
					</div>
				)}
			</div>
			{openView ? (
				<DocumentsViewer
					url={invoiceUrl!}
					isPdf={true}
					isOpen={openView}
					onClose={() => setOpenView(false)}
				/>
			) : null}
		</>
	);
};

export default PaymentTerms;
