import { SypacButton, SypacText } from '@sypac/component-library-react';
import Modal from 'react-modal';
import { T } from '@tolgee/react';

import Close from '../../assets/Close';

export interface ImageViewerProps {
	isOpen: boolean;
	url: string;
	onClose: () => void;
}

const ImageViewer = ({ isOpen, onClose, url }: ImageViewerProps) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal-inside-image h-[90%] outline-none max-h-[90%] max-w-[90%]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col h-full items-center justify-center">
				<div className="overflow-y-auto p-4">
					<img
						src={url}
						alt="company document"
						className="max-h-full max-w-full object-cover"
					/>
				</div>
				<div>
					<SypacButton variant="subTitle">
						<button onClick={onClose} className="cursor-pointer bg-transparent">
							<div className="bg-white border border-solid border-primary-dark-gray h-11 w-11 flex flex-row justify-center items-center rounded-10">
								<Close />
							</div>
							<SypacText variant="body-regular-medium" className="ml-3">
								<p className="text-white">
									<T keyName="imageViewer.close">Close</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</Modal>
	);
};

export default ImageViewer;
