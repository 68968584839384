import React, { useContext, useEffect, useRef, useState } from 'react';
import Flag from 'react-world-flags';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { ModalConfirmPaymentStatusProps } from './ModalConfirmPayment.interface';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import { COLUMNS_CARRIER, COLUMNS_PRODUCER } from './columns.constants';
import OverdueBadge from '../../assets/OverdueBadge';
import PaidBadge from '../../assets/PaidBadge';
import PaymentStatusPayment from '../TableStatus/PaymentStatusPayment';
import ArrowToRight from '../../assets/ArrowToRight';
import { TableStatusEnum } from '../TableStatus/TableStatus.enum';
import { T } from '@tolgee/react';
import { useGetCompanyById } from '../../hooks/use-get-company-by-id';
import Close from '../../assets/Close';
import LoadingSpinner from '../../assets/LoadingSpinner';
import { OrderContext } from '../../context/OrderContext/order.context';
import { OrderTarget } from '../OrdersTable/interfaces/OrderStatus.interface';

const ModalConfirmPaymentStatus: React.FC<ModalConfirmPaymentStatusProps> = ({
	data,
	isOpen,
	onClose,
	onSubmit,
	invoiceLoading,
}) => {
	const { setAdditional } = useContext(OrderContext);

	const [company] = useGetCompanyById({
		id: data.companyId,
	});
	const [isOverdue, setOverdue] = useState<boolean>(false);
	const [firstPartial, setFirstPartial] = useState<boolean>(true);
	const [secondPartial, setSecondPartial] = useState<boolean>(true);
	const [firstStatus, setFirstStatus] = useState<string>();
	const [secondStatus, setSecondStatus] = useState<string>();
	const [days, setDays] = useState<number>(0);
	const [futureBalance, setBalance] = useState<number>(0);
	const modalRef = useRef<HTMLDivElement>(null);

	const checkSubmit = () => {
		onSubmit();
	};

	useEffect(() => {
		if (data?.paidData?.amount && data?.invoice) {
			const invoice = data.invoice;
			const overdue =
				dayjs(invoice.dueDate).toISOString() < dayjs().toISOString();
			setOverdue(overdue);
			const firstStatus = overdue
				? TableStatusEnum.OVERDUE
				: TableStatusEnum.PENDING;
			setFirstStatus(firstStatus);
			setFirstPartial(!!invoice.payments?.length);
			const days = overdue
				? dayjs().diff(invoice?.dueDate, 'day')
				: dayjs(invoice?.dueDate).diff(dayjs(), 'day');
			setDays(days);
			const balance = invoice.balanceDue - parseFloat(data.paidData.amount);
			setSecondPartial(balance !== 0);
			setSecondStatus(
				balance > 0 ? TableStatusEnum.PENDING : TableStatusEnum.PAID,
			);
			setBalance(balance);
		}
	}, [data?.paidData?.amount, data?.invoice]);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={onClose}>
			<div
				className="w-[828px] bg-white rounded-xl"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="flex flex-col">
					<div className="flex items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalConfirmPaymentStatus.confirmPaymentDateAmount">
									Confirm payment date & amount
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="border-0 border-t border-solid border-gray-10" />

					<div className="flex flex-col items-center justify-center p-3 gap-3">
						<div className="w-full flex flex-col gap-6">
							<div className="flex gap-4">
								<div className="w-[58px] h-[58px] flex items-center justify-center rounded-10 bg-gray-10">
									<SypacText variant="heading-5">
										<p className="text-gray-90">
											{data?.companyName
												.split(' ')
												.map((name) => name[0])
												.join('')
												.toUpperCase()
												.slice(0, 2)}
										</p>
									</SypacText>
								</div>

								<div className="flex flex-col gap-2">
									<SypacText variant="heading-5">
										<p className="text-xl text-gray-90">{data?.companyName}</p>
									</SypacText>
									<div className="flex items-center gap-[10px]">
										<Flag
											className="object-cover rounded z-10 border border-solid border-gray-10"
											code={company?.countryCode.toUpperCase()}
											width={22}
											height={16}
										/>

										<SypacText variant="body-normal-medium">
											<p className="text-sm text-gray-80 mt-[2px]">
												{company?.registryAddress}
											</p>
										</SypacText>
									</div>
								</div>
							</div>
							<div className="bg-alabaster rounded-10">
								<div className="flex flex-col justify-center dashed-box p-3 gap-5">
									<div>
										<SypacText variant="body-regular-small">
											<p className="text-gray-80">
												FAKTURA NR/Invoice no {data.invoiceName}
											</p>
										</SypacText>
									</div>
									{data.tableData.length ? (
										<div>
											<InvoiceTable
												columns={
													[OrderTarget.producer, OrderTarget.customer].includes(
														data.invoice.companyType as OrderTarget,
													)
														? COLUMNS_PRODUCER
														: COLUMNS_CARRIER
												}
												data={data.tableData}
											/>
										</div>
									) : null}
									<div className="text-end">
										<SypacText variant="body-regular-small">
											<p className="text-gray-80">
												Amount to be paid:{' '}
												<NumericFormat
													type="text"
													className="font-bold"
													displayType="text"
													thousandSeparator=" "
													decimalSeparator="."
													value={parseFloat(data.amount?.toFixed(2) || '0')}
													suffix={
														data.invoice?.countryCode === 'MD' ? ' MDL' : ' PLN'
													}
												/>
											</p>
										</SypacText>
									</div>
								</div>
							</div>
						</div>

						<div className="w-full flex flex-col gap-4 justify-start items-start bg-alabaster border border-dashed border-gray-10 rounded-10 p-[10px] box-border">
							<div className="flex gap-3">
								{isOverdue ? (
									<OverdueBadge width={56} height={56} />
								) : (
									<PaidBadge width={56} height={56} />
								)}

								<div className="flex flex-col gap-3">
									<div className="grid grid-cols-[200px,auto]">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">Amount paid:</p>
										</SypacText>
										<SypacText variant="body-regular-medium">
											<p className="text-gray-90">
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator=" "
													decimalSeparator="."
													value={parseFloat(data.paidData?.amount) || 0}
													suffix={
														data.invoice?.countryCode === 'MD' ? ' MDL' : ' PLN'
													}
												/>{' '}
												{secondPartial ? '(partial payment)' : '(full amount)'};
											</p>
										</SypacText>
									</div>

									{secondPartial ? (
										<>
											<div className="col-span-5">
												<SypacText variant="body-normal-medium">
													<p className="text-gray-90">Left to pay:</p>
												</SypacText>
											</div>
											<div className="col-span-7">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-90">
														<NumericFormat
															type="text"
															displayType="text"
															thousandSeparator=" "
															decimalSeparator="."
															value={parseFloat(
																futureBalance?.toFixed(2) || '0',
															)}
															suffix={
																data.invoice?.countryCode === 'MD'
																	? ' MDL'
																	: ' PLN'
															}
														/>
													</p>
												</SypacText>
											</div>
										</>
									) : null}

									<div className="grid grid-cols-[200px,auto]">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-40">Payment execution:</p>
										</SypacText>
										<SypacText variant="body-regular-medium">
											<p className="text-gray-90">
												{isOverdue ? `Overdue, ${days} days.` : 'On time'}
											</p>
										</SypacText>
									</div>
								</div>
							</div>
							<div className="w-full border-0 border-t border-solid border-gray-10" />

							<div className="flex flex-col">
								<div className="grid grid-cols-12 gap-x-6">
									{firstStatus && secondStatus ? (
										<>
											<div className="col-span-12 mt-5">
												<SypacText variant="body-regular-small">
													<p className="text-gray-40">
														{secondPartial
															? `The status of the payment will be changed from "${
																	firstStatus.charAt(0).toUpperCase() +
																	firstStatus.slice(1)
															  }" to "${
																	secondStatus.charAt(0).toUpperCase() +
																	secondStatus.slice(1)
															  } / partial payment ". Please check the summary table for detailed information about
											the payment.`
															: `The status of the payment will be changed from "${
																	firstStatus.charAt(0).toUpperCase() +
																	firstStatus.slice(1)
															  }"
										to "${
											secondStatus.charAt(0).toUpperCase() +
											secondStatus.slice(1)
										}". Please check the summary table for detailed
										information about the payment.`}
													</p>
												</SypacText>
											</div>
											<div className="col-span-12 mt-5">
												<div className="flex flex-row">
													<PaymentStatusPayment
														isPartial={firstPartial}
														state={firstStatus}
														days={days}
													/>
													<div className="flex items-center justify-start mx-6">
														<ArrowToRight />
													</div>
													<PaymentStatusPayment
														days={days}
														state={secondStatus}
														isPartial={secondPartial}
													/>
												</div>
											</div>
										</>
									) : null}
								</div>
							</div>
						</div>
					</div>

					<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
						<SypacButton
							variant="secondary"
							size="small"
							onClick={onClose}
							className="w-full"
						>
							<button
								type="reset"
								className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="imageViewer.close">Close</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								onClick={checkSubmit}
								className="w-full h-[44px] flex items-center justify-center border-0 bg-primary-violet rounded-lg hover:bg-primary-violet/80"
							>
								<SypacText variant="body-regular-medium">
									<p className="flex items-center gap-4 text-white">
										<T keyName="modalConfirmationCode.confirm">Confirm</T>
										{invoiceLoading && <LoadingSpinner />}
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default ModalConfirmPaymentStatus;
