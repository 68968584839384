import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { CheckmarkCircle } from '../../assets/CheckmarkCircle';
import DocumentIcon from '../../assets/DocumentIcon';
import {
	BillingService,
	PaymentNotesStatusesEnum,
} from '../../services/billing.service';
import ModalUploadInvoice from '../../containers/Producer/ProducerBilling/components/ModalUploadInvoice';
import ClockSmall from '../../assets/ClockSmall';
import { formatDate } from '../../utils/time.util';
import Download from '../../assets/Download';
import { OrderTarget } from '../OrdersTable/interfaces/OrderStatus.interface';
import StatusDocument from '../../assets/StatusDocument';
import { OrderDetailsContext } from '../../context/OrderDetailsContext/order-details.context';
import ModalPendingInvoiceConfirmation from '../../containers/Admin/Billing/components/ModalPendingInvoiceConfirmation';
import ModalSuccessPayment from '../ModalSuccessPayment/ModalSuccessPayment';
import { FileType, PageProps } from '../../constants';
import StatusPendingPayment from '../../assets/StatusPendingPayment';
import { InvoiceInterface } from '../PaymentsTable/interfaces/Payment.interface';
import { Order, OrdersService } from '../../services/orders.services';
import ModalConfirmPayment from '../ModalConfirmPayment/ModalConfirmPayment';
import ModalConfirmPaymentStatus from '../ModalConfirmPayment/ModalConfirmPaymentStatus';
import { ConfirmPaymentProps } from '../ModalConfirmPayment/ModalConfirmPayment.interface';
import { getFileType } from '../../utils/files.util';
import { toastVariant } from '../ToastVariant/toastVariant';
import Emitter, { EventType } from '../../services/events';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import { Tooltip } from 'react-tooltip';
import ModalRejectInvoice from '../../containers/Admin/Billing/components/ModalRejectInvoice';
import { OrderDetailsInvoiceProps } from './OrderDetailsInvoice.interface';

const OrderDetailsInvoice: React.FC<OrderDetailsInvoiceProps> = ({
	currentStatus,
	target,
	order,
}) => {
	const { t } = useTranslate();
	const { producerInvoice, carrierInvoice, actionFor } =
		useContext(OrderDetailsContext);
	const [openUpload, setOpenUpload] = useState<boolean>(false);
	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const [openReject, setOpenReject] = useState<boolean>(false);
	const [openSuccess, setOpenSuccess] = useState<boolean>(false);
	const [showPay, setShowPay] = useState<boolean>(false);
	const [showPrevSuccess, setShowPrevSuccess] = useState<boolean>(false);
	const [payment, setPayment] = useState<ConfirmPaymentProps>();
	const [selectedInvoice, setInvoice] = useState<InvoiceInterface>();
	const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
	const [fileSizes, setFileSizes] = useState<Record<string, number>>({});
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [openDocView, setOpenDocView] = useState<boolean>(false);
	const [openDocUrl, setOpenDocUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();

	const currentInvoice = useMemo(() => {
		if (target === OrderTarget.admin) {
			return actionFor === OrderTarget.producer
				? producerInvoice
				: carrierInvoice;
		}
		return target === OrderTarget.producer ? producerInvoice : carrierInvoice;
	}, [target, actionFor, producerInvoice, carrierInvoice]);

	const localFiles = useMemo(
		() =>
			currentInvoice?.fullInvoiceUrls?.length
				? currentInvoice.fullInvoiceUrls.map((url, index) => ({
						file: {
							lastModified: formatDate(currentInvoice.updatedAt),
							name: `invoice_${index + 1}.pdf`,
							size: fileSizes[url] || 0,
							path: url.match(/\/dev\/[^?]+/)?.[0].substring(1),
						},
						mimeType: `${getFileType(url)?.toUpperCase()}`,
						name: `Invoice ${index + 1}`,
						size: fileSizes[url]
							? `${(fileSizes[url] / (1024 * 1024)).toFixed(2)} MB`
							: 'Unknown',
						fileUrl: url,
				  }))
				: [],
		[currentInvoice?.fullInvoiceUrls, currentInvoice?.updatedAt, fileSizes],
	);

	const confirmInvoice = () => {
		setOpenConfirm(false);
		setOpenSuccess(true);
	};

	const rejectInvoice = () => {
		setOpenConfirm(false);
		setOpenReject(true);
	};

	const confirmPrevPayment = (values: ConfirmPaymentProps) => {
		setPayment(values);
		setShowPay(false);
		setShowPrevSuccess(true);
	};

	const onPay = async (value: InvoiceInterface) => {
		const invoice = value as InvoiceInterface;
		const orderIds = invoice?.invoiceLines?.map((line) => line.orderId);
		if (orderIds?.length) {
			const orders = await OrdersService.getOrders({ ids: orderIds }, 'admin');
			const ordersMap: Record<number, Order> = {};
			orders.data?.items?.forEach((order) => {
				ordersMap[order.id] = order;
			});
			invoice.invoiceLines = invoice.invoiceLines?.map((line) => {
				line.order = ordersMap[line.orderId];
				return line;
			});
		}
		setInvoice(invoice);
		setShowPay(true);
	};

	const confirmPayment = async () => {
		setInvoiceLoading(true);
		try {
			if (selectedInvoice?.id && payment) {
				await BillingService.acceptInvoice(actionFor, selectedInvoice.id, {
					paymentAmount: parseFloat(payment?.amount)!,
					paymentDate: payment?.date!,
				});
				Emitter.emit(EventType.PAYMENT_NOTE_REFRESH);
				setShowPrevSuccess(false);
				setPayment(undefined);
			}
		} catch (e) {
		} finally {
			setInvoiceLoading(false);
		}
	};

	const downloadDocument = async (fileUrl: string) => {
		if (!fileUrl) return;

		try {
			const response = await fetch(fileUrl);
			const blob = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');

			const fileName = new URL(fileUrl).pathname.split('/').pop();

			link.href = downloadUrl;
			link.download = fileName || 'document';
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	};

	const handleRemoveFile = async (url: string) => {
		if (isUpdating) return;

		setIsUpdating(true);
		try {
			const updatedFiles = localFiles.filter((file) => file.fileUrl !== url);
			const patchInvoiceUrls = updatedFiles.map((file) => file.file.path!);

			await BillingService.uploadPaymentNotes(target, currentInvoice?.id!, {
				patchInvoiceUrls,
			});
			toastVariant('File successfully deleted.', false);
			Emitter.emit(EventType.PAYMENT_NOTE_REFRESH);
		} catch (error) {
			console.error('Error updating files:', error);
		} finally {
			setIsUpdating(false);
		}
	};

	const openFile = (url: string) => {
		setOpenDocUrl(url);
		setOpenDocView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const handleRemoveFileModal = () => {
		handleRemoveFile(openDocUrl!).then(() => {});
		setOpenDocView(false);
	};

	useEffect(() => {
		const fetchFileSizes = async () => {
			if (!currentInvoice?.fullInvoiceUrls?.length) return;

			const sizePromises = currentInvoice.fullInvoiceUrls.map(async (url) => {
				try {
					const response = await fetch(url, { method: 'HEAD' });
					const contentLength = response.headers.get('Content-Length');
					return { url, size: contentLength ? parseInt(contentLength, 10) : 0 };
				} catch (error) {
					console.error('Error fetching file size:', error);
					return { url, size: 0 };
				}
			});

			const results = await Promise.all(sizePromises);
			const sizeMap = results.reduce((acc, { url, size }) => {
				acc[url] = size;
				return acc;
			}, {} as Record<string, number>);

			setFileSizes(sizeMap);
		};

		fetchFileSizes().then(() => {});
	}, [currentInvoice?.fullInvoiceUrls]);

	return (
		<>
			<div className="flex flex-col gap-6">
				{[OrderTarget.producer, OrderTarget.transporter].includes(
					target as OrderTarget,
				) ? (
					currentStatus === PaymentNotesStatusesEnum.PENDING_INVOICE ? (
						<>
							<div className="flex gap-6">
								<DocumentIcon />
								<SypacText variant="body-regular-medium">
									<p className="text-2xl text-gray-80">
										<T keyName="orderStepper.paymentNotesPendingYourInvoice">
											Pending your invoice
										</T>
									</p>
								</SypacText>
							</div>
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-40">
									<T keyName="adminBilling.uploadTheInvoiceDescription">
										Upload the invoice for this order to start payment
										processing. Ensure it matches the order details: product,
										quantity, and price.
									</T>
								</p>
							</SypacText>

							<SypacButton variant="secondary" size="small">
								<button
									type="button"
									className="w-full h-[44px] flex gap-2 justify-center items-center px-3 bg-primary-violet border-0 rounded-lg p-0 cursor-pointer transition hover:bg-primary-violet/80"
									onClick={() => setOpenUpload(true)}
								>
									<SypacText variant="body-regular-small">
										<p className="text-base text-white mt-1">
											<T keyName="invoiceLinesTable.uploadInvoice">
												Upload invoice
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</>
					) : currentStatus === PaymentNotesStatusesEnum.UPLOADED_INVOICE ? (
						<>
							<div className="flex gap-6">
								<span className="flex mt-1">
									<ClockSmall
										width={24}
										height={24}
										color="#FFB352"
										secondColor="#454545"
									/>
								</span>
								<SypacText variant="body-regular-medium">
									<p className="text-2xl text-gray-80">
										<T keyName="adminBilling.yourInvoiceUploaded">
											Your invoice has been uploaded and is awaiting
											confirmation
										</T>
									</p>
								</SypacText>
							</div>
							<div className="border-0 border-solid border-t-[1px] border-gray-10" />

							<div className="w-full flex flex-col gap-[14px] box-border">
								{localFiles.map((file, index) => (
									<>
										<div
											className="flex gap-[14px]"
											key={file?.name || Math.random() + index}
										>
											{[FileType.JPG, FileType.PNG].includes(
												getFileType(file.fileUrl) as FileType,
											) ? (
												<div
													style={{
														backgroundImage: `url(${file.fileUrl})`,
													}}
													className="relative w-[76px] h-[76px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
													onClick={() => openFile(file.fileUrl)}
													onMouseEnter={() => handleBoxToggle(file.fileUrl)}
													onMouseLeave={() => handleBoxToggle(undefined)}
													role="img"
												>
													{showBlur === file.fileUrl && (
														<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
															<SypacIcon
																size="custom"
																width="32px"
																height="32px"
																icon-name="Magnifer"
																className="text-white"
															/>
														</div>
													)}
												</div>
											) : (
												<div
													className="relative w-[76px] h-[76px] flex justify-center items-center border border-solid border-gray-10 rounded-lg overflow-hidden cursor-pointer"
													onClick={() => openFile(file.fileUrl)}
													onMouseEnter={() => handleBoxToggle(file.fileUrl)}
													onMouseLeave={() => handleBoxToggle(undefined)}
												>
													<Document file={file.fileUrl} renderMode="canvas">
														{Array.from({ length: 1 }, (_, i) => (
															<Page
																key={`page_${i + 1}`}
																pageNumber={i + 1}
																{...PageProps}
																width={76}
																height={76}
																children={null}
															/>
														))}
													</Document>
													{showBlur === file.fileUrl && (
														<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
															<SypacIcon
																size="custom"
																width="32px"
																height="32px"
																icon-name="Magnifer"
																className="text-white"
															/>
														</div>
													)}
												</div>
											)}
											<div className="flex flex-col gap-3">
												<SypacText variant="body-regular-medium">
													<p className="text-base text-gray-80">{file.name}</p>
												</SypacText>
												<div className="flex justify-start items-center">
													<SypacText variant="overline-regular-large">
														<p className="text-[10px] text-gray-40">
															{file.mimeType}{' '}
														</p>
													</SypacText>
													<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
													<SypacText variant="overline-regular-large">
														<p className="text-[10px] text-gray-40">
															{file.size}{' '}
														</p>
													</SypacText>
												</div>
												<div className="flex gap-3 justify-start items-center">
													<SypacText variant="overline-regular-large">
														<p className="text-xs text-gray-40">
															<T keyName="modalUploadInvoice.uploadedBy">
																Uploaded by:
															</T>
														</p>
													</SypacText>
													<SypacText variant="overline-regular-large">
														<p className="text-xs text-gray-80">
															{currentInvoice?.invoiceLines?.[0]?.assigneeUser
																?.profile?.firstName +
																' ' +
																currentInvoice?.invoiceLines?.[0]?.assigneeUser
																	?.profile?.lastName}
														</p>
													</SypacText>
													<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
													<SypacText variant="overline-regular-large">
														<p className="text-xs text-gray-80">
															{file.file.lastModified}
														</p>
													</SypacText>
												</div>
												<div className="flex gap-3 justify-start items-center">
													<SypacText variant="body-regular-medium">
														<p className="text-xs text-gray-40">
															<T keyName="modalUploadInvoice.status">Status:</T>
														</p>
													</SypacText>
													<ClockSmall
														width={14}
														height={14}
														color="#FFB352"
														secondColor="#454545"
													/>
													<SypacText variant="body-regular-medium">
														<p className="text-xs text-gray-40">
															Pending approval
														</p>
													</SypacText>
												</div>
											</div>

											<div className="flex gap-3 ml-auto">
												{/* <SypacButton variant="secondary" size="small">
													<button
														type="button"
														data-tooltip-id="order-details-invoice"
														data-tooltip-content={t(
															'modalUploadInvoice.deleteFile',
															'Delete File',
														)}
														className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
														onClick={() => handleRemoveFile(file.fileUrl)}
													>
														<Trash />
													</button>
												</SypacButton> */}
												<SypacButton variant="secondary" size="small">
													<button
														type="button"
														data-tooltip-id="order-details-invoice"
														data-tooltip-content={t(
															'modalUploadInvoice.downloadFile',
															'Download File',
														)}
														className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
														onClick={() => downloadDocument(file?.fileUrl)}
													>
														<Download />
													</button>
												</SypacButton>
											</div>
										</div>
										<div className="border-0 border-solid border-t-[1px] border-gray-10" />
									</>
								))}
							</div>

							<SypacButton variant="secondary" size="small">
								<button
									type="button"
									className="w-full h-[44px] flex gap-2 justify-center items-center px-3 bg-primary-violet border-0 rounded-lg p-0 cursor-pointer transition hover:bg-primary-violet/80"
									onClick={() => setOpenUpload(true)}
								>
									<SypacText variant="body-regular-small">
										<p className="text-base text-white mt-1">
											<T keyName="invoiceLinesTable.addDocument">
												Add document
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</>
					) : (
						<>
							<div className="flex gap-6">
								<span className="flex mt-[2px]">
									<CheckmarkCircle color="#20AC93" width={24} height={24} />
								</span>
								<section className="flex flex-col gap-3">
									<SypacText variant="body-regular-medium">
										<p className="text-2xl text-gray-80">
											<T keyName="adminBilling.yourInvoiceApproved">
												Your invoice has been approved
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-40">
											<T keyName="adminBilling.paymentBeingProcessed">
												Payment is now being processed. Payment due date:
											</T>{' '}
											<span className="text-gray-80">
												{formatDate(currentInvoice?.dueDate)}
											</span>
										</p>
									</SypacText>
								</section>
							</div>
							<div className="border-0 border-solid border-t-[1px] border-gray-10" />

							<div className="w-full flex flex-col gap-[14px] box-border">
								{localFiles.map((file, index) => (
									<>
										<div
											className="flex gap-[14px]"
											key={file?.name || Math.random() + index}
										>
											{[FileType.JPG, FileType.PNG].includes(
												getFileType(file.fileUrl) as FileType,
											) ? (
												<div
													style={{
														backgroundImage: `url(${file.fileUrl})`,
													}}
													className="relative w-[76px] h-[76px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
													onClick={() => openFile(file.fileUrl)}
													onMouseEnter={() => handleBoxToggle(file.fileUrl)}
													onMouseLeave={() => handleBoxToggle(undefined)}
													role="img"
												>
													{showBlur === file.fileUrl && (
														<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
															<SypacIcon
																size="custom"
																width="32px"
																height="32px"
																icon-name="Magnifer"
																className="text-white"
															/>
														</div>
													)}
												</div>
											) : (
												<div
													className="relative w-[76px] h-[76px] flex justify-center items-center border border-solid border-gray-10 rounded-lg overflow-hidden cursor-pointer"
													onClick={() => openFile(file.fileUrl)}
													onMouseEnter={() => handleBoxToggle(file.fileUrl)}
													onMouseLeave={() => handleBoxToggle(undefined)}
												>
													<Document file={file.fileUrl} renderMode="canvas">
														{Array.from({ length: 1 }, (_, i) => (
															<Page
																key={`page_${i + 1}`}
																pageNumber={i + 1}
																{...PageProps}
																width={76}
																height={76}
																children={null}
															/>
														))}
													</Document>
													{showBlur === file.fileUrl && (
														<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
															<SypacIcon
																size="custom"
																width="32px"
																height="32px"
																icon-name="Magnifer"
																className="text-white"
															/>
														</div>
													)}
												</div>
											)}
											<div className="flex flex-col gap-3">
												<SypacText variant="body-regular-medium">
													<p className="text-base text-gray-80">{file.name}</p>
												</SypacText>
												<div className="flex justify-start items-center">
													<SypacText variant="overline-regular-large">
														<p className="text-[10px] text-gray-40">
															{file.mimeType}{' '}
														</p>
													</SypacText>
													<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
													<SypacText variant="overline-regular-large">
														<p className="text-[10px] text-gray-40">
															{file.size}{' '}
														</p>
													</SypacText>
												</div>
												<div className="flex gap-3 justify-start items-center">
													<SypacText variant="overline-regular-large">
														<p className="text-xs text-gray-40">
															<T keyName="modalUploadInvoice.uploadedBy">
																Uploaded by:
															</T>
														</p>
													</SypacText>
													<SypacText variant="overline-regular-large">
														<p className="text-xs text-gray-80">
															{currentInvoice?.invoiceLines?.[0]?.assigneeUser
																?.profile?.firstName +
																' ' +
																currentInvoice?.invoiceLines?.[0]?.assigneeUser
																	?.profile?.lastName}
														</p>
													</SypacText>
													<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
													<SypacText variant="overline-regular-large">
														<p className="text-xs text-gray-80">
															{file.file.lastModified}
														</p>
													</SypacText>
												</div>
												<div className="flex gap-3 justify-start items-center">
													<SypacText variant="body-regular-medium">
														<p className="text-xs text-gray-40">
															<T keyName="modalUploadInvoice.approved">
																Approved:
															</T>
														</p>
													</SypacText>
													<SypacText variant="overline-regular-large">
														<p className="text-xs text-gray-80">
															{formatDate(
																new Date()
																	.toUTCString()
																	.replace(
																		'GMT',
																		'GMT+0000 (Coordinated Universal Time)',
																	),
															)}
														</p>
													</SypacText>
												</div>
											</div>
										</div>
										{index !== localFiles.length - 1 ? (
											<div className="border-0 border-solid border-t-[1px] border-gray-10" />
										) : null}
									</>
								))}
							</div>
						</>
					)
				) : currentStatus === PaymentNotesStatusesEnum.UPLOADED_INVOICE ? (
					<>
						<div className="flex gap-6">
							<span className="flex mt-1">
								<StatusDocument width={21} height={24} />
							</span>
							<SypacText variant="body-regular-medium">
								<p className="text-2xl text-gray-80">
									<T keyName="adminBilling.confirmInvoice">Confirm invoice</T>
								</p>
							</SypacText>
						</div>
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="adminBilling.reviewTheUploadedInvoice">
									Review the uploaded invoice for this order. Verify that it
									accurately matches the order details before approval.
								</T>
							</p>
						</SypacText>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />

						<div className="w-full flex flex-col gap-[14px] box-border">
							{localFiles.map((file, index) => (
								<>
									<div
										key={file?.name || Math.random() + index}
										className="flex gap-[14px]"
									>
										{[FileType.JPG, FileType.PNG].includes(
											getFileType(file.fileUrl) as FileType,
										) ? (
											<div
												style={{
													backgroundImage: `url(${file.fileUrl})`,
												}}
												className="relative w-[76px] h-[76px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
												onClick={() => openFile(file.fileUrl)}
												onMouseEnter={() => handleBoxToggle(file.fileUrl)}
												onMouseLeave={() => handleBoxToggle(undefined)}
												role="img"
											>
												{showBlur === file.fileUrl && (
													<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
														<SypacIcon
															size="custom"
															width="32px"
															height="32px"
															icon-name="Magnifer"
															className="text-white"
														/>
													</div>
												)}
											</div>
										) : (
											<div
												className="relative w-[76px] h-[76px] flex justify-center items-center border border-solid border-gray-10 rounded-lg overflow-hidden cursor-pointer"
												onClick={() => openFile(file.fileUrl)}
												onMouseEnter={() => handleBoxToggle(file.fileUrl)}
												onMouseLeave={() => handleBoxToggle(undefined)}
											>
												<Document file={file.fileUrl} renderMode="canvas">
													{Array.from({ length: 1 }, (_, i) => (
														<Page
															key={`page_${i + 1}`}
															pageNumber={i + 1}
															{...PageProps}
															width={76}
															height={76}
															children={null}
														/>
													))}
												</Document>
												{showBlur === file.fileUrl && (
													<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
														<SypacIcon
															size="custom"
															width="32px"
															height="32px"
															icon-name="Magnifer"
															className="text-white"
														/>
													</div>
												)}
											</div>
										)}
										<div className="flex flex-col gap-3">
											<SypacText variant="body-regular-medium">
												<p className="text-base text-gray-80">{file.name}</p>
											</SypacText>
											<div className="flex justify-start items-center">
												<SypacText variant="overline-regular-large">
													<p className="text-[10px] text-gray-40">
														{file.mimeType}{' '}
													</p>
												</SypacText>
												<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
												<SypacText variant="overline-regular-large">
													<p className="text-[10px] text-gray-40">
														{file.size}{' '}
													</p>
												</SypacText>
											</div>
											<div className="flex gap-3 justify-start items-center">
												<SypacText variant="overline-regular-large">
													<p className="text-xs text-gray-40">
														<T keyName="modalUploadInvoice.uploadedBy">
															Uploaded by:
														</T>
													</p>
												</SypacText>
												<SypacText variant="overline-regular-large">
													<p className="text-xs text-gray-80">
														{currentInvoice?.invoiceLines?.[0]?.assigneeUser
															?.profile?.firstName +
															' ' +
															currentInvoice?.invoiceLines?.[0]?.assigneeUser
																?.profile?.lastName}
													</p>
												</SypacText>
												<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
												<SypacText variant="overline-regular-large">
													<p className="text-xs text-gray-80">
														{file.file.lastModified}
													</p>
												</SypacText>
											</div>
										</div>

										<div className="flex gap-3 ml-auto">
											<SypacButton variant="secondary" size="small">
												<button
													type="button"
													data-tooltip-id="order-details-invoice"
													data-tooltip-content={t(
														'modalUploadInvoice.downloadFile',
														'Download File',
													)}
													className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
													onClick={() => downloadDocument(file?.fileUrl)}
												>
													<Download />
												</button>
											</SypacButton>
										</div>
									</div>
								</>
							))}
						</div>

						<SypacButton variant="secondary" size="small">
							<button
								type="button"
								className="w-full h-[44px] flex gap-2 justify-center items-center px-3 bg-primary-violet border-0 rounded-lg p-0 cursor-pointer transition hover:bg-primary-violet/80"
								onClick={() => setOpenConfirm(true)}
							>
								<SypacText variant="body-regular-small">
									<p className="text-base text-white mt-1">
										<T keyName="invoiceLinesTable.viewDetails">View details</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</>
				) : currentStatus === PaymentNotesStatusesEnum.INVOICE_CONFIRMED ? (
					<>
						<div className="flex gap-6">
							<span className="flex mt-1">
								<StatusPendingPayment width={28.5} height={24} />
							</span>
							<SypacText variant="body-regular-medium">
								<p className="text-2xl text-gray-80">
									<T keyName="adminBilling.confirmPayment">
										Confirm payment for this order
									</T>
								</p>
							</SypacText>
						</div>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />

						<div className="w-full flex flex-col gap-[14px] box-border">
							{localFiles.map((file, index) => (
								<>
									<div
										key={file?.name || Math.random() + index}
										className="flex gap-[14px]"
									>
										{[FileType.JPG, FileType.PNG].includes(
											getFileType(file.fileUrl) as FileType,
										) ? (
											<div
												style={{
													backgroundImage: `url(${file.fileUrl})`,
												}}
												className="relative w-[76px] h-[76px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
												onClick={() => openFile(file.fileUrl)}
												onMouseEnter={() => handleBoxToggle(file.fileUrl)}
												onMouseLeave={() => handleBoxToggle(undefined)}
												role="img"
											>
												{showBlur === file.fileUrl && (
													<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
														<SypacIcon
															size="custom"
															width="32px"
															height="32px"
															icon-name="Magnifer"
															className="text-white"
														/>
													</div>
												)}
											</div>
										) : (
											<div
												className="relative w-[76px] h-[76px] flex justify-center items-center border border-solid border-gray-10 rounded-lg overflow-hidden cursor-pointer"
												onClick={() => openFile(file.fileUrl)}
												onMouseEnter={() => handleBoxToggle(file.fileUrl)}
												onMouseLeave={() => handleBoxToggle(undefined)}
											>
												<Document file={file.fileUrl} renderMode="canvas">
													{Array.from({ length: 1 }, (_, i) => (
														<Page
															key={`page_${i + 1}`}
															pageNumber={i + 1}
															{...PageProps}
															width={76}
															height={76}
															children={null}
														/>
													))}
												</Document>
												{showBlur === file.fileUrl && (
													<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
														<SypacIcon
															size="custom"
															width="32px"
															height="32px"
															icon-name="Magnifer"
															className="text-white"
														/>
													</div>
												)}
											</div>
										)}
										<div className="flex flex-col gap-3">
											<SypacText variant="body-regular-medium">
												<p className="text-base text-gray-80">{file.name}</p>
											</SypacText>
											<div className="flex justify-start items-center">
												<SypacText variant="overline-regular-large">
													<p className="text-[10px] text-gray-40">
														{file.mimeType}{' '}
													</p>
												</SypacText>
												<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
												<SypacText variant="overline-regular-large">
													<p className="text-[10px] text-gray-40">
														{file.size}{' '}
													</p>
												</SypacText>
											</div>
											<div className="flex gap-3 justify-start items-center">
												<SypacText variant="overline-regular-large">
													<p className="text-xs text-gray-40">
														<T keyName="modalUploadInvoice.uploadedBy">
															Uploaded by:
														</T>
													</p>
												</SypacText>
												<SypacText variant="overline-regular-large">
													<p className="text-xs text-gray-80">
														{currentInvoice?.invoiceLines?.[0]?.assigneeUser
															?.profile?.firstName +
															' ' +
															currentInvoice?.invoiceLines?.[0]?.assigneeUser
																?.profile?.lastName}
													</p>
												</SypacText>
												<div className="w-1 h-1 bg-gray-20 rounded-full mx-2" />
												<SypacText variant="overline-regular-large">
													<p className="text-xs text-gray-80">
														{file.file.lastModified}
													</p>
												</SypacText>
											</div>
										</div>

										<div className="flex gap-3 ml-auto">
											<SypacButton variant="secondary" size="small">
												<button
													type="button"
													data-tooltip-id="order-details-invoice"
													data-tooltip-content={t(
														'modalUploadInvoice.downloadFile',
														'Download File',
													)}
													className="w-[32px] h-[32px] flex justify-center items-center bg-white border border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-30"
													onClick={() => downloadDocument(file?.fileUrl)}
												>
													<Download />
												</button>
											</SypacButton>
										</div>
									</div>
								</>
							))}
						</div>

						<SypacButton variant="secondary" size="small">
							<button
								type="button"
								className="w-full h-[44px] flex gap-2 justify-center items-center px-3 bg-primary-violet border-0 rounded-lg p-0 cursor-pointer transition hover:bg-primary-violet/80"
								onClick={() => onPay(currentInvoice!)}
							>
								<SypacText variant="body-regular-small">
									<p className="text-base text-white mt-1">
										<T keyName="paymentsTableAdminProducer.confirmPayment">
											Confirm payment
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</>
				) : null}
				<Tooltip
					place="top"
					id="order-details-invoice"
					style={{
						backgroundColor: '#E8E8E8',
						color: '#000000',
					}}
				/>
			</div>
			{showPay ? (
				<ModalConfirmPayment
					data={{
						companyName: selectedInvoice?.companyName! || 'Not set',
						companyId: selectedInvoice?.companyId!,
						invoiceName: selectedInvoice?.id?.toString()!,
						amount: selectedInvoice?.balanceDue!,
						tableData:
							selectedInvoice?.invoiceLines?.map((line) => {
								return {
									order: line.orderId,
									product: line.description,
									quantity:
										actionFor === 'transporter'
											? order?.quantity.toString()!
											: line.quantity?.toString()!,
									price: line.lineTotal?.toString()!,
									...(actionFor === 'transporter' && {
										description: 'Transportation',
										distance: `${order?.trucks?.[0].distance} ${line.unit}`,
									}),
								};
							}) || [],
						invoice: selectedInvoice!,
					}}
					isOpen={showPay}
					error={false}
					onClose={() => {
						setShowPay(false);
					}}
					onSubmit={confirmPrevPayment}
					realAmount={selectedInvoice?.balanceDue}
				/>
			) : null}
			<ModalConfirmPaymentStatus
				data={{
					companyName: selectedInvoice?.companyName! || 'Not set',
					companyId: selectedInvoice?.companyId!,
					invoiceName: selectedInvoice?.id?.toString()!,
					amount: selectedInvoice?.balanceDue!,
					tableData:
						selectedInvoice?.invoiceLines?.map((line) => {
							return {
								order: line.orderId,
								product: line.description,
								quantity:
									actionFor === 'transporter'
										? order?.quantity.toString()!
										: line.quantity?.toString()!,
								price: line.lineTotal?.toString()!,
								...(actionFor === 'transporter' && {
									description: 'Transportation',
									distance: `${order?.trucks?.[0].distance} ${line.unit}`,
								}),
							};
						}) || [],
					paidData: payment!,
					invoice: selectedInvoice!,
				}}
				isOpen={showPrevSuccess}
				onClose={() => setShowPrevSuccess(false)}
				onSubmit={confirmPayment}
				companyType={target}
				invoiceLoading={invoiceLoading}
			/>
			<ModalUploadInvoice
				isOpen={openUpload}
				onClose={() => setOpenUpload(false)}
				target={target}
			/>
			<ModalPendingInvoiceConfirmation
				isOpen={openConfirm}
				onClose={() => setOpenConfirm(false)}
				invoice={currentInvoice!}
				order={order}
				onSubmit={confirmInvoice}
				onReject={rejectInvoice}
			/>
			<ModalRejectInvoice
				isOpen={openReject}
				onClose={() => setOpenReject(false)}
				invoice={currentInvoice!}
			/>
			<ModalSuccessPayment
				isOpen={openSuccess}
				onClose={() => setOpenSuccess(false)}
			/>
			{openDocView ? (
				<DocumentsViewer
					url={openDocUrl!}
					isPdf={
						getFileType(
							localFiles.find((file) => file.fileUrl === openDocUrl)?.fileUrl!,
						) === FileType.PDF
					}
					isOpen={openDocView}
					onClose={() => setOpenDocView(false)}
					canDelete={target === OrderTarget.producer}
					handleDelete={handleRemoveFileModal}
					canDownload={true}
				/>
			) : null}
		</>
	);
};

export default OrderDetailsInvoice;
