import React, { useContext, useMemo, useState } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { MapIcon } from '../../assets/MapIcon';
import Flag from 'react-world-flags';
import Close from '../../assets/Close';
import { ClientStoreBreadcrumbsProps } from './ClientStoreBreadcrumbs.interface';
import { OrderContext } from '../../context/OrderContext/order.context';
import { useNavigate } from 'react-router-dom';
import ModalDeleteAddress from './components/ModalDeleteAddress';
import { Address } from '../../interfaces/geo.interface';

const ClientStoreBreadcrumbs: React.FC<ClientStoreBreadcrumbsProps> = ({
	currentGroup,
	currentCategory,
	categoryId,
	currentProduct,
}) => {
	const { order, additional, setAdditional } = useContext(OrderContext);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const navigate = useNavigate();

	const deliveryAddress = useMemo(() => {
		if (!order?.location) return '';

		const { street, houseNumber, postalCode, city, country }: Address =
			order.location.address!;

		return [
			[street, houseNumber].filter(Boolean).join(' '),
			postalCode,
			city,
			country,
		]
			.filter(Boolean)
			.join(', ');
	}, [order?.location]);

	const handleDontShowAnymore = () => {
		setAdditional((prev) => ({
			...prev,
			dontShowAnymore: true,
		}));
	};

	const BreadcrumbItem = ({
		text,
		isActive,
		onClick,
		isClickable = true,
	}: {
		text: React.ReactNode;
		isActive: boolean;
		onClick?: () => void;
		isClickable?: boolean;
	}) => (
		<SypacText variant="body-regular-medium">
			<p
				className={`
          text-xs sm:text-sm transition
          ${isClickable ? 'hover:text-gray-80' : ''}
          ${isActive ? 'text-cornflower-blue' : 'text-gray-40'}
        `}
				onClick={isClickable ? onClick : undefined}
				style={{ cursor: isClickable ? 'pointer' : 'default' }}
			>
				{text}
			</p>
		</SypacText>
	);

	return (
		<nav className="w-full flex flex-col gap-3">
			<section className="flex gap-3 items-center whitespace-nowrap">
				<BreadcrumbItem
					text={<T keyName="clientSelectQuantity.store">Store</T>}
					isActive={!currentGroup}
					onClick={() => navigate('/store/groups')}
				/>

				{currentGroup && (
					<>
						<p className="text-[10px] text-gray-22">/</p>
						<BreadcrumbItem
							text={currentGroup.name}
							isActive={!currentCategory}
							onClick={() => currentCategory && navigate('/store/groups')}
							isClickable={Boolean(currentCategory)}
						/>
					</>
				)}

				{currentCategory && (
					<>
						<p className="text-[10px] text-gray-22">/</p>
						<BreadcrumbItem
							text={currentCategory.name}
							isActive={!currentProduct}
							onClick={() =>
								categoryId &&
								navigate(
									`/store/groups/${currentGroup?.id}/categories/${categoryId}/products`,
								)
							}
							isClickable={Boolean(categoryId)}
						/>
					</>
				)}

				{currentProduct && (
					<>
						<p className="text-[10px] text-gray-22">/</p>
						<BreadcrumbItem
							text={currentProduct.name}
							isActive={true}
							isClickable={false}
						/>
					</>
				)}
			</section>

			<section className="w-full flex flex-col lg:flex-row gap-4">
				<div className="flex gap-4 whitespace-nowrap">
					<span className="scale-[1.2]">
						<MapIcon green={true} />
					</span>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80 mt-[1px]">
							<T keyName="clientSelectQuantity.deliveryAddress">
								Delivery address:
							</T>
						</p>
					</SypacText>
				</div>

				<div className="w-full flex gap-4">
					<span className="mt-[2px]">
						<Flag
							className="object-cover border border-solid border-gray-10 rounded"
							code={order?.location?.countryCode?.toUpperCase()}
							width={20}
							height={14}
						/>
					</span>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80 mt-[1px]">{deliveryAddress}</p>
					</SypacText>

					<SypacButton
						variant="subTitle"
						className="ml-auto sm:ml-0 lg:ml-[4px]"
					>
						<button
							type="button"
							onClick={() =>
								additional.dontShowAnymore
									? navigate('/store')
									: setShowDeleteModal(true)
							}
							className="w-[22px] h-[22px] flex justify-center items-center p-0 bg-white border border-solid border-gray-10 rounded-md transition hover:border-gray-60"
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>
			</section>

			<ModalDeleteAddress
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				dontShowAnymore={handleDontShowAnymore}
				isChecked={additional.dontShowAnymore!}
			/>
		</nav>
	);
};

export default ClientStoreBreadcrumbs;
