import React from 'react';
import IconCompleted from '../../assets/IconCompleted';
import IconRegistration from '../../assets/IconRegistration';
import IconVerification from '../../assets/IconVerification';

interface RenderIconByStatusProps {
	status: string;
	isActive?: boolean;
}

const RenderIconByStatus: React.FC<RenderIconByStatusProps> = ({
	status,
	isActive = false,
}) => {
	const normalizedStatus = status?.toLowerCase().replace(/_/g, '');

	const getIcon = React.useMemo(() => {
		const iconMap: Record<string, React.ReactNode> = {
			registrationinprogress: <IconRegistration isActive={isActive} />,
			verificationpending: <IconVerification />,
			verified: <IconCompleted />,
		};

		return iconMap[normalizedStatus!] || <IconCompleted />;
	}, [isActive, normalizedStatus]);

	return <React.Fragment>{getIcon}</React.Fragment>;
};

export default RenderIconByStatus;
