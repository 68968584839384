import React from 'react';
import {
	SypacText,
	SypacBox,
	SypacAvatar,
} from '@sypac/component-library-react';
import {
	ClientsProductCardProps,
	PriceDisplayProps,
	ProductDetailsProps,
} from '../clientSelectProduct.interface';
import { NumericFormat } from 'react-number-format';
import { T } from '@tolgee/react';
import { CompanyResponse } from '../../../../services/company.services';

const ClientsProductCard: React.FC<ClientsProductCardProps> = ({
	data: {
		name,
		// availableQuantity = 0,
		type,
		pricePerUnitWithProcent = 0,
		pricePerUnitWithVat = 0,
		photoUrl,
		size,
		currency,
		company,
	},
	onClick,
}) => {
	const PriceDisplay = ({
		label,
		value,
		currency,
		textColor = 'text-gray-80',
	}: PriceDisplayProps) => (
		<div className={`flex gap-1 whitespace-nowrap ${textColor}`}>
			<SypacText variant="body-regular-medium">
				<p className="text-sm sm:text-xs xl:text-sm">{label}</p>
			</SypacText>
			<SypacText variant="body-regular-medium">
				<p className="text-sm sm:text-xs xl:text-sm">
					<NumericFormat
						type="text"
						displayType="text"
						thousandSeparator=" "
						decimalSeparator="."
						value={parseFloat(value?.toFixed(2) || '0')}
						suffix={currency === 'lei' ? ' MDL' : ' PLN'}
					/>
				</p>
			</SypacText>
		</div>
	);

	const ProductDetails = ({ name, type, size }: ProductDetailsProps) => (
		<div
			className={`flex flex-col gap-[10px] px-2.5 ${
				!type && !size ? 'mb-7.5' : ''
			}`}
		>
			<SypacText variant="body-regular-medium">
				<p className="text-base leading-[22px] text-gray-80 truncate">{name}</p>
			</SypacText>
			{(type || size) && (
				<SypacText variant="body-regular-medium">
					<p className="text-sm text-gray-40">
						{`${type || ''}${size ? ` (${size})` : ''}`}
					</p>
				</SypacText>
			)}
		</div>
	);

	const CompanyInfo = ({ company }: { company?: CompanyResponse }) => {
		if (!company) return null;

		return (
			<div className="flex items-center gap-2 px-1 py-[3.5px] border-0 border-solid border-t-[1px] border-gray-10">
				<SypacAvatar
					className="scale-[0.6]"
					size="sm"
					initials={company?.name}
				/>
				<SypacText variant="body-regular-medium">
					<p className="text-sm text-cornflower-blue mt-1">{company.name}</p>
				</SypacText>
			</div>
		);
	};

	return (
		<article className="relative">
			<SypacBox
				hover={true}
				className="lg:w-[215px] xl:w-[268px] h-fit flex gap-3 rounded-xl bg-white transition box-border"
				onClick={() => {
					onClick && onClick();
				}}
			>
				<div
					style={{ backgroundImage: `url(${photoUrl})` }}
					className="h-[134px] xl:h-[174px] m-[10px] mb-0 bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 box-border"
					role="img"
					aria-label={name}
				/>

				<ProductDetails name={name!} type={type} size={size} />
				<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

				<section className="flex flex-col gap-[10px] px-2.5">
					<PriceDisplay
						label={
							<T keyName="clientsProductCard.pricePerTonExcl">
								Price per ton (excl. VAT):
							</T>
						}
						value={pricePerUnitWithProcent}
						currency={currency!}
					/>

					<PriceDisplay
						label={
							<T keyName="clientsProductCard.pricePerTonIncl">
								Price per ton (incl. VAT):
							</T>
						}
						value={pricePerUnitWithVat}
						currency={currency!}
						textColor="text-gray-40"
					/>
				</section>

				<CompanyInfo company={company} />
			</SypacBox>
		</article>
	);
};

export default ClientsProductCard;
