import {
	createContext,
	useState,
	Dispatch,
	SetStateAction,
	useMemo,
	ReactNode,
} from 'react';

export interface QuantityProps {
	quantity: number;
	payload: number;
	type: string;
}

interface QuantityPickerProps {
	totalQuantityCounter: QuantityProps[] | undefined;
	setTotalQuantityCounter: Dispatch<
		SetStateAction<QuantityProps[] | undefined>
	>;
	inputQuantity: number;
	setInputQuantity: Dispatch<SetStateAction<number>>;
}

export const QuantityPickerContext = createContext<QuantityPickerProps>({
	totalQuantityCounter: undefined,
	setTotalQuantityCounter: () => null,
	inputQuantity: 0,
	setInputQuantity: () => null,
});

const QuantityPickerContextProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [totalQuantityCounter, setTotalQuantityCounter] = useState<
		QuantityProps[] | undefined
	>();
	const [inputQuantity, setInputQuantity] = useState<number>(0);

	const value = useMemo<QuantityPickerProps>(
		() => ({
			totalQuantityCounter,
			setTotalQuantityCounter,
			inputQuantity,
			setInputQuantity,
		}),
		[
			totalQuantityCounter,
			setTotalQuantityCounter,
			inputQuantity,
			setInputQuantity,
		],
	);

	return (
		<QuantityPickerContext.Provider value={value}>
			{children}
		</QuantityPickerContext.Provider>
	);
};

export default QuantityPickerContextProvider;
