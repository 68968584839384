import React, { useEffect, useState } from 'react';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import {
	DropdownType,
	PeriodDropdownProps,
	ProducerBillingStatisticProps,
} from './ProducerBillingStatistic.interface';
import { T, useTranslate } from '@tolgee/react';
import { NumericFormat } from 'react-number-format';
import { ChevronRightIcon } from '../../assets/ChevronRightIcon';
import ApexDonutChart from '../ApexDonutChart/ApexDonutChart';

const ProducerBillingStatistic: React.FC<ProducerBillingStatisticProps> = ({
	amountPaid = '0',
	amountPending = '0',
	allOrders = '0',
	ordersLast30Days = '0',
	ordersLast60Days = '0',
	paidOrders = '0',
	orderInProgress = '0',
	ordersInDispute = '0',
	revenueLast30Days = '0',
	revenueLast60Days = '0',
	revenueLast90Days = '0',
	countryCode,
}) => {
	const { t } = useTranslate();
	const [activeDropdown, setActiveDropdown] = useState<DropdownType>(null);
	const [selectedOrdersPeriod, setSelectedOrdersPeriod] = useState<number>(30);
	const [selectedRevenuePeriod, setSelectedRevenuePeriod] =
		useState<number>(30);
	const [showChart, setShowChart] = useState<boolean>(false);

	const ordersMap: Record<number, string> = {
		30: ordersLast30Days,
		60: ordersLast60Days,
	};

	const revenueMap: Record<number, string> = {
		30: revenueLast30Days,
		60: revenueLast60Days,
		90: revenueLast90Days,
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowChart(true);
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	const PeriodDropdown = ({
		type,
		periods,
		selectedPeriod,
		onPeriodChange,
	}: PeriodDropdownProps) => (
		<div
			className="relative flex"
			// onMouseEnter={() => setActiveDropdown(type)}
			// onMouseLeave={() => setActiveDropdown(null)}
		>
			<button
				type="button"
				onClick={() => setActiveDropdown(activeDropdown === type ? null : type)}
				className={`flex gap-3 items-center justify-center bg-transparent border border-solid rounded-md cursor-pointer transition ${
					activeDropdown === type
						? 'border-cornflower-blue'
						: 'border-transparent hover:border-gray-10'
				}`}
			>
				<SypacText variant="overline-regular-large">
					<p className="text-xs text-gray-80">
						<T keyName="producerBillingStatistic.last">last</T> {selectedPeriod}{' '}
						<T keyName="paymentPeriod.days">days</T>
					</p>
				</SypacText>

				<span
					className={`flex transform transition-all duration-300 ease-in-out ${
						activeDropdown === type ? 'rotate-90' : ''
					}`}
				>
					<ChevronRightIcon />
				</span>
			</button>

			{activeDropdown === type && (
				<div className="absolute top-[22px] flex flex-col p-[3px] border border-solid border-gray-10 rounded-10 shadow-dropdown bg-white min-w-[145px] w-max z-30">
					{Object.keys(periods)
						.map(Number)
						.map((period) => (
							<div
								key={period}
								className="flex items-center justify-center gap-3 py-[10px] px-3 bg-white border border-solid border-transparent rounded-lg cursor-pointer hover:bg-gray-10-opacity-50 hover:border-gray-10"
								onClick={() => {
									onPeriodChange(period);
									setActiveDropdown(null);
								}}
							>
								<SypacText variant="body-regular-medium" className="mr-auto">
									<p className="text-gray-80 mt-[2px]">Last {period} days</p>
								</SypacText>
							</div>
						))}
				</div>
			)}
		</div>
	);

	return (
		<div className="relative w-full">
			<SypacBox
				hover={false}
				boxStyle="solid"
				boxColor="primary"
				className="border-0"
			>
				{/* <div className="flex gap-[14px] mb-[14px]">
					<div className="w-[197px] flex flex-col border border-solid border-gray-10 rounded-10 box-border">
						<div className="flex p-3">
							<SypacText variant="overline-regular-large">
								<p className="text-sm text-gray-40">
									<T keyName="producerBillingStatistic.creditLimit">
										Credit limit
									</T>
								</p>
							</SypacText>
						</div>

						<div className="flex gap-5 px-3 py-[10px] bg-alabaster border-0 border-t-[1px] border-solid border-gray-10 rounded-10">
							<SypacText variant="heading-4">
								<p className="w-[173px] text-xl text-gray-80 truncate">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(amountPending)}
										suffix={countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								</p>
							</SypacText>
						</div>
					</div>

					<div className="w-full flex flex-col border border-solid border-gray-10 rounded-10 box-border">
						<div className="flex p-3">
							<SypacText variant="overline-regular-large">
								<p className="text-sm text-gray-40">
									<T keyName="producerBillingStatistic.creditUsed">
										Credit used
									</T>
								</p>
							</SypacText>
						</div>

						<div className="flex gap-5 px-3 py-[10px] bg-alabaster border-0 border-t-[1px] border-solid border-gray-10 rounded-10">
							<SypacText variant="heading-4">
								<p className="w-[173px] text-xl text-gray-80 truncate">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(amountPending)}
										suffix={countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								</p>
							</SypacText>
						</div>
					</div>

					<div className="w-full flex flex-col border border-solid border-gray-10 rounded-10 box-border">
						<div className="flex p-3">
							<SypacText variant="overline-regular-large">
								<p className="text-sm text-gray-40">
									<T keyName="producerBillingStatistic.availableCredit">
										Available credit
									</T>
								</p>
							</SypacText>
						</div>

						<div className="flex gap-5 px-3 py-[10px] bg-alabaster border-0 border-t-[1px] border-solid border-gray-10 rounded-10">
							<SypacText variant="heading-4">
								<p className="w-[173px] text-xl text-gray-80 truncate">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(amountPending)}
										suffix={countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								</p>
							</SypacText>
						</div>
					</div>
				</div> */}

				<div className="flex gap-[14px]">
					<div className="w-[355px] h-fit grid grid-cols-2 grid-rows-2 gap-[14px]">
						<div className="col-span-2 flex flex-col px-3 rounded-10 border border-solid border-gray-10 justify-center gap-[6px] h-[80px]">
							<div className="flex justify-between items-center">
								<SypacText variant="overline-regular-large">
									<p className="text-xs text-gray-40">
										<T keyName="producerBillingStatistic.orders">Orders:</T>
									</p>
								</SypacText>
								<PeriodDropdown
									type="orders"
									periods={ordersMap}
									selectedPeriod={selectedOrdersPeriod}
									onPeriodChange={setSelectedOrdersPeriod}
								/>
							</div>
							<SypacText variant="body-regular-large">
								<p className="text-gray-80 font-medium">
									{parseFloat(ordersMap[selectedOrdersPeriod])}
								</p>
							</SypacText>
						</div>

						<div className="col-span-2 flex flex-col px-3 rounded-10 border border-solid border-gray-10 justify-center gap-[6px] h-[80px]">
							<div className="flex justify-between items-center">
								<SypacText variant="overline-regular-large">
									<p className="text-xs text-gray-40">
										<T keyName="producerBillingStatistic.revenue">Revenue:</T>
									</p>
								</SypacText>
								<PeriodDropdown
									type="revenue"
									periods={revenueMap}
									selectedPeriod={selectedRevenuePeriod}
									onPeriodChange={setSelectedRevenuePeriod}
								/>
							</div>
							<SypacText variant="body-regular-large">
								<p className="text-gray-80 font-medium">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator=" "
										decimalSeparator="."
										value={parseFloat(revenueMap[selectedRevenuePeriod])}
										suffix={countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								</p>
							</SypacText>
						</div>
					</div>

					<div className="flex-1 flex border border-solid border-gray-10 rounded-10">
						<div className="w-full h-[170px] flex">
							<div className="w-[180px]">
								{showChart && (
									<ApexDonutChart
										labels={[
											t(
												'producerBillingStatistic.donutPaidOrders',
												'Paid orders',
											),
											t(
												'producerBillingStatistic.donutPendingPayment',
												'Pending payment',
											),
											...(+ordersInDispute > 0
												? [
														t(
															'producerBillingStatistic.donutOverdueInDispute',
															'Overdue in dispute',
														),
												  ]
												: []),
										]}
										series={
											[paidOrders, orderInProgress, ordersInDispute]
												.map(Number)
												.reduce((sum, val) => sum + val, 0) === 0
												? []
												: [paidOrders, orderInProgress, ordersInDispute].map(
														Number,
												  )
										}
										total={+allOrders}
									/>
								)}
							</div>

							<div
								className={`flex flex-col gap-[10px] justify-center pt-8 pb-5 ${
									!showChart ? 'ml-[196px]' : '-ml-1'
								}`}
							>
								<div className="grid grid-cols-[164px,auto] items-start mr-4">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											<T keyName="producerBillingStatistic.allOrders">
												All orders:
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">{+allOrders || '—'}</p>
									</SypacText>
								</div>
								<div className="grid grid-cols-[164px,auto] items-start mr-4">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											<T keyName="producerBillingStatistic.paidOrders">
												Paid orders:
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">{+paidOrders || '—'}</p>
									</SypacText>
								</div>
								<div className="grid grid-cols-[164px,auto] items-start mr-4">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40">
											<T keyName="producerBillingStatistic.pendingPayment">
												Pending payment:
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">{+orderInProgress || '—'}</p>
									</SypacText>
								</div>
								{+ordersInDispute > 0 && (
									<div className="grid grid-cols-[164px,auto] items-start mr-4">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-40">
												<T keyName="producerBillingStatistic.overdueInDispute">
													Overdue in dispute:
												</T>
											</p>
										</SypacText>
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">{+ordersInDispute || '—'}</p>
										</SypacText>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</SypacBox>
		</div>
	);
};

export default ProducerBillingStatistic;
