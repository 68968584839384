import React, { useEffect, useState } from 'react';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { AvatarDropDownProps, AvatarItem } from './AvatarDropDown.interface';

const AvatarDropDown: React.FC<AvatarDropDownProps> = ({
	items,
	onChange,
	userIsAssigned,
}) => {
	const [listItems, setListItems] = useState<AvatarItem[]>([]);
	const [dropDownItems, setDropDownItems] = useState<AvatarItem[]>([]);
	const [openDropDown, setOpenDropDown] = useState<boolean>(false);
	const [selectedItem, setItem] = useState<string>();
	const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

	const dropDownRef = useDetectClickOutside({
		onTriggered: () => openDropDown && setOpenDropDown(false),
	});

	useEffect(() => {
		const handleResize = () => setScreenSize(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (items.length) {
			let displayCount = 5;
			if (screenSize >= 1024 && screenSize <= 1280) {
				displayCount = 2;
			}

			const avatars = [...items];
			const firstItems = avatars.splice(0, displayCount);
			setListItems(firstItems);
			setDropDownItems(avatars);
		} else {
			setListItems(items);
		}
	}, [items, screenSize]);

	const openDropDownItems = () => {
		setOpenDropDown(!openDropDown);
	};

	const selectItem = (item: AvatarItem) => {
		setOpenDropDown(false);
		setItem(item.id);
		onChange && onChange(item.id);
	};

	return (
		<div className="relative w-fit" ref={dropDownRef}>
			<div className="flex items-center">
				{listItems?.length
					? listItems?.map((item, index) => (
							<SypacAvatar
								onClick={() => selectItem(item)}
								initials={item.fullName}
								isGrouped={true}
								isChosen={userIsAssigned}
								key={index}
								size="md"
								className="cursor-pointer w-[29px] xl-2xl:w-auto scale-75 xl-2xl:scale-100"
							>
								{item.avatarUrl && (
									<img
										src={item.avatarUrl}
										alt={item.fullName}
										className="w-full h-full object-cover rounded-full"
									/>
								)}
							</SypacAvatar>
					  ))
					: null}
				{dropDownItems.length ? (
					<div
						className="relative w-[38px] xl-2xl:w-[47px] h-[38px] xl-2xl:h-[47px] flex justify-center items-center rounded-full outline-2 outline-white outline bg-primary-violet cursor-pointer"
						onClick={openDropDownItems}
					>
						<SypacText variant="body-regular-small">
							<p className="text-white font-bold">+{dropDownItems.length}</p>
						</SypacText>
					</div>
				) : null}
			</div>
			{openDropDown && dropDownItems.length ? (
				<div className="absolute top-[50px] left-1/2 transform -translate-x-1/2 flex flex-col p-[3px] border border-solid border-gray-10 rounded-10 shadow-dropdown-avatar bg-white min-w-[145px] w-max z-30">
					<div className="flex flex-col overflow-y-auto gap-1">
						{dropDownItems?.map((item, index) => {
							return (
								<div
									key={index}
									className={`flex items-center py-[10px] px-3 bg-white border border-solid border-transparent rounded-lg cursor-pointer hover:bg-gray-10-opacity-50 hover:border-gray-10 ${
										item.id === selectedItem ? 'bg-gray-10-opacity-50' : ''
									}`}
									onClick={() => selectItem(item)}
								>
									<SypacAvatar initials={item.fullName} size="sm">
										{item.avatarUrl && (
											<img
												src={item.avatarUrl}
												alt={item.fullName}
												className="w-full h-full object-cover rounded-full"
											/>
										)}
									</SypacAvatar>
									<SypacText variant="body-regular-small" className="ml-3">
										<p className="text-gray-80">{item.fullName}</p>
									</SypacText>
								</div>
							);
						})}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default AvatarDropDown;
