import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Flag from 'react-world-flags';
import { ProductContext } from '../../context/DatabaseProductContext/database-product.context';
import Close from '../../assets/Close';
import {
	ModalTranslatesProps,
	TranslationFormValues,
} from './ModalAddTranslates.interface';

const ModalAddTranslates: React.FC<ModalTranslatesProps> = ({
	isOpen,
	onClose,
	onSave,
	currentValues,
	type,
	currentTranslate,
	translations = [],
}) => {
	const { t } = useTranslate();
	const { setAdditional } = useContext(ProductContext);
	const modalRef = useRef<HTMLDivElement>(null);
	const [charCount, setCharCount] = useState<{ [key: string]: number }>({
		mainField: 0,
		pl: 0,
		ro: 0,
		ru: 0,
	});

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			modalRefs: [...prev.modalRefs, modalRef],
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInitialValues = () => {
		const valueFromTranslations = (lang: string) => {
			const translation = translations.find((t) => t.language === lang);
			if (translation) {
				return translation[type] || translation.value || '';
			}
			return '';
		};

		return {
			mainField:
				valueFromTranslations('en') ||
				currentValues[type as keyof typeof currentValues] ||
				'',
			pl: valueFromTranslations('pl'),
			ro: valueFromTranslations('ro'),
			ru: valueFromTranslations('ru'),
		};
	};

	const validationSchema = Yup.object({
		mainField: Yup.string().required(
			t(
				`modalAddProduct.${type}Required`,
				`Please enter the ${currentTranslate} ${type}`,
			),
		),
		pl: Yup.string().required(
			t(
				`modalAddProduct.${type}PLRequired`,
				`Please enter the ${currentTranslate} ${type} in Polish`,
			),
		),
		ro: Yup.string().required(
			t(
				`modalAddProduct.${type}RORequired`,
				`Please enter the ${currentTranslate} ${type} in Romanian`,
			),
		),
		ru: Yup.string().required(
			t(
				`modalAddProduct.${type}RURequired`,
				`Please enter the ${currentTranslate} ${type} in Russian`,
			),
		),
	});

	if (type === 'description') {
		Object.keys(validationSchema.fields).forEach((key) => {
			// @ts-ignore
			validationSchema.fields[key] = validationSchema.fields[key].concat(
				Yup.string().max(
					1500,
					t(
						'modalAddProduct.descriptionTooLong',
						'Description must not exceed 1500 characters',
					),
				),
			);
		});
	}

	const formik = useFormik({
		initialValues: getInitialValues(),
		validationSchema,
		onSubmit: (values) => {
			const body: {
				key: string;
				translations: Array<{
					language: string;
					value: string;
					[key: string]: string;
				}>;
			} = {
				key: type,
				translations: [
					{
						language: 'en',
						[type]: values.mainField,
						value: values.mainField,
					},
					{
						language: 'ro',
						[type]: values.ro,
						value: values.ro,
					},
					{
						language: 'ru',
						[type]: values.ru,
						value: values.ru,
					},
					{
						language: 'pl',
						[type]: values.pl,
						value: values.pl,
					},
				],
			};
			onSave(body);
			onClose();
		},
	});

	useEffect(() => {
		const values = getInitialValues();
		formik.setValues(values);

		setCharCount({
			mainField: values.mainField.length || 0,
			pl: values.pl.length || 0,
			ro: values.ro.length || 0,
			ru: values.ru.length || 0,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentValues, type, translations]);

	const translationFields = [
		{ field: 'pl', label: 'PL', flag: 'POL' },
		{ field: 'ro', label: 'RO', flag: 'ROU' },
		{ field: 'ru', label: 'RU', flag: 'RUS' },
	];

	const handleTextareaChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
		field: string,
	) => {
		const value = event.target.value;
		formik.handleChange(event);
		setCharCount((prev) => ({
			...prev,
			[field]: value.length,
		}));
	};

	const handleReset = () => {
		formik.resetForm();
		setCharCount({
			mainField: 0,
			pl: 0,
			ro: 0,
			ru: 0,
		});
		onClose();
	};

	const getFieldError = (fieldName: string) => {
		return (
			formik.touched[fieldName as keyof TranslationFormValues] &&
			formik.errors[fieldName as keyof TranslationFormValues]
		);
	};

	const getLanguageName = (field: string) => {
		const fieldMapping: Record<string, string> = {
			PL: 'Polish',
			RO: 'Romanian',
			RU: 'Russian',
		};

		return fieldMapping[field] ?? 'English';
	};

	const renderField = (fieldName: string, label: string, flagCode: string) => {
		const isDescriptionType = type === 'description';
		const fieldError = getFieldError(fieldName);

		return (
			<div className="flex flex-col p-[10px] border border-dashed border-gray-10 rounded-10 bg-alabaster">
				<SypacText className="mb-1" variant="overline-normal-large">
					<p>
						<T
							keyName={`modalAddProduct.${label} language ${currentTranslate?.toLowerCase()} ${type}`}
						>
							{`${getLanguageName(
								label,
							)} language ${currentTranslate?.toLowerCase()} ${type}`}
						</T>
						<span className="text-red">*</span>
					</p>
				</SypacText>

				{isDescriptionType ? (
					<div className="relative">
						<SypacInput>
							<div className="relative w-full flex flex-col gap-[10px]">
								<textarea
									name={fieldName}
									className={`w-full box-border h-[80px] font-sans py-3.5 pl-3 border border-solid rounded-xl placeholder:text-gray-40 placeholder:text-base resize-none outline-none ${
										fieldError
											? 'border-red-orange'
											: 'border-gray-20 hover:border-gray-40/75 focus:border-[#5682fa]'
									}`}
									rows={2}
									maxLength={1500}
									placeholder={t(
										`modalAddProduct.enter${type}`,
										`Enter ${currentTranslate?.toLowerCase()} ${type}`,
									)}
									onChange={(e) => handleTextareaChange(e, fieldName)}
									onBlur={formik.handleBlur}
									value={
										formik.values[fieldName as keyof TranslationFormValues]
									}
								/>
								<div className="flex items-center justify-between">
									{fieldError && (
										<span className="input-error mb-1">
											{formik.errors[
												fieldName as keyof TranslationFormValues
											]?.toString()}
										</span>
									)}
									<div className="w-fit h-fit flex bg-gray-10 text-xs text-gray-40 rounded-2xl px-[5px] py-[1px] ml-auto">
										{`${charCount[fieldName as keyof typeof charCount]} / 1500`}
									</div>
								</div>
							</div>
						</SypacInput>
						<div className="absolute flex top-[8px] right-[8px]">
							<Flag
								className="object-cover border border-solid border-gray-10 rounded"
								code={flagCode}
								width={20}
								height={14}
							/>
						</div>
					</div>
				) : (
					<div className="relative">
						<SypacInput error={!!fieldError}>
							<input
								className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
									fieldError
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
								}`}
								name={fieldName}
								type="text"
								placeholder={t(
									`modalAddProduct.enter${type}`,
									`Enter ${currentTranslate?.toLowerCase()} ${type}`,
								)}
								value={formik.values[fieldName as keyof TranslationFormValues]}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</SypacInput>
						{fieldError && (
							<span className="input-error">
								{formik.errors[
									fieldName as keyof TranslationFormValues
								]?.toString()}
							</span>
						)}
						<div className="absolute flex top-[14px] right-[8px]">
							<Flag
								className="object-cover border border-solid border-gray-10 rounded"
								code={flagCode}
								width={20}
								height={14}
							/>
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none w-[660px] box-border"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div ref={modalRef} className="h-full flex flex-col">
				<form onSubmit={formik.handleSubmit} className="h-full flex flex-col">
					<div className="flex flex-row items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T
									keyName={`modalTranslates.${currentTranslate?.toLowerCase()}${
										type.charAt(0).toUpperCase() + type.slice(1)
									}Translates`}
								>
									{`${currentTranslate} ${type} translates`}
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div className="flex flex-col gap-3 p-3">
						{renderField('mainField', 'English', 'GBR')}
						{translationFields.map(({ field, label, flag }) =>
							renderField(field, label, flag),
						)}
					</div>

					<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[42px] flex items-center justify-center rounded-lg transition"
								onClick={handleReset}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="modalDownload.close">Close</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>

						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="submit"
								className="w-full h-[42px] flex items-center justify-center bg-primary-violet border-0 rounded-lg transition hover:bg-primary-violet/70"
							>
								<SypacText variant="body-regular-medium">
									<p className="text-white">
										<T keyName="modalManageProduct.save">Save</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default ModalAddTranslates;
