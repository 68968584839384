import React, { DragEvent, useState, ChangeEvent } from 'react';
import Modal from 'react-modal';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import CircleClose from '../../../../assets/CircleClose';
import CircleValid from '../../../../assets/CircleValid';
import PreviewDocument from '../../../../assets/PreviewDocument';
import { toastVariant } from '../../../../components/ToastVariant/toastVariant';
import { UploadLocationPhotoProps } from '../clientSelectLocation.interface';
import Close from '../../../../assets/Close';
import { MaxFileSizeMB } from '../../../../constants';

const UploadLocationPhoto: React.FC<UploadLocationPhotoProps> = ({
	isOpen,
	onClose,
	onSave,
}) => {
	const [file, setFile] = useState<File>();

	const validateFileSize = (uploadedFile: File): boolean => {
		if (uploadedFile.size / (1024 * 1024) > MaxFileSizeMB.DOCUMENT) {
			toastVariant(
				`File size exceeds ${MaxFileSizeMB.DOCUMENT} MB. Please upload a smaller file.`,
				true,
			);
			return false;
		}
		return true;
	};

	const handleFileSelection = (selectedFile: File) => {
		if (validateFileSize(selectedFile)) {
			setFile(selectedFile);
		}
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const droppedFile = e.dataTransfer.files?.[0];
		if (droppedFile) {
			handleFileSelection(droppedFile);
		}
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const selectedFile = e.target.files?.[0];
		if (selectedFile) {
			handleFileSelection(selectedFile);
		}
	};

	const FileDisplay = () => (
		<article className="flex py-6 justify-between items-center">
			<div className="flex items-start">
				<PreviewDocument />
				<div className="flex flex-col ml-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{file?.name}</p>
					</SypacText>
					<SypacText variant="overline-regular-large" className="mt-3">
						<p className="text-gray-20">
							{`${file?.size ? (file.size / (1024 * 1024)).toFixed(1) : 0} MB`}
						</p>
					</SypacText>
				</div>
			</div>
		</article>
	);

	const DropZone = () => (
		<div
			onDragEnter={(e) => e.preventDefault()}
			onDragLeave={(e) => e.preventDefault()}
			onDragOver={(e) => e.preventDefault()}
			onDrop={handleDrop}
			className="border border-gray-90 border-dashed rounded-10 h-24 w-full mt-4"
		>
			<input
				type="file"
				id="input-file-upload"
				className="hidden"
				multiple
				accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
				onChange={handleChange}
			/>
			<label
				htmlFor="input-file-upload"
				className="flex flex-col gap-[10px] justify-center items-center h-full cursor-pointer"
			>
				<SypacText variant="body-regular-medium" className="flex">
					<p className="text-gray-80">
						<T keyName="userSettings.dragAndDrop">Drag and drop or</T>
					</p>
					&nbsp;
					<p className="text-cornflower-blue underline">
						<T keyName="userSettings.browseFile">Browse File</T>
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium">
					<p className="text-xs text-nevada">
						<T
							keyName="modalAddProduct.weSupport"
							params={{ size: MaxFileSizeMB.DOCUMENT }}
						>
							We support JPEG, or PNG, files. Max file size: MB.
						</T>
					</p>
				</SypacText>
			</label>
		</div>
	);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[408px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<header className="flex items-center justify-between px-6 py-4">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-90">
						<T keyName="uploadLocationPhoto.uploadDeliveryLocationPhoto">
							Do you wish to upload delivery location photo
						</T>
					</p>
				</SypacText>
				<SypacButton variant="subTitle">
					<button
						type="button"
						className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
						onClick={onClose}
					>
						<Close width="10" height="10" />
					</button>
				</SypacButton>
			</header>

			<main className="flex flex-col items-start justify-center modal-confirmation-code-border p-6 gap-4">
				{file ? <FileDisplay /> : <DropZone />}
			</main>

			<footer className="flex justify-center p-6">
				<div className="flex gap-x-4">
					<SypacButton
						variant="secondary"
						size="small"
						onClick={() => onSave()}
					>
						<button
							className="px-10 py-2.5 border-red flex items-center"
							type="reset"
						>
							<CircleClose />
							<SypacText variant="body-regular-medium" className="ml-3">
								<p className="text-red">
									<T keyName="uploadLocationPhoto.no">No</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="secondary"
						size="small"
						onClick={() => onSave(file)}
					>
						<button
							className="px-10 py-2.5 border-mountain-meadow flex items-center"
							type="reset"
							disabled={!file}
						>
							<CircleValid />
							<SypacText variant="body-regular-medium" className="ml-3">
								<p className="text-mountain-meadow">
									<T keyName="uploadLocationPhoto.save">Save</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</footer>
		</Modal>
	);
};

export default UploadLocationPhoto;
