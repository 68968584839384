import React, { useContext, useEffect, useRef } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { ModalVerificationCodeProps } from './ModalVerificationCode.interface';
import './modal-verification-code.css';
import { T } from '@tolgee/react';
import PrintCode from './PrintCode';
import { OrderContext } from '../../context/OrderContext/order.context';
import Close from '../../assets/Close';

const ModalVerificationCode: React.FC<ModalVerificationCodeProps> = ({
	truck,
	isOpen,
	onClose,
}) => {
	const { setAdditional } = useContext(OrderContext);
	const modalRef = useRef<HTMLDivElement>(null);
	const printRef = useRef<{ print: () => void } | null>(null);

	const download = async () => {
		try {
			if (!truck?.qrPdfUrl) {
				return;
			}
			const response = await fetch(truck?.qrPdfUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.setAttribute('download', `${truck.code}.pdf`);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} finally {
			onClose && onClose();
		}
	};

	const handlePrint = () => {
		printRef.current?.print();
	};

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={onClose}>
			<div
				className="max-w-[563px] bg-white rounded-xl"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between p-5">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalViewQrCode.loginCredentials">
									Driver app login credentials
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="flex flex-col items-center justify-center modal-verification-code-border p-6">
						<div>
							<SypacText variant="heading-5">
								<p className="text-gray-90">
									<T keyName="modalViewQrCode.truckName">Truck name:</T>{' '}
									<span className="font-medium">{truck?.name}</span>
								</p>
							</SypacText>
						</div>
						<div className="mt-6">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-40">
									<T keyName="modalViewQrCode.scan">
										Scan with your phone to login via the QR code.
									</T>
								</p>
							</SypacText>
						</div>
						<div className="mt-10 rounded-10 border border-solid border-gray-10">
							<div className="py-3 px-2 flex border-0 border-solid border-b border-gray-10 rounded-10 justify-center">
								<SypacText variant="body-regular-small">
									<p className="text-gray-40 text-center">
										<T keyName="modalViewQrCode.licensePlates">
											License plates:
										</T>{' '}
										{truck?.licensePlates}
									</p>
								</SypacText>
							</div>
							<div className="w-60 h-60">
								<img
									src={truck?.qrCodeUrl}
									alt={truck?.code}
									className="w-full object-cover"
								/>
							</div>
							<div className="py-2 px-2 flex border-0 border-solid border-t border-gray-10 rounded-10 justify-center">
								<SypacText variant="heading-5">
									<p className="text-gray-90">
										<T keyName="modalViewQrCode.loginCode">Login code:</T>{' '}
										<span className="font-medium">{truck?.code}</span>
									</p>
								</SypacText>
							</div>
						</div>
					</div>

					<div className="flex flex-row px-3 gap-3">
						<SypacButton
							variant="secondary"
							size="small"
							className="flex flex-row justify-center w-full py-5"
							onClick={handlePrint}
						>
							<button className="flex flex-row w-full">
								<SypacText variant="body-regular-medium">
									<p className="text-primary-violet -tracking-[0.01em]">
										<T keyName="modalViewQrCode.print">Print</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton
							variant="primary"
							size="small"
							className="flex flex-row justify-center w-full py-5"
							onClick={download}
						>
							<button className="flex flex-row w-full">
								<SypacText variant="body-regular-medium">
									<p className="text-white -tracking-[0.01em]">
										<T keyName="modalViewQrCode.download">Download</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>

				<div style={{ display: 'none' }}>
					<PrintCode truck={truck} ref={printRef} />
				</div>
			</div>
		</div>
	) : null;
};

export default ModalVerificationCode;
