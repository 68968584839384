import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import './orders-table.css';
import { OrdersTableInterface } from './interfaces/OrdersTable.interface';
import { OrderContext } from '../../context/OrderContext/order.context';
import { OrderInterface } from './interfaces/Order.interface';
import { T, useTranslate } from '@tolgee/react';
import { NewNotification } from '../../assets/NewNotification';
import { Stack } from '../../assets/Stack';
import { TruckApprove } from '../../assets/TruckApprove';
import Flag from 'react-world-flags';
import { Calendar } from '../../assets/Calendar';
import Clock from '../../assets/Clock';
import Tons from '../../assets/Tons';
import PriceTag from '../../assets/PriceTag';
import Arrows from '../../assets/Arrows';
import StatusBadge from '../StatusBadge/StatusBadge';
import { TruckNotApprove } from '../../assets/TruckNotApprove';
import { BlueBox } from '../../assets/BlueBox';
import {
	convertToTimeZone,
	deliveryDates,
	formatDate,
	formatTimeSince,
} from '../../utils/time.util';
import { Tooltip } from 'react-tooltip';
import { OrderTarget, PaymentMethod } from './interfaces/OrderStatus.interface';
import { OrderStatusesEnum } from '../../services/orders.services';
import { NumericFormat } from 'react-number-format';
import EmptyPlaceholder from './components/EmptyPlaceholder/EmptyPlaceholder';
import { ChevronRightIcon } from '../../assets/ChevronRightIcon';
import TruckIcon from '../TruckIcon/TruckIcon';
import { AuthContext } from '../../context/context';
import useDebounce from '../../hooks/useDebounce';
import { numberTwoDecimal } from '../../utils/number.util';
import { isUrl } from '../../utils/string.util';

export const OrdersTable = ({
	rows,
	rowsLoading,
	rowClick,
	search,
	target,
	currentTab,
}: OrdersTableInterface) => {
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const { selectedOrderId } = useContext(OrderContext);
	const language = localStorage.getItem('lang') || 'en';
	const [keyRows, setRows] = useState<OrderInterface[]>([]);
	const [showWithVat, setShowWithVat] = useState(true);
	const [languageDropdownOpen, setLanguageDropdownOpen] =
		useState<boolean>(false);
	const [activeLanguage, setActiveLanguage] = useState<string>(language);
	const ordersLoading = useDebounce(rowsLoading, 200);

	const activeLanguageRef = useRef(activeLanguage);

	useEffect(() => {
		activeLanguageRef.current = activeLanguage;
	}, [activeLanguage]);

	const toggleVatDisplay = () => {
		setShowWithVat(!showWithVat);
	};

	const getPriceColumn = (showWithVat: boolean) => {
		return {
			title: (
				<div className="flex gap-1 items-center">
					<p className="w-[142px] m-0">
						{showWithVat ? (
							<T keyName="basicDetails.priceWithWat">Price (incl.VAT)</T>
						) : (
							<T keyName="basicDetails.price">Price (excl.VAT)</T>
						)}
					</p>
					<button
						onClick={() => toggleVatDisplay()}
						className="flex px-2 py-1 items-center justify-center border border-solid border-transparent rounded-md bg-transparent cursor-pointer transition hover:border-gray-20 active:border-gray-40"
					>
						<Arrows width={9} height={16} />
					</button>
				</div>
			),
			dataIndex: '',
			className: 'text-left',
			key: 'pricePerUnit',
			width: 150,
			render(order: OrderInterface) {
				const {
					productPriceForClientWithVat,
					transportPriceForClientWithVat,
					productPriceForClient,
					transportPriceForClient,
					priceForTransporterWithVat,
					priceForTransporter,
					priceForProducerWithVat,
					priceForProducer,
					paymentMethod,
					trucks = [],
					product,
				} = order;

				const isOffer = 'offerId' in order;

				const carrierPrices = trucks.reduce(
					(acc, truck) => {
						acc.priceForTransporter += truck.priceForTransporter!;
						acc.priceForTransporterWithVat += truck.priceForTransporterWithVat!;
						return acc;
					},
					{ priceForTransporter: 0, priceForTransporterWithVat: 0 },
				);

				const formatPrice = (price: number): string => {
					return price
						.toString()
						.replace(/(\.\d{1,2})\d*$/, '$1')
						.replace(/\.0+$/, '');
				};

				const calculatePrices = (target: string) => {
					const commonClientPrices = {
						priceWithVat: formatPrice(
							productPriceForClientWithVat! + transportPriceForClientWithVat!,
						),
						priceNoVat: formatPrice(
							productPriceForClient! + transportPriceForClient!,
						),
					};

					const priceMap: Record<string, any> = {
						customer: commonClientPrices,
						admin: commonClientPrices,
						transporter: isOffer
							? {
									priceWithVat: formatPrice(
										carrierPrices.priceForTransporterWithVat,
									),
									priceNoVat: formatPrice(carrierPrices.priceForTransporter),
							  }
							: {
									priceWithVat: formatPrice(priceForTransporterWithVat!),
									priceNoVat: formatPrice(priceForTransporter!),
							  },
						producer: {
							priceWithVat: formatPrice(priceForProducerWithVat!),
							priceNoVat: formatPrice(priceForProducer!),
						},
					};

					return priceMap[target] || { priceWithVat: 0, priceNoVat: 0 };
				};

				const { priceWithVat, priceNoVat } = calculatePrices(target!);
				const displayPaymentMethod =
					paymentMethod === PaymentMethod.bank ? 'bankWireShort' : 'card';

				return (
					<div className="flex flex-col gap-2 [&_p]:text-gray-80">
						<div className="flex items-center px-[4px]">
							<PriceTag />
							<SypacText variant="body-regular-medium" className="ml-4">
								<p className="font-bold">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator=" "
										decimalSeparator="."
										value={showWithVat ? +priceWithVat : +priceNoVat}
										suffix={product.currency === 'lei' ? ' MDL' : ' PLN'}
									/>
								</p>
							</SypacText>
						</div>

						{target !== 'producer' && (
							<div className="w-fit min-w-[125px] flex gap-1.5 rounded-md items-center justify-between px-1 py-[2px] bg-gray-10-opacity-50 border border-solid border-gray-10 whitespace-nowrap">
								<SypacText variant="body-regular-medium">
									<p className="text-xs">
										<T keyName={`baseOrderDetails.${displayPaymentMethod}`}>
											{displayPaymentMethod}
										</T>
									</p>
								</SypacText>
								<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
								<SypacText variant="body-regular-medium">
									<p className="text-xs">
										{order.paymentTerm}{' '}
										<T keyName="baseOrderDetails.paymentDays">Days</T>
									</p>
								</SypacText>
							</div>
						)}
					</div>
				);
			},
		};
	};

	const locale = [
		{
			title: 'English',
			value: 'en',
		},
		{
			title: 'Polish',
			value: 'pl',
		},
		{
			title: 'Romanian',
			value: 'ro',
		},
		{
			title: 'Russian',
			value: 'ru',
		},
	];

	const getNameColumn = (search: string | undefined) => {
		return {
			title: (
				<div className="relative flex items-center">
					<p className="m-0 mt-[2px]">
						<T keyName="ordersTable.productAndQuantity">Product</T>
					</p>

					{/* <button
						type="button"
						onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
						className="absolute top-[1px] left-[74px] flex gap-3 items-center justify-center border border-solid border-transparent rounded-md bg-transparent cursor-pointer transition hover:border-gray-20 px-1 py-[1px] active:border-cornflower-blue"
					>
						<span className="rounded-[3px] overflow-hidden">
							<Flag
								className="object-cover border border-solid border-gray-10 rounded mt-1"
								code={
									{
										en: 'GBR',
										ro: 'ROU',
										ru: 'RUS',
										pl: 'POL',
									}[activeLanguage] || 'GBR'
								}
								width={20}
								height={14}
							/>
						</span>
						<span
							className={`flex transform transition-all duration-300 ease-in-out ${
								languageDropdownOpen ? 'rotate-90' : ''
							}`}
						>
							<ChevronRightIcon />
						</span>
					</button> */}

					{languageDropdownOpen && (
						<div className="absolute top-8 left-10 flex flex-col p-[3px] border border-solid border-gray-10 rounded-10 shadow-dropdown bg-white min-w-[145px] w-max z-30">
							{locale.map((local) => (
								<div
									key={local.value}
									className="flex py-[10px] px-3 bg-white border border-solid border-transparent rounded-lg cursor-pointer hover:bg-gray-10-opacity-50 hover:border-gray-10"
									onClick={() => {
										setActiveLanguage(local.value);
										setLanguageDropdownOpen(false);
									}}
								>
									<SypacText variant="body-regular-medium" className="mr-auto">
										<p className="text-gray-80">{local.title}</p>
									</SypacText>
								</div>
							))}
						</div>
					)}
				</div>
			),
			dataIndex: '',
			className: 'text-left',
			key: 'name',
			width: 50,
			render(order: OrderInterface) {
				const productName = `${
					order?.product?.translations?.find(
						(locale) => locale.language === activeLanguage,
					)?.name || order?.product?.name
				}`;
				const productSize = `${
					order?.product?.type ? `${order?.product?.type}` : ''
				} ${order?.product?.size ? `(${order?.product?.size})` : ''}`;
				const orderQuantity = order?.trucks?.reduce(
					(sum, truck) => sum + truck.payload,
					0,
				);

				const htmlName = search
					? productName?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productName;
				const htmlSize = search
					? productSize?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productSize;
				const productUrl = order?.product?.photoUrl;

				return (
					<div className="flex gap-6 [&_p]:text-gray-80">
						<div
							style={{
								backgroundImage: `url(${productUrl})`,
							}}
							className="flex flex-none w-[58px] h-[58px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10"
							role="img"
						/>
						<div className="flex flex-col gap-2 justify-center w-full">
							<div className="flex items-center gap-4">
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlName }} />
								</SypacText>
								{productSize.trim() ? (
									<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
								) : null}
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlSize }} />
								</SypacText>
							</div>
							<div className="flex gap-5">
								<Tons />
								<SypacText variant="body-regular-medium">
									<p>
										{orderQuantity} <T keyName="ordersTable.tons">tons</T>
									</p>
								</SypacText>
							</div>
						</div>
					</div>
				);
			},
		};
	};

	const columnsAdmin = (search?: string) => [
		{
			title: '',
			dataIndex: '',
			className: '',
			key: 'notification',
			width: 20,
			render(order: OrderInterface) {
				return (
					<div className="flex justify-center">
						{order.notificationCount ? <NewNotification /> : null}
					</div>
				);
			},
		},
		{
			title: t('ordersTable.orderStatusTypeAndId', 'Order status, type & ID'),
			dataIndex: '',
			className: '',
			key: 'status',
			width: 50,
			render(value: OrderInterface) {
				const htmlId = search
					? value?.orderId
							?.toString()
							.replace(search, `<span class="text-texas-rose">${search}</span>`)
					: value?.orderId?.toString();

				return (
					<div className="flex flex-col gap-2 items-center">
						<div className="w-full flex gap-2 items-center justify-center text-gray">
							<span
								data-tooltip-id="order-hint"
								data-tooltip-content={
									value?.trucks && value.trucks.length > 1
										? t(
												'baseOrderDetails.multipleDelivery',
												'Multiple delivery',
										  )
										: t('baseOrderDetails.singleDelivery', 'Single delivery')
								}
								className="p-0 m-0 flex scale-[1.2]"
							>
								{value?.trucks && value.trucks.length > 1 ? (
									<Stack />
								) : (
									<BlueBox />
								)}
							</span>

							<span
								data-tooltip-id="order-hint"
								data-tooltip-content={
									value.deliveryIncluded
										? t(
												'baseOrderDetails.transportIncluded',
												'Transport included',
										  )
										: t(
												'baseOrderDetails.transportExcluded',
												'Transport excluded',
										  )
								}
								className="p-0 m-0 flex scale-[1.2] ml-1"
							>
								{value.deliveryIncluded ? (
									<TruckApprove />
								) : (
									<TruckNotApprove />
								)}
							</span>

							<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
							<SypacText variant="body-regular-medium" className="mt-[2px]">
								<p className="flex m-0">
									ID:
									<p
										className="m-0"
										dangerouslySetInnerHTML={{ __html: htmlId! }}
									/>
								</p>
							</SypacText>
							<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
							<span
								data-tooltip-id="order-hint"
								data-tooltip-content={t(
									'ordersTable.timeSinceOrderCreated',
									'Time since Order created',
								)}
							>
								<SypacText variant="body-regular-medium" className="mt-[2px]">
									<p>
										<T
											keyName={`baseOrderDetails.${formatTimeSince(
												value.createdAt,
												false,
											)}`}
										>
											{formatTimeSince(value.createdAt, false)}
										</T>
									</p>
								</SypacText>
							</span>
						</div>
						{value.status ? <StatusBadge status={value.status} /> : null}
						<Tooltip
							place="top"
							id="order-hint"
							style={{
								backgroundColor: '#E8E8E8',
								color: '#000000',
							}}
						/>
					</div>
				);
			},
		},
		{
			title: t('ordersTable.deliveryDateAndPlace', 'Delivery date & place'),
			dataIndex: '',
			className: 'text-left',
			key: 'carrierDatePlace',
			width: 50,
			render(order: OrderInterface) {
				const htmlDelivery = search
					? order?.deliveryAddress
							?.toString()
							.replace(
								new RegExp(`(${search})`, 'gi'),
								`<span class="text-texas-rose">$1</span>`,
							)
					: order?.deliveryAddress?.toString();

				return (
					<div className="flex flex-col gap-4 [&_p]:text-gray-80">
						<div className="flex gap-8">
							<div className="flex gap-6 items-center">
								<span className="flex items-center scale-[1.2]">
									<Calendar />
								</span>
								<SypacText variant="body-regular-medium">
									<p className="w-[100px] truncate">
										{deliveryDates(order?.trucks!, false, 'deliveryFrom', true)}
									</p>
								</SypacText>
							</div>
							<div className="flex gap-6 items-center">
								<div className="w-[7px] h-[7px] rounded-full bg-texas-rose" />
								<div className="flex flex-row items-center">
									{order?.deliveryCountryCode ? (
										<Flag
											className="object-cover border border-solid border-gray-10 rounded mt-1"
											code={order?.deliveryCountryCode}
											width={20}
											height={14}
										/>
									) : null}
									<SypacText
										variant="body-regular-medium"
										className={order?.deliveryCountryCode ? 'ml-3' : ''}
									>
										<p
											className="max-w-[655px] truncate"
											dangerouslySetInnerHTML={{ __html: htmlDelivery! }}
										/>
									</SypacText>
								</div>
							</div>
						</div>

						<div className="flex gap-8">
							<div className="flex gap-6 items-center">
								<span className="flex items-center scale-[1.2]">
									<Calendar />
								</span>
								<SypacText variant="body-regular-medium">
									<p className="w-[100px] truncate">
										{deliveryDates(order?.trucks!, false, 'deliveryTo', true)}
									</p>
								</SypacText>
							</div>
							<div className="flex gap-6 items-center">
								<div className="w-[7px] h-[7px] rounded-full bg-mountain-meadow" />
								<div className="flex flex-row items-center">
									{order?.pickupCountryCode ? (
										<Flag
											className="object-cover border border-solid border-gray-10 rounded mt-1"
											code={order?.pickupCountryCode}
											width={20}
											height={14}
										/>
									) : null}
									<SypacText
										variant="body-regular-medium"
										className={order?.pickupCountryCode ? 'ml-3' : ''}
									>
										<p className="max-w-[655px] truncate">
											{order?.pickupAddress}
										</p>
									</SypacText>
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
		getNameColumn(search),
		{
			title: t('ordersTable.truckInfo', 'Truck info'),
			dataIndex: '',
			className: 'text-left',
			key: 'truckInfo',
			width: 50,
			render(order: OrderInterface) {
				const trucks = order?.trucks!;
				const type = trucks?.[0].truckType;
				const payload = trucks?.[0].payload;

				return (
					<div className="flex flex-col gap-2">
						{trucks.length > 1 ? (
							<div className="w-full h-[25px] flex gap-3 rounded-md items-center justify-center p-1 bg-shadow-blue box-border">
								<span className="flex">
									<Stack color="#FFFFFF" />
								</span>
								<SypacText variant="overline-regular-large">
									<p className="text-white mt-[1px]">Multiple order</p>
								</SypacText>
							</div>
						) : null}

						<div className="flex gap-3">
							<div className="w-full h-[15px] flex gap-3 rounded-md items-center justify-center p-1 bg-gray-10-opacity-50 border border-solid border-gray-10">
								<span className="w-[37px] flex">
									<TruckIcon type={type} />
								</span>
								<SypacText variant="overline-regular-large">
									<p>
										<T keyName={`quantityPicker.${type}`}>
											{type?.charAt(0).toUpperCase() + type?.slice(1)}
										</T>{' '}
										{payload}t
									</p>
								</SypacText>
							</div>
							{order?.trucks && order.trucks.length > 1 ? (
								<div className="w-fit h-[15px] flex rounded-md items-center p-1 bg-gray-10-opacity-50 border border-solid border-gray-10">
									<SypacText variant="overline-regular-large">
										<p>+{order.trucks.length - 1}</p>
									</SypacText>
								</div>
							) : null}
						</div>
					</div>
				);
			},
		},
		getPriceColumn(showWithVat),
		{
			title: t('ordersTable.Client', 'Client'),
			dataIndex: '',
			className: 'text-left',
			key: 'clientCompany',
			width: 50,
			render(order: OrderInterface) {
				const client = order?.createdByUser;
				const company = order?.createdByUser?.company;
				const htmlCompanyName = search
					? company?.name
							?.toString()
							.replace(search, `<span class="text-texas-rose">${search}</span>`)
					: order?.createdByUser?.company?.name?.toString();

				return (
					<div className="flex flex-col gap-2 [&_p]:text-gray-80">
						<div className="flex items-center">
							<SypacAvatar initials={company?.name} size="sm">
								{isUrl(company?.logoUrl!) && (
									<img
										src={company?.logoUrl}
										alt={company?.name}
										className="w-full h-full object-cover rounded-full"
									/>
								)}
							</SypacAvatar>
							<SypacText variant="body-regular-medium" className="ml-3">
								<p
									className="m-0"
									dangerouslySetInnerHTML={{ __html: htmlCompanyName! }}
								/>
							</SypacText>
						</div>
						<div className="flex items-center">
							<SypacAvatar size="sm" initials={client?.name}>
								{client?.profile?.photoUrl && (
									<img
										src={client?.profile?.photoUrl}
										alt={client?.name}
										className="w-full h-full object-cover rounded-full"
									/>
								)}
							</SypacAvatar>
							<SypacText variant="body-regular-medium" className="ml-3">
								<p>{client?.name}</p>
							</SypacText>
						</div>
					</div>
				);
			},
		},
	];

	const columns = (search?: string, target?: string) => [
		{
			title: '',
			dataIndex: '',
			className: '',
			key: 'notification',
			width: 20,
			render(order: OrderInterface) {
				return (
					<div className="flex justify-center">
						{order.notificationCount ? <NewNotification /> : null}
					</div>
				);
			},
		},
		{
			title: t('ordersTable.orderStatusTypeAndId', 'Order status, type & ID'),
			dataIndex: '',
			className: '',
			key: 'status',
			width: 50,
			render(order: OrderInterface) {
				const id = order?.orderId || order?.id;
				const html = search
					? id
							?.toString()
							.replace(search, `<span class="text-texas-rose">${search}</span>`)
					: id?.toString();

				return (
					<div className="flex flex-col gap-2 items-center">
						<div className="w-full flex gap-2 items-center justify-center text-gray">
							{order.target === OrderTarget.customer ? (
								<span
									data-tooltip-id="order-hint"
									data-tooltip-content={
										order?.trucks && order.trucks.length > 1
											? t(
													'baseOrderDetails.multipleDelivery',
													'Multiple delivery',
											  )
											: t('baseOrderDetails.singleDelivery', 'Single delivery')
									}
									className="p-0 m-0 flex scale-[1.2]"
								>
									{order?.trucks && order.trucks.length > 1 ? (
										<Stack />
									) : (
										<BlueBox />
									)}
								</span>
							) : null}

							<span
								data-tooltip-id="order-hint"
								data-tooltip-content={
									order.deliveryIncluded
										? t(
												'baseOrderDetails.transportIncluded',
												'Transport included',
										  )
										: order.target !== OrderTarget.producer
										? t(
												'baseOrderDetails.transportExcluded',
												'Transport excluded',
										  )
										: t('baseOrderDetails.selfService', 'Self service')
								}
								className="p-0 m-0 flex scale-[1.2] ml-1"
							>
								{order.deliveryIncluded ? (
									<TruckApprove />
								) : (
									<TruckNotApprove />
								)}
							</span>

							<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
							<SypacText variant="body-regular-medium" className="mt-[2px]">
								<p className="flex m-0">
									ID:
									<p
										className="m-0"
										dangerouslySetInnerHTML={{ __html: html! }}
									/>
								</p>
							</SypacText>
							<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
							<span
								data-tooltip-id="order-hint"
								data-tooltip-content={t(
									'ordersTable.timeSinceOrderCreated',
									'Time since Order created',
								)}
							>
								<SypacText variant="body-regular-medium" className="mt-[2px]">
									<p>
										<T
											keyName={`baseOrderDetails.${formatTimeSince(
												order.createdAt,
												false,
											)}`}
										>
											{formatTimeSince(order.createdAt, false)}
										</T>
									</p>
								</SypacText>
							</span>
						</div>
						{order.status ? (
							<StatusBadge
								status={
									order.status === 'in_progress'
										? order.status
										: target === OrderTarget.producer && order.deliveryIncluded
										? order.state
										: order.status
								}
								isState={
									target === OrderTarget.producer &&
									order.deliveryIncluded &&
									order.status !== 'in_progress'
								}
							/>
						) : null}
						<Tooltip
							place="top"
							id="order-hint"
							style={{
								backgroundColor: '#E8E8E8',
								color: '#000000',
							}}
						/>
					</div>
				);
			},
		},
		{
			title: t(
				'ordersTable.productDeliveryDateAndPlace',
				'Product, delivery date & place',
			),
			dataIndex: '',
			className: 'text-left',
			key: 'product',
			width: 50,
			render(order: OrderInterface) {
				const productName = order?.product?.name || '';
				const productSize = `${
					order?.product?.type ? `${order?.product?.type}` : ''
				} ${order?.product?.size ? `(${order?.product?.size})` : ''}`;
				const orderQuantity = order?.quantity;

				const htmlName = search
					? productName?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productName;
				const htmlSize = search
					? productSize?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productSize;
				const productUrl = order?.product?.photoUrl;
				const htmlDelivery = search
					? order?.deliveryAddress
							?.toString()
							.replace(
								new RegExp(`(${search})`, 'gi'),
								`<span class="text-texas-rose">$1</span>`,
							)
					: order?.deliveryAddress?.toString();

				return (
					<div className="flex gap-6 [&_p]:text-gray-80">
						<div
							style={{
								backgroundImage: `url(${productUrl})`,
							}}
							className="flex flex-none w-[58px] h-[58px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10"
							role="img"
						/>
						<div className="flex flex-col gap-2 justify-center w-full">
							<div className="flex items-center gap-4">
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlName }} />
								</SypacText>
								{productSize.trim() ? (
									<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
								) : null}
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlSize }} />
								</SypacText>
							</div>

							<div
								className={`flex gap-4 ${
									target !== 'producer' ? 'justify-between' : ''
								}`}
							>
								<div className="flex flex-row items-center">
									{order?.deliveryCountryCode ? (
										<Flag
											className="object-cover border border-solid border-gray-10 rounded mt-1"
											code={order?.pickupCountryCode}
											width={20}
											height={14}
										/>
									) : null}
									<SypacText
										variant="body-regular-medium"
										className={order?.deliveryCountryCode ? 'ml-3' : ''}
									>
										<p
											className="max-w-[655px] truncate"
											dangerouslySetInnerHTML={{ __html: htmlDelivery! }}
										/>
									</SypacText>
								</div>
								<div className="flex gap-6">
									<div className="h-[16px] border border-solid border-gray-10 border-t-0 border-b-0 border-r-0 border-l-[2px]" />

									{target !== 'producer' ? (
										<div className="flex gap-4">
											<Calendar />
											<SypacText variant="body-regular-medium">
												<p>{deliveryDates(order?.trucks!)}</p>
											</SypacText>
										</div>
									) : (
										<div className="flex gap-5">
											<Tons />
											<SypacText variant="body-regular-medium">
												<p>
													{orderQuantity} <T keyName="ordersTable.tons">tons</T>
												</p>
											</SypacText>
										</div>
									)}

									{target && target === 'customer' ? (
										<>
											<div className="h-[16px] border border-solid border-gray-10 border-t-0 border-b-0 border-r-0 border-l-[2px]" />
											<div className="flex gap-4">
												<Clock />

												<div className="flex gap-5">
													{order.trucks && order.trucks.length > 0 ? (
														order.trucks.some(
															(truck) =>
																convertToTimeZone(truck.deliveryTimeFrom) ===
																	'0:00' &&
																convertToTimeZone(truck.deliveryTimeTo) ===
																	'23:59',
														) ? (
															<SypacText variant="body-regular-medium">
																<p>Full Time</p>
															</SypacText>
														) : (
															<div className="flex items-center gap-2.5">
																<SypacText variant="body-regular-medium">
																	<p>
																		{convertToTimeZone(
																			order.trucks.reduce(
																				(earliest, truck) =>
																					new Date(truck.deliveryTimeFrom) <
																					new Date(earliest)
																						? truck.deliveryTimeFrom
																						: earliest,
																				order.trucks[0].deliveryTimeFrom,
																			),
																		)}
																	</p>
																</SypacText>
																<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
																<SypacText variant="body-regular-medium">
																	<p>
																		{convertToTimeZone(
																			order.trucks.reduce(
																				(latest, truck) =>
																					new Date(truck.deliveryTimeTo) >
																					new Date(latest)
																						? truck.deliveryTimeTo
																						: latest,
																				order.trucks[0].deliveryTimeTo,
																			),
																		)}
																	</p>
																</SypacText>
															</div>
														)
													) : null}
												</div>
											</div>
										</>
									) : null}
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: t('ordersTable.deliveryDateAndPlace', 'Delivery date & place'),
			dataIndex: '',
			className: 'text-left',
			key: 'carrierDatePlace',
			width: 50,
			render(order: OrderInterface) {
				const isOffer = order.hasOwnProperty('offerId');
				const htmlDelivery = search
					? order?.deliveryAddress
							?.toString()
							.replace(
								new RegExp(`(${search})`, 'gi'),
								`<span class="text-texas-rose">$1</span>`,
							)
					: order?.deliveryAddress?.toString();

				return (
					<div className="flex flex-col gap-4 [&_p]:text-gray-80">
						<div className="flex gap-8">
							<div className="flex gap-6 items-center">
								<span className="flex items-center scale-[1.2]">
									<Calendar />
								</span>
								<SypacText variant="body-regular-medium">
									<p className="w-[100px] truncate">
										{isOffer
											? deliveryDates(
													order?.trucks!,
													false,
													'deliveryFrom',
													true,
											  )
											: formatDate(order?.trucks?.[0].deliveryFrom)}
									</p>
								</SypacText>
							</div>
							<div className="flex gap-6 items-center">
								<div className="w-[7px] h-[7px] rounded-full bg-texas-rose" />
								<div className="flex flex-row items-center">
									{order?.deliveryCountryCode ? (
										<Flag
											className="object-cover border border-solid border-gray-10 rounded mt-1"
											code={order?.pickupCountryCode}
											width={20}
											height={14}
										/>
									) : null}
									<SypacText
										variant="body-regular-medium"
										className={order?.deliveryCountryCode ? 'ml-3' : ''}
									>
										<p
											className="max-w-[655px] truncate"
											dangerouslySetInnerHTML={{ __html: htmlDelivery! }}
										/>
									</SypacText>
								</div>
							</div>
						</div>

						<div className="flex gap-8">
							<div className="flex gap-6 items-center">
								<span className="flex items-center scale-[1.2]">
									<Calendar />
								</span>
								<SypacText variant="body-regular-medium">
									<p className="w-[100px] truncate">
										{isOffer
											? deliveryDates(order?.trucks!, false, 'deliveryTo', true)
											: formatDate(order?.trucks?.[0].deliveryTo)}
									</p>
								</SypacText>
							</div>
							<div className="flex gap-6 items-center">
								<div className="w-[7px] h-[7px] rounded-full bg-mountain-meadow" />
								<div className="flex flex-row items-center">
									{order?.pickupCountryCode ? (
										<Flag
											className="object-cover border border-solid border-gray-10 rounded mt-1"
											code={order?.pickupCountryCode}
											width={20}
											height={14}
										/>
									) : null}
									<SypacText
										variant="body-regular-medium"
										className={order?.pickupCountryCode ? 'ml-3' : ''}
									>
										<p className="max-w-[655px] truncate">
											{order?.pickupAddress}
										</p>
									</SypacText>
								</div>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: t('ordersTable.productAndQuantity', 'Product & quantity'),
			dataIndex: '',
			className: 'text-left',
			key: 'carrierProduct',
			width: 50,
			render(order: OrderInterface) {
				const productName = order?.product?.name || '';
				const productSize = `${
					order?.product?.type ? `${order?.product?.type}` : ''
				} ${order?.product?.size ? `(${order?.product?.size})` : ''}`;
				const orderQuantity = order?.trucks?.reduce(
					(sum, truck) => sum + truck.payload,
					0,
				);

				const htmlName = search
					? productName?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productName;
				const htmlSize = search
					? productSize?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productSize;
				const productUrl = order?.product?.photoUrl;

				return (
					<div className="flex gap-6 [&_p]:text-gray-80">
						<div
							style={{
								backgroundImage: `url(${productUrl})`,
							}}
							className="flex flex-none w-[58px] h-[58px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10"
							role="img"
						/>
						<div className="flex flex-col gap-2 justify-center w-full">
							<div className="flex items-center gap-4">
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlName }} />
								</SypacText>
								{productSize.trim() ? (
									<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
								) : null}
								<SypacText variant="body-regular-medium">
									<p dangerouslySetInnerHTML={{ __html: htmlSize }} />
								</SypacText>
							</div>
							<div className="flex gap-5">
								<Tons />
								<SypacText variant="body-regular-medium">
									<p>
										{orderQuantity} <T keyName="ordersTable.tons">tons</T>
									</p>
								</SypacText>
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: t('basicDetails.pickupDate', 'Pick-up date'),
			dataIndex: '',
			className: 'text-left',
			key: 'pickUpDate',
			width: 80,
			render(order: OrderInterface) {
				const deliveryFrom = formatDate(order?.trucks?.[0].deliveryFrom);
				const deliveryTo = formatDate(order?.trucks?.[0].deliveryTo);

				return (
					<div className="flex items-center gap-4">
						<Calendar />
						<SypacText variant="body-regular-medium">
							<p>{deliveryFrom}</p>
						</SypacText>
						<div className="w-[2px] h-[2px] bg-gray-60 rounded-full" />
						<SypacText variant="body-regular-medium">
							<p>{deliveryTo}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('ordersTable.quantity', 'Quantity'),
			dataIndex: '',
			className: 'text-left',
			key: 'quantity',
			width: 50,
			render(order: OrderInterface) {
				const trucks = order?.trucks!;
				const type = trucks?.[0].truckType;
				const payload = trucks?.[0].payload;
				const quantity =
					order.status !== OrderStatusesEnum.PAID
						? order?.trucks?.some((truck) => truck.loadedQuantity !== undefined)
							? order.trucks.reduce(
									(sum, truck) => sum + (truck.loadedQuantity ?? 0),
									0,
							  )
							: order.quantity
						: order.quantity;

				return (
					<div className="flex flex-col gap-2 [&_p]:text-gray-80">
						<div className="flex">
							<Tons />
							<SypacText variant="body-regular-medium" className="ml-4">
								<p className="font-bold">
									{numberTwoDecimal(quantity)}{' '}
									<T keyName="quantityCounter.tons">tons</T>
								</p>
							</SypacText>
						</div>
						<div className="flex gap-3">
							<div className="w-fit h-[15px] flex gap-3 rounded-md items-center p-1 bg-gray-10-opacity-50 border border-solid border-gray-10">
								<span className="w-[37px] flex">
									<TruckIcon type={type} />
								</span>
								<SypacText variant="overline-regular-large">
									<p>
										<T keyName={`quantityPicker.${type}`}>
											{type?.charAt(0).toUpperCase() + type?.slice(1)}
										</T>{' '}
										{payload}t
									</p>
								</SypacText>
							</div>
							{order?.trucks && order.trucks.length > 1 ? (
								<div className="w-fit h-[15px] flex rounded-md items-center p-1 bg-gray-10-opacity-50 border border-solid border-gray-10">
									<SypacText variant="overline-regular-large">
										<p>+{order.trucks.length - 1}</p>
									</SypacText>
								</div>
							) : null}
						</div>
					</div>
				);
			},
		},
		{
			title: t('ordersTable.truckInfo', 'Truck info'),
			dataIndex: '',
			className: 'text-left',
			key: 'truckInfo',
			width: 50,
			render(order: OrderInterface) {
				const trucks = order?.trucks!;
				const type = trucks?.[0].truckType;
				const payload = trucks?.[0].payload;

				return (
					<div className="flex flex-col gap-2">
						{order.hasOwnProperty('offerId') ? (
							<div className="w-full h-[25px] flex gap-3 rounded-md items-center justify-center p-1 bg-shadow-blue box-border">
								<span className="flex">
									<Stack color="#FFFFFF" />
								</span>
								<SypacText variant="overline-regular-large">
									<p className="text-white mt-[1px]">
										<T keyName="ordersTable.transportOffer">Transport offer</T>
									</p>
								</SypacText>
							</div>
						) : null}

						<div className="flex gap-3">
							<div className="w-full h-[15px] flex gap-3 rounded-md items-center justify-center p-1 bg-gray-10-opacity-50 border border-solid border-gray-10">
								<span className="w-[37px] flex">
									<TruckIcon type={type} />
								</span>
								<SypacText variant="overline-regular-large">
									<p>
										<T keyName={`quantityPicker.${type}`}>
											{type?.charAt(0).toUpperCase() + type?.slice(1)}
										</T>{' '}
										{payload}t
									</p>
								</SypacText>
							</div>
							{order?.trucks && order.trucks.length > 1 ? (
								<div className="w-fit h-[15px] flex rounded-md items-center p-1 bg-gray-10-opacity-50 border border-solid border-gray-10">
									<SypacText variant="overline-regular-large">
										<p>+{order.trucks.length - 1}</p>
									</SypacText>
								</div>
							) : null}
						</div>
					</div>
				);
			},
		},
		{
			title: t('ordersTable.assignee', 'Assignee'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			width: 150,
			render(value: OrderInterface) {
				const assignee = value?.assigneeUser ?? user;
				const fullName =
					assignee?.profile?.firstName + ' ' + assignee?.profile?.lastName;

				return (
					<div className="flex items-center">
						<SypacAvatar size="sm" initials={fullName}>
							{assignee?.profile?.photoUrl && (
								<img
									src={assignee?.profile?.photoUrl}
									alt={fullName}
									className="w-full h-full object-cover rounded-full"
								/>
							)}
						</SypacAvatar>
						<SypacText variant="body-regular-medium" className="ml-3">
							<p className="text-gray-80">{fullName}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('ordersTable.dispatcher', 'Dispatcher'),
			dataIndex: '',
			className: 'text-left',
			key: 'dispatcher',
			width: 150,
			render(value: OrderInterface) {
				const assignee = value?.assigneeUser;
				const fullName =
					assignee?.profile?.firstName + ' ' + assignee?.profile?.lastName;

				return assignee ? (
					<div className="flex items-center">
						<SypacAvatar size="sm" initials={fullName}>
							{assignee?.profile?.photoUrl && (
								<img
									src={assignee?.profile?.photoUrl}
									alt={fullName}
									className="w-full h-full object-cover rounded-full"
								/>
							)}
						</SypacAvatar>
						<SypacText variant="body-regular-medium" className="ml-3">
							<p className="text-gray-80">{fullName}</p>
						</SypacText>
					</div>
				) : (
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80 text-center font-bold">–</p>
					</SypacText>
				);
			},
		},
		{
			title: t('ordersTable.orderedAndCreatedBy', 'Ordered and created by:'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdByUser',
			width: 50,
			render(value: OrderInterface) {
				const user = value?.createdByUser;

				return user && user.name ? (
					<div className="flex flex-col gap-2">
						<div className="flex items-center">
							<SypacAvatar size="sm" initials={user.name}>
								{user?.profile?.photoUrl && (
									<img
										src={user?.profile?.photoUrl}
										alt={user?.name}
										className="w-full h-full object-cover rounded-full"
									/>
								)}
							</SypacAvatar>
							<SypacText variant="body-regular-medium" className="ml-3">
								<p className="text-gray-80">{user.name}</p>
							</SypacText>
						</div>
						<div className="flex items-center ml-[6px]">
							<Calendar />
							<SypacText variant="body-regular-medium" className="ml-[18px]">
								<p>{formatDate(value?.createdAt)}</p>
							</SypacText>
						</div>
					</div>
				) : null;
			},
		},
	];

	const newColumns = (search?: string, target?: string) => {
		let initialColumns = columns(search, target);

		let keysToRemove: any[] = [];
		if (target === OrderTarget.producer) {
			keysToRemove = [
				'quantity',
				'createdByUser',
				'truckInfo',
				'dispatcher',
				'carrierDatePlace',
				'carrierProduct',
			];
		} else if (target === OrderTarget.customer) {
			keysToRemove = [
				'createdBy',
				'pickUpDate',
				'truckInfo',
				'dispatcher',
				'carrierDatePlace',
				'carrierProduct',
			];
		} else if (target === OrderTarget.transporter) {
			keysToRemove = [
				'createdBy',
				'pickUpDate',
				'quantity',
				'createdByUser',
				'product',
			];
		}

		if (keysToRemove.length > 0) {
			initialColumns = initialColumns.filter(
				(column) => !keysToRemove.includes(column.key),
			);
		}

		if (initialColumns.length > 0) {
			return [
				...initialColumns.slice(0, initialColumns.length - 1),
				getPriceColumn(showWithVat),
				initialColumns[initialColumns.length - 1],
			];
		} else {
			return [];
		}
	};

	useEffect(() => {
		const newRows = rows.map((row) => ({ ...row, key: row?.id }));
		setRows(newRows);
	}, [rows]);

	const handleRowClick = useCallback(
		(record: OrderInterface) => {
			rowClick(record);
		},
		[rowClick],
	);

	const getRowProps = useCallback(
		(record: OrderInterface) => {
			return {
				onClick: () => handleRowClick(record),
				className:
					record.id === selectedOrderId
						? `order-table-row-selected order-table-row-${record.id}`
						: `order-table-row-${record.id}`,
			};
		},
		[handleRowClick, selectedOrderId],
	);

	return (
		<Table
			className={`order-table ${rows.length === 0 ? 'h-full' : ''}`}
			columns={
				target === OrderTarget.admin
					? columnsAdmin(search)
					: newColumns(search, target)
			}
			data={keyRows}
			onRow={getRowProps}
			emptyText={
				!ordersLoading && <EmptyPlaceholder currentTab={currentTab!} />
			}
		/>
	);
};
