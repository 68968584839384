import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { useGetCategories } from '../../../hooks/use-get-categories';
import '../select-product.scss';
import {
	OrderContext,
	OrderProps,
} from '../../../context/OrderContext/order.context';
import { NumericFormat } from 'react-number-format';
import { T } from '@tolgee/react';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import ListMenu from '../../../components/ListMenu/ListMenu';
import ArrowToLeft from '../../../assets/ArrowToLeft';
import ProducerCompanyIcon from '../../../assets/ProducerCompanyIcon';
import Tons from '../../../assets/Tons';
import HashBox from '../../../assets/HashBox';
import PriceTag from '../../../assets/PriceTag';
import { GeoService } from '../../../services/geo.services';
import { OrdersService } from '../../../services/orders.services';
import ClientStoreBreadcrumbs from '../../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';
import { CompanyService } from '../../../services/company.services';
import { toastVariant } from '../../../components/ToastVariant/toastVariant';
import Clock from '../../../assets/Clock';
import { useGetProducts } from '../../../hooks/use-get-products';
import RecentOrderItem from '../ClientSelectProduct/components/RecentOrderItem';

const ClientSelectProductDetails: React.FC = () => {
	const { groupId, categoryId, productId } = useParams();
	const navigate = useNavigate();
	const { order, setOrder } = useContext(OrderContext);
	const { setInputQuantity, setTotalQuantityCounter } = useContext(
		QuantityPickerContext,
	);

	const [userCountry, setUserCountry] = useState<string>();
	const [coordinates, setCoordinates] = useState<number[]>([]);
	const [localRecentOrders, setLocalRecentOrders] = useState<OrderProps[]>([]);
	const [activeCategory, setActiveCategory] = useState<string>(categoryId!);

	const [groups] = useGetCategories({ countryCode: userCountry });
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [categories, _, isCategoryLoading] = useGetCategories({
		parentIds: [groupId!],
		coordinates,
		countryCode: userCountry,
	});
	const [products] = useGetProducts(
		'all',
		activeCategory,
		100,
		0,
		'customer',
		userCountry!,
		coordinates,
	);

	const currentProduct = useMemo(
		() => products?.items?.find((item) => item.id === +productId!),
		[products, productId],
	);
	const currentCategory = useMemo(
		() => categories?.items?.find((item) => item.id === categoryId),
		[categories, categoryId],
	);
	const currentGroup = useMemo(
		() => groups?.items?.find((item) => item.id === groupId),
		[groups, groupId],
	);

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			const countryCodeMap: Record<string, string> = {
				MD: 'MDA',
				PL: 'POL',
			};
			setUserCountry(countryCodeMap[data.countryCode] || '');
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	const getLocation = useCallback(async (locationId: string) => {
		if (locationId) {
			const { data } = await GeoService.getLocationDetails({
				locationid: locationId,
			});
			setCoordinates([data.Longitude, data.Latitude]);
		}
	}, []);

	const getRecentOrders = async () => {
		try {
			const { data } = await OrdersService.getRecentOrders({
				limit: 5,
				offset: 0,
			});
			if (data.count) {
				setLocalRecentOrders(data.items.slice(0, 3) || []);
			}
		} catch (e) {}
	};

	const handleSelectRecentOrder = (order: OrderProps) => {
		setOrder({ ...order });
		navigate(
			`/store/groups/${order?.group?.id}/categories/${order?.category?.id}/products/${order?.product?.id}/quantity`,
		);
	};

	const handleNext = () => {
		setOrder({
			...order,
			product: currentProduct,
			group: currentGroup,
			category: currentCategory,
		});
		navigate('quantity');
	};

	const listItems =
		categories?.items?.map((item) => ({
			id: item.id,
			name: item.name,
			onClick: () => {
				if (item.id !== activeCategory) {
					setActiveCategory(item.id);
					navigate(`/store/groups/${groupId}/categories/${item.id}/products`);
				}
			},
		})) || [];

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	useEffect(() => {
		if (order?.location?.locationId) {
			getLocation(order?.location?.locationId).then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.location?.locationId]);

	useEffect(() => {
		getRecentOrders().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setOrder({
			...order,
			// dateFrom: undefined,
			// dateTo: undefined,
			product: currentProduct,
			group: currentGroup,
			category: currentCategory,
		});
		setInputQuantity(0);
		setTotalQuantityCounter(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<main className="w-full flex flex-col lg:gap-4 gap-x-2.5 h-[calc(100vh-60px)] overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			<header className="mx-3 lg:ml-5 xl:ml-0 my-3 lg:my-0 lg:w-[1132px] xl:self-center flex justify-between items-center xl:mb-4">
				<ClientStoreBreadcrumbs
					currentGroup={currentGroup}
					currentCategory={currentCategory}
					categoryId={categoryId}
					currentProduct={currentProduct}
				/>
			</header>

			<section className="flex xl:justify-center border border-solid border-gray-10 border-b-0 border-l-0 border-r-0">
				<div className="w-full lg:w-auto flex">
					<aside className="h-fit hidden lg:flex flex-col gap-5 mt-5 ml-5 xl:ml-0">
						<div className="lg:w-[195px] xl:w-[226px] xl-2xl:w-[255px] mr-5">
							<ListMenu
								items={!isCategoryLoading ? listItems : []}
								selected={categoryId}
							/>
						</div>
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

						<div className="lg:w-[195px] xl:w-[226px] xl-2xl:w-[255px] flex flex-col gap-5">
							<div className="flex gap-3 items-center">
								<Clock />
								<SypacText variant="body-regular-medium">
									<p className="text-base text-gray-40 mt-[2px]">
										<T keyName="clientSelectCategory.recent">Recent</T>
									</p>
								</SypacText>
							</div>

							{localRecentOrders.length ? (
								<div className="flex flex-col">
									{localRecentOrders.map((order: OrderProps) => (
										<RecentOrderItem
											key={order?.product?.id}
											order={order}
											onSelect={handleSelectRecentOrder}
										/>
									))}
								</div>
							) : (
								<div className="w-[232px] flex flex-col gap-6 box-border">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T keyName="clientSelectCategory.noRecentProductsPreviewed">
												You haven’t previewed any products recently
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-xs text-gray-40">
											<T keyName="clientSelectCategory.noRecentProductsPreviewedDescription">
												Previously viewed products will be listed here
											</T>
										</p>
									</SypacText>
								</div>
							)}
						</div>
					</aside>
					<div className="border border-solid border-gray-10 border-b-0 border-t-0 border-r-0" />

					<article className="overflow-x-hidden scroll-smooth flex-1 pr-[12px] pb-5">
						<section className="hidden lg:flex border border-solid border-gray-10 border-t-0 border-l-0 border-r-0 pl-2 xl:pl-6 py-3">
							<SypacButton
								variant="secondary"
								onClick={() => {
									navigate(-1);
								}}
							>
								<button className="flex gap-6 border-none h-[24px] bg-transparent group">
									<span className="mt-[4px] transition group-hover:text-gray-80 group-hover:-translate-x-[6px]">
										<ArrowToLeft width={38.5} height={16.5} />
									</span>
									<SypacText variant="body-normal-medium">
										<p className="text-gray-80">
											<T keyName="clientSelectProductDetails.backToProductList">
												Back to product list
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</section>

						<section className="flex gap-6 pt-5">
							<div className="w-[460px] hidden xl:flex flex-col gap-6 pl-5">
								<div className="flex flex-col gap-3">
									<div
										style={{
											backgroundImage: `url(${currentProduct?.photoUrl})`,
										}}
										className="w-full h-[240px] rounded-10 border border-solid border-gray-10 bg-no-repeat bg-cover bg-center"
										role="img"
										aria-label={currentProduct?.name}
									/>
								</div>

								{currentProduct?.description ? (
									<div className="flex flex-col gap-3">
										<SypacText variant="body-normal-medium">
											<p className="text-gray-80">
												<T keyName="clientSelectProductDetails.aboutProduct">
													About product
												</T>
											</p>
										</SypacText>

										<SypacText variant="body-normal-medium">
											<p className="text-gray-80 line-clamp-3 mb-3">
												{currentProduct?.description}
											</p>
										</SypacText>
									</div>
								) : null}
							</div>

							<div className="w-full xl:w-[365px] flex flex-col gap-6 mx-3 mr-0 lg:mx-0 lg:ml-5 xl:ml-0">
								<div className="flex flex-col gap-6 [&_p]:text-gray-80">
									<SypacText variant="body-regular-medium">
										<p className="text-2xl leading-7">{currentProduct?.name}</p>
									</SypacText>
									<SypacText variant="body-regular-small">
										<p className="text-sm">
											{currentProduct?.type}{' '}
											{currentProduct?.size ? `(${currentProduct?.size})` : ''}
										</p>
									</SypacText>
								</div>
								{/*<div className="flex flex-col gap-2">
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-40">
											4 — 6{' '}
											<T keyName="clientsProductCard.workingDaysToDelivery">
												working days to delivery
											</T>
										</p>
									</SypacText>
									<div className="flex gap-3 items-center">
										<RatingStars
											score={4}
											stars={[1, 2, 3, 4, 5]}
											disabled={true}
										/>
										<SypacText variant="body-regular-medium">
											<p className="text-xs text-gray-40">4.0</p>
										</SypacText>
										<SypacText variant="body-regular-medium">
											<p className="text-xs text-gray-40">
												<T
													keyName="clientsProductCard.basedOnCustomerReviews"
													params={{
														reviews: 25,
													}}
												>
													Based on customer reviews.
												</T>
											</p>
										</SypacText>
									</div>
								</div> */}
								<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

								<div className="flex flex-col gap-3">
									<div className="flex gap-4">
										<span className="mt-[2px]">
											<HashBox width={19} height={19} />
										</span>
										<section className="w-full flex justify-between">
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">
													<T keyName="clientsProductCard.productId">
														Product ID
													</T>
												</p>
											</SypacText>
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">{currentProduct?.id}</p>
											</SypacText>
										</section>
									</div>
									<div className="flex gap-4">
										<span className="mt-[2px]">
											<Tons width={19} height={21.5} />
										</span>
										<section className="w-full flex justify-between">
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">
													<T keyName="clientsProductCard.availableQuantity">
														Available quantity
													</T>
												</p>
											</SypacText>
											<SypacText variant="body-normal-medium">
												{currentProduct?.unlimited ? (
													<p className="text-gray-80">
														<T keyName="clientsProductCard.unlimited">
															Unlimited
														</T>
													</p>
												) : (
													<p className="text-gray-80">
														{currentProduct?.availableQuantity || 0}{' '}
														{currentProduct?.unit}
													</p>
												)}
											</SypacText>
										</section>
									</div>
									<div className="flex gap-4">
										<span className="-ml-[1px]">
											<ProducerCompanyIcon width={20} height={22} />
										</span>
										<section className="w-full flex justify-between">
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">
													<T keyName="clientsProductCard.productStock">
														Product stock
													</T>
												</p>
											</SypacText>
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">
													<T keyName="clientSelectProductDetails.available">
														Available
													</T>
												</p>
											</SypacText>
										</section>
									</div>
								</div>
								<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

								<div className="flex flex-col gap-3">
									<div className="flex gap-4">
										<span className="mt-[2px]">
											<PriceTag width={21.5} height={21.5} />
										</span>
										<section className="w-full flex justify-between">
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">
													<T keyName="clientSelectProductDetails.pricePerTonExcl">
														Price per ton (excl. VAT)
													</T>
												</p>
											</SypacText>
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">
													<NumericFormat
														type="text"
														displayType="text"
														thousandSeparator=" "
														decimalSeparator="."
														value={
															parseFloat(
																currentProduct?.pricePerUnitWithProcent?.toFixed(
																	2,
																)!,
															) || 0
														}
														suffix={
															currentProduct?.currency === 'lei'
																? ' MDL'
																: ' PLN'
														}
													/>
												</p>
											</SypacText>
										</section>
									</div>
									<div className="flex gap-2 [&_p]:text-sm [&_p]:text-gray-40">
										<SypacText variant="body-normal-medium">
											<p>
												<T keyName="clientSelectProductDetails.pricePerTonIncl">
													Price per ton (incl. VAT):
												</T>
											</p>
										</SypacText>
										<SypacText variant="body-normal-medium">
											<p>
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator=" "
													decimalSeparator="."
													value={
														parseFloat(
															currentProduct?.pricePerUnitWithVat?.toFixed(2)!,
														) || 0
													}
													suffix={
														currentProduct?.currency === 'lei' ? ' MDL' : ' PLN'
													}
												/>
											</p>
										</SypacText>
									</div>
								</div>

								<div className="flex justify-between">
									<button
										type="button"
										onClick={handleNext}
										className="w-full h-[44px] flex items-center rounded-10 cursor-pointer px-3 border-0 bg-gradient-primary text-white justify-center"
									>
										<SypacText variant="body-regular-medium">
											<p>
												<T keyName="clientSelectProductDetails.orderNow">
													Order now
												</T>
											</p>
										</SypacText>
									</button>

									{/* <div className="w-[44px] h-[44px] flex items-center justify-center border border-solid border-gray-10 rounded-[10px] bg-white">
										<span className="scale-[1.2] mt-[4px]">
											<MarkProductIcon />
										</span>
									</div> */}
								</div>

								<div className="w-auto lg:w-[460px] flex lg:flex xl:hidden flex-col gap-5">
									<div
										style={{
											backgroundImage: `url(${currentProduct?.photoUrl})`,
										}}
										className="w-full h-[240px] rounded-10 border border-solid border-gray-10 bg-no-repeat bg-cover bg-center"
										role="img"
										aria-label={currentProduct?.name}
									/>

									{currentProduct?.description ? (
										<div className="flex flex-col gap-3">
											<SypacText variant="body-normal-medium">
												<p className="text-gray-80">
													<T keyName="clientSelectProductDetails.aboutProduct">
														About product
													</T>
												</p>
											</SypacText>

											<SypacText variant="body-normal-medium">
												<p className="text-gray-80 line-clamp-3 mb-3">
													{currentProduct?.description}
												</p>
											</SypacText>
										</div>
									) : null}
								</div>
							</div>
						</section>
					</article>
				</div>
			</section>
		</main>
	);
};

export default ClientSelectProductDetails;
