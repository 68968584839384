import { ZoomLevel } from '../Map.interface';

export const WarsawCoords = {
	lat: 52.232938,
	lng: 21.0611941,
};

export const ChisinauCoords = {
	lat: 47.0167,
	lng: 28.8667,
};

export const zoomLevels: ZoomLevel = {
	25: 11,
	50: 10,
	75: 9,
	100: 9,
	200: 8,
};
