import React, { useContext, useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import {
	SypacBadge,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { NumericFormat } from 'react-number-format';
import { AuthContext } from '../../context/context';
import { calculateRemainingDays, formatDate } from '../../utils/time.util';
import { OrderStatusesEnum } from '../../services/orders.services';
import { TableStatusEnum } from '../TableStatus/TableStatus.enum';
import { PaymentStatusColor } from '../TableStatus/TableStatus.constants';
import { PaymentTypeAction } from '../PaymentsTable/interfaces/PaymentStatus.interface';
import {
	OrderTarget,
	PaymentMethod,
} from '../OrdersTable/interfaces/OrderStatus.interface';
import {
	BaseOrderDetailsProps,
	InfoRowProps,
} from './BaseOrderDetails.interface';
import { InvoiceInterface } from '../PaymentsTable/interfaces/Payment.interface';
import { ExpandedBoxType } from '../ExpandedBox/ExpandedBox.interface';
import ExpandedBox from '../ExpandedBox/ExpandedBox';
import StatusBadge from '../StatusBadge/StatusBadge';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import { BlueBox } from '../../assets/BlueBox';
import { Stack } from '../../assets/Stack';
import { TruckApprove } from '../../assets/TruckApprove';
import { TruckNotApprove } from '../../assets/TruckNotApprove';
import { Calendar } from '../../assets/Calendar';
import Invoice from '../../assets/Invoice';
import { SypacGrLoader } from '../../assets/SypacGrLoader';
import { OrderDetailsContext } from '../../context/OrderDetailsContext/order-details.context';
import { PaymentNotesStatusesEnum } from '../../services/billing.service';
import { Person } from '../../assets/Person';
import { numberTwoDecimal } from '../../utils/number.util';

const BaseOrderDetails: React.FC<BaseOrderDetailsProps> = ({
	isOpen,
	selectedOrder,
	simpleOrder,
	deliveryIncluded,
	target,
}) => {
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const { customerInvoice, producerInvoice, carrierInvoice } =
		useContext(OrderDetailsContext);

	const [invoiceUrl, setInvoiceUrl] = useState<string>();
	const [openView, setShowView] = useState<boolean>(false);
	const [sellerView, setSellerView] = useState<boolean>(false);

	const orderTrucks = useMemo(() => {
		return selectedOrder?.trucks?.slice().sort((a, b) => a.id - b.id);
	}, [selectedOrder?.trucks]);

	const orderInvoice = useMemo(
		() =>
			[OrderTarget.admin, OrderTarget.customer].includes(selectedOrder?.target!)
				? customerInvoice
				: selectedOrder?.target === OrderTarget.producer
				? producerInvoice
				: carrierInvoice,
		[selectedOrder?.target, customerInvoice, producerInvoice, carrierInvoice],
	);

	const boxType = useMemo(() => {
		if (simpleOrder) {
			return deliveryIncluded
				? ExpandedBoxType.SIMPLE_ORDER
				: ExpandedBoxType.EXCLUDED_ORDER;
		}
		return deliveryIncluded
			? ExpandedBoxType.MULTIPLE_ORDER
			: ExpandedBoxType.EXCLUDED_ORDER;
	}, [simpleOrder, deliveryIncluded]);

	const shippingCost = useMemo(
		() => selectedOrder?.transportPriceForClientWithVat || 0,
		[selectedOrder?.transportPriceForClientWithVat],
	);

	const { priceNoVat, priceWithVat } = useMemo(() => {
		let noVat = 0;
		let withVat = 0;

		switch (target) {
			case OrderTarget.admin:
			case OrderTarget.customer:
				noVat =
					(selectedOrder?.transportPriceForClient ?? 0) +
					(selectedOrder?.productPriceForClient ?? 0);
				withVat =
					(selectedOrder?.transportPriceForClientWithVat ?? 0) +
					(selectedOrder?.productPriceForClientWithVat ?? 0);
				break;
			case OrderTarget.transporter:
				noVat = selectedOrder?.priceForTransporter ?? 0;
				withVat = selectedOrder?.priceForTransporterWithVat ?? 0;
				break;
			case OrderTarget.producer:
				noVat = selectedOrder?.priceForProducer ?? 0;
				withVat = selectedOrder?.priceForProducerWithVat ?? 0;
				break;
		}

		return {
			priceNoVat: Number(noVat.toFixed(2)),
			priceWithVat: Number(withVat.toFixed(2)),
		};
	}, [selectedOrder, target]);

	const orderVat = useMemo(
		() =>
			parseFloat(
				(Math.abs((priceWithVat - priceNoVat) / priceNoVat) * 100).toFixed(0),
			),
		[priceWithVat, priceNoVat],
	);

	const pricePerTon = useMemo(() => {
		const price =
			(target === OrderTarget.customer || target === OrderTarget.admin) &&
			!sellerView
				? selectedOrder?.product.pricePerUnitWithProcent ?? 0
				: selectedOrder?.product.pricePerUnit ?? 0;
		return Number(price.toFixed(2));
	}, [selectedOrder?.product, target, sellerView]);

	const variationPercent = (weight: number, quantity: number) => {
		const result = ((weight - quantity) / quantity) * 100 || 0;
		const percent = Number.isInteger(result)
			? result
			: parseFloat(result.toFixed(2));
		return result > 0 ? `+${percent}` : `${percent}`;
	};

	const FormattedNumber = ({ value }: { value: string }) => (
		<NumericFormat
			type="text"
			displayType="text"
			thousandSeparator=" "
			decimalSeparator="."
			value={parseFloat(value ?? 0)}
			decimalScale={2}
			fixedDecimalScale={false}
			suffix={selectedOrder?.product.currency === 'lei' ? ' MDL' : ' PLN'}
		/>
	);

	const InfoRow = ({
		label,
		value,
		isBadge,
		badgeContent,
		isNumeric,
		className,
	}: InfoRowProps) => (
		<div
			className={`grid grid-cols-[250px,auto] mb-3 items-start ${className}`}
		>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			{isBadge ? (
				badgeContent
			) : (
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">
						{isNumeric ? <FormattedNumber value={value as string} /> : value}
					</p>
				</SypacText>
			)}
		</div>
	);

	const downloadInvoice = async (invoice: InvoiceInterface) => {
		try {
			if (!invoice.invoiceUrl) {
				return;
			}

			const companyName = user?.company.name;
			const invoiceSort =
				companyName
					?.split(' ')
					?.map((r) => r[0])
					?.join('')
					?.toUpperCase() || 'FV';
			const response = await fetch(invoice.invoiceUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			const fileName = invoice.invoiceNr
				? `${invoice.invoiceNr}.pdf`
				: `${invoiceSort}${dayjs(invoice.createdAt).format('DD.MM.YYYY')}${
						invoice.id
				  }.pdf`;
			link.setAttribute('download', fileName);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} catch (e) {}
	};

	const viewInvoice = (url?: string) => {
		setInvoiceUrl(url);
		setShowView(!!url);
	};

	const actionClick = (type: string, payment: InvoiceInterface) => {
		switch (type) {
			case PaymentTypeAction.download:
				return downloadInvoice(payment);
			case PaymentTypeAction.view_invoice:
				return viewInvoice(payment?.invoiceUrl);
		}
	};

	return (
		<>
			<ExpandedBox
				isOpen={isOpen}
				title={t('baseOrderDetails.orderInfo', 'Order Info')}
				boxType={boxType}
			>
				<div className="flex flex-col">
					<InfoRow
						label={t('baseOrderDetails.orderId', 'Order ID:')}
						value={selectedOrder?.orderId || selectedOrder?.id}
					/>
					<InfoRow
						label={t('baseOrderDetails.created', 'Created:')}
						value={
							<div className="flex items-center gap-3 ml-[5px]">
								<span className="p-0 m-0 flex scale-[1.1]">
									<Calendar />
								</span>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										{formatDate(selectedOrder?.createdAt)}
									</p>
								</SypacText>
							</div>
						}
					/>
					<InfoRow
						label={t('baseOrderDetails.orderStatus', 'Order status:')}
						isBadge={true}
						badgeContent={
							<StatusBadge
								status={
									[OrderTarget.producer, OrderTarget.transporter].includes(
										target as OrderTarget,
									) &&
									[
										PaymentNotesStatusesEnum.PENDING_INVOICE,
										PaymentNotesStatusesEnum.UPLOADED_INVOICE,
										PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
									].includes(orderInvoice?.status as PaymentNotesStatusesEnum)
										? orderInvoice?.status!
										: target === OrderTarget.producer && deliveryIncluded
										? selectedOrder?.state!
										: selectedOrder?.status!
								}
							/>
						}
					/>

					{target === OrderTarget.admin || target === OrderTarget.customer ? (
						<InfoRow
							label={t('baseOrderDetails.orderType', 'Order type:')}
							value={
								<div className="flex items-center gap-3 ml-[5px]">
									<span className="p-0 m-0 flex scale-[1.1]">
										{simpleOrder ? <BlueBox /> : <Stack />}
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{simpleOrder ? (
												<T keyName="baseOrderDetails.singleDelivery">
													Single delivery
												</T>
											) : (
												<T keyName="baseOrderDetails.multipleDelivery">
													Multiple delivery
												</T>
											)}
										</p>
									</SypacText>
								</div>
							}
						/>
					) : null}

					{target !== OrderTarget.transporter ? (
						<InfoRow
							label={t('baseOrderDetails.deliveryType', 'Delivery type:')}
							value={
								<div className="flex items-center gap-3 ml-[5px]">
									<span className="p-0 m-0 flex scale-[1.1]">
										{deliveryIncluded ? <TruckApprove /> : <TruckNotApprove />}
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{deliveryIncluded ? (
												<T keyName="baseOrderDetails.transportIncluded">
													Transport included
												</T>
											) : target !== OrderTarget.producer ? (
												<T keyName="baseOrderDetails.transportExcluded">
													Transport excluded
												</T>
											) : (
												<T keyName="baseOrderDetails.selfService">
													Self service
												</T>
											)}
										</p>
									</SypacText>
								</div>
							}
						/>
					) : null}

					<InfoRow
						label={t('baseOrderDetails.productName', 'Product name:')}
						value={selectedOrder?.product.name}
						className="mt-6"
					/>
					{selectedOrder?.product.type && (
						<InfoRow
							label={t('baseOrderDetails.type', 'Type:')}
							value={`${selectedOrder?.product.type} ${
								selectedOrder?.product.size
									? `(${selectedOrder.product.size})`
									: ''
							}`}
						/>
					)}
					{target !== OrderTarget.transporter ? (
						<InfoRow
							label={t('baseOrderDetails.pricePerTon', 'Price per ton:')}
							value={
								<div className="flex items-center gap-3">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<NumericFormat
												type="text"
												displayType="text"
												thousandSeparator=" "
												decimalSeparator="."
												value={pricePerTon}
												suffix={
													selectedOrder?.product.currency === 'lei'
														? ' MDL'
														: ' PLN'
												}
											/>
										</p>
									</SypacText>
									{target === OrderTarget.admin ? (
										<SypacButton variant="secondary" size="small">
											<button
												type="button"
												className="relative w-full border-none bg-transparent p-0 group"
												onClick={() => setSellerView(!sellerView)}
											>
												<span className="absolute w-[154px] flex items-center gap-1 left-0">
													<SypacIcon
														iconName="Eye"
														size="custom"
														width="28px"
														height="28px"
														className="text-gray"
													/>
													<SypacText variant="body-normal-medium">
														<p className="text-cornflower-blue transition group-hover:text-gray-80">
															{!sellerView ? (
																<T keyName="baseOrderDetails.viewSellerPrice">
																	View seller price
																</T>
															) : (
																<T keyName="baseOrderDetails.viewBuyerPrice">
																	View buyer price
																</T>
															)}
														</p>
													</SypacText>
												</span>
											</button>
										</SypacButton>
									) : null}
								</div>
							}
						/>
					) : null}
					<InfoRow
						label={t('baseOrderDetails.orderedQuantity', 'Ordered quantity:')}
						value={
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									{target !== OrderTarget.admin &&
									target !== OrderTarget.customer
										? orderTrucks?.[0].payload
										: selectedOrder?.quantity}{' '}
									<T keyName="basicDetails.tons">tons</T>
								</p>
							</SypacText>
						}
					/>

					{target !== OrderTarget.transporter &&
					orderTrucks?.every((item) => item.loadedQuantity !== undefined) ? (
						simpleOrder ? (
							<InfoRow
								label={t('baseOrderDetails.loadedQuantity', 'Loaded quantity:')}
								value={
									<div className="flex gap-3 items-center whitespace-nowrap">
										<SypacText variant="body-regular-medium">
											<p className="font-bold">
												{numberTwoDecimal(
													orderTrucks?.reduce(
														(sum, item) => sum + item.loadedQuantity!,
														0,
													) || 0,
												)}{' '}
												<T keyName="baseOrder.tonns">tons</T>
											</p>
										</SypacText>
										<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
											<SypacText variant="body-regular-medium">
												<p className="text-xs leading-none">
													{variationPercent(
														target === OrderTarget.producer
															? orderTrucks?.[0]?.loadedQuantity!
															: orderTrucks?.reduce(
																	(sum, item) => sum + item.loadedQuantity!,
																	0,
															  ),
														target === OrderTarget.producer
															? orderTrucks?.[0]?.payload
															: selectedOrder?.quantity!,
													)}
													%
												</p>
											</SypacText>
										</span>
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40 whitespace-normal">
												<T keyName="baseOrderDetails.quantityDifference">
													Quantity difference
												</T>
											</p>
										</SypacText>
									</div>
								}
							/>
						) : selectedOrder?.status === 'paid' ||
						  selectedOrder?.status === 'delivered' ? (
							orderTrucks?.map((orderTruck, index) => (
								<div
									className={`flex flex-col gap-3 ${index === 0 ? 'mt-6' : ''}`}
									key={orderTruck?.id}
								>
									<div className="flex gap-4">
										<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-90">
													<T keyName="requestedStep.payloadTruck">Truck</T>{' '}
													{index + 1}
												</p>
											</SypacText>
										</div>
										<SypacText variant="body-regular-medium">
											<p className="text-gray-60">
												{orderTruck?.driver?.name ||
													orderTruck?.driver?.licensePlates}
											</p>
										</SypacText>
									</div>

									<InfoRow
										label={t(
											'baseOrderDetails.loadedQuantity',
											'Loaded quantity:',
										)}
										value={
											<div className="flex gap-3 items-center whitespace-nowrap">
												<SypacText variant="body-regular-medium">
													<p className="font-bold">
														{numberTwoDecimal(orderTruck?.loadedQuantity!)}{' '}
														<T keyName="baseOrder.tonns">tons</T>
													</p>
												</SypacText>
												<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
													<SypacText variant="body-regular-medium">
														<p className="text-xs leading-none">
															{variationPercent(
																orderTruck?.loadedQuantity!,
																orderTruck.payload!,
															)}
															%
														</p>
													</SypacText>
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-40 whitespace-normal">
														<T keyName="baseOrderDetails.quantityDifference">
															Quantity difference
														</T>
													</p>
												</SypacText>
											</div>
										}
									/>
								</div>
							))
						) : null
					) : null}

					{target === OrderTarget.admin ? (
						<InfoRow
							label={t(
								'baseOrderDetails.productCostTotal',
								'Product cost (total):',
							)}
							value={selectedOrder?.productPriceForClientWithVat || 0}
							isNumeric={true}
						/>
					) : null}

					{target === OrderTarget.admin && deliveryIncluded ? (
						<InfoRow
							label={t(
								'baseOrderDetails.transportationCost',
								'Transportation cost:',
							)}
							value={shippingCost || 0}
							isNumeric={true}
						/>
					) : null}

					{target === OrderTarget.transporter ? (
						<>
							{selectedOrder?.pickupLocation &&
							selectedOrder?.deliveryLocation ? (
								<InfoRow
									label={t('baseOrderDetails.distance', 'Distance:')}
									value={`${selectedOrder?.distance} km`}
									className="mt-6"
								/>
							) : null}
							<InfoRow
								label={t(
									'baseOrderDetails.startTripPrice',
									'Start trip price:',
								)}
								value={selectedOrder?.transporterStartPrice ?? 0}
								isNumeric={true}
							/>
							<InfoRow
								label={t(
									'baseOrderDetails.pricePerKm',
									'Price per km (excl. VAT):',
								)}
								value={selectedOrder?.transporterPricePerKm ?? 0}
								isNumeric={true}
							/>
							<InfoRow
								label={t(
									'baseOrderDetails.tripPrice',
									'Trip price (exc. VAT):',
								)}
								value={priceNoVat ?? 0}
								isNumeric={true}
							/>
						</>
					) : null}

					{target !== OrderTarget.admin && (
						<>
							{target !== OrderTarget.transporter ? (
								<InfoRow
									label={t('baseOrderDetails.paymentTerms', 'Payment terms:')}
									value={
										<div className="flex gap-3 items-center whitespace-nowrap">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">
													{selectedOrder?.paymentMethod ===
													PaymentMethod.bank ? (
														<T keyName="baseOrderDetails.bankWire">Bank Wire</T>
													) : (
														<T keyName="baseOrderDetails.card">Card</T>
													)}
												</p>
											</SypacText>
											<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">
													{selectedOrder?.paymentTerm}{' '}
													<T keyName="baseOrderDetails.paymentDays">Days</T>
												</p>
											</SypacText>
										</div>
									}
									className="mt-6"
								/>
							) : null}

							<div
								className={`grid grid-cols-[250px,auto] mb-3 items-start ${
									target === OrderTarget.transporter ? 'mt-6' : ''
								}`}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="baseOrderDetails.totalPriceExclVat">
											Total price (excl. VAT):
										</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<NumericFormat
											type="text"
											displayType="text"
											thousandSeparator=" "
											decimalSeparator="."
											value={
												(target === OrderTarget.producer || sellerView
													? selectedOrder?.priceForProducer
													: priceNoVat) || 0
											}
											suffix={
												selectedOrder?.product.currency === 'lei'
													? ' MDL'
													: ' PLN'
											}
										/>
									</p>
								</SypacText>
							</div>
							<div className="grid grid-cols-[250px,auto] mb-3 items-start">
								<SypacText variant="body-regular-medium">
									<p className="text-lg text-gray-80">
										<T
											keyName="baseOrderDetails.totalPriceInclVat"
											params={{
												vat: `${
													target !== OrderTarget.transporter
														? orderVat
														: selectedOrder?.transportVat
												}`,
											}}
										>
											Total price (incl. VAT)
										</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium">
									<p className="text-lg font-bold text-gray-80">
										<NumericFormat
											type="text"
											displayType="text"
											thousandSeparator=" "
											decimalSeparator="."
											value={
												(target === OrderTarget.producer || sellerView
													? selectedOrder?.priceForProducerWithVat
													: priceWithVat) || 0
											}
											suffix={
												selectedOrder?.product.currency === 'lei'
													? ' MDL'
													: ' PLN'
											}
										/>
									</p>
								</SypacText>
							</div>
						</>
					)}

					{[
						OrderStatusesEnum.DELIVERED,
						OrderStatusesEnum.PAID,
						OrderStatusesEnum.PENDING_PAYMENT,
					].includes(selectedOrder?.status!) &&
					![
						PaymentNotesStatusesEnum.PENDING_INVOICE,
						PaymentNotesStatusesEnum.UPLOADED_INVOICE,
					].includes(orderInvoice?.status as PaymentNotesStatusesEnum) &&
					target === OrderTarget.admin ? (
						orderInvoice === undefined ? (
							<div className="w-full flex flex-col gap-6 mb-2">
								<SypacGrLoader />

								<SypacText variant="body-regular-medium">
									<p className="text-2xl text-shark">
										<T keyName="paymentTerms.invoiceGenerationInProgress">
											Invoice Generation in Progress
										</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-40">
										<T keyName="paymentTerms.pleaseWaitGeneration">
											Please wait. Invoice generation might take a while.
										</T>
									</p>
								</SypacText>
							</div>
						) : (
							<>
								{target === OrderTarget.admin && (
									<>
										<div className="border-0 border-dashed border-t-[1px] border-gray-20 mb-5" />
										<div className="flex items-center gap-3">
											<Person width={16} height={20} />
											<SypacText variant="body-regular-medium">
												<p className="text-base text-gray-60">
													<T keyName="paymentTerms.buyerPaymentTerms">
														Buyer payment terms
													</T>
												</p>
											</SypacText>
										</div>

										<InfoRow
											label={t(
												'baseOrderDetails.paymentTerms',
												'Payment terms:',
											)}
											value={
												<div className="flex gap-3 items-center whitespace-nowrap">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">
															{selectedOrder?.paymentMethod ===
															PaymentMethod.bank ? (
																<T keyName="baseOrderDetails.bankWire">
																	Bank Wire
																</T>
															) : (
																<T keyName="baseOrderDetails.card">Card</T>
															)}
														</p>
													</SypacText>
													<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">
															{selectedOrder?.paymentTerm}{' '}
															<T keyName="baseOrderDetails.paymentDays">Days</T>
														</p>
													</SypacText>
												</div>
											}
											className="mt-6"
										/>

										<div className="grid grid-cols-[250px,auto] mb-3 items-start">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">
													<T keyName="baseOrderDetails.totalPriceExclVat">
														Total price (excl. VAT):
													</T>
												</p>
											</SypacText>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">
													<NumericFormat
														type="text"
														displayType="text"
														thousandSeparator=" "
														decimalSeparator="."
														value={
															// @ts-ignore
															(target === OrderTarget.producer || sellerView
																? selectedOrder?.priceForProducer
																: priceNoVat) || 0
														}
														suffix={
															selectedOrder?.product.currency === 'lei'
																? ' MDL'
																: ' PLN'
														}
													/>
												</p>
											</SypacText>
										</div>
										<div className="grid grid-cols-[250px,auto] mb-3 items-start">
											<SypacText variant="body-regular-medium">
												<p className="text-lg text-gray-80">
													<T
														keyName="baseOrderDetails.totalPriceInclVat"
														params={{
															vat: `${
																// @ts-ignore
																target !== OrderTarget.transporter
																	? orderVat
																	: selectedOrder?.transportVat
															}`,
														}}
													>
														Total price (incl. VAT)
													</T>
												</p>
											</SypacText>
											<SypacText variant="body-regular-medium">
												<p className="text-lg font-bold text-gray-80">
													<NumericFormat
														type="text"
														displayType="text"
														thousandSeparator=" "
														decimalSeparator="."
														value={
															// @ts-ignore
															(target === OrderTarget.producer || sellerView
																? selectedOrder?.priceForProducerWithVat
																: priceWithVat) || 0
														}
														suffix={
															selectedOrder?.product.currency === 'lei'
																? ' MDL'
																: ' PLN'
														}
													/>
												</p>
											</SypacText>
										</div>
									</>
								)}

								<InfoRow
									label={t('paymentTerms.invoiceStatus', 'Invoice status:')}
									className="mt-4"
									isBadge={true}
									badgeContent={
										<div className="flex items-center gap-3">
											{calculateRemainingDays(orderInvoice?.dueDate!) > 0 ? (
												<>
													<StatusBadge
														status={orderInvoice?.status!}
														isInvoiceStatus={true}
													/>
													{orderInvoice?.status === TableStatusEnum.PENDING ? (
														<>
															<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
																<SypacText variant="body-regular-medium">
																	<p className="text-xs truncate">
																		{calculateRemainingDays(
																			orderInvoice.dueDate,
																		)}{' '}
																		<T keyName="baseOrderDetails.paymentDays">
																			Days
																		</T>
																	</p>
																</SypacText>
															</span>
															<SypacText variant="body-regular-medium">
																<p className="text-sm text-gray-40 max-w-[78px]">
																	<T keyName="paymentTerms.remainingToPay">
																		Remaining
																	</T>
																</p>
															</SypacText>
														</>
													) : null}
												</>
											) : (
												<>
													<SypacBadge
														color={PaymentStatusColor['overdue']}
														size="large"
														className="h-[24px]"
													>
														<div className="flex gap-1 items-center text-red w-fit justify-center cursor-auto">
															<SypacIcon
																iconName="Danger Filled"
																size="custom"
																width="12px"
																height="11px"
															/>
															<SypacText variant="overline-regular-large">
																<p className="text-white">
																	<T keyName="paymentStatus.overdue">Overdue</T>
																</p>
															</SypacText>
														</div>
													</SypacBadge>
													<span className="flex items-center h-[22px] bg-gray-10 rounded px-1">
														<SypacText variant="body-regular-medium">
															<p className="text-xs truncate">
																{Math.abs(
																	calculateRemainingDays(
																		orderInvoice?.dueDate!,
																	),
																)}{' '}
																<T keyName="baseOrderDetails.paymentDays">
																	Days
																</T>
															</p>
														</SypacText>
													</span>
													<SypacText variant="body-regular-medium">
														<p className="text-sm text-gray-40 truncate">
															<T keyName="paymentTerms.sinceOverdue">
																Since overdue
															</T>
														</p>
													</SypacText>
												</>
											)}
										</div>
									}
								/>

								<InfoRow
									label={t('paymentTerms.issued', 'Issued:')}
									value={
										<div className="flex items-center gap-3 ml-[5px]">
											<span className="p-0 m-0 flex scale-[1.1]">
												<Calendar />
											</span>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">
													{formatDate(orderInvoice?.createdAt)}
												</p>
											</SypacText>
										</div>
									}
								/>

								<div className="flex gap-5 mb-5">
									<SypacButton
										variant="secondary"
										size="large"
										className="w-full"
									>
										<button
											type="button"
											className="w-full h-[42px] transition"
											onClick={() =>
												//@ts-ignore
												actionClick(PaymentTypeAction.download, orderInvoice!)
											}
										>
											<span className="p-0 m-0 flex scale-[1.1]">
												<Invoice />
											</span>
											<SypacText variant="body-normal-medium">
												<p>
													<T keyName="paymentsWithdrawHistory.downloadInvoice">
														Download invoice
													</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>

									<SypacButton
										variant="secondary"
										size="large"
										className="w-full"
									>
										<button
											type="button"
											className="w-full h-[42px] transition"
											onClick={() =>
												actionClick(
													PaymentTypeAction.view_invoice,
													//@ts-ignore
													orderInvoice!,
												)
											}
										>
											<SypacIcon
												icon-name="Eye"
												className="text-gray"
												size="custom"
												width="32px"
												height="32px"
											/>
											<SypacText variant="body-normal-medium">
												<p className="-ml-[6px]">
													<T keyName="paymentsWithdrawHistory.viewInvoice">
														View invoice
													</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>
								</div>
							</>
						)
					) : null}
				</div>
			</ExpandedBox>

			{openView ? (
				<DocumentsViewer
					url={invoiceUrl!}
					isPdf={true}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}

			<Tooltip
				place="top"
				id="base-order-details"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
					whiteSpace: 'normal',
					maxWidth: '248px',
				}}
			/>
		</>
	);
};

export default BaseOrderDetails;
