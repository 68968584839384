import { IconInterface } from '../interfaces/icon.interface';

export const LogoBlack = (props: IconInterface) => {
	const { width = 134, height = 25 } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 134 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M33.0091 16.4416C33.0091 15.5799 33.7025 14.8833 34.5601 14.8833H46.2195C46.3837 14.8833 46.5479 14.8833 46.7121 14.8833C46.9858 14.8833 47.2139 14.6541 47.223 14.3883C47.2321 14.1041 47.0041 13.8658 46.7121 13.8566C46.5479 13.8566 46.3837 13.8566 46.2195 13.8566H37.5981C34.5327 13.8566 33 12.72 33 10.4558C33 9.41081 33.3649 8.56749 34.0948 7.94416C34.8246 7.31166 35.9924 7 37.5981 7H49.4126C50.2701 7 50.9635 7.69666 50.9635 8.55832V8.57665C50.9635 9.43831 50.2701 10.135 49.4126 10.135H37.7805C37.6163 10.135 37.4521 10.135 37.2879 10.135C37.0142 10.135 36.7861 10.3641 36.777 10.63C36.7679 10.9141 36.9959 11.1525 37.2879 11.1616C37.4521 11.1616 37.6163 11.1616 37.7805 11.1616H46.4019C49.4673 11.1616 51 12.2983 51 14.5624C51 15.6074 50.6351 16.4416 49.9052 17.0649C49.1754 17.6882 48.0076 17.9999 46.4019 17.9999H34.5692C33.7116 18.0091 33.0091 17.3033 33.0091 16.4416Z"
				fill="#171717"
			/>
			<path
				d="M76 16.4417V8.55833C76 7.69667 76.747 7 77.671 7H89.5841C91.4615 7 92.8376 7.3575 93.7025 8.06333C94.5675 8.76917 95 9.70417 95 10.85C95 12.0142 94.5675 12.9492 93.7025 13.6458C92.8376 14.3425 91.4713 14.7 89.5841 14.7H80.8262C80.3151 14.7 79.9022 15.085 79.9022 15.5617V16.4417C79.9022 17.3033 79.1552 18 78.2312 18H77.6808C76.7569 18 76 17.3033 76 16.4417ZM80.6591 10.1442C80.2364 10.1442 79.9022 10.465 79.9022 10.85C79.9022 11.235 80.2463 11.5558 80.6591 11.5558C83.8339 11.5558 87.0088 11.5558 90.1837 11.5558C90.6063 11.5375 90.9405 11.2167 90.9405 10.85C90.9405 10.52 90.6751 10.2175 90.3016 10.1533C87.0874 10.1442 83.8733 10.1442 80.6591 10.1442Z"
				fill="#171717"
			/>
			<path
				d="M110.981 17.3673L110.621 16.9181C110.299 16.5148 109.81 16.2765 109.293 16.2765H100.847C100.33 16.2765 99.8414 16.5148 99.5186 16.9089L99.1497 17.3673C98.827 17.7706 98.3382 17.9997 97.8218 17.9997H97.7019C96.2726 17.9997 95.4888 16.3589 96.374 15.2498L102.193 8.02664C102.718 7.37582 103.512 7 104.351 7H105.669C106.509 7 107.302 7.37582 107.827 8.03581L113.628 15.259C114.513 16.3681 113.72 17.9997 112.3 17.9997C111.793 18.0089 111.304 17.7706 110.981 17.3673ZM104.646 10.5199L102.931 12.6557C102.645 13.0132 102.903 13.5448 103.364 13.5448H106.785C107.246 13.5448 107.504 13.0132 107.219 12.6557L105.513 10.5199C105.291 10.2449 104.867 10.2449 104.646 10.5199Z"
				fill="#171717"
			/>
			<path
				d="M120.658 10.7492C120.196 11.1525 119.96 11.7392 119.96 12.5092C119.96 13.2792 120.186 13.875 120.649 14.2783C121.111 14.6817 122.025 14.8833 123.421 14.8833H132.397C133.283 14.8833 134 15.58 134 16.4417C134 17.3033 133.283 18 132.397 18H123.421C120.847 18 118.97 17.4958 117.782 16.4875C116.594 15.4792 116 14.15 116 12.5C116 10.85 116.594 9.52083 117.782 8.5125C118.97 7.50417 120.847 7 123.421 7H132.397C133.283 7 134 7.69667 134 8.55833V8.57667C134 9.43833 133.283 10.135 132.397 10.135H123.421C122.044 10.1533 121.129 10.3458 120.658 10.7492Z"
				fill="#171717"
			/>
			<path
				d="M70.5283 7.5775C70.3011 7.33 69.9886 7.1375 69.6193 7.055H69.6099H69.6004C69.5815 7.055 69.5625 7.04583 69.5436 7.04583C69.5247 7.04583 69.5152 7.03667 69.4962 7.03667C69.4773 7.03667 69.4584 7.0275 69.4394 7.0275C69.43 7.0275 69.411 7.0275 69.4016 7.01833C69.3826 7.01833 69.3542 7.01833 69.3353 7.00917C69.3258 7.00917 69.3069 7.00917 69.2974 7.00917C69.2595 7.00917 69.2217 7.00917 69.1933 7.00917C69.1838 7.00917 69.1743 7.00917 69.1648 7.00917C69.1364 7.00917 69.108 7.00917 69.0796 7.01833C69.0607 7.01833 69.0323 7.01833 69.0133 7.0275C68.6251 7.07333 68.2558 7.23833 67.9623 7.50417L64.2316 10.8592C63.8813 11.1708 63.4362 11.3267 62.9912 11.3267C62.5462 11.3267 62.1011 11.18 61.7508 10.8592L58.0295 7.495C57.7644 7.2475 57.4235 7.09167 57.0637 7.0275C56.9785 7.00917 56.8838 7 56.7986 7C56.7891 7 56.7702 7 56.7607 7H56.7512C56.7323 7 56.7134 7 56.6944 7C56.685 7 56.666 7 56.6566 7C56.6376 7 56.6092 7 56.5903 7.00917C56.5713 7.00917 56.5619 7.00917 56.5524 7.01833C56.5335 7.01833 56.5145 7.0275 56.4956 7.0275C56.4767 7.0275 56.4672 7.03667 56.4482 7.03667C56.4293 7.03667 56.4104 7.04583 56.3914 7.04583H56.382H56.3725C56.0032 7.12833 55.6907 7.32083 55.4635 7.56833C54.848 8.2375 54.7912 9.35583 55.6245 10.0525L60.5104 14.1225C60.9081 14.4525 61.1353 14.9292 61.1353 15.4425V16.2675C61.1353 17.23 61.9402 18 62.9249 18H63.0007H63.0764C64.0706 18 64.866 17.2208 64.866 16.2675V15.4425C64.866 14.9383 65.0933 14.4525 65.4909 14.1225L70.3769 10.0525C71.2101 9.365 71.1533 8.24667 70.5283 7.5775Z"
				fill="#171717"
			/>
			<path
				d="M17.1296 25H7.87037C3.51852 25 0 21.4722 0 17.1296V7.87037C0 3.51852 3.52778 0 7.87037 0H17.1296C21.4815 0 25 3.52778 25 7.87037V17.1296C25 21.4722 21.4722 25 17.1296 25Z"
				fill="url(#paint0_linear_877_13727)"
			/>
			<path
				d="M10.1944 9.31402C10.3426 9.05476 10.6018 8.90661 10.8981 8.90661L17.287 8.92513C17.6018 8.92513 17.8518 9.10106 17.9907 9.33254C18.1204 9.56402 18.1481 9.86957 17.9907 10.1474L14.7778 15.6751C14.6296 15.9344 14.3704 16.0825 14.0741 16.0825C13.7685 16.0825 13.5278 15.9344 13.3796 15.6751L11.787 12.9066L10.1944 10.1381C10.037 9.86032 10.037 9.58254 10.1944 9.31402ZM19.3611 8.55476C19.1574 8.20291 18.8611 7.89735 18.4907 7.67513C18.1389 7.47143 17.7315 7.36032 17.2963 7.36032C15.1666 7.35106 13.037 7.3418 10.9074 7.3418C9.53702 7.3418 8.5185 8.44365 8.50924 9.72143C8.50924 9.92513 8.53702 10.1288 8.58331 10.3233C8.18517 10.4622 7.85183 10.7399 7.62961 11.1196L5.57406 14.6566C5.2222 15.2585 5.2685 15.9344 5.5648 16.4622C5.87035 16.9899 6.43517 17.3696 7.12961 17.3696L11.2222 17.3788C11.6666 17.3788 12.0648 17.2399 12.3889 16.9622C12.537 17.1011 12.6944 17.2307 12.8704 17.3325C13.9722 17.9714 15.4444 17.6474 16.1296 16.4622C17.2037 14.6196 18.2685 12.777 19.3426 10.9436C19.5648 10.564 19.6759 10.1659 19.6759 9.7492C19.6759 9.31402 19.5648 8.90661 19.3611 8.55476ZM6.92591 15.6751C6.88887 15.6103 6.87961 15.527 6.92591 15.4344C7.61109 14.2585 8.29628 13.0918 8.99998 11.9251C9.10183 11.7492 9.27776 11.7029 9.38887 11.9066L10.4074 13.6844L11.4259 15.4622C11.537 15.6566 11.4074 15.7862 11.2129 15.7862C9.85183 15.8048 8.49998 15.8048 7.13887 15.8048C7.03702 15.7955 6.96294 15.7399 6.92591 15.6751Z"
				fill="white"
			/>
			<mask
				id="mask0_877_13727"
				type="alpha"
				maskUnits="userSpaceOnUse"
				x="7"
				y="5"
				width="8"
				height="8"
			>
				<mask
					id="mask1_877_13727"
					type="alpha"
					maskUnits="userSpaceOnUse"
					x="7"
					y="5"
					width="8"
					height="8"
				>
					<path
						d="M10.4799 5.61328L14.7947 8.12254L12.2484 12.4929L7.93359 9.98365L10.4799 5.61328Z"
						fill="url(#paint1_linear_877_13727)"
					/>
				</mask>
				<g mask="url(#mask1_877_13727)">
					<path
						d="M10.4799 5.61133L14.7947 8.12059L12.2484 12.491L7.93359 9.9817L10.4799 5.61133Z"
						fill="url(#paint2_linear_877_13727)"
					/>
				</g>
			</mask>
			<g mask="url(#mask0_877_13727)">
				<path
					d="M14.2865 8.92513L13.3791 7.35106L10.8884 7.3418C9.51801 7.3418 8.49949 8.44365 8.49023 9.72143C8.49023 9.92513 8.51801 10.1288 8.56431 10.3233C9.38838 10.0362 10.3143 10.3696 10.7495 11.1288L10.1754 10.1288C9.84209 9.55476 10.2773 8.91587 10.8791 8.91587L14.2865 8.92513Z"
					fill="url(#paint3_linear_877_13727)"
				/>
			</g>
			<mask
				id="mask2_877_13727"
				type="alpha"
				maskUnits="userSpaceOnUse"
				x="7"
				y="13"
				width="6"
				height="7"
			>
				<mask
					id="mask3_877_13727"
					type="alpha"
					maskUnits="userSpaceOnUse"
					x="7"
					y="13"
					width="6"
					height="7"
				>
					<path
						d="M8.87869 13.9355L12.8417 16.2411L11.2305 19.0189L7.26758 16.7133L8.87869 13.9355Z"
						fill="url(#paint4_linear_877_13727)"
					/>
				</mask>
				<g mask="url(#mask3_877_13727)">
					<path
						d="M8.87869 13.9355L12.8417 16.2411L11.2305 19.0189L7.26758 16.7133L8.87869 13.9355Z"
						fill="url(#paint5_linear_877_13727)"
					/>
				</g>
			</mask>
			<g mask="url(#mask2_877_13727)">
				<path
					d="M11.9992 16.4531L11.4251 15.4531C11.5362 15.6476 11.4066 15.7772 11.2122 15.7772C10.0825 15.7865 8.96217 15.7957 7.8418 15.7957L8.48069 17.3698L11.2214 17.3791C11.6659 17.3791 12.064 17.2402 12.3881 16.9624C12.2399 16.8142 12.1103 16.6476 11.9992 16.4531Z"
					fill="url(#paint6_linear_877_13727)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_877_13727"
					x1="0"
					y1="12.5"
					x2="25"
					y2="12.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.0220187" stopColor="#494C83" />
					<stop offset="1" stopColor="#7693F4" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_877_13727"
					x1="7.35724"
					y1="11.3543"
					x2="12.3488"
					y2="8.49385"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_877_13727"
					x1="7.35724"
					y1="11.3523"
					x2="12.3488"
					y2="8.4919"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_877_13727"
					x1="8.57655"
					y1="10.3274"
					x2="13.5153"
					y2="7.55647"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopOpacity="0.5" />
					<stop offset="1" stopColor="#363976" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_877_13727"
					x1="11.9274"
					y1="15.4069"
					x2="9.29346"
					y2="16.9161"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_877_13727"
					x1="11.9274"
					y1="15.4069"
					x2="9.29346"
					y2="16.9161"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_877_13727"
					x1="11.6055"
					y1="15.9871"
					x2="8.16215"
					y2="17.0825"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopOpacity="0.5" />
					<stop offset="1" stopColor="#363976" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
