import React, { useEffect, useState } from 'react';
import { SypacBox, SypacText } from '@sypac/component-library-react';
import { ListMenuProps } from './ListMenu.interface';

const ListMenu: React.FC<ListMenuProps> = ({ items = [], selected }) => {
	const [active, setActive] = useState<string>(selected || '');

	useEffect(() => {
		if (!selected && items?.length) {
			setActive(items[0].id);
		}
	}, [selected, items]);

	return (
		<SypacBox
			hover={false}
			boxStyle="none"
			//className="lg:w-[195px] xl:w-[255px]"
		>
			<nav className="flex flex-col gap-2.5">
				{items?.map(({ id, name, onClick }) => (
					<button
						key={id}
						type="button"
						className={`w-full flex items-center rounded-lg py-2.75 px-3 border-none transition cursor-pointer text-start ${
							active === id
								? 'bg-primary-violet text-white'
								: 'bg-transparent hover:bg-gray-10 text-gray-80'
						}`}
						onClick={() => {
							setActive(id);
							onClick && onClick();
						}}
					>
						<SypacText variant="body-regular-medium">
							<p>{name}</p>
						</SypacText>
					</button>
				))}
			</nav>
		</SypacBox>
	);
};

export default ListMenu;
