import React, { createContext, useState, useMemo } from 'react';
import { InvoiceInterface } from '../../components/PaymentsTable/interfaces/Payment.interface';

interface OrderDetailsContextProps {
	customerInvoice: InvoiceInterface | undefined;
	setCustomerInvoice: (invoice: InvoiceInterface | undefined) => void;
	carrierInvoice: InvoiceInterface | undefined;
	setCarrierInvoice: (invoice: InvoiceInterface | undefined) => void;
	producerInvoice: InvoiceInterface | undefined;
	setProducerInvoice: (invoice: InvoiceInterface | undefined) => void;
	actionFor: string;
	setActionFor: (action: string) => void;
}

export const OrderDetailsContext = createContext<OrderDetailsContextProps>({
	customerInvoice: undefined,
	setCustomerInvoice: () => null,
	carrierInvoice: undefined,
	setCarrierInvoice: () => null,
	producerInvoice: undefined,
	setProducerInvoice: () => null,
	actionFor: '',
	setActionFor: () => null,
});

const OrderDetailsContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [customerInvoice, setCustomerInvoice] = useState<
		InvoiceInterface | undefined
	>();
	const [carrierInvoice, setCarrierInvoice] = useState<
		InvoiceInterface | undefined
	>();
	const [producerInvoice, setProducerInvoice] = useState<
		InvoiceInterface | undefined
	>();
	const [actionFor, setActionFor] = useState<string>('');

	const value = useMemo<OrderDetailsContextProps>(
		() => ({
			customerInvoice,
			setCustomerInvoice,
			carrierInvoice,
			setCarrierInvoice,
			producerInvoice,
			setProducerInvoice,
			actionFor,
			setActionFor,
		}),
		[customerInvoice, carrierInvoice, producerInvoice, actionFor],
	);

	return (
		<OrderDetailsContext.Provider value={value}>
			{children}
		</OrderDetailsContext.Provider>
	);
};

export default OrderDetailsContextProvider;
