/**
 * Formats a number to ensure it has at most two decimal places.
 * @param {number} value - The number to be formatted.
 * @returns {number} - The formatted number.
 */
export const numberTwoDecimal = (value: number): number => {
	const decimalCount = (value?.toString().split('.')[1] || '').length;

	if (decimalCount <= 2) {
		return value;
	}

	return parseFloat(value.toFixed(2));
};
