import React from 'react';
import { SypacText } from '@sypac/component-library-react';
import AlertSmall from '../../../assets/AlertSmall';
import { T, useTranslate } from '@tolgee/react';
import StatusBadge from '../../StatusBadge/StatusBadge';
import { ContentDto } from '../../../containers/Transporter/OrderDetails/components/Timeline/Notification/NotificationIssueSolved';
import { InfoRowProps } from '../../BaseOrderDetails/BaseOrderDetails.interface';

interface IssuesDefaultProps {
	notification: boolean;
	title: string;
	content: ContentDto;
	newIssue: boolean;
	solvedByDriver?: boolean;
	changeTruck?: boolean;
}

const IssuesDefault = ({
	notification,
	title,
	content,
	newIssue,
	solvedByDriver,
	changeTruck,
}: IssuesDefaultProps) => {
	const { t } = useTranslate();

	const InfoRow = ({
		label,
		value,
		isBadge,
		badgeContent,
		className,
	}: InfoRowProps) => (
		<div className={`grid grid-cols-[160px,auto] items-start ${className}`}>
			<SypacText variant="body-regular-medium">
				<p className="text-sm text-gray-40">{label}</p>
			</SypacText>
			{isBadge ? (
				badgeContent
			) : (
				<SypacText variant="body-regular-medium">
					<p className="w-[260px] overflow-hidden text-ellipsis text-sm text-gray-80">
						{value}
					</p>
				</SypacText>
			)}
		</div>
	);

	return (
		<div
			className={`flex flex-col gap-3 bg-white border border-solid rounded-10 ${
				notification
					? 'border-primary-violet box-notification-dot'
					: 'border-gray-10'
			}`}
		>
			<div className="flex gap-4 px-3 pt-3">
				{newIssue && <AlertSmall color="#F44A77" />}
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{title}</p>
				</SypacText>
			</div>

			<div className="border-0 border-solid border-t-[1px] border-gray-10" />

			<div className={`flex flex-col gap-3 px-3 ${!changeTruck ? 'pb-3' : ''}`}>
				<InfoRow
					label={t('defaultIssueEvent.issueType', 'Issue type:')}
					value={t(
						`driverIssueBlock.${content.reason
							.toLowerCase()
							.replace(/ (.)/g, (_, c) => c.toUpperCase())}`,
						`${content.reason}`,
					)}
				/>
				<InfoRow
					label={t('defaultIssueEvent.issueStatus', 'Issue status:')}
					isBadge={true}
					badgeContent={
						<StatusBadge status={content.status!} isIssueStatus={true} />
					}
				/>
				<InfoRow
					label={
						solvedByDriver !== false
							? t('defaultIssueEvent.driverComment', 'Driver comment:')
							: t(
									'defaultIssueEvent.dispatcherComments',
									'Dispatcher comments:',
							  )
					}
					value={
						content[
							solvedByDriver !== false ? 'comment' : 'transporterComment'
						] || '-'
					}
				/>
			</div>

			{changeTruck ? (
				<div className="flex flex-col gap-3 px-3 pb-3">
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div className="flex flex-col gap-1.5">
						<div className="w-fit flex px-2 py-1 bg-mountain-meadow rounded-full mb-1">
							<SypacText variant="body-regular-medium">
								<p className="text-xs text-white">
									<T keyName="defaultIssueEvent.newTruck">New truck</T>
								</p>
							</SypacText>
						</div>

						<InfoRow
							label={t('truckDetails.driverName', 'Driver name:')}
							value={content.driverData?.driverFullName}
						/>
						<InfoRow
							label={t('truckAllocated.truckName', 'Truck Name:')}
							value={content.driverData?.name}
						/>
						<InfoRow
							label={t('truckDetails.licensePlates', 'License plates:')}
							value={content.driverData?.licensePlates}
						/>
						<InfoRow
							label={t('truckAllocated.truckType', 'Truck type:')}
							value={content.driverData?.type}
						/>
					</div>

					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div className="flex flex-col gap-1.5">
						<div className="w-fit flex px-2 py-1 bg-gray-40 rounded-full mb-1">
							<SypacText variant="body-regular-medium">
								<p className="text-xs text-white">
									<T keyName="defaultIssueEvent.oldTruck">Old truck</T>
								</p>
							</SypacText>
						</div>

						<InfoRow
							label={t('truckDetails.driverName', 'Driver name:')}
							value={content.oldDriverData?.driverFullName}
						/>
						<InfoRow
							label={t('truckAllocated.truckName', 'Truck Name:')}
							value={content.oldDriverData?.name}
						/>
						<InfoRow
							label={t('truckDetails.licensePlates', 'License plates:')}
							value={content.oldDriverData?.licensePlates}
						/>
						<InfoRow
							label={t('truckAllocated.truckType', 'Truck type:')}
							value={content.oldDriverData?.type}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default IssuesDefault;
