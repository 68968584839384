import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import { CompanyAdminStats, CompanyStats } from '../services/company.services';
interface Props {
	fromDate?: string;
	toDate?: string;
	target: string | number;
	refresh: number;
}

interface PropsStats {
	fromDate?: string;
	toDate?: string;
}

type UseGetStats = [CompanyStats | undefined, Error | undefined, boolean];

export const useGetStats = ({
	target,
	refresh = 0,
	toDate,
	fromDate,
}: Props): UseGetStats => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/${target}/billings/stats`, {
				params: {
					fromDate,
					toDate,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, target, toDate, fromDate, refresh]);

	return [data, error, isLoading];
};

type UseGetCompanyAdminStats = [
	CompanyAdminStats | undefined,
	Error | undefined,
	boolean,
];

export const useGetCompanyAdminStats = (
	status?: string,
	companyTypes?: string[],
	search?: string,
	countryCode?: string,
	refresh = 0,
): UseGetCompanyAdminStats => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get('/v1/admin/companies/stats', {
				params: {
					status,
					companyTypes,
					search,
					countryCode,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [companyTypes, countryCode, facade, refresh, search, status]);

	return [data, error, isLoading];
};

export const useGetInvoiceStatsAdmin = ({
	target,
	refresh = 0,
	toDate,
	fromDate,
}: Props): UseGetStats => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/admin/stats/${target}`, {
				params: {
					fromDate,
					toDate,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, target, toDate, fromDate, refresh]);

	return [data, error, isLoading];
};

export const useGetInvoiceStatsByCompnayId = (
	companyId: number,
	{ toDate, fromDate }: PropsStats,
): UseGetStats => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/admin/stats/companies/${companyId}`, {
				params: {
					fromDate,
					toDate,
				},
			})
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	}, [facade, toDate, fromDate, companyId]);

	return [data, error, isLoading];
};
