import React, { DragEvent, useContext, useState } from 'react';
import { ProductContext } from '../../../../context/DatabaseProductContext/database-product.context';
import {
	SypacButton,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import Close from '../../../../assets/Close';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ProductService } from '../../../../services/product.services';
import * as Yup from 'yup';
import { FileInterface } from '../../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { FileService } from '../../../../services/file.service';
import { toastVariant } from '../../../../components/ToastVariant/toastVariant';
import FolderIcon from '../../../../assets/FolderIcon';
import { StepProps } from '../addNewProduct.interface';
import ModalAddTranslates from '../../../../components/ModalAddTranslates/ModalAddTranslates';
import LoadingBar from '../../../../components/Loaders/LoadingBar';
import { MaxFileSizeMB } from '../../../../constants';

const ProductDetailsStep: React.FC<StepProps> = ({ setStep, setProduct }) => {
	const { t } = useTranslate();
	const navigate = useNavigate();
	const { product } = useContext(ProductContext);
	const [file, setFile] = useState<FileInterface | undefined>(undefined);
	const [fileLoading, setFileLoading] = useState<boolean>(false);
	const [showFile, setShowFile] = useState<boolean>(false);
	const [charCount, setCharCount] = useState<number>(0);
	const [addTranslate, setAddTranslate] = useState<boolean>(false);
	const [translateType, setTranslateType] = useState<string>('');
	const [translations, setTranslations] = useState<{
		[key: string]: Array<{
			language: string;
			value: string;
			[key: string]: string;
		}>;
	}>({
		name: [],
		type: [],
		description: [],
	});

	const formik = useFormik({
		initialValues: {
			size: '',
			type: '',
			productName: '',
			description: '',
			productNameRO: '',
			productNameRU: '',
			productNamePL: '',
			typeRO: '',
			typeRU: '',
			typePL: '',
			descriptionRO: '',
			descriptionRU: '',
			descriptionPL: '',
		},
		onSubmit: async (values) => {
			const translations = [
				{
					language: 'en',
					name: values.productName || '',
					description: values.description || '',
					// type: values.type || '',
				},
				{
					language: 'ro',
					name: values.productNameRO || '',
					description: values.descriptionRO || '',
					// type: values.typeRO || '',
				},
				{
					language: 'ru',
					name: values.productNameRU || '',
					description: values.descriptionRU || '',
					// type: values.typeRU || '',
				},
				{
					language: 'pl',
					name: values.productNamePL || '',
					description: values.descriptionPL || '',
					// type: values.typePL || '',
				},
			];

			const nonEmptyTranslations = translations.filter(
				(translation) => translation.name || translation.description,
			);

			const body = {
				name: values.productName,
				categoryId: product?.categoryId!,
				photoUrl: file?.file?.name,
				countryCode: product?.countryCode!,
				size: values.size,
				type: values.type,
				unit: 'ton',
				description: values.description,
				translations: nonEmptyTranslations,
			};

			try {
				const { data } = await ProductService.createProductAdmin(body);
				setProduct &&
					setProduct({
						...data,
						photoUrl: file?.file?.url,
					});
				setStep((prevStep) => prevStep + 1);
			} catch (e) {}
		},
		validationSchema: Yup.object().shape({
			productName: Yup.string().required(
				t(
					'modalAddProduct.productNameRequired',
					'Please enter the product name',
				),
			),
			size: Yup.string(),
			type: Yup.string(),
		}),
		validateOnMount: true,
	});

	const handleFileUpload = async (file: File) => {
		if (file.size / (1024 * 1024) > MaxFileSizeMB.IMAGE) {
			toastVariant(
				`File size exceeds ${MaxFileSizeMB.IMAGE} MB. Please upload a smaller file.`,
				true,
			);
			return;
		}

		setFileLoading(true);
		try {
			const { data } = await FileService.uploadFiles([file]);
			const newFile = data.items[0];
			const [name, type] = newFile.originalName?.split('.') || [];
			const mimeType =
				newFile.type?.split('/').pop()?.toUpperCase() ||
				type?.toUpperCase() ||
				'';
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

			setFile({ name, mimeType, size, file: newFile });
		} catch (e) {
			toastVariant(
				t('modalAddNewGroup.fileUploadError', 'Error uploading file'),
				true,
			);
		} finally {
			setFileLoading(false);
		}
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const droppedFile = e.dataTransfer.files?.[0];
		if (droppedFile) handleFileUpload(droppedFile).then(() => {});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files?.[0];
		if (selectedFile) handleFileUpload(selectedFile).then(() => {});
	};

	const handleDeleteFile = () => {
		setShowFile(false);
		setFile(undefined);
	};

	const handleTextareaChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		const text = event.target.value;
		formik.handleChange(event);
		setCharCount(text.length);
	};

	const handleReset = () => {
		formik.resetForm();
		setFile(undefined);
		setCharCount(0);
		setAddTranslate(false);
		setStep((prevStep) => prevStep - 1);
	};

	const closeTransModal = () => {
		setAddTranslate(false);
		setTranslateType('');
	};

	const handleModalSave = (translationsData: {
		key: string;
		translations: Array<{
			language: string;
			value: string;
			[key: string]: string;
		}>;
	}) => {
		const { key, translations: newTranslations } = translationsData;

		setTranslations((prev) => ({
			...prev,
			[key]: newTranslations,
		}));

		const translationMap: { [key: string]: string } = newTranslations.reduce(
			(acc: { [key: string]: string }, translation) => {
				acc[translation.language] = translation[key];
				return acc;
			},
			{},
		);

		if (key === 'name') {
			formik.setFieldValue(
				'productName',
				translationMap.en || formik.values.productName,
			);
			formik.setFieldValue('productNameRO', translationMap.ro || '');
			formik.setFieldValue('productNameRU', translationMap.ru || '');
			formik.setFieldValue('productNamePL', translationMap.pl || '');
		} else if (key === 'type') {
			formik.setFieldValue('type', translationMap.en || formik.values.type);
			formik.setFieldValue('typeRO', translationMap.ro || '');
			formik.setFieldValue('typeRU', translationMap.ru || '');
			formik.setFieldValue('typePL', translationMap.pl || '');
		} else if (key === 'description') {
			formik.setFieldValue(
				'description',
				translationMap.en || formik.values.description,
			);
			formik.setFieldValue('descriptionRO', translationMap.ro || '');
			formik.setFieldValue('descriptionRU', translationMap.ru || '');
			formik.setFieldValue('descriptionPL', translationMap.pl || '');
		}

		closeTransModal();
	};

	return (
		<div className="w-full h-full flex items-center justify-center">
			<main className="max-w-[660px] w-full h-[560px] 2xl:h-auto flex flex-col gap-6">
				<section className="flex justify-between items-center">
					<SypacText variant="body-normal-medium">
						<p className="text-xl text-gray-80">
							<T keyName="productGroup.manageProduct">
								Manage product groups and categories
							</T>
						</p>
					</SypacText>
					<SypacButton variant="secondary" size="medium">
						<button
							onClick={() => navigate('/product-database')}
							className="p-2 border border-solid border-gray-10 transition hover:border-primary-violet"
						>
							<Close />
						</button>
					</SypacButton>
				</section>

				<SypacText variant="body-normal-medium">
					<p className="text-gray-60">
						<T keyName="selectProductStore.addProductTypeDetails">
							Add product type details
						</T>
					</p>
				</SypacText>
				<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

				<form
					onSubmit={formik.handleSubmit}
					className="flex flex-col py-3 border border-solid border-gray-10 rounded-xl shadow-dropdown-avatar"
				>
					<div className="flex flex-col gap-3 box-border">
						<div className="flex flex-col gap-3 px-3">
							<SypacInput
								error={
									!!(formik.touched.productName && formik.errors.productName)
								}
							>
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="modalAddProduct.productName">Product name</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<input
									className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
										formik.touched.productName && formik.errors.productName
											? 'border-red-orange'
											: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
									}`}
									name="productName"
									type="text"
									placeholder={t(
										'modalAddProduct.enterProductName',
										'Enter product name',
									)}
									value={formik?.values.productName}
									onChange={(event) => formik?.handleChange(event)}
								/>
								{formik?.touched.productName && formik?.errors.productName ? (
									<span className="input-error">
										{formik.errors.productName}
									</span>
								) : null}
							</SypacInput>

							{/* {addTranslate && (
								<>
									{translateFieldKeys.map((fieldKey) => (
										<SypacInput
											key={fieldKey}
											error={
												!!(formik.touched[fieldKey] && formik.errors[fieldKey])
											}
										>
											<SypacText
												className="mb-1"
												variant="overline-normal-large"
											>
												<p>
													<T
														keyName={`modalAddProduct.${fieldKey}`}
													>{`Product name ${fieldKey
														.slice(-2)
														.toUpperCase()}`}</T>{' '}
													<span className="text-red">*</span>
												</p>
											</SypacText>
											<input
												className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
													formik.touched[fieldKey] && formik.errors[fieldKey]
														? 'border-red-orange'
														: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
												}`}
												name={fieldKey}
												type="text"
												placeholder={t(
													`modalAddProduct.enter${fieldKey}`,
													`Enter product name ${fieldKey
														.slice(-2)
														.toUpperCase()}`,
												)}
												value={formik?.values[fieldKey]}
												onChange={(event) => formik?.handleChange(event)}
											/>
											{formik?.touched[fieldKey] && formik?.errors[fieldKey] ? (
												<span className="input-error">
													{formik.errors[fieldKey]}
												</span>
											) : null}
										</SypacInput>
									))}
								</>
							)} */}

							<SypacButton
								variant="secondary"
								size="small"
								className="self-start -ml-[10px]"
							>
								<button
									className="flex items-center bg-transparent border-none p-0 group"
									type="button"
									onClick={() => {
										setTranslateType('name');
										setAddTranslate(true);
									}}
								>
									<span className="relative h-[18px] flex m-0 scale-[1.3] mr-[38px]">
										<SypacIcon
											iconName="Document Add"
											size="custom"
											width="32px"
											height="32px"
											className="absolute -bottom-[8px] text-primary-violet transition group-hover:text-primary-violet/70"
										/>
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-primary-violet mt-[2px] transition group-hover:text-primary-violet/70">
											<T keyName="addProductDetails.addTranslates">
												Add translates
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />

						<div className="flex flex-col gap-3 px-3">
							<div className="flex gap-4">
								<SypacInput
									error={!!(formik.touched.type && formik.errors.type)}
									className="w-full"
								>
									<SypacText className="mb-1" variant="overline-normal-large">
										<p>
											<T keyName="modalAddProduct.productType">Product type</T>
										</p>
									</SypacText>
									<input
										className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
											formik.touched.type && formik.errors.type
												? 'border-red-orange'
												: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
										}`}
										name="type"
										type="text"
										placeholder={t(
											'modalAddProduct.enterProductType',
											'Enter product type',
										)}
										value={formik?.values.type}
										onChange={(event) => formik?.handleChange(event)}
									/>
									{formik?.touched.type && formik?.errors.type ? (
										<span className="input-error">{formik.errors.type}</span>
									) : null}
								</SypacInput>

								<SypacInput
									error={!!(formik.touched.size && formik.errors.size)}
									className="w-[188px]"
								>
									<SypacText className="mb-1" variant="overline-normal-large">
										<p>
											<T keyName="modalAddProduct.productSize">Product size</T>
										</p>
									</SypacText>
									<input
										className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
											formik.touched.size && formik.errors.size
												? 'border-red-orange'
												: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
										}`}
										name="size"
										type="text"
										placeholder={t(
											'modalAddProduct.enterProductSize',
											'Enter product size',
										)}
										value={formik?.values.size}
										onChange={(event) => formik?.handleChange(event)}
									/>
									{formik?.touched.size && formik?.errors.size ? (
										<span className="input-error">{formik.errors.size}</span>
									) : null}
								</SypacInput>
							</div>

							<SypacButton
								variant="secondary"
								size="small"
								className="self-start -ml-[10px]"
							>
								<button
									className="flex items-center bg-transparent border-none p-0 group"
									type="button"
									onClick={() => {
										setTranslateType('type');
										setAddTranslate(true);
									}}
								>
									<span className="relative h-[18px] flex m-0 scale-[1.3] mr-[38px]">
										<SypacIcon
											iconName="Document Add"
											size="custom"
											width="32px"
											height="32px"
											className="absolute -bottom-[8px] text-primary-violet transition group-hover:text-primary-violet/70"
										/>
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-primary-violet mt-[2px] transition group-hover:text-primary-violet/70">
											<T keyName="addProductDetails.addTranslates">
												Add translates
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />

						<div className="flex flex-col gap-3 px-3">
							<SypacInput>
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="modalAddProduct.productDescription">
											Product description
										</T>
									</p>
								</SypacText>
								<div className="w-full flex flex-col gap-[10px]">
									<textarea
										name="description"
										className="w-full box-border h-[80px] font-sans py-3.5 pl-3 border border-solid border-gray-20 hover:border-gray-40/75 focus:border-[#5682fa] rounded-xl placeholder:text-gray-40 placeholder:text-base resize-none outline-none"
										rows={3}
										maxLength={1500}
										placeholder={t(
											'modalAddProduct.enterProductDescription',
											'Enter product description',
										)}
										onChange={handleTextareaChange}
										onBlur={formik.handleBlur}
										value={formik.values.description}
									/>
									<div className="flex justify-between">
										<SypacButton
											variant="secondary"
											size="small"
											className="self-start -ml-[10px]"
										>
											<button
												className="flex items-center bg-transparent border-none p-0 group"
												type="button"
												onClick={() => {
													setTranslateType('description');
													setAddTranslate(true);
												}}
											>
												<span className="relative h-[18px] flex m-0 scale-[1.3] mr-[38px]">
													<SypacIcon
														iconName="Document Add"
														size="custom"
														width="32px"
														height="32px"
														className="absolute -bottom-[8px] text-primary-violet transition group-hover:text-primary-violet/70"
													/>
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-primary-violet mt-[2px] transition group-hover:text-primary-violet/70">
														<T keyName="addProductDetails.addTranslates">
															Add translates
														</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
										<div className="w-fit flex bg-gray-10 text-xs text-gray-40 rounded-2xl px-[5px] py-[1px] self-end">{`${charCount} / 1500`}</div>
									</div>
								</div>
							</SypacInput>
						</div>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />

						<div className="flex flex-col px-3">
							<div className="flex flex-col">
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="modalAddProduct.productPhoto">Product photo</T>
									</p>
								</SypacText>
								{!file && !fileLoading && (
									<div
										onDragEnter={(e) => e.preventDefault()}
										onDragLeave={(e) => e.preventDefault()}
										onDragOver={(e) => e.preventDefault()}
										onDrop={handleDrop}
										className="flex px-5 py-[25px] bg-white border border-dashed border-gray-40 rounded-lg cursor-pointer transition hover:border-gray-50"
									>
										<input
											type="file"
											id="input-file-upload"
											className="hidden"
											multiple={true}
											accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
											onChange={handleChange}
										/>
										<label
											htmlFor="input-file-upload"
											className="w-full flex gap-6 items-center justify-center cursor-pointer"
										>
											<FolderIcon />
											<div className="flex flex-col gap-[10px]">
												<SypacText
													variant="body-regular-medium"
													className="flex"
												>
													<p className="text-gray-80">
														<T keyName="userSettings.dragAndDrop">
															Drag and drop or
														</T>
													</p>
													&nbsp;
													<p className="text-cornflower-blue underline">
														<T keyName="userSettings.browseFile">Browse File</T>
													</p>
												</SypacText>
												<SypacText variant="body-regular-medium">
													<p className="text-xs text-nevada">
														<T
															keyName="modalAddProduct.weSupport"
															params={{ size: MaxFileSizeMB.IMAGE }}
														>
															We support JPEG, or PNG, files. Max file size: 2
															MB.
														</T>
													</p>
												</SypacText>
											</div>
										</label>
									</div>
								)}

								{!showFile ? (
									<LoadingBar
										isLoading={fileLoading}
										onComplete={() => setShowFile(true)}
										className="flex w-full h-[86px] justify-center items-center"
									/>
								) : (
									<div className="flex items-center mt-4">
										<div
											style={{
												backgroundImage: `url(${file?.file.url})`,
											}}
											className="w-[66px] h-[66px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 box-border"
											role="img"
											aria-label={file?.name}
										/>
										<div className="flex flex-col ml-4">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80">{file?.name}</p>
											</SypacText>

											<div className="flex gap-2 justify-start items-center mt-1">
												<SypacText variant="overline-regular-large">
													<p className="text-gray-20">{file?.mimeType}</p>
												</SypacText>
												<div className="w-[2px] h-[2px] bg-gray-20 rounded-full" />
												<SypacText variant="overline-regular-large">
													<p className="text-gray-20">{file?.size}</p>
												</SypacText>
											</div>

											<SypacText
												variant="overline-normal-large"
												className="mt-3 cursor-pointer"
												onClick={handleDeleteFile}
											>
												<p className="text-red">
													<T keyName="uploadDocuments.deleteFile">
														Delete file
													</T>
												</p>
											</SypacText>
										</div>
									</div>
								)}
							</div>
						</div>

						<nav className="flex w-full pt-2.5 xl-2xl:pt-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 mt-auto ">
							<SypacButton
								variant="secondary"
								size="small"
								className="w-full pl-2.5 xl-2xl:pl-3"
							>
								<button
									type="button"
									className="w-full py-2.5 rounded-lg"
									onClick={handleReset}
								>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="modalAddProduct.back">Back</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton
								variant="primary"
								size="small"
								className="w-full pr-2.5 xl-2xl:pr-3"
							>
								<button type="submit" className="w-full py-2.75 rounded-lg">
									<SypacText variant="body-regular-medium">
										<p className="text-white">
											<T keyName="modalAddProduct.create">Create</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</nav>
					</div>
				</form>
			</main>
			<ModalAddTranslates
				isOpen={addTranslate}
				onClose={closeTransModal}
				onSave={handleModalSave}
				type={translateType}
				currentTranslate="Product"
				currentValues={{
					name: formik.values.productName,
					type: formik.values.type,
					description: formik.values.description,
				}}
				translations={translations[translateType] || []}
			/>
		</div>
	);
};
export default ProductDetailsStep;
