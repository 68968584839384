import React, { useEffect, useMemo, useState } from 'react';
import { SypacText } from '@sypac/component-library-react';
import { TruckDto } from '../../../services/truck.services';
import { T, useTranslate } from '@tolgee/react';
import {
	MultipleStepperStatsProps,
	SectionedProgressBarProps,
	SectionTypes,
	SingleProgressBarProps,
} from '../OrderDetailsStepper.interface';

const SectionTypeDetails: SectionTypes = {
	ARRIVED_TO_LOADING: {
		label: 'arrivedAtTheLoadingPlace',
		color: 'bg-texas-rose',
	},
	TRUCKS_LOADED: { label: 'loadedTrucks', color: 'bg-mountain-meadow' },
	LOADING_CONFIRMED: {
		label: 'loadingConfirmed',
		color: 'bg-mountain-meadow',
	},
	PROOF_OF_LOADING: {
		label: 'proofOfDeliveryConfirmed',
		color: 'bg-cornflower-blue',
	},
};

const MultipleStepperStats: React.FC<MultipleStepperStatsProps> = ({
	order,
}) => {
	const { t } = useTranslate();
	const [arrivedToLoadingCount, setArrivedToLoadingCount] = useState<number>(0);
	const [loadedCount, setLoadedCount] = useState<number>(0);
	const [unloadedCount, setUnloadedCount] = useState<number>(0);

	const deliveryIncluded = useMemo(() => {
		return order?.deliveryIncluded;
	}, [order?.deliveryIncluded]);

	useEffect(() => {
		const arrivedToLoadingStatuses = ['arrived_to_loading', 'loading'];
		const loadedStatuses = [
			'loaded',
			'to_unloading',
			'arrived_to_unloading',
			'unloading',
		];
		const unloadedStatuses = ['unloaded', 'completed'];
		const excludedArrivedToLoading = ['confirm_pick_up'];
		const excludedLoaded = ['pending_payment', 'paid'];

		let arrivedNumber = 0;
		let loadedNumber = 0;
		let unloadedNumber = 0;

		order?.trucks?.forEach((truck: TruckDto) => {
			const status = truck.orderStatus;
			if (deliveryIncluded) {
				switch (true) {
					case arrivedToLoadingStatuses.includes(status):
						arrivedNumber++;
						break;
					case loadedStatuses.includes(status):
						loadedNumber++;
						break;
					case unloadedStatuses.includes(status):
						unloadedNumber++;
						break;
				}
			} else {
				switch (true) {
					case excludedArrivedToLoading.includes(status):
						arrivedNumber++;
						break;
					case excludedLoaded.includes(status):
						loadedNumber++;
						break;
				}
			}
		});

		setArrivedToLoadingCount(
			deliveryIncluded
				? arrivedNumber + loadedNumber + unloadedNumber
				: arrivedNumber + loadedNumber,
		);
		setLoadedCount(
			deliveryIncluded ? loadedNumber + unloadedNumber : loadedNumber,
		);
		setUnloadedCount(deliveryIncluded ? unloadedNumber : 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.trucks]);

	const SingleProgressBar = ({
		title,
		trucks,
		orderedQuantity,
	}: SingleProgressBarProps) => {
		const loadedQuantity: string = (
			trucks?.reduce(
				(sum: number, item: any) => sum + (item.loadedQuantity ?? 0),
				0,
			) || 0
		)
			.toFixed(2)
			.replace(/(\.0+|(?<=\.\d)0+)$/, '');

		const progress = (+loadedQuantity / orderedQuantity!) * 100;

		return (
			<div className="w-full flex flex-col gap-4">
				<SypacText variant="body-regular-small">
					<p className="text-xs text-gray-60">{title}</p>
				</SypacText>
				<div className="flex gap-2 items-center border border-solid border-gray-10 rounded-md px-[3px]">
					<div className="bg-gray-10 rounded w-full h-[14px] relative">
						<div
							className="bg-mountain-meadow h-full absolute top-0 left-0 rounded"
							style={{ width: `${progress > 100 ? 100 : progress}%` }}
						/>
					</div>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-60 leading-none mt-[3px] whitespace-nowrap">
							{`${loadedQuantity} / ${orderedQuantity}`}
							<T keyName="basicDetails.tons">t</T>
						</p>
					</SypacText>
				</div>
			</div>
		);
	};

	const SectionedProgressBar = ({
		type,
		orderTrucks,
		count,
	}: SectionedProgressBarProps) => {
		return (
			<div className="w-full flex flex-col gap-4">
				<SypacText variant="body-regular-small">
					<p className="text-xs text-gray-60 truncate">
						<T keyName={`multipleStepperStats.${type.label}`}>{type.label}</T>
					</p>
				</SypacText>
				<div className="flex gap-2 items-center border border-solid border-gray-10 rounded-md px-[3px]">
					<div className="w-full flex gap-[2px]">
						{orderTrucks?.map((_: any, index: number) => (
							<div
								key={index}
								className="bg-gray-10 rounded w-full h-[14px] relative"
							>
								<div
									className={`${index < count! ? 'w-full' : 'w-auto'} ${
										type.color
									} h-full absolute top-0 left-0 rounded`}
								/>
							</div>
						))}
					</div>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-60 leading-none mt-[3px] whitespace-nowrap">
							{`${count} / ${orderTrucks?.length}`}
						</p>
					</SypacText>
				</div>
			</div>
		);
	};

	return deliveryIncluded ? (
		<>
			{order?.trucks?.length! <= 5 &&
			arrivedToLoadingCount !== order?.trucks?.length ? (
				<div className="flex flex-col gap-4 my-4">
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div
						className={`${
							unloadedCount >= 1 ? 'flex flex-col gap-4' : 'flex flex-row gap-6'
						}`}
					>
						<SingleProgressBar
							title={t(
								'multipleStepperStats.loadedQuantity',
								'Loaded quantity',
							)}
							trucks={order?.trucks}
							orderedQuantity={order?.quantity}
						/>

						{unloadedCount >= 1 ? (
							<div className="flex flex-row gap-6">
								<SectionedProgressBar
									type={SectionTypeDetails.ARRIVED_TO_LOADING}
									orderTrucks={order?.trucks}
									count={arrivedToLoadingCount}
								/>

								<SectionedProgressBar
									type={SectionTypeDetails.TRUCKS_LOADED}
									orderTrucks={order?.trucks}
									count={loadedCount}
								/>

								{unloadedCount >= 1 ? (
									<SectionedProgressBar
										type={SectionTypeDetails.PROOF_OF_LOADING}
										orderTrucks={order?.trucks}
										count={unloadedCount}
									/>
								) : null}
							</div>
						) : (
							<>
								<SectionedProgressBar
									type={SectionTypeDetails.ARRIVED_TO_LOADING}
									orderTrucks={order?.trucks}
									count={arrivedToLoadingCount}
								/>

								<SectionedProgressBar
									type={SectionTypeDetails.TRUCKS_LOADED}
									orderTrucks={order?.trucks}
									count={loadedCount}
								/>

								{unloadedCount >= 1 ? (
									<SectionedProgressBar
										type={SectionTypeDetails.PROOF_OF_LOADING}
										orderTrucks={order?.trucks}
										count={unloadedCount}
									/>
								) : null}
							</>
						)}
					</div>

					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				</div>
			) : null}

			{order?.trucks?.length! <= 5 &&
			arrivedToLoadingCount === order?.trucks?.length ? (
				<div className="flex flex-col gap-4 my-4">
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div className={`flex flex-row gap-6`}>
						<SingleProgressBar
							title={t(
								'multipleStepperStats.loadedQuantity',
								'Loaded quantity',
							)}
							trucks={order?.trucks}
							orderedQuantity={order?.quantity}
						/>

						<>
							{arrivedToLoadingCount !== order?.trucks?.length ? (
								<SectionedProgressBar
									type={SectionTypeDetails.ARRIVED_TO_LOADING}
									orderTrucks={order?.trucks}
									count={arrivedToLoadingCount}
								/>
							) : null}

							<SectionedProgressBar
								type={SectionTypeDetails.TRUCKS_LOADED}
								orderTrucks={order?.trucks}
								count={loadedCount}
							/>

							{unloadedCount >= 1 ? (
								<SectionedProgressBar
									type={SectionTypeDetails.PROOF_OF_LOADING}
									orderTrucks={order?.trucks}
									count={unloadedCount}
								/>
							) : null}
						</>
					</div>

					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				</div>
			) : null}

			{order?.trucks?.length! > 5 ? (
				<div className="flex flex-col gap-4 my-4">
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div
						className={`${
							order?.trucks?.length! > 5 && unloadedCount === 0
								? 'flex flex-col gap-4'
								: 'grid grid-cols-2 grid-rows-2 gap-4'
						}`}
					>
						<SingleProgressBar
							title={t(
								'multipleStepperStats.loadedQuantity',
								'Loaded quantity',
							)}
							trucks={order?.trucks}
							orderedQuantity={order?.quantity}
						/>

						<>
							<SectionedProgressBar
								type={SectionTypeDetails.ARRIVED_TO_LOADING}
								orderTrucks={order?.trucks}
								count={arrivedToLoadingCount}
							/>

							<SectionedProgressBar
								type={SectionTypeDetails.TRUCKS_LOADED}
								orderTrucks={order?.trucks}
								count={loadedCount}
							/>

							{unloadedCount >= 1 ? (
								<SectionedProgressBar
									type={SectionTypeDetails.PROOF_OF_LOADING}
									orderTrucks={order?.trucks}
									count={unloadedCount}
								/>
							) : null}
						</>
					</div>
				</div>
			) : null}
		</>
	) : (
		<>
			{order?.trucks && (
				<div className="flex flex-col gap-4 my-4">
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div
						className={`flex ${
							order.trucks.length <= 5 ? 'flex-row' : 'flex-col'
						} gap-6`}
					>
						<SingleProgressBar
							title={t(
								'multipleStepperStats.loadedQuantity',
								'Loaded quantity',
							)}
							trucks={order.trucks}
							orderedQuantity={order.quantity}
						/>

						<SectionedProgressBar
							type={SectionTypeDetails.ARRIVED_TO_LOADING}
							orderTrucks={order.trucks}
							count={arrivedToLoadingCount}
						/>

						<SectionedProgressBar
							type={SectionTypeDetails.LOADING_CONFIRMED}
							orderTrucks={order.trucks}
							count={loadedCount}
						/>
					</div>

					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				</div>
			)}
		</>
	);
};

export default MultipleStepperStats;
