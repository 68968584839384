import Api from '../axios/api';

export interface ContactUsBody {
	name: string;
	email: string;
	companyName: string;
	phone: string;
	message: string;
}

export class ContactService {
	static sendContactUs(target: string, body: ContactUsBody): Promise<void> {
		return Api.post(`/v1/${target}/contact`, body);
	}
}
