import React, { memo, useCallback, useEffect, useState } from 'react';
import CompanyFleet from '../CompanyFleet';
import { useGetFleet } from '../../../../../hooks/use-get-fleet';
import { MutatingDots } from 'react-loader-spinner';
import { LIMIT } from '../../../../../constants';
import {
	FleetBaseDto,
	GetFleetBasesParams,
	TruckService,
} from '../../../../../services/truck.services';
import { GeoService } from '../../../../../services/geo.services';
import { toastVariant } from '../../../../../components/ToastVariant/toastVariant';
import { FleetTabProps } from '../../comanyDetails.interface';

export const FleetTab: React.FC<FleetTabProps> = memo(
	({ companyId }: FleetTabProps) => {
		const [baseLoading, setBaseLoading] = useState<boolean>(false);
		const [carrierBase, setCarrierBase] = useState<FleetBaseDto | undefined>(
			undefined,
		);
		const [refresh, setRefresh] = useState<number>(0);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [data, _, isLoading] = useGetFleet({
			companyId: companyId,
			target: 'admin',
		});

		const getFleetBase = useCallback(async () => {
			setBaseLoading(true);
			try {
				const params: GetFleetBasesParams = {
					limit: LIMIT,
					offset: 0,
					ids: [],
					companyIds: [],
				};

				const { data } = await TruckService.getFleetBases(params);
				const fleetBaseData = data?.items.find(
					(item) => item.companyId === companyId,
				);
				if (fleetBaseData) {
					const { data: locationDetails } = await GeoService.getLocations({
						phrase: fleetBaseData.address,
						maxResults: '1',
					});

					setCarrierBase({
						...fleetBaseData,
						countryCode: locationDetails?.[0]?.countryCode || '',
					});
				}
			} catch (error) {
				return toastVariant(
					`Something went wrong. ${error?.toString()!}`,
					true,
				);
			} finally {
				setBaseLoading(false);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [refresh]);

		useEffect(() => {
			getFleetBase().then(() => {});
		}, [getFleetBase]);

		return !isLoading && !baseLoading ? (
			<CompanyFleet
				fleet={data}
				carrierBase={carrierBase}
				companyId={companyId}
				refresh={setRefresh}
			/>
		) : (
			<div className="flex w-full h-[500px] items-center justify-center">
				<MutatingDots
					height="100"
					width="100"
					color="#7693F4"
					secondaryColor="#494C83"
					radius="12.5"
					ariaLabel="mutating-dots-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/>
			</div>
		);
	},
);
