import React, { useCallback, useEffect, useRef, useState } from 'react';
import BillingTabs from '../../../../components/BillingTabs/BillingTabs';
import AvatarDropDown from '../../../../components/AvatarDropDown/AvatarDropDown';
import { Sorting } from '../../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../../components/Sorting/Sorting.interface';
import { BillingService } from '../../../../services/billing.service';
import { PaymentsTable } from '../../../../components/PaymentsTable/PaymentsTable';
import { InvoiceInterface } from '../../../../components/PaymentsTable/interfaces/Payment.interface';
import { MutatingDots } from 'react-loader-spinner';
import Pagination from '../../../../components/Pagination/Pagination';
import { LIMIT } from '../../../../constants';
import { AvatarItem } from '../../../../components/AvatarDropDown/AvatarDropDown.interface';
import ModalConfirmPayment from '../../../../components/ModalConfirmPayment/ModalConfirmPayment';
import { Order, OrdersService } from '../../../../services/orders.services';
import { ConfirmPaymentProps } from '../../../../components/ModalConfirmPayment/ModalConfirmPayment.interface';
import ModalConfirmPaymentStatus from '../../../../components/ModalConfirmPayment/ModalConfirmPaymentStatus';
import { useTranslate } from '@tolgee/react';
import { BillingForAdminProps, TabInterface } from '../adminBilling.interface';
import { toastVariant } from '../../../../components/ToastVariant/toastVariant';
import ModalDownload from '../../../../components/ModalDownload/ModalDownload';
import useDebounce from '../../../../hooks/useDebounce';
import OrderDetails from '../../OrderDetails';
import { OrderInterface } from '../../../../components/OrdersTable/interfaces/Order.interface';
import { OrderTarget } from '../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { PaymentTypeAction } from '../../../../components/PaymentsTable/interfaces/PaymentStatus.interface';
import Emitter, { EventType } from '../../../../services/events';

const initialTabs = [
	{ label: 'All', color: 'white', count: '0', value: 'all' },
	{ label: 'Pending payment', color: 'yellow', count: '0', value: 'pending' },
	{ label: 'Payment overdue', color: 'red', count: '0', value: 'overdue' },
	{ label: 'Paid', color: 'green', count: '0', value: 'paid' },
];
const sortOptions = [
	{
		title: 'Sort by date descending',
		value: 'invoiceDate DESC',
	},
	{
		title: 'Sort by date ascending',
		value: 'invoiceDate ASC',
	},
	{
		title: 'Sort by price ascending',
		value: 'totalAmount ASC',
	},
	{
		title: 'Sort by price descending',
		value: 'totalAmount DESC',
	},
];

const ProducerAndCarrierBilling: React.FC<BillingForAdminProps> = ({
	target = 'producer',
	countryQuery,
}) => {
	const { t } = useTranslate();
	const detailsRef = useRef<HTMLDivElement>(null);

	const [searchLocal] = useState<string>('');
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [currentTab, setCurrentTab] = useState<string | string[]>('all');
	const [tabs, setTabs] = useState<TabInterface[]>(initialTabs);
	const [page, setPage] = useState<number>(0);
	const [invoices, setInvoices] = useState<InvoiceInterface[]>([]);
	const [selectedInvoice, setInvoice] = useState<InvoiceInterface>();
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
	const [avatars, setAvatars] = useState<AvatarItem[]>([]);
	const [assigneeId, setAssignee] = useState<string>();
	const [showPay, setShowPay] = useState<boolean>(false);
	const [showPrevSuccess, setShowPrevSuccess] = useState<boolean>(false);
	const [payment, setPayment] = useState<ConfirmPaymentProps>();
	const [downloadModal, setDownloadModal] = useState<boolean>(false);
	const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
	const [isOpen, setOpenDetails] = useState<boolean>(false);

	const searchQuery = useDebounce(searchLocal, 500);

	const selectTab = (tab: string | string[]) => {
		setCurrentTab(tab);
		setPage(0);
	};

	const getStatistics = useCallback(async () => {
		try {
			const { data } = await BillingService.getInvoiceStatsAdmin(target);
			const tabs = [
				{ label: 'All', color: 'white', count: data?.count?.all, value: 'all' },
				{
					label: t('producerBilling.pendingInvoice', 'Pending invoice'),
					color: 'white',
					count: data?.count?.pendingInvoice,
					value: 'pending_invoice',
				},
				{
					label: t(
						'producerBilling.pendingInvoiceConfirmation',
						'Pending invoice confirmation',
					),
					color: 'white',
					count: data?.count?.uploadedInvoice,
					value: 'uploaded_invoice',
				},
				{
					label: 'Pending payment',
					color: 'yellow',
					count: String(+data?.count?.pending + +data?.count?.invoiceConfirmed),
					value: ['pending', 'invoice_confirmed'],
				},
				{
					label: 'Paid',
					color: 'green',
					count: data?.count?.paid,
					value: 'paid',
				},
			];

			if (data?.count?.overdue !== '0') {
				tabs.push({
					label: t('producerBilling.paymentDispute', 'Dispute'),
					color: 'red',
					count: data?.count?.overdue,
					value: 'overdue',
				});
			}

			setTabs(tabs);
		} catch (e) {
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [target]);

	const getInvoiceList = useCallback(async () => {
		try {
			setLoading(true);
			const sort = sorting?.value?.split(' ');
			const sortDirection = sort?.at(1);
			const sortBy = sort?.at(0);
			const { data } = await BillingService.getInvoiceListAdmin(target, {
				orderId: undefined,
				statuses: Array.isArray(currentTab) ? currentTab : [currentTab],
				limit: LIMIT,
				offset: page * LIMIT,
				sortDirection,
				sortBy,
				assigneeId,
				search: searchQuery,
				countryCode: countryQuery,
			});

			if (!assigneeId) {
				const lines =
					data.items?.map((invoice) => invoice?.invoiceLines?.at(0)!) || [];

				const avatarsMap: Record<string, string> = {};
				lines?.forEach((line) => {
					avatarsMap[line?.assigneeUser?.uid] = line?.assigneeUser?.name;
				});
				const avatars: AvatarItem[] = Object.keys(avatarsMap)
					.map((key) => {
						return {
							id: key,
							fullName: avatarsMap[key],
						};
					})
					.filter((r) => r.fullName);
				setAvatars(avatars);
			}
			setInvoices(data.items);
			setCount(data.count);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, [
		assigneeId,
		countryQuery,
		currentTab,
		page,
		searchQuery,
		sorting?.value,
		target,
	]);

	const changeAssignee = (uid: string) => {
		const assignee = assigneeId === uid ? undefined : uid;
		setAssignee(assignee);
	};

	const onPay = async (value: InvoiceInterface) => {
		let invoice = value;
		setPayment(undefined);

		const orderIds = invoice?.invoiceLines
			?.map((line) => line.adminOrderId)
			.filter((id): id is number => id !== undefined);

		if (orderIds?.length) {
			const orders = await OrdersService.getOrders({ ids: orderIds }, 'admin');
			const ordersMap: Record<number, Order> = {};

			orders.data?.items?.forEach((order) => {
				if (order.id !== undefined) {
					ordersMap[order.id] = order;
				}
			});

			invoice.invoiceLines = invoice.invoiceLines?.map((line) => {
				if (line.adminOrderId && ordersMap[line.adminOrderId]) {
					line.order = ordersMap[line.adminOrderId];
				}
				return line;
			});
		}

		setInvoice(invoice);
		setShowPay(true);
	};

	const viewOrder = async (orderId: number) => {
		try {
			const { data } = await OrdersService.getOrder(
				orderId!,
				OrderTarget.admin,
			);
			setOrder(data);
			setOpenDetails(true);
		} catch (e) {
			console.log(e);
		}
	};

	const confirmPrevPayment = (values: ConfirmPaymentProps) => {
		setPayment(values);
		setShowPay(false);
		setShowPrevSuccess(true);
	};

	const confirmPayment = async () => {
		setInvoiceLoading(true);
		try {
			if (selectedInvoice?.id && payment) {
				await BillingService.acceptInvoice(target, selectedInvoice.id, {
					paymentAmount: parseFloat(payment?.amount)!,
					paymentDate: payment?.date!,
				});
				setShowPrevSuccess(false);
				setPayment(undefined);
				getStatistics().then(() => {});
				getInvoiceList().then(() => {});
			}
		} catch (e) {
		} finally {
			setInvoiceLoading(false);
		}
	};

	// const checkDownloadInvoices = async () => {
	// 	setDownloadModal(true);
	// };

	const additionalClick = (value: InvoiceInterface | number, type?: string) => {
		if (type === PaymentTypeAction.view_order) {
			viewOrder(value as number).then(() => {});
		} else {
			onPay(value as InvoiceInterface).then(() => {});
		}
	};

	const downloadInvoices = async (fromDate: string, toDate: string) => {
		setDownloadLoading(true);
		try {
			const response = await BillingService.downloadInvoices({
				fromDate,
				toDate,
			});
			if (response.data.type === 'text/html') {
				toastVariant(
					t(
						'billing.noInvoicesAvailable',
						'No invoices available for download during this period.',
					),
					true,
				);
				setDownloadLoading(false);
				setDownloadModal(false);
				return;
			}
			const url = window.URL.createObjectURL(new Blob([response.data]));

			const a = document.createElement('a');
			a.href = url;
			a.download = 'invoices.zip';
			a.click();

			window.URL.revokeObjectURL(url);
		} finally {
			setDownloadLoading(false);
			setDownloadModal(false);
		}
	};

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		if (!detailsRef.current?.contains(event.target as Node)) {
			closeModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getStatistics().then(() => {});
		getInvoiceList().then(() => {});
	}, [getInvoiceList, getStatistics]);

	useEffect(() => {
		const refreshHandler = () => {
			getStatistics().then(() => {});
			getInvoiceList().then(() => {});
		};
		Emitter.on(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		return () => {
			Emitter.off(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		};
	}, [getInvoiceList, getStatistics]);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	return (
		<div className="flex flex-col gap-5 w-full h-full ml-5 mr-7.5">
			<span className="mb-[10px]">
				<Sorting
					options={sortOptions}
					action={(item) => setSorting(item)}
					current={sorting}
					label={{
						title: 'Showing:',
						key: 'showing',
					}}
					customDefault={{
						title: 'All transaction types',
						key: 'allTypes',
					}}
				/>
			</span>

			<header className="w-full flex justify-between">
				<nav className="flex items-center lg:gap-3 2xl:gap-[32px]">
					<BillingTabs
						data={tabs}
						callback={selectTab}
						activeTab={currentTab}
					/>
					{avatars.length ? (
						<div className="w-fit h-[44px] flex items-center">
							<AvatarDropDown items={avatars} onChange={changeAssignee} />
						</div>
					) : null}
					<Sorting options={sortOptions} action={(item) => setSorting(item)} />
				</nav>
			</header>

			{loading || downloadLoading ? (
				<div className="flex w-full h-full items-center justify-center">
					<MutatingDots
						height="100"
						width="100"
						color="#7693F4"
						secondaryColor="#494C83"
						radius="12.5"
						ariaLabel="mutating-dots-loading"
						visible={true}
					/>
				</div>
			) : (
				<section className="h-[calc(100%-52px)] relative border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
					<div className="w-full h-[calc(100%-52px)] overflow-y-auto scroll-smooth pr-[5px]">
						<PaymentsTable
							rows={invoices}
							rowClick={() => {}}
							target={'admin_producer'}
							additionalClick={additionalClick}
							countryCode={countryQuery!}
						/>
					</div>
					<footer className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
						<div className="flex justify-between items-center h-[51px] px-3">
							<Pagination
								showText={true}
								count={count}
								page={page}
								onClick={(item) => setPage(item)}
							/>
						</div>
					</footer>
				</section>
			)}

			{showPay ? (
				<ModalConfirmPayment
					data={{
						companyName: selectedInvoice?.company?.name! || 'Not set',
						companyId: selectedInvoice?.company?.id!,
						invoiceName: selectedInvoice?.id?.toString()!,
						amount: selectedInvoice?.balanceDue!,
						tableData:
							selectedInvoice?.invoiceLines?.map((line) => {
								return {
									order: line.orderId,
									product: line.description,
									quantity:
										target === 'transporter'
											? line.order?.quantity.toString()!
											: line.quantity?.toString()!,
									price: line.lineTotal?.toString()!,
									...(target === 'transporter' && {
										description: 'Transportation',
										distance: `${line.order?.trucks?.[0].distance} ${line.unit}`,
									}),
								};
							}) || [],
						invoice: selectedInvoice!,
					}}
					isOpen={showPay}
					error={false}
					onClose={() => {
						setShowPay(false);
					}}
					onSubmit={confirmPrevPayment}
					realAmount={selectedInvoice?.balanceDue}
				/>
			) : null}
			<ModalConfirmPaymentStatus
				data={{
					companyName: selectedInvoice?.company?.name || 'Not set',
					companyId: selectedInvoice?.company?.id!,
					invoiceName: selectedInvoice?.id?.toString()!,
					amount: selectedInvoice?.balanceDue!,
					tableData:
						selectedInvoice?.invoiceLines?.map((line) => {
							return {
								order: line.orderId,
								product: line.description,
								quantity:
									target === 'transporter'
										? line.order?.quantity.toString()!
										: line.quantity?.toString()!,
								price: line.lineTotal?.toString()!,
								...(target === 'transporter' && {
									description: 'Transportation',
									distance: `${line.order?.trucks?.[0].distance} ${line.unit}`,
								}),
							};
						}) || [],
					paidData: payment!,
					invoice: selectedInvoice!,
				}}
				isOpen={showPrevSuccess}
				onClose={() => setShowPrevSuccess(false)}
				onSubmit={confirmPayment}
				companyType={target}
				invoiceLoading={invoiceLoading}
			/>
			{downloadModal ? (
				<ModalDownload
					isOpen={downloadModal}
					onClose={() => setDownloadModal(false)}
					onSuccess={downloadInvoices}
				/>
			) : null}
			{isOpen && selectedOrder?.id && (
				<aside ref={detailsRef}>
					<OrderDetails
						isOpen={!!(isOpen && selectedOrder?.id)}
						selectedOrder={selectedOrder}
						position="fixed"
					/>
				</aside>
			)}
		</div>
	);
};

export default ProducerAndCarrierBilling;
