import React from 'react';
import Modal from 'react-modal';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { ModalRemoveTruckProps } from './ModalRemoveTruck.interface';
import './modal-remove-truck.css';
import { T } from '@tolgee/react';
import NewTrash from '../../assets/NewTrash';

const ModalRemoveTruck: React.FC<ModalRemoveTruckProps> = ({
	id,
	name,
	isOpen,
	onClose,
	onDelete,
}: ModalRemoveTruckProps) => {
	return (
		<Modal
			isOpen={!!isOpen}
			onRequestClose={onClose}
			className="modal-inside outline-none max-w-[540px]"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col">
				<main className="flex flex-col gap-[28px] p-3">
					<span className="flex -ml-1">
						<NewTrash width={60} height={60} strokeWidth="0.5" />
					</span>

					<SypacText variant="body-regular-medium">
						<p className="text-2xl text-gray-80 w-[400px]">
							<T keyName="modalRemoveTruck.reallyWantDeleteTruck">
								Do you really want to delete this truck:
							</T>{' '}
							“{name}”?
						</p>
					</SypacText>

					<SypacText variant="body-regular-small">
						<p className="text-gray-40">
							<T keyName="modalRemoveTruck.removeTruckDescription">
								Warning: This operation cannot be undone.
							</T>
						</p>
					</SypacText>
				</main>

				<nav className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className="w-full h-[42px] flex items-center justify-center rounded-lg transition"
							onClick={onClose}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="modalDeleteProduct.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className="w-full h-[42px] flex items-center justify-center bg-red border-0 rounded-lg transition hover:bg-red/70"
							onClick={() => (onDelete ? onDelete(id) : null)}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									<T keyName="modalDeleteProduct.delete">Delete</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</nav>
			</div>
		</Modal>
	);
};

export default ModalRemoveTruck;
