import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import ListMenu from '../../../components/ListMenu/ListMenu';
import { useGetCategories } from '../../../hooks/use-get-categories';
import ClientsProductCard from './components/ClientsProductCard';
import '../select-product.scss';
import {
	OrderContext,
	OrderProps,
} from '../../../context/OrderContext/order.context';
import { useGetProducts } from '../../../hooks/use-get-products';
import { GeoService } from '../../../services/geo.services';
import { T } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import { OrdersService } from '../../../services/orders.services';
import ClientStoreBreadcrumbs from '../../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';
import { CompanyService } from '../../../services/company.services';
import { toastVariant } from '../../../components/ToastVariant/toastVariant';
import Clock from '../../../assets/Clock';
import ArrowToLeft from '../../../assets/ArrowToLeft';
import RecentOrderItem from './components/RecentOrderItem';

const ClientSelectCategory: React.FC = () => {
	const { groupId, categoryId } = useParams();
	const navigate = useNavigate();
	const { order, setOrder } = useContext(OrderContext);

	const [userCountry, setUserCountry] = useState<string>();
	const [coordinates, setCoordinates] = useState<number[]>([]);
	const [localRecentOrders, setLocalRecentOrders] = useState<OrderProps[]>([]);
	const [activeCategory, setActiveCategory] = useState<string>(categoryId!);

	const [groups] = useGetCategories({ countryCode: userCountry });
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [categories, _, isCategoryLoading] = useGetCategories({
		parentIds: [groupId!],
		coordinates,
		countryCode: userCountry,
	});
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [products, _e, isLoading] = useGetProducts(
		'all',
		activeCategory,
		100,
		0,
		'customer',
		userCountry!,
		coordinates,
	);

	const currentCategory = useMemo(
		() => categories?.items?.find((item) => item.id === activeCategory),
		[categories, activeCategory],
	);

	const currentGroup = useMemo(
		() => groups?.items?.find((item) => item.id === groupId),
		[groupId, groups?.items],
	);

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			const countryCodeMap: Record<string, string> = {
				MD: 'MDA',
				PL: 'POL',
			};
			setUserCountry(countryCodeMap[data.countryCode] || '');
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	const getLocation = useCallback(async (locationId: string) => {
		if (locationId) {
			const { data } = await GeoService.getLocationDetails({
				locationid: locationId,
			});
			setCoordinates([data.Longitude, data.Latitude]);
		}
	}, []);

	const getRecentOrders = async () => {
		try {
			const { data } = await OrdersService.getRecentOrders({
				limit: 5,
				offset: 0,
			});
			if (data.count) {
				setLocalRecentOrders(data.items.slice(0, 3) || []);
			}
		} catch (e) {}
	};

	const handleSelectRecentOrder = (order: OrderProps) => {
		setOrder({ ...order });
		navigate(
			`/store/groups/${order?.group?.id}/categories/${order?.category?.id}/products/${order?.product?.id}/quantity`,
		);
	};

	const listItems =
		categories?.items?.map((item) => ({
			id: item.id,
			name: item.name,
			onClick: () => {
				if (item.id !== activeCategory) {
					setActiveCategory(item.id);
					navigate(`/store/groups/${groupId}/categories/${item.id}/products`);
				}
			},
		})) || [];

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	useEffect(() => {
		getRecentOrders().then(() => {});
	}, []);

	useEffect(() => {
		if (order?.location?.locationId) {
			getLocation(order.location.locationId).then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.location?.locationId]);

	const EmptyProductsState = () => (
		<div className="flex flex-col md:w-[560px] xl:w-[864px] h-[252px] justify-between items-center text-center mt-[100px] lg:mt-[212px]">
			<SypacIcon
				icon-name="Magnifer"
				className="text-gray-40"
				size="custom"
				width="88px"
				height="88px"
			/>
			<div className="flex flex-col gap-6">
				<SypacText variant="body-regular-medium">
					<p className="text-2xl text-gray-80">
						<T keyName="clientSelectCategory.noProductsAvailableInYourArea">
							No products available in your area at the moment
						</T>
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium">
					<p className="text-sm text-gray-40">
						<T keyName="clientSelectCategory.pleaseGetInTouchForFurtherInquiries">
							Please get in touch for further inquiries.
						</T>
					</p>
				</SypacText>
			</div>
		</div>
	);

	return (
		<main className="w-full flex flex-col lg:gap-4 gap-x-2.5 h-[calc(100vh-60px)] overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			<header className="mx-3 lg:ml-5 xl:ml-0 my-3 lg:my-0 lg:w-[1132px] xl:self-center flex justify-between items-center xl:mb-4">
				<ClientStoreBreadcrumbs
					currentGroup={currentGroup}
					currentCategory={currentCategory}
				/>
			</header>

			<div className="flex xl:justify-center border border-solid border-gray-10 border-b-0 border-l-0 border-r-0">
				<div className="w-full lg:w-auto flex">
					<aside className="h-fit hidden lg:flex flex-col gap-5 mt-5 ml-5 xl:ml-0">
						<nav className="lg:w-[195px] xl:w-[226px] xl-2xl:w-[255px] mr-5">
							<ListMenu
								items={!isCategoryLoading ? listItems : []}
								selected={categoryId}
							/>
						</nav>
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

						<section className="lg:w-[195px] xl:w-[226px] xl-2xl:w-[255px] flex flex-col gap-5">
							<div className="flex gap-3 items-center">
								<Clock />
								<SypacText variant="body-regular-medium">
									<p className="text-base text-gray-40 mt-[2px]">
										<T keyName="clientSelectCategory.recent">Recent</T>
									</p>
								</SypacText>
							</div>

							{localRecentOrders.length ? (
								<div className="flex flex-col">
									{localRecentOrders.map((order: OrderProps) => (
										<RecentOrderItem
											key={order?.product?.id}
											order={order}
											onSelect={handleSelectRecentOrder}
										/>
									))}
								</div>
							) : (
								<div className="w-[232px] flex flex-col gap-6 box-border">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T keyName="clientSelectCategory.noRecentProductsPreviewed">
												You haven't previewed any products recently
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-xs text-gray-40">
											<T keyName="clientSelectCategory.noRecentProductsPreviewedDescription">
												Previously viewed products will be listed here
											</T>
										</p>
									</SypacText>
								</div>
							)}
						</section>
					</aside>
					<div className="border border-solid border-gray-10 border-b-0 border-t-0 border-r-0" />

					<section className="w-full overflow-x-hidden scroll-smooth flex-1 p-3 lg:pl-6 pt-[25px] lg:pr-3">
						<SypacButton variant="secondary" className="-ml-2 mb-6">
							<button
								type="button"
								className="flex gap-6 border-none h-[24px] bg-transparent group"
								onClick={() => navigate(-1)}
							>
								<span className="mt-[4px] transition group-hover:text-gray-80 group-hover:-translate-x-[6px]">
									<ArrowToLeft width={38.5} height={16.5} />
								</span>
								<SypacText variant="body-normal-medium">
									<p className="text-gray-80 mt-[2px]">
										<T keyName="clientSelectCategory.backButton">Back</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>

						{isLoading ? (
							<div className="flex lg:w-[864px] justify-center mt-[30%]">
								<MutatingDots
									height="100"
									width="100"
									color="#7693F4"
									secondaryColor="#494C83"
									radius="12.5"
									ariaLabel="mutating-dots-loading"
									visible={true}
								/>
							</div>
						) : (
							<div className="grid gap-5 lg:grid-cols-[repeat(3,minmax(0,215px))] xl:grid-cols-[repeat(3,minmax(0,272px))]">
								{products?.items?.length ? (
									products.items.map((item) => (
										<ClientsProductCard
											key={item.id}
											data={item}
											onClick={() =>
												navigate(
													`/store/groups/${currentGroup?.id}/categories/${categoryId}/products/${item.id}`,
												)
											}
										/>
									))
								) : (
									<EmptyProductsState />
								)}
							</div>
						)}
					</section>
				</div>
			</div>
		</main>
	);
};

export default ClientSelectCategory;
