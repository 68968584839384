export const LIMIT = 10;

export enum FileType {
	JPG = 'jpg',
	JPEG = 'jpeg',
	PDF = 'pdf',
	PNG = 'png',
}

export const fileExtensionRegex = /^\.(pdf|png|jpe?g)$/i;

export const PageProps = {
	renderTextLayer: false,
	renderAnnotationLayer: false,
	renderInteractiveForms: false,
	scale: 1.0,
};

export const PREFERRED_PHONE_COUNTIES = ['pl', 'ro', 'ru', 'gb'];

export enum MaxFileSizeMB {
	IMAGE = 2,
	DOCUMENT = 10,
}
