import React, { useContext, useEffect, useRef, useState } from 'react';
import Flag from 'react-world-flags';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import {
	SypacButton,
	SypacDatepicker,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import {
	ConfirmPaymentProps,
	ModalConfirmPaymentProps,
} from './ModalConfirmPayment.interface';
import InvoiceTable from '../InvoiceTable/InvoiceTable';
import { COLUMNS_CARRIER, COLUMNS_PRODUCER } from './columns.constants';
import { T, useTranslate } from '@tolgee/react';
import { useGetCompanyById } from '../../hooks/use-get-company-by-id';
import useDebounce from '../../hooks/useDebounce';
import Close from '../../assets/Close';
import { OrderContext } from '../../context/OrderContext/order.context';
import { OrderTarget } from '../OrdersTable/interfaces/OrderStatus.interface';

const ModalConfirmPayment: React.FC<ModalConfirmPaymentProps> = ({
	data,
	isOpen,
	//error,
	onClose,
	onSubmit,
	realAmount,
}) => {
	const { t } = useTranslate();
	const { setAdditional } = useContext(OrderContext);
	const language = localStorage.getItem('lang') || 'en';

	const [displayAmount, setDisplayAmount] = useState<string>('');
	const [company] = useGetCompanyById({
		id: data.companyId,
	});
	const modalRef = useRef<HTMLDivElement>(null);

	const formik = useFormik({
		initialValues: {
			date: '',
			amount: '',
			realAmount: realAmount?.toString() || '',
		},
		initialTouched: {
			amount: true,
		},
		onSubmit: async (values) => {
			prevSubmit(values);
		},
		validationSchema: Yup.object({
			realAmount: Yup.string().required('RealAmount is required'),
			date: Yup.string().required('Please enter date.'),
			amount: realAmount
				? Yup.string()
						.oneOf(
							[Yup.ref('realAmount')],
							'Please enter an amount equal with the amount to pay.',
						)
						.required('Please enter a valid amount.')
				: Yup.string().required('Please enter a valid amount.'),
		}),
	});

	const errorAmount = useDebounce(
		formik.touched.amount && formik.errors.amount,
		500,
	);

	const prevClose = () => {
		formik.resetForm();
		onClose && onClose();
	};

	const prevSubmit = (values: ConfirmPaymentProps) => {
		formik.resetForm();
		onSubmit && onSubmit(values);
	};

	const setPrice = (value: string) => {
		setDisplayAmount(value);
		if (!realAmount) {
			formik.setFieldValue('realAmount', value.replace(/,/g, '.'), true);
		}
		formik.setFieldValue('amount', value.replace(/,/g, '.'), true);
	};

	const onCallback = (detail: string) => {
		formik.setFieldTouched('amount', false);
		formik.setFieldValue('date', dayjs(detail).toISOString());
	};

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={prevClose}>
			<section
				className="w-[828px] bg-white rounded-xl"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<form onSubmit={formik.handleSubmit} className="h-full">
					<header className="flex items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalConfirmPaymentStatus.confirmPaymentDateAmount">
									Confirm payment date & amount
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={prevClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</header>
					<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

					<main className="max-h-[calc(100%-100px)] h-full">
						<article className="flex flex-col justify-between h-full">
							<section className="flex flex-col items-center justify-center p-3 gap-3">
								<div className="w-full flex flex-col gap-6">
									<div className="flex gap-4">
										<div className="w-[58px] h-[58px] flex items-center justify-center rounded-10 bg-gray-10">
											<SypacText variant="heading-5">
												<p className="text-gray-90 mt-1">
													{data?.companyName
														.split(' ')
														.map((name) => name[0])
														.join('')
														.toUpperCase()
														.slice(0, 2)}
												</p>
											</SypacText>
										</div>

										<div className="flex flex-col gap-2">
											<SypacText variant="heading-5">
												<p className="text-xl text-gray-90">
													{data?.companyName}
												</p>
											</SypacText>
											<div className="flex items-center gap-[10px]">
												<Flag
													className="object-cover rounded z-10 border border-solid border-gray-10"
													code={company?.countryCode.toUpperCase()}
													width={22}
													height={16}
												/>
												<SypacText variant="body-normal-medium">
													<p className="text-sm text-gray-80 mt-[2px]">
														{company?.registryAddress}
													</p>
												</SypacText>
											</div>
										</div>
									</div>

									<section className="bg-alabaster rounded-10">
										<div className="flex flex-col justify-center dashed-box p-3 gap-5">
											<div>
												<SypacText variant="body-regular-small">
													<p className="text-gray-80">
														FAKTURA NR/Invoice no {data.invoiceName}
													</p>
												</SypacText>
											</div>
											{data.tableData.length && (
												<>
													<InvoiceTable
														columns={
															[
																OrderTarget.producer,
																OrderTarget.customer,
															].includes(
																data.invoice.companyType as OrderTarget,
															)
																? COLUMNS_PRODUCER
																: COLUMNS_CARRIER
														}
														data={data.tableData}
													/>
													<div className="text-end">
														<SypacText variant="body-regular-small">
															<p className="text-gray-80">
																Amount to be paid:{' '}
																<NumericFormat
																	type="text"
																	className="font-bold"
																	displayType="text"
																	thousandSeparator=" "
																	decimalSeparator="."
																	value={parseFloat(
																		data.amount?.toFixed(2) || '0',
																	)}
																	suffix={
																		data.invoice?.countryCode === 'MD'
																			? ' MDL'
																			: ' PLN'
																	}
																/>
															</p>
														</SypacText>
													</div>
												</>
											)}
										</div>
									</section>
								</div>

								<div className="w-full flex gap-3">
									<div className="w-full flex bg-alabaster border border-dashed border-gray-10 rounded-10 p-[10px]">
										<SypacInput
											className="w-full"
											error={!!(formik.touched.date && formik.errors.date)}
										>
											<label className="text-primary-dark-gray text-xs leading-4 -tracking-[0.01em]">
												<T keyName="modalConfirmPayment.paymentDate">
													Payment date
												</T>{' '}
												<span className="text-red">*</span>
											</label>
											<SypacDatepicker
												name="date"
												placeholder={t(
													'modalConfirmPayment.enterPaymentDate',
													'Enter payment date',
												)}
												min="1950-01-01"
												max={dayjs().toString()}
												value={formik?.values.date ? formik?.values.date : ''}
												inputReadOnly={true}
												onCallback={({ detail }) => onCallback(detail)}
												locale={language}
											/>
											{formik.touched.date && formik.errors.date ? (
												<span className="bottom-helper text-red text-sm">
													{formik.errors.date}
												</span>
											) : null}
										</SypacInput>
									</div>

									<div className="w-full flex bg-alabaster border border-dashed border-gray-10 rounded-10 p-[10px]">
										<SypacInput className="w-full" error={!!errorAmount}>
											<label
												className={`${
													errorAmount ? 'text-red' : 'text-primary-dark-gray'
												}  text-xs leading-4 -tracking-[0.01em]`}
											>
												<T keyName="modalConfirmPayment.price">Price</T>{' '}
												<span className="text-red">*</span>
											</label>
											<div
												className={`${
													errorAmount ? 'border-red' : 'border-gray-22'
												} h-[40px] flex border-solid border-[1px] rounded-md hover:border-gray-40/80`}
											>
												<input
													type="text"
													name="amount"
													className="block border-0 py-2.5 pl-3 rounded-md placeholder:text-gray-22 outline-none flex-1"
													placeholder={t(
														'modalConfirmPayment.enterPriceAmount',
														'Enter price amount',
													)}
													value={displayAmount}
													onChange={(event) =>
														setPrice(event.target.value.trim())
													}
												/>
												<div
													className={`${
														errorAmount ? 'border-left-error' : 'border-left'
													} py-2.5 px-5 text-gray-22`}
												>
													<SypacText
														variant="overline-normal-large"
														className="flex"
													>
														<p className="text-gray-80 mt-1">
															{data.invoice?.countryCode === 'MD'
																? 'MDL'
																: 'PLN'}
														</p>
													</SypacText>
												</div>
											</div>
											{errorAmount ? (
												<span className={`bottom-helper text-red text-sm`}>
													{formik.errors.amount}
												</span>
											) : null}
										</SypacInput>
									</div>
								</div>

								<SypacText variant="overline-regular-large">
									<p className="text-gray-40 text-left">
										<T keyName="modalConfirmPayment.pleaseEnterPaymentDate">
											Please enter the payment date and paid amount to confirm
											it. This is necessary to ensure the security of your
											transaction and the proper processing of the payment.
										</T>
									</p>
								</SypacText>
							</section>

							<footer className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
								<SypacButton
									variant="secondary"
									size="small"
									className="w-full"
								>
									<button
										type="button"
										className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
										onClick={prevClose}
									>
										<SypacText variant="body-regular-medium">
											<p>
												<T keyName="imageViewer.close">Close</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton
									variant="secondary"
									size="small"
									className="w-full"
								>
									<button
										type="submit"
										className="w-full h-[44px] flex items-center justify-center border-0 bg-primary-violet rounded-lg hover:bg-primary-violet/80"
									>
										<SypacText variant="body-regular-medium">
											<p className="text-white">
												<T keyName="modalConfirmationCode.nextStep">
													Next step
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</footer>
						</article>
					</main>
				</form>
			</section>
		</div>
	) : null;
};

export default ModalConfirmPayment;
