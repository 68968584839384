import React, { useState } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T, useTolgee, useTranslate } from '@tolgee/react';
import Flag from 'react-world-flags';
import { ChevronRightIcon } from '../../assets/ChevronRightIcon';
import dayjs from 'dayjs';

const LanguagePicker: React.FC = () => {
	const { t } = useTranslate();
	const language = localStorage.getItem('lang') || 'en';
	const [activeLanguage, setActiveLanguage] = useState<string>(language);
	const [languageDropdownOpen, setLanguageDropdownOpen] =
		useState<boolean>(false);
	const tolgee = useTolgee();

	const unsortedLocale = [
		{
			title: t('languagePicker.english', 'English'),
			value: 'en',
			flag: 'GBR',
		},
		{
			title: t('languagePicker.polish', 'Polish'),
			value: 'pl',
			flag: 'POL',
		},
		{
			title: t('languagePicker.romanian', 'Romanian'),
			value: 'ro',
			flag: 'ROU',
		},
		{
			title: t('languagePicker.russian', 'Russian'),
			value: 'ru',
			flag: 'RUS',
		},
	];

	const getSortedLocales = () => {
		if (activeLanguage === 'ru') {
			const russianOrder = ['en', 'pl', 'ro', 'ru'];
			return [...unsortedLocale].sort(
				(a, b) => russianOrder.indexOf(a.value) - russianOrder.indexOf(b.value),
			);
		} else {
			const langCollator = new Intl.Collator(activeLanguage, {
				sensitivity: 'base',
			});
			return [...unsortedLocale].sort((a, b) =>
				langCollator.compare(a.title, b.title),
			);
		}
	};

	const locale = getSortedLocales();

	const handleChangeLanguage = (lang: string) => {
		localStorage.setItem('lang', lang);
		dayjs.locale(lang);
		tolgee.changeLanguage(lang).then(() => {});
		setActiveLanguage(lang);
		setLanguageDropdownOpen(false);
	};

	return (
		<div className="relative flex">
			<SypacButton variant="subTitle">
				<button
					type="button"
					onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
					className="flex gap-3 items-center justify-center p-0 bg-transparent border-0 cursor-pointer"
				>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40 mt-[2px]">
							<T keyName="languagePicker.platformLanguage">
								Platform language:
							</T>
						</p>
					</SypacText>
					<span className="rounded-[3px] overflow-hidden">
						<Flag
							className="object-cover border border-solid border-gray-10 rounded mt-1"
							code={
								{
									en: 'GBR',
									ro: 'ROU',
									ru: 'RUS',
									pl: 'POL',
								}[activeLanguage] || 'GBR'
							}
							width={22}
							height={16}
						/>
					</span>
					<span
						className={`flex transform transition-all duration-300 ease-in-out ${
							languageDropdownOpen ? '-rotate-90' : ''
						}`}
					>
						<ChevronRightIcon />
					</span>
				</button>
			</SypacButton>

			{languageDropdownOpen && (
				<div className="absolute bottom-[46px] left-[72px] flex flex-col p-[3px] border border-solid border-gray-10 rounded-10 shadow-dropdown bg-white min-w-[145px] w-max z-30">
					{locale.map((local) => (
						<div
							key={local.value}
							className="flex items-center justify-center gap-3 py-[10px] px-3 bg-white border border-solid border-transparent rounded-lg cursor-pointer hover:bg-gray-10-opacity-50 hover:border-gray-10"
							onClick={() => handleChangeLanguage(local.value)}
						>
							<Flag
								className="object-cover border border-solid border-gray-10 rounded"
								code={local.flag}
								width={22}
								height={16}
							/>
							<SypacText variant="body-regular-medium" className="mr-auto">
								<p className="text-gray-80 mt-[2px]">{local.title}</p>
							</SypacText>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default LanguagePicker;
