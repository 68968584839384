import React, { useEffect, useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { CompanyDocumentsProps } from './CompanyDocuments.interface';
import ModalUploadDocuments, {
	DOCUMENT_TYPES,
} from '../ModalUploadDocuments/ModalUploadDocuments';
import ModalDeleteDocument from '../ModalDeleteDocument/ModalDeleteDocument';
import { FileInterface } from '../CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import { T } from '@tolgee/react';
import { FileType, PageProps } from '../../constants';
import { Document, Page } from 'react-pdf';

const CompanyDocuments: React.FC<CompanyDocumentsProps> = ({
	files,
	saveFiles,
	deleteDocument,
	docsLoading,
	uploadFiles,
}) => {
	const [openUpload, setShowUpload] = useState<boolean>(false);
	const [openView, setShowView] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [isPdf, setType] = useState<boolean>(false);
	const [localFiles, setLocalFiles] = useState<FileInterface[]>([]);
	const [editFile, setEditFile] = useState<FileInterface | undefined>();
	const [deleteFile, setDeleteFile] = useState<FileInterface | undefined>();
	const [openDelete, setDelete] = useState<boolean>(false);

	const [showBlur, setShowBlur] = useState<string>();

	const openFile = (file: FileInterface) => {
		setUrl(file.fileUrl);
		setType(file.mimeType === 'PDF');
		setShowView(true);
	};

	useEffect(() => {
		setLocalFiles(files);
	}, [files]);

	const closeUploadModal = () => {
		setShowUpload(false);
		setEditFile(undefined);
	};

	const removeDocument = (files: FileInterface[]) => {
		const ids = files?.map((r) => r.id);
		const dataFiles = localFiles.filter((r) => !ids.includes(r.id));
		setLocalFiles(dataFiles);
		deleteDocument(files);
		setDeleteFile(undefined);
	};

	const uploadLocalDocuments = (
		files: FileInterface[],
		deletedFiles: FileInterface[],
	) => {
		saveFiles(files);
		if (deletedFiles.length) {
			removeDocument(deletedFiles);
		}
	};

	const saveLocalDocuments = () => {
		setShowUpload(false);
		uploadFiles();
	};

	const updateType = (file: FileInterface[]) => {
		let index = localFiles.findIndex((obj) => obj.id === file[0].id);
		let newFiles: FileInterface[] = [];
		if (index !== -1) {
			newFiles = localFiles.map((obj, i) =>
				i === index ? { ...obj, type: file[0].type } : obj,
			);
		}
		setLocalFiles(newFiles);
	};

	const editDocument = (file: FileInterface) => {
		setEditFile(file);
		setShowUpload(true);
	};

	const deleteLocalDocument = () => {
		const dataFiles = JSON.parse(JSON.stringify(localFiles));
		const index = localFiles.findIndex((r) => r.id === deleteFile?.id!);
		dataFiles.splice(index, 1);
		setLocalFiles(dataFiles);
		deleteDocument([deleteFile!]);
		setDeleteFile(undefined);
		setDelete(false);
	};

	const checkDeleteDocument = (file: FileInterface) => {
		setDeleteFile(file);
		setDelete(true);
	};

	const downloadDocument = async (file: FileInterface) => {
		try {
			if (!file.fileUrl) {
				return;
			}
			const response = await fetch(file.fileUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.setAttribute('download', file.path!);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} catch (e) {}
	};

	const closeDeleteModal = () => {
		setDelete(false);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	return (
		<div>
			<div className="flex flex-col px-10 py-6">
				<div className="flex justify-between items-center py-6">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="companyDocuments.attachments">Attachments</T>
						</p>
					</SypacText>
					<SypacButton variant="secondary" size="small">
						<button
							type="button"
							className="px-8 py-2.5"
							onClick={() => setShowUpload(true)}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-primary-violet">
									<T keyName="companyDocuments.uploadNewFile">
										Upload new file
									</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
				<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

				<div className="flex flex-col">
					{localFiles?.length ? (
						localFiles?.map((file, index) => {
							const type = DOCUMENT_TYPES.find((r) => r.value === file.type);

							return (
								<div
									className="flex py-6 justify-between items-center"
									key={index + (file.id || 0)}
								>
									<div className="flex">
										{[FileType.JPG, FileType.PNG, FileType.JPEG].includes(
											file.mimeType?.toLowerCase() as FileType,
										) ? (
											<div
												style={{
													backgroundImage: `url(${file.fileUrl})`,
												}}
												className="relative w-[80px] h-[80px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
												onClick={() => openFile(file)}
												onMouseEnter={() => handleBoxToggle(file.fileUrl)}
												onMouseLeave={() => handleBoxToggle(undefined)}
												role="img"
											>
												{showBlur === file.fileUrl && (
													<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
														<SypacIcon
															size="custom"
															width="32px"
															height="32px"
															icon-name="Magnifer"
															className="text-white"
														/>
													</div>
												)}
											</div>
										) : (
											<div
												className="relative w-[80px] h-[80px] flex justify-center items-center border border-solid border-gray-10 bg-white rounded-lg overflow-hidden cursor-pointer"
												onClick={() => openFile(file)}
												onMouseEnter={() => handleBoxToggle(file.fileUrl)}
												onMouseLeave={() => handleBoxToggle(undefined)}
											>
												<div className="flex justify-center items-center w-full h-full">
													<Document
														file={file.fileUrl}
														renderMode="canvas"
														className="flex justify-center items-center"
													>
														{Array.from({ length: 1 }, (_, i) => (
															<Page
																key={`page_${i + 1}`}
																pageNumber={i + 1}
																{...PageProps}
																width={80}
																children={null}
															/>
														))}
													</Document>
												</div>
												{showBlur === file.fileUrl && (
													<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
														<SypacIcon
															size="custom"
															width="32px"
															height="32px"
															icon-name="Magnifer"
															className="text-white"
														/>
													</div>
												)}
											</div>
										)}
										<div className="flex flex-col ml-4">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-80 whitespace-nowrap overflow-hidden overflow-ellipsis w-[200px]">
													{file.name}
												</p>
											</SypacText>
											<SypacText
												variant="overline-regular-large"
												className="mt-1"
											>
												<p className="text-gray-40">{type?.label}</p>
											</SypacText>
											<div className="flex flex-row justify-start items-center mt-3">
												<SypacText variant="overline-regular-large">
													<p className="text-gray-20">{file.mimeType} </p>
												</SypacText>
												<div className="bg-gray-20 w-1 h-1 rounded-full mx-2" />
												<SypacText variant="overline-regular-large">
													<p className="text-gray-20">{file.size} </p>
												</SypacText>
											</div>
										</div>
									</div>

									<div className="flex gap-x-3">
										<SypacButton
											variant="secondary"
											size="small"
											onClick={() => editDocument(file)}
										>
											<button className="p-3" type="button">
												<SypacIcon iconName="Pen 2" />
											</button>
										</SypacButton>
										<SypacButton
											variant="secondary"
											size="small"
											onClick={() => checkDeleteDocument(file)}
										>
											<button className="p-3" type="button">
												<SypacIcon iconName="Trash Bin Trash" />
											</button>
										</SypacButton>
										<SypacButton
											variant="secondary"
											size="small"
											onClick={() => downloadDocument(file)}
										>
											<button className="p-3" type="button">
												<SypacIcon iconName="Download Minimalistic" />
											</button>
										</SypacButton>
										<SypacButton
											variant="secondary"
											size="small"
											onClick={() => openFile(file)}
										>
											<button className="p-3" type="button">
												<SypacIcon iconName="Eye" />
											</button>
										</SypacButton>
									</div>
								</div>
							);
						})
					) : (
						<div className="flex flex-col justify-center items-center py-6">
							<SypacText variant="heading-5">
								<p className="text-gray-90 font-medium">
									<T keyName="companyDocuments.noAttachments">No attachments</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-medium" className="mt-3">
								<p className="text-gray-90">
									<T keyName="companyDocuments.noUploadedDocuments">
										No uploaded documents yet. To add documents click on "Upload
										new file"
									</T>
								</p>
							</SypacText>
							<SypacButton
								variant="secondary"
								size="small"
								onClick={() => setShowUpload(true)}
								className="mt-10"
							>
								<button className="px-8 py-2.5" type="reset">
									<SypacText variant="body-regular-medium">
										<p className="text-primary-violet">
											<T keyName="companyDocuments.uploadNewFile">
												Upload new file
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					)}
				</div>
			</div>
			<ModalUploadDocuments
				isOpen={openUpload}
				onClose={closeUploadModal}
				onSave={uploadLocalDocuments}
				files={editFile ? [editFile] : []}
				saveLocalDocuments={saveLocalDocuments}
				docsLoading={docsLoading}
				updateType={updateType}
			/>
			<ModalDeleteDocument
				isOpen={openDelete}
				onClose={closeDeleteModal}
				onSubmit={deleteLocalDocument}
			/>
			{openView ? (
				<DocumentsViewer
					url={url!}
					isPdf={isPdf}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}
		</div>
	);
};

export default CompanyDocuments;
