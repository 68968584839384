import React from 'react';
import { SypacTabs } from '@sypac/component-library-react';
import { BillingTab, BillingTabsProps } from './BillingTabs.interface';
import { TabsButton } from '../TabsButton/TabsButton';

const BillingTabs: React.FC<BillingTabsProps> = ({
	data,
	callback,
	activeTab,
	classNames,
}) => {
	const isTabActive = (
		tabValue: string | string[],
		activeTab: string | string[],
	) => {
		if (Array.isArray(tabValue) && Array.isArray(activeTab)) {
			return (
				tabValue.length === activeTab.length &&
				tabValue.every((value) => activeTab.includes(value))
			);
		}
		if (!Array.isArray(tabValue) && !Array.isArray(activeTab)) {
			return tabValue === activeTab;
		}
		return false;
	};

	return (
		<SypacTabs className={`flex w-fit p-[3px] gap-[4px] ${classNames}`}>
			{data?.map(({ label, value, count }: BillingTab) => {
				return (
					<TabsButton
						key={Array.isArray(value) ? value.join('-') : value}
						label={label}
						buttonId={Array.isArray(value) ? value[0] : value!}
						onClick={() => callback(value ?? label.toLowerCase())}
						isSelected={isTabActive(value ?? label.toLowerCase(), activeTab!)}
						notification={+count}
						color="#E8E8E8"
						classNames={classNames}
					/>
				);
			})}
		</SypacTabs>
	);
};

export default BillingTabs;
