import React from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { TabButtonProps } from './OrderDetailsTabButton.interface';

const TabButton: React.FC<TabButtonProps> = ({
	tab,
	isActive,
	config,
	notificationCount,
	onClick,
	target,
}) => {
	const shouldShowNotification =
		target === 'producer'
			? notificationCount && notificationCount >= 1
			: notificationCount && notificationCount > 1;
	const displayCount =
		target === 'producer'
			? notificationCount
			: notificationCount
			? notificationCount - 1
			: 0;

	return (
		<SypacButton variant="secondary" size="small" className="w-full">
			<button
				type="button"
				className={`w-full h-[40px] rounded-lg flex gap-2 items-center border-none transition group
          ${
						isActive
							? 'bg-primary-violet text-white'
							: 'hover:bg-gray-10 bg-alabaster text-gray-80'
					}`}
				onClick={() => onClick(tab)}
			>
				<SypacIcon
					iconName={config.icon}
					size="custom"
					width="24px"
					height="24px"
					className="mb-[2px]"
				/>
				<SypacText variant="body-regular-medium" className="flex gap-4">
					<p>
						<T keyName={config.label}>{config.translatedLabel}</T>
					</p>
					{shouldShowNotification && (
						<span
							className={`w-fit px-2 flex justify-center rounded-full text-gray-80 transition
                ${isActive ? 'bg-white' : 'bg-gray-10 group-hover:bg-white'}`}
						>
							{displayCount}
						</span>
					)}
				</SypacText>
			</button>
		</SypacButton>
	);
};

export default TabButton;
