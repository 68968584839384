/**
 * Converts a string from camelCase to snake_case.
 * @param {string} str - The string in camelCase format to be converted.
 * @returns {string} - The string converted to snake_case format.
 */
export const camelToSnakeCase = (str: string): string =>
	str?.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Converts a string from snake_case to camelCase.
 * @param {string} str - The string in snake_case format to be converted.
 * @returns {string} - The string converted to camelCase format.
 */
export const snakeToCamelCase = (str: string): string =>
	str?.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

/**
 * Checks if a given string is a valid URL.
 * @param {string} str - The string to be tested.
 * @returns {boolean} - Returns true if the string is a valid URL, otherwise false.
 */
export const isUrl = (str: string): boolean => {
	const urlRegex = /^(https?:\/\/)([\w-]+(\.[\w-]+)+)(\/[\w-.?%&=]*)?$/i;
	return urlRegex.test(str);
};
