import React from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import './companies-table.css';
import { CompaniesTableInterface } from './CompaniesTable.interface';
import { CompanyResponse, CompanyShort } from '../../services/company.services';
import RenderIconByStatus from '../RenderIconByStatus/RenderIconByStatus';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import { countries } from '../../assets/countries.constant';
import dayjs from 'dayjs';
import AvatarDropDown from '../AvatarDropDown/AvatarDropDown';
import { T, useTranslate } from '@tolgee/react';
import { formatDate } from '../../utils/time.util';
import { NoResults } from '../../assets/NoResult';
import { isUrl, snakeToCamelCase } from '../../utils/string.util';

const CompaniesTable: React.FC<CompaniesTableInterface> = ({
	rows,
	onClick,
	selectedCompany,
	search,
	status,
}) => {
	const { t } = useTranslate();

	const keyRows = rows?.map((row) => ({ ...row, key: row?.id }));

	const columns = (search?: string) => [
		{
			title: t('companiesTable.accountState', 'Account State'),
			dataIndex: '',
			className: 'text-left',
			width: 50,
			key: 'status',
			render(value: CompanyResponse) {
				const companyStatus = value.status;

				return (
					<div
						className="w-full flex justify-start gap-4"
						onClick={() => onClick(value)}
					>
						<RenderIconByStatus status={companyStatus} />
						<SypacText variant="body-regular-medium">
							<p className="text-base text-gray-80">
								<T
									keyName={`companiesDashboard.${snakeToCamelCase(
										companyStatus,
									)}`}
								>
									{(
										companyStatus.charAt(0).toUpperCase() +
										companyStatus.slice(1)
									).replace(/_/g, ' ')}
								</T>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companiesTable.companyId', 'Company ID'),
			dataIndex: '',
			className: 'text-left',
			width: 30,
			key: 'status',
			render(value: CompanyResponse) {
				const htmlId = search
					? value?.id
							?.toString()
							.replace(search, `<span class="text-texas-rose">${search}</span>`)
					: value?.id?.toString();

				return (
					<div className="w-full flex" onClick={() => onClick(value)}>
						<SypacText variant="body-regular-medium">
							<p
								className="text-gray-80"
								dangerouslySetInnerHTML={{ __html: htmlId! }}
							/>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companiesTable.country', 'Country'),
			dataIndex: '',
			className: 'text-left',
			width: 50,
			key: 'countryCode',
			render(value: CompanyResponse) {
				return (
					<div className="w-full flex" onClick={() => onClick(value)}>
						<SypacText variant="body-regular-medium" className="ml-2">
							<p className="text-gray-80">
								{countries[value?.countryCode?.toUpperCase()]}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companiesTable.registered', 'Registered'),
			dataIndex: '',
			className: 'text-left',
			width: 50,
			key: 'createdAt',
			render(value: CompanyResponse) {
				return (
					<div className="w-full flex" onClick={() => onClick(value)}>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{formatDate(value.createdAt)}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companiesTable.companyName', 'Company name'),
			dataIndex: '',
			className: 'text-left',
			width: 300,
			key: 'name',
			render(value: CompanyResponse) {
				const htmlName = search
					? value?.name
							?.toString()
							.replace(
								new RegExp(search, 'gi'),
								(match) => `<span class="text-texas-rose">${match}</span>`,
							)
					: value?.name?.toString();

				return (
					<div
						className="w-full flex items-center gap-6"
						onClick={() => onClick(value)}
					>
						<SypacAvatar initials={value.name} size="sm">
							{isUrl(value?.logoUrl!) && (
								<img
									src={value.logoUrl}
									alt={value.name}
									className="w-full h-full object-cover rounded-full"
								/>
							)}
						</SypacAvatar>
						<SypacText variant="body-regular-medium">
							<p
								className="text-gray-80"
								dangerouslySetInnerHTML={{ __html: htmlName! }}
							/>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companiesTable.seen', 'Seen'),
			dataIndex: '',
			className: 'text-left',
			width: 20,
			key: 'updatedAt',
			render(value: CompanyResponse) {
				return (
					<div className="w-full flex" onClick={() => onClick(value)}>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{dayjs(value.updatedAt).fromNow(true)}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('companiesTable.assignedTo', 'Assigned to'),
			dataIndex: '',
			className: 'text-left',
			width: 20,
			key: 'assignee',
			render(value: CompanyResponse) {
				const isOneAssignee = value.assignees?.length === 1;
				const avatars = value?.assignees?.map((item) => ({
					id: item.id.toString(),
					avatarUrl: item.assigneeUser?.avatarUrl,
					fullName: item.assigneeUser?.name || '',
				}));
				const firstAssignee = value?.assignees?.at(0);

				return (
					<div className="w-full flex" onClick={() => onClick(value)}>
						{value?.assignees && value?.assignees?.length > 0 ? (
							<>
								{isOneAssignee ? (
									<div className="flex flex-row justify-start items-center">
										<SypacAvatar
											initials={firstAssignee?.assigneeUser?.name}
											isGrouped={true}
											key={1}
											size="md"
										>
											{firstAssignee?.assigneeUser?.avatarUrl ? (
												<img
													src={firstAssignee?.assigneeUser?.avatarUrl}
													alt={firstAssignee?.assigneeUser?.name}
													className="w-full h-full object-cover rounded-full"
												/>
											) : null}
										</SypacAvatar>
										<SypacText variant="body-regular-medium" className="ml-3">
											<p className="text-gray-80">
												{firstAssignee?.assigneeUser?.name}
											</p>
										</SypacText>
									</div>
								) : (
									<AvatarDropDown items={avatars!} />
								)}
							</>
						) : null}
					</div>
				);
			},
		},
	];

	const getTrProps = (
		record: CompanyShort,
		_index: number,
		_indent: number,
	): string => {
		return record.id === selectedCompany ? 'companies-table-row-selected' : '';
	};

	return (
		<Table
			className="h-full companies-table"
			columns={columns(search)}
			rowClassName={getTrProps}
			data={keyRows}
			emptyText={() => {
				return (
					<div className="h-[calc(100vh-373px)] flex flex-col justify-center items-center gap-8">
						<NoResults />
						<SypacText variant="heading-3">
							<p className="text-gray-80">
								<T
									keyName={
										status === 'all'
											? 'companiesTable.noCompanies'
											: `companiesTable.noCompaniesIn${status}`
									}
								>
									{status === 'all'
										? 'No companies'
										: `No companies in stage ${status
												?.replace(/_/g, ' ')
												.replace(/^\w/, (c) => c.toUpperCase())}`}
								</T>
							</p>
						</SypacText>
					</div>
				);
			}}
		/>
	);
};

export default CompaniesTable;
