import React from 'react';

import BoxComponent from '../../BoxComponent/BoxComponent';
import { SypacText } from '@sypac/component-library-react';
import { DriverArrivedLoadingProps } from './DriverArrivedLoading.interface';
import Flag from 'react-world-flags';
import '../notification.css';
import { T } from '@tolgee/react';

const DriverArrivedLoading = ({
	notification,
	title,
	description,
	loadingPlace,
	countryCode,
	arrivalTime,
	color,
}: DriverArrivedLoadingProps) => {
	return (
		<BoxComponent color={color} notification={notification}>
			<div className="flex flex-col p-3 gap-3">
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">{title}</p>
				</SypacText>
				<SypacText variant="overline-regular-large">
					<p className="text-gray-80 leading-3">{description}</p>
				</SypacText>
				<div className="grid grid-cols-3 gap-2.5">
					<div>
						<SypacText variant="overline-regular-large">
							<p className="text-gray-40">
								<T keyName="driverArrivedLoading.loadingPlace">
									Loading place:
								</T>
							</p>
						</SypacText>
					</div>
					<div className="col-span-2">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-80">
								<span className="flag mr-[20px]">
									<Flag
										code={countryCode?.toUpperCase()}
										width={24}
										height={16}
									/>
								</span>
								{loadingPlace}
							</p>
						</SypacText>
					</div>
				</div>
				<div className="grid grid-cols-3 gap-2.5">
					<SypacText variant="overline-regular-large">
						<p className="text-gray-40">
							<T keyName="driverArrivedLoading.arrivalTime">Arrival Time:</T>
						</p>
					</SypacText>
					<div className="col-span-2">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-80 leading-3">{arrivalTime}</p>
						</SypacText>
					</div>
				</div>
			</div>
		</BoxComponent>
	);
};

export default DriverArrivedLoading;
