import React, { useCallback, useEffect, useState } from 'react';
import { T } from '@tolgee/react';
import { OrderInterface } from '../OrdersTable/interfaces/Order.interface';
import { SypacIcon, SypacText } from '@sypac/component-library-react';
import { DocumentsDTO, FileService } from '../../services/file.service';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import SimpleFile from '../../assets/SimpleFile';
import { MutatingDots } from 'react-loader-spinner';
import { OrderTarget } from '../OrdersTable/interfaces/OrderStatus.interface';

interface DocumentsTabProps {
	selectedOrder: OrderInterface | undefined;
	target: string;
}

const DocumentsTab: React.FC<DocumentsTabProps> = ({
	selectedOrder,
	target,
}) => {
	const [openDocView, setOpenDocView] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [showBlur, setShowBlur] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [docs, setDocs] = useState<DocumentsDTO[]>();

	const hasUndefinedLoadDetails = selectedOrder?.deliveryIncluded
		? docs?.some((doc) => doc.loadDetails === undefined)
		: docs?.some((doc) => doc.producerLoadDetails === undefined);

	const getDocsCallback = useCallback(async () => {
		setIsLoading(true);
		try {
			if (selectedOrder?.id && selectedOrder?.target) {
				const { data } = await FileService.getFiles(
					selectedOrder.id!,
					selectedOrder.target!,
				);
				setDocs(data);
			}
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	}, [selectedOrder?.id, selectedOrder?.target]);

	useEffect(() => {
		getDocsCallback().then(() => {});
	}, [getDocsCallback]);

	const openFile = (url: string) => {
		setUrl(url);
		setOpenDocView(true);
	};

	const handleBoxToggle = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const findDocumentOrigin = (dataArray: DocumentsDTO[], search: string) => {
		for (const item of dataArray) {
			if (
				item.loadDetails?.documents?.includes(search) ||
				(selectedOrder?.target === OrderTarget.producer &&
					item.producerLoadDetails?.documents?.includes(search))
			) {
				return 'Pick-up document';
			} else if (item.unloadDetails?.documents?.includes(search)) {
				return 'Proof of delivery document';
			}
		}
		return undefined;
	};

	const ImageCard = ({ imageUrl }: { imageUrl: string }) => {
		return (
			<div
				style={{
					backgroundImage: `url(${imageUrl})`,
				}}
				className={`relative flex flex-none bg-no-repeat bg-cover bg-center rounded-[12px] border border-solid border-gray-10 cursor-pointer w-[144px] h-[151px] ${
					showBlur === imageUrl ? 'hover:border-kimberly' : ''
				}`}
				onClick={() => openFile(imageUrl)}
				onMouseEnter={() => handleBoxToggle(imageUrl)}
				onMouseLeave={() => handleBoxToggle(undefined)}
				role="img"
			>
				{showBlur === imageUrl && (
					<div className="bg-black-50 absolute rounded-lg backdrop-blur-sm h-full w-full flex justify-center items-center">
						<SypacIcon
							size="custom"
							width="32px"
							height="32px"
							icon-name="Magnifer"
							className="text-white"
						/>
					</div>
				)}
			</div>
		);
	};

	const renderDocuments = (docProperty: string) =>
		docs?.map((doc, index) => (
			<React.Fragment key={index}>
				{/*@ts-ignore*/}
				{doc[docProperty]?.documents?.map((url, idx) => (
					<ImageCard key={idx} imageUrl={url} />
				))}
			</React.Fragment>
		));

	const renderNoDocuments = () => (
		<div className="flex flex-col justify-center items-center gap-14 mt-12">
			<span className="flex scale-[3.35]">
				<SimpleFile color="#454545" />
			</span>
			<div className="flex flex-col gap-2">
				<SypacText variant="body-regular-medium">
					<p className="text-xl text-gray-80">
						<T keyName="documentsTab.noDocumentsUploaded">
							No documents uploaded yet
						</T>
					</p>
				</SypacText>
			</div>
		</div>
	);

	return (
		<>
			<div>
				<div className="flex flex-col gap-6 px-10 pb-3.5">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-40">
							<T keyName="documentsTab.proofOfLoading">Proof of loading</T>
						</p>
					</SypacText>
					{isLoading ? (
						<div className="flex w-full h-full items-center justify-center">
							<MutatingDots
								height="100"
								width="100"
								color="#7693F4"
								secondaryColor="#494C83"
								radius="12.5"
								ariaLabel="mutating-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div
							className={`gap-3 mb-4 ${
								hasUndefinedLoadDetails ? 'justify-center' : ''
							} ${
								(docs ?? []).filter(
									(item) =>
										Object.keys(item?.loadDetails ?? {}).length > 0 ||
										Object.keys(item?.producerLoadDetails ?? {}).length > 0,
								).length > 4
									? 'grid grid-cols-4'
									: 'flex'
							}`}
						>
							{docs?.some((item) => item.loadDetails !== undefined)
								? renderDocuments('loadDetails')
								: docs?.some((item) => item.producerLoadDetails !== undefined)
								? renderDocuments('producerLoadDetails')
								: renderNoDocuments()}
						</div>
					)}
				</div>

				{selectedOrder?.target !== OrderTarget.producer ? (
					<>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />
						<div className="flex flex-col gap-6 px-10 pt-8">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-40">
									<T keyName="documentsTab.proofOfDelivery">
										Proof of delivery
									</T>
								</p>
							</SypacText>
							{isLoading ? (
								<div className="flex w-full h-full items-center justify-center">
									<MutatingDots
										height="100"
										width="100"
										color="#7693F4"
										secondaryColor="#494C83"
										radius="12.5"
										ariaLabel="mutating-dots-loading"
										wrapperStyle={{}}
										wrapperClass=""
										visible={true}
									/>
								</div>
							) : (
								<div
									className={`gap-3 mb-4 ${
										docs?.some((item) => item.unloadDetails === undefined)
											? 'justify-center'
											: ''
									} ${
										(docs ?? []).filter(
											(item) =>
												Object.keys(item?.unloadDetails ?? {}).length > 0,
										).length > 4
											? 'grid grid-cols-4'
											: 'flex'
									}`}
								>
									{docs?.some((item) => item.unloadDetails !== undefined) ? (
										docs?.map((doc, index) => (
											<React.Fragment key={index}>
												{doc?.unloadDetails?.documents?.map((url, idx) => (
													<ImageCard key={idx} imageUrl={url} />
												))}
											</React.Fragment>
										))
									) : (
										<div className="flex flex-col justify-center items-center gap-14 mt-12">
											<span className="flex scale-[3.35]">
												<SimpleFile color="#454545" />
											</span>

											<div className="flex flex-col gap-2">
												<SypacText variant="body-regular-medium">
													<p className="text-xl text-gray-80">
														<T keyName="documentsTab.noDocumentsUploaded">
															No documents uploaded yet
														</T>
													</p>
												</SypacText>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					</>
				) : null}
			</div>
			{openDocView ? (
				<DocumentsViewer
					url={url!}
					isPdf={false}
					isOpen={openDocView}
					onClose={() => setOpenDocView(false)}
					additional={{
						updatedAt: docs?.[0].updatedAt!,
						text: findDocumentOrigin(docs!, url!)!,
					}}
					canDownload={target === OrderTarget.admin}
				/>
			) : null}
		</>
	);
};

export default DocumentsTab;
