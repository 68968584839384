import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { LogoShort } from '../../assets/LogoShort';
import { SideBarMenuItem } from './components/SideBarMenuItem/SideBarMenuItem';
import { SypacAvatar } from '@sypac/component-library-react';
import { SideBarMenuProps } from './SideBarMenuProps';
import { ProfileModal } from '../ProfileModal/ProfileModal';
import Emitter, { EventType } from '../../services/events';
import { AuthContext } from '../../context/context';
import { NotificationsService } from '../../services/notifications.services';
import useDebounce from '../../hooks/useDebounce';
import { LineStroke } from '../../assets/LineStroke';
import { MenuIcon } from '../../assets/MenuIcon';
import { LogoBlack } from '../../assets/LogoBlack';
import TermsAndConditionsSecondStep from '../TermsAndConditions/SecondStep';
import { NotificationCategory } from '../OrdersTable/interfaces/Order.interface';

export const SideBarMenu: React.FC<SideBarMenuProps> = ({ groups }) => {
	const location = useLocation();
	const { user } = useContext(AuthContext);

	const [count, setCount] = useState<number>(0);
	const showCount = useDebounce(count, 500);
	const [openedProfile, openProfile] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false);
	const [userRole, setUserRole] = useState<string | undefined>(undefined);
	const firstDivRef = useRef<HTMLDivElement | null>(null);
	const profileModalRef = useRef<HTMLDivElement | null>(null);
	const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const userName = useMemo(() => {
		return user?.profile
			? `${user?.profile.firstName} ${user?.profile.lastName}`
			: user?.name;
	}, [user]);

	const getCount = useCallback(async () => {
		try {
			if (user?.uid) {
				const resp = await NotificationsService.getCount({
					recipientId: user?.uid,
					limit: 500,
				});
				const counter = new Set(
					resp.data.items
						.filter(
							(item) => item.category !== NotificationCategory.NEW_PRODUCT,
						)
						.map((item) => item.groupId),
				).size;
				if (counter) {
					setCount(counter);
					Emitter.emit(EventType.ORDER_REFRESH, resp.data.items);
				}
			}
		} catch (e) {}
	}, [user?.uid]);

	const closeModal = () => {
		openProfile(false);
	};

	const handleMouseEnter = () => {
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
		openProfile(true);
	};

	const handleMouseLeave = () => {
		hoverTimeoutRef.current = setTimeout(() => {
			if (!isMouseOverElements()) {
				openProfile(false);
			}
		}, 100);
	};

	const isMouseOverElements = () => {
		const avatarElement = firstDivRef.current;
		const modalElement = profileModalRef.current;

		if (!avatarElement || !modalElement) return false;

		return (
			document.querySelectorAll(':hover').length > 0 &&
			(avatarElement.contains(document.activeElement) ||
				modalElement.contains(document.activeElement) ||
				Array.from(document.querySelectorAll(':hover')).some(
					(el) =>
						avatarElement.contains(el as Node) ||
						modalElement.contains(el as Node),
				))
		);
	};

	const viewTerms = () => {
		setIsTermsOpen(true);
	};

	const closeTerms = () => {
		setIsTermsOpen(false);
		openProfile(false);
	};

	const getModalPosition = useCallback(() => {
		if (!firstDivRef.current) return {};

		const rect = firstDivRef.current.getBoundingClientRect();
		const isWideScreen = window.innerWidth > 1365;

		let topOffset = -285;
		if (user?.isAdmin) {
			topOffset = -98;
		} else if (user?.company?.industries?.length === 2) {
			topOffset = -440;
		}

		const leftOffset = isWideScreen ? 46 : 36;

		return {
			top: rect.top + topOffset,
			left: rect.left + leftOffset,
		};
	}, [user?.isAdmin, user?.company?.industries?.length]);

	useEffect(() => {
		const role = user?.isAdmin
			? undefined
			: ['customer', 'producer', 'transporter'].find((role) =>
					window.location.origin.includes(role),
			  );
		setUserRole(role);
	}, [user]);

	useEffect(() => {
		Emitter.on(EventType.NOTIFICATION_COUNT, () => {
			getCount().then(() => {});
		});
		Emitter.on(EventType.NOTIFICATION_COUNT_DECREASE, (count: number) => {
			setCount((prev) => (prev - count >= 0 ? prev - count : 0));
		});

		return () => {
			Emitter.off(EventType.NOTIFICATION_COUNT);
			Emitter.off(EventType.NOTIFICATION_COUNT_DECREASE);
		};
	}, [getCount]);

	useEffect(() => {
		getCount().then(() => {});
	}, [getCount]);

	return (
		<>
			<div className="w-[calc(100vw-26px)] sm:w-fit fixed sm:relative flex sm:flex-col items-center bg-white sm:bg-gray-1 border border-solid border-gray-10 sm:rounded-10 p-3 sm:p-0 xl-2xl:p-2 z-50">
				<div className="w-full flex sm:flex-col h-full justify-between items-center">
					<div className="flex items-center sm:flex-col gap-4 sm:max-w-[42px]">
						<div className="flex flex-col items-center">
							<span className="hidden sm:block scale-[0.8] xl-2xl:scale-100">
								<LogoShort />
							</span>
							<span className="block mt-[4px] sm:hidden">
								<LogoBlack width="97" height="18" />
							</span>
							<span className="hidden sm:block">
								<LineStroke width={[42, 42, 42]} />
							</span>
						</div>

						<div className="hidden sm:flex sm:flex-col gap-5">
							{groups.map((item) => {
								return (
									<SideBarMenuItem
										key={item.alt}
										url={item.url}
										logo={item.logo}
										count={item.count && showCount ? showCount : 0}
										alt={item.alt}
										active={location.pathname === item.url}
									/>
								);
							})}
						</div>
					</div>
					<div className="hidden sm:block">
						<div
							ref={firstDivRef}
							className="flex flex-col gap-4 items-center"
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							<span className="hidden sm:block">
								<LineStroke width={[42, 42, 42]} />
							</span>
							<SypacAvatar
								initials={userName}
								onClick={() => {
									openProfile(!openedProfile);
								}}
								className="scale-75 xl-2xl:scale-100"
								isChosen={openedProfile}
							>
								{user?.profile?.photoUrl && (
									<img
										src={user?.profile.photoUrl || ''}
										alt={userName}
										className="w-full h-full object-cover rounded-full"
									/>
								)}
							</SypacAvatar>
						</div>
					</div>

					<button
						type="button"
						onClick={() => setIsOpen(!isOpen)}
						className="w-[87px] h-[42px] flex sm:hidden bg-transparent rounded-10 border border-solid border-gray-10 justify-center items-center cursor-pointer"
					>
						{isOpen ? (
							<span className="mt-[3px] mr-[5px]">
								<MenuIcon />
							</span>
						) : (
							<span className="mt-[3px] mr-[5px]">
								<MenuIcon />
							</span>
						)}

						{isOpen ? (
							<span className="text-sm text-gray-80">Back</span>
						) : (
							<span className="text-sm text-gray-80">Menu</span>
						)}
					</button>
				</div>
			</div>
			{isOpen && (
				<div className="w-screen h-screen fixed mt-[66px] z-[22] bg-gray-1/5">
					<div className="flex flex-col m-3 gap-3">
						{groups.map((item, i) => {
							return (
								<a
									key={i}
									href={item.url}
									className={`h-[60px] box-border bg-white border border-solid rounded-xl p-5 cursor-pointer no-underline text-gray-80 ${
										location.pathname === item.url
											? 'border-primary-violet'
											: 'border-gray-10'
									}`}
								>
									{item.alt}
								</a>
							);
						})}
					</div>
				</div>
			)}

			{isTermsOpen ? (
				<TermsAndConditionsSecondStep
					isOpen={isTermsOpen}
					target={userRole!}
					termsAccepted={user?.company.termsAccepted}
					onClose={closeTerms}
				/>
			) : null}
			{openedProfile ? (
				<div
					ref={profileModalRef}
					className="absolute z-[1000]"
					style={getModalPosition()}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<ProfileModal onClose={closeModal} viewTerms={viewTerms} />
				</div>
			) : null}
		</>
	);
};
