import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { PaymentsTable } from '../../../components/PaymentsTable/PaymentsTable';
import BillingTabs from '../../../components/BillingTabs/BillingTabs';
import { useGetStats } from '../../../hooks/use-get-stats';
import { useGetInvoices } from '../../../hooks/use-get-invoices';
import { BillingTab } from '../../../components/BillingTabs/BillingTabs.interface';
import dayjs from 'dayjs';
import { LIMIT } from '../../../constants';
import { useGetInvoicesLines } from '../../../hooks/use-get-invoices-lines';
import { InvoiceLinesTable } from '../../../components/InvoiceLinesTable/InvoiceLinesTable';
import Pagination from '../../../components/Pagination/Pagination';
import ProducerBillingStatistic from '../../../components/ProducerBillingStatistic/ProducerBillingStatistic';
import { useGetInvoiceLinesStats } from '../../../hooks/use-get-lines-stats';
import { InvoiceInterface } from '../../../components/PaymentsTable/interfaces/Payment.interface';
import { PaymentTypeAction } from '../../../components/PaymentsTable/interfaces/PaymentStatus.interface';
import { OrdersService } from '../../../services/orders.services';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import OrderDetails from '../OrderDetails';
import { T, useTranslate } from '@tolgee/react';
import { PaymentPeriod } from '../../../components/PyamentPeriod';
import useDebounce from '../../../hooks/useDebounce';
import {
	CompanyResponse,
	CompanyService,
} from '../../../services/company.services';
import { toastVariant } from '../../../components/ToastVariant/toastVariant';
import { formatDate } from '../../../utils/time.util';
import { NumericFormat } from 'react-number-format';
import Emitter, { EventType } from '../../../services/events';
import ModalContactUs from '../../../components/ModalContactUs/ModalContactUs';

const ProducerBilling: React.FC = () => {
	const { t } = useTranslate();
	const [searchLocal, setSearchLocal] = useState<string>('');
	const [currentTab, setCurrentTab] = useState<string | string[]>('all');
	const [page, setPage] = useState<number>(0);
	const [invoicesPage, setInvoicesPage] = useState<number>(0);
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);
	const searchQuery = useDebounce(searchLocal, 500);
	const [myCompany, setMyCompany] = useState<CompanyResponse>();
	const [refresh, setRefresh] = useState<number>(0);
	const [openContact, setOpenContact] = useState<boolean>(false);
	const detailsRef = useRef<HTMLDivElement>(null);

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setMyCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	}, []);

	const selectTab = (tab: string | string[]) => {
		setCurrentTab(tab);
		setPage(0);
	};

	const [stats] = useGetStats({
		target: 'producer',
		refresh,
	});
	const [statsLines] = useGetInvoiceLinesStats({ target: 'producer', refresh });
	const [stats30] = useGetStats({
		target: 'producer',
		fromDate: dayjs().subtract(30, 'days').format(),
		refresh,
	});
	const [stats60] = useGetStats({
		target: 'producer',
		fromDate: dayjs().subtract(60, 'days').format(),
		refresh,
	});
	const [stats90] = useGetStats({
		target: 'producer',
		fromDate: dayjs().subtract(90, 'days').format(),
		refresh,
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesList, _, invoicesAreLoading] = useGetInvoices({
		limit: LIMIT,
		offset: invoicesPage * LIMIT,
		target: 'producer',
		refresh,
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesLines, lerr, linesAreLoading] = useGetInvoicesLines({
		status: currentTab,
		limit: LIMIT,
		offset: page * LIMIT,
		target: 'producer',
		search: searchQuery,
		refresh,
	});

	const billingStats = {
		amountPaid: stats?.amountTotal?.pending!,
		amountPending: stats?.amount!,
		allOrders: String(
			+stats?.count?.paid! +
				+stats?.count?.pending! +
				+stats?.count?.invoiceConfirmed! +
				+stats?.count?.overdue!,
		),
		ordersLast30Days: stats30?.countOrders?.all!,
		ordersLast60Days: stats30?.countOrders?.all!,
		paidOrders: stats?.count?.paid!,
		orderInProgress: String(
			+stats?.count?.pending! + +stats?.count?.invoiceConfirmed!,
		),
		ordersInDispute: stats?.count?.overdue!,
		revenueLast30Days: stats30?.amountTotal?.all!,
		revenueLast60Days: stats60?.amountTotal?.all!,
		revenueLast90Days: stats90?.amountTotal?.all!,
		paymentTerm: stats?.paymentTerm!,
		nextInvoicingDate: stats?.nextInvoicingDate!,
		countryCode: myCompany?.countryCode!,
	};

	const tabs: BillingTab[] = [
		{
			label: t('producerBilling.all', 'All'),
			value: 'all',
			color: 'white',
			count: statsLines?.all!,
		},
		{
			label: t('producerBilling.pendingYourInvoice', 'Pending your invoice'),
			value: 'pending_invoice',
			color: 'white',
			count: statsLines?.pendingInvoice!,
		},
		{
			label: t(
				'producerBilling.pendingInvoiceConfirmation',
				'Pending invoice confirmation',
			),
			value: 'uploaded_invoice',
			color: 'white',
			count: statsLines?.uploadedInvoice!,
		},
		{
			label: t('producerBilling.pendingPayment', 'Pending payment'),
			value: ['pending', 'invoice_confirmed'],
			color: 'yellow',
			count: String(+statsLines?.pending! + +statsLines?.invoiceConfirmed!),
		},
		{
			label: t('producerBilling.paid', 'Paid'),
			value: 'paid',
			color: 'green',
			count: statsLines?.paid!,
		},
	];

	if (statsLines?.overdue !== '0') {
		tabs.push({
			label: t('producerBilling.paymentDispute', 'Dispute'),
			value: 'overdue',
			color: 'red',
			count: statsLines?.overdue!,
		});
	}

	const viewOrder = async (orderId?: number) => {
		try {
			const { data } = await OrdersService.getOrder(
				orderId!,
				OrderTarget.producer,
			);
			setOrder(data as unknown as OrderInterface);
			setOpenDetails(true);
		} catch (e) {
			console.log(e);
		}
	};

	const downloadDocuments = async (fileUrls: string[]) => {
		if (!fileUrls || fileUrls.length === 0) return;

		try {
			for (const fileUrl of fileUrls) {
				const response = await fetch(fileUrl);
				const blob = await response.blob();
				const downloadUrl = window.URL.createObjectURL(blob);
				const link = document.createElement('a');

				const fileName = new URL(fileUrl).pathname.split('/').pop();

				link.href = downloadUrl;
				link.download = fileName || 'document';
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	};

	const additionalClick = (value: InvoiceInterface | number, type?: string) => {
		switch (type) {
			case PaymentTypeAction.view_order:
				return viewOrder(value as number).then(() => {});
			case PaymentTypeAction.download:
				return downloadDocuments(
					(value as InvoiceInterface).invoice?.fullInvoiceUrls!,
				).then(() => {});
		}
	};

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		if (!detailsRef.current?.contains(event.target as Node)) {
			closeModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	useEffect(() => {
		const refreshHandler = () => setRefresh(refresh + 1);
		Emitter.on(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		return () => {
			Emitter.off(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		};
	}, [refresh]);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	return (
		<main className="w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-114px)] h-full flex gap-[18px]">
			<section className="flex-1 flex flex-col gap-[14px]">
				<div className="flex gap-3 ml-5">
					<div className="flex-1 flex flex-col gap-3">
						<header className="flex items-center justify-between">
							<SypacText variant="heading-4">
								<p className="text-gray-80">
									<T keyName="producerBilling.manageYourWalletBilling">
										Manage your wallet & billing
									</T>
								</p>
							</SypacText>
							<SypacButton variant="secondary" size="small">
								<button
									type="button"
									className="h-[32px] flex justify-center items-center px-5 bg-primary-violet border-0 rounded-lg transition hover:bg-primary-violet/80"
									onClick={() => setOpenContact(true)}
								>
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-white whitespace-nowrap">
											<T keyName="producerBilling.contactSypac">
												Contact Sypac
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</header>
						<SearchBar
							placeholder={t('transporterBilling.searchOrder', 'Search order')}
							onChange={setSearchLocal}
							classNames="w-full"
							showButton={true}
						/>
					</div>

					<div className="w-[415px] flex flex-col border border-solid border-gray-10 rounded-10">
						<div className="flex gap-3 p-3">
							<SypacText variant="overline-regular-large">
								<p className="text-sm text-gray-40">
									<T keyName="producerBillingStatistic.eardnedFrom">
										Earned from
									</T>{' '}
									{formatDate(
										dayjs(billingStats.nextInvoicingDate)
											.subtract(+billingStats.paymentTerm, 'days')
											.toString(),
									)}{' '}
									{' — '}
									{formatDate(billingStats.nextInvoicingDate)}
								</p>
							</SypacText>

							<div className="flex items-center justify-center bg-[#20AC93AB] border border-solid border-[#16A28966] rounded-[5px] px-[2.5px]">
								<SypacText variant="overline-regular-large">
									<p className="text-xs text-white">+12%</p>
								</SypacText>
							</div>
						</div>
						<div className="flex justify-between px-3 py-[10px] bg-alabaster border-0 border-t-[1px] border-solid border-gray-10 rounded-10">
							<SypacText variant="heading-4">
								<p className="text-xl text-gray-80 whitespace-nowrap">
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator=" "
										decimalSeparator="."
										value={parseFloat(billingStats.amountPaid)}
										suffix={billingStats.countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								</p>
							</SypacText>

							<div className="h-[24px] flex items-center justify-center bg-white border border-solid border-gray-10 rounded-full px-4">
								<SypacText variant="overline-regular-medium">
									<p className="text-sm text-gray-80">
										<T keyName="producerBillingStatistic.paymentScheduledFor">
											Payment scheduled for
										</T>{' '}
										{formatDate(billingStats.nextInvoicingDate, 'DD MMM')}
									</p>
								</SypacText>
							</div>
						</div>
					</div>
				</div>

				<div className="ml-5 mr-2">
					<BillingTabs
						activeTab={currentTab}
						data={tabs}
						callback={selectTab}
						classNames="w-full whitespace-nowrap"
					/>
				</div>

				{linesAreLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							visible={true}
						/>
					</div>
				) : (
					<div className="h-full relative border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap ml-5">
						<div
							className={`w-full overflow-y-auto scroll-smooth ${
								invoicesLines?.items.length
									? 'h-[calc(100%-52px)]'
									: 'h-[calc(100%+2px)]'
							}`}
						>
							<InvoiceLinesTable
								target={OrderTarget.producer}
								rows={invoicesLines?.items || []}
								rowClick={() => {}}
								currentTab={
									Array.isArray(currentTab) ? currentTab.join('_') : currentTab
								}
								additionalClick={additionalClick}
							/>
						</div>
						{invoicesLines?.items.length ? (
							<footer className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
								<div className="flex justify-between items-center h-[51px] px-3">
									<Pagination
										showText={true}
										count={invoicesLines?.count!}
										page={page}
										onClick={(item) => setPage(item)}
									/>
								</div>
							</footer>
						) : null}
					</div>
				)}
			</section>

			<section className="w-[632px] flex flex-col gap-[14px]">
				<ProducerBillingStatistic {...billingStats} />

				{invoicesAreLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							visible={true}
						/>
					</div>
				) : (
					<div className="h-full relative border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<section className="flex justify-between p-3">
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="paymentsTable.withdrawHistory">Payment bundles</T>
								</p>
							</SypacText>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="paymentsTable.withdrawInterval">
										Withdraw interval:
									</T>{' '}
									<PaymentPeriod value={stats?.paymentTerm!} />
								</p>
							</SypacText>
						</section>

						<div
							className={`w-full h-[calc(100%-98px)] overflow-y-auto scroll-smooth border-0 border-solid border-t-[1px] border-gray-10 rounded-t-10 ${
								invoicesList?.items.length
									? 'h-[calc(100%-98px)]'
									: 'h-[calc(100%+2px)]'
							}`}
						>
							<PaymentsTable
								target="withdraw_history"
								rows={invoicesList?.items || []}
								rowClick={() => {}}
								additionalClick={additionalClick}
							/>
						</div>
						{invoicesList?.items.length ? (
							<footer className="w-full absolute bg-alabaster bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
								<div className="flex justify-between items-center h-[51px] px-3">
									<Pagination
										showText={true}
										count={invoicesList?.count!}
										page={invoicesPage}
										onClick={(item) => setInvoicesPage(item)}
									/>
								</div>
							</footer>
						) : null}
					</div>
				)}
			</section>

			{isOpen && selectedOrder?.id && (
				<aside ref={detailsRef}>
					<OrderDetails
						isOpen={!!(isOpen && selectedOrder?.id)}
						selectedOrder={selectedOrder}
					/>
				</aside>
			)}

			{openContact && (
				<ModalContactUs
					isOpen={openContact}
					onClose={() => setOpenContact(false)}
					company={myCompany!}
					target="producer"
				/>
			)}
		</main>
	);
};

export default ProducerBilling;
