import React, { useEffect, useState } from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import Close from '../../../assets/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkmark } from '../../../assets/Checkmark';
import MoonIcon from '../../../assets/MoonIcon';
import Modal from 'react-modal';
import { ChevronRightIcon } from '../../../assets/ChevronRightIcon';
import { toastVariant } from '../../ToastVariant/toastVariant';
import {
	FormValues,
	VariantInterface,
	WorkingTimeModalProps,
} from '../ProductLocation.interface';

const WorkingTimeModal: React.FC<WorkingTimeModalProps> = ({
	isOpen,
	onClose,
	weekDays,
	workHours,
	handleDataFromChild,
	selectedDays,
}) => {
	const { t } = useTranslate();
	const [localDays, setLocalDays] = useState<string[]>([]);
	const timeFormatRegex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

	useEffect(() => {
		if (
			selectedDays?.variant === 'business_days' ||
			selectedDays?.variant === 'days'
		) {
			setLocalDays([...weekDays.slice(0, 5).map((obj) => obj.variant)]);
		} else if (selectedDays?.variant === 'full_week') {
			setLocalDays([...weekDays.slice(0, 7).map((obj) => obj.variant)]);
		} else if (selectedDays?.variant === 'custom') {
			const customDays = Object.entries(selectedDays?.submenu!)
				.filter(([, value]) => value.from && value.to)
				.map(([key]) =>
					// eslint-disable-next-line no-control-regex
					key.toLowerCase().replace(/[\u200B-\u200F\uFEFF\u0000-\u001F]/g, ''),
				);
			setLocalDays([...customDays]);
		}
	}, [selectedDays, weekDays]);

	const timeEntrySchema = (menuTitle: string) =>
		Yup.object({
			from: Yup.string()
				.required("'From' Time is required")
				.matches(timeFormatRegex, 'Invalid time format, must contain :'),
			to: Yup.string()
				.required("'To' Time is required")
				.matches(timeFormatRegex, 'Invalid time format, must contain :')
				.test(
					'to-after-from',
					"Time must be later than 'From' time",
					function (value, context) {
						const { from } = context.parent;
						return compareTimes(from, value as string)
							? true
							: this.createError({
									message: "Time must be later than 'From' time",
									path: `${menuTitle}.to`,
							  });
					},
				),
		});

	const validationSchema = Yup.object().shape(
		weekDays.reduce<Record<string, any>>((acc, menu) => {
			if (localDays.includes(menu.variant)) {
				acc[menu.title] = timeEntrySchema(menu.title);
			}
			return acc;
		}, {}),
	);

	const compareTimes = (from: string, to: string) => {
		if (!from || !to) return true;
		const [fromHour, fromMinute] = from.split(':').map(Number);
		const [toHour, toMinute] = to.split(':').map(Number);
		return fromHour < toHour || (fromHour === toHour && fromMinute < toMinute);
	};

	const formik = useFormik<FormValues>({
		initialValues: weekDays.reduce(
			(acc, menu) => ({
				...acc,
				[menu.title]: { from: '', to: '' },
			}),
			{},
		),
		validationSchema,
		validateOnBlur: false,
		validateOnChange: true,
		onSubmit: (values) => {
			handleDataFromChild(values as unknown as VariantInterface[]);
			formik.resetForm();
		},
	});

	useEffect(() => {
		let defaultFrom = '';
		let defaultTo = '';

		if (workHours?.variant === 'working_hours') {
			defaultFrom = '09:00';
			defaultTo = '18:00';
		} else if (workHours?.variant === 'full_time') {
			defaultFrom = '00:00';
			defaultTo = '23:59';
		}

		const newValues = weekDays.reduce((acc, menu) => {
			const dayInLocalDays = localDays.includes(menu.variant);

			if (
				workHours?.variant &&
				workHours.variant !== 'custom' &&
				dayInLocalDays
			) {
				return {
					...acc,
					[menu.title]: {
						from: defaultFrom,
						to: defaultTo,
					},
				};
			}

			if (
				workHours?.variant === 'custom' &&
				selectedDays?.variant === 'custom'
			) {
				// @ts-ignore
				const customTimes = selectedDays?.submenu?.[menu.title];
				if (customTimes) {
					return {
						...acc,
						[menu.title]: {
							from: customTimes.from,
							to: customTimes.to,
						},
					};
				}
			}

			return {
				...acc,
				[menu.title]: {
					from: dayInLocalDays ? defaultFrom : '',
					to: dayInLocalDays ? defaultTo : '',
				},
			};
		}, {});

		formik.setValues(newValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDays, weekDays, workHours, localDays, formik.setValues]);

	const handleFormValidation = () => {
		const touchedFields = weekDays.reduce(
			(acc, menu) => ({
				...acc,
				[menu.title]: { from: true, to: true },
			}),
			{},
		);

		formik.setTouched(touchedFields);
		formik.validateForm().then((errors) => {
			const filteredErrors = Object.keys(errors).filter((key) =>
				localDays.includes(
					weekDays.find((menu) => menu.title === key)?.variant as string,
				),
			);
			if (filteredErrors.length === 0) {
				// Only include selected days in the submission
				const selectedValues = Object.entries(formik.values).reduce(
					(acc, [key, value]) => {
						const day = weekDays.find((menu) => menu.title === key);
						if (day && localDays.includes(day.variant)) {
							return { ...acc, [key]: value };
						}
						return acc;
					},
					{},
				);

				handleDataFromChild(selectedValues as VariantInterface[]);
			}
		});
	};

	const toggleDay = (day: string) => {
		setLocalDays((prevItems) => {
			if (prevItems.includes(day) && prevItems.length === 1) {
				toastVariant(
					t(
						`workingTimeModal.oneDayMustBeSelected`,
						'At least one day must be selected.',
					),
					true,
					'last-day-error',
				);
				return prevItems;
			}

			// When toggling a day, inherit the current workHours times if they exist
			let defaultFrom = '';
			let defaultTo = '';
			if (workHours?.variant === 'working_hours') {
				defaultFrom = '09:00';
				defaultTo = '18:00';
			} else if (workHours?.variant === 'full_time') {
				defaultFrom = '00:00';
				defaultTo = '23:59';
			}

			const newDays = prevItems.includes(day)
				? prevItems.filter((item) => item !== day)
				: [...prevItems, day];

			// Update form values for the toggled day
			const dayTitle = weekDays.find((menu) => menu.variant === day)?.title;
			if (dayTitle) {
				formik.setFieldValue(dayTitle, {
					from: newDays.includes(day) ? defaultFrom : '',
					to: newDays.includes(day) ? defaultTo : '',
				});
			}

			return newDays;
		});
	};

	const handleClose = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal-inside w-[650px] border border-solid border-gray-10 bg-white outline-none rounded-xl"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col h-full w-full">
				<div className="flex items-center justify-between p-3 border-0 border-solid border-b-[1px] border-gray-10">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="workingTimeModal.customWorkingTime">
								Custom working time
							</T>
						</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>

				<div className="flex flex-col gap-3 items-center justify-between pt-3">
					<div className="flex flex-col items-center px-3">
						<div className="flex items-center">
							<div className="w-[180px] flex flex-col gap-[6px]">
								<SypacText variant="body-regular-medium">
									<p className="text-base text-gray-80">
										<T keyName="workingTimeModal.timezone">Timezone</T>
									</p>
								</SypacText>

								<SypacText variant="body-regular-medium">
									<p className="text-xs text-gray-60">
										<T keyName="workingTimeModal.setYourTimeZone">
											Set your time zone
										</T>
									</p>
								</SypacText>
							</div>

							<div
								className="w-[444px] h-[44px] flex justify-between items-center border border-solid border-gray-20 py-2.5 pl-3 pr-5 rounded-lg transition hover:border-gray-40 box-border"
								// onClick={() => {}}
							>
								<SypacText variant="body-normal-small">
									<p className="text-gray-80">(UTC-08:00) Pacific Time</p>
								</SypacText>
								<span className={`flex my-auto transition`}>
									<ChevronRightIcon />
								</span>
							</div>
						</div>

						<div className="w-full border-0 border-solid border-t-[1px] border-gray-10 mt-2" />
					</div>

					<form className="flex flex-col gap-3">
						{weekDays.map(({ title, variant }) => {
							const isDisabled = !localDays.includes(variant);

							return (
								<div key={variant} className="flex justify-between">
									<div className="w-[180px] flex gap-5 items-center">
										<button
											type="button"
											className={`relative flex w-[32px] h-[14px] border-none rounded-full items-center transition duration-300 ease-in-out cursor-pointer ${
												!isDisabled ? 'bg-secondary-violet' : 'bg-gray-10'
											}`}
											onClick={() => toggleDay(variant)}
										>
											<div
												className={`absolute box-border left-0 w-[20px] h-[20px] rounded-full bg-white border-[2px] border-solid cursor-pointer transition-transform duration-300 ease-in-out ${
													!isDisabled
														? 'translate-x-[14px] border-secondary-violet'
														: '-translate-x-[2px] border-gray-10'
												}`}
											/>
										</button>

										<div className="h-[22px] border-0 border-solid border-l-[1px] border-gray-10" />

										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">
												<T keyName={`workingTimeModal.${variant}`}>{title}</T>
											</p>
										</SypacText>
									</div>

									{!isDisabled ? (
										<div className="w-[444px] flex gap-3 items-center">
											<SypacInput
												size="sm"
												className="w-full"
												error={
													!!(
														formik.touched[title]?.from &&
														formik.errors[title]?.from
													)
												}
											>
												<input
													className="h-[44px] px-2.5 placeholder:text-base placeholder:text-gray-40"
													type="text"
													value={formik.values[title].from}
													placeholder={t('menuItems.from', 'From')}
													onChange={(e) =>
														formik.setFieldValue(
															`${title}.from`,
															e.target.value,
														)
													}
													disabled={isDisabled}
												/>

												{/* <SypacText
													variant="body-regular-medium"
													className="absolute top-[10px] right-[10px] z-10"
												>
													<p className="text-gray-80">
														{formik.values[title].from}
													</p>
												</SypacText> */}
											</SypacInput>

											<SypacInput
												size="sm"
												className="w-full"
												error={
													!!(
														formik.touched[title]?.to &&
														formik.errors[title]?.to
													)
												}
											>
												<input
													className="h-[44px] px-2.5 placeholder:text-gray-40"
													type="text"
													value={formik.values[title].to}
													placeholder={t('menuItems.to', 'To')}
													onChange={(e) =>
														formik.setFieldValue(`${title}.to`, e.target.value)
													}
													disabled={isDisabled}
												/>
											</SypacInput>
										</div>
									) : (
										<div className="w-[444px] flex gap-3 items-center border border-solid border-gray-10 bg-gray-1 rounded-lg p-2.5 box-border">
											<MoonIcon />
											<SypacText variant="body-regular-medium">
												<p className="text-gray-40">
													<T keyName="workingTimeModal.closed">Closed</T>
												</p>
											</SypacText>
										</div>
									)}
								</div>
							);
						})}
					</form>

					<div className="flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl mt-auto">
						<SypacButton
							variant="secondary"
							size="small"
							className="w-full pl-2.5 xl-2xl:pl-3"
						>
							<button
								type="reset"
								className="w-full py-2.5 rounded-lg"
								onClick={() => handleClose()}
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="workingTimeModal.close">Close</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton
							variant="primary"
							size="small"
							className="w-full pr-2.5 xl-2xl:pr-3"
						>
							<button
								type="button"
								onClick={() => handleFormValidation()}
								className="w-full py-2.75 rounded-lg"
								disabled={false}
							>
								<Checkmark />
								<SypacText variant="body-regular-medium">
									<p className="ml-3 text-white">
										<T keyName="modalSelfPickup.confirm">Confirm</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default WorkingTimeModal;
