import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import {
	InvoiceLinesResponse,
	RequestInvoiceListDto,
} from '../interfaces/invoice.interface';

type UseGetInvoiceLines = [
	InvoiceLinesResponse | undefined,
	Error | undefined,
	boolean,
];

export const useGetInvoicesLines = ({
	limit,
	offset,
	orderId,
	status,
	target = 'producer',
	search,
	refresh = 0,
}: Partial<RequestInvoiceListDto>): UseGetInvoiceLines => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	let statuses = Array.isArray(status) ? status : [status];

	const params: Record<string, any> = {
		limit,
		orderId,
		statuses,
		offset,
		search,
	};

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/${target}/invoice-lines`, {
				params,
			})
			.then((response) => {
				setData(response.data);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facade, limit, orderId, status, offset, target, search, refresh]);

	return [data, error, isLoading];
};
