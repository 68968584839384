import React, { useContext, useMemo, useState } from 'react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import FulfilmentSchedule from '../../AdditionalDetails/FulfilmentSchedule';
import PickUpDeliveryDetails from '../../AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import TruckDetailsMap from '../../AdditionalDetails/TruckDetailsMap';
import { AdminOrderProps } from '../../../adminOrder.interface';
import { OrderDetailsContext } from '../../../../../../context/OrderDetailsContext/order-details.context';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { PaymentNotesStatusesEnum } from '../../../../../../services/billing.service';
import OrderDetailsInvoice from '../../../../../../components/OrderDetailsInvoice/OrderDetailsInvoice';

const TransporterConfirmed: React.FC<AdminOrderProps> = ({ selectedOrder }) => {
	const { producerInvoice, carrierInvoice, customerInvoice, actionFor } =
		useContext(OrderDetailsContext);
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const simpleOrder = useMemo(() => {
		return selectedOrder?.trucks?.length === 1;
	}, [selectedOrder?.trucks]);

	const deliveryIncluded = useMemo(() => {
		return selectedOrder?.deliveryIncluded;
	}, [selectedOrder?.deliveryIncluded]);

	const currentInvoice = useMemo(() => {
		const invoices = {
			[OrderTarget.producer]: producerInvoice,
			[OrderTarget.customer]: customerInvoice,
			[OrderTarget.transporter]: carrierInvoice,
		};
		return invoices[actionFor as keyof typeof invoices] ?? null;
	}, [actionFor, producerInvoice, customerInvoice, carrierInvoice]);

	const currentStep = useMemo(() => {
		const baseStep = 3;

		if (currentInvoice?.status === PaymentNotesStatusesEnum.PENDING_INVOICE) {
			return baseStep + 1;
		}

		if (currentInvoice?.status === PaymentNotesStatusesEnum.UPLOADED_INVOICE) {
			return baseStep + 2;
		}

		if (currentInvoice?.status === PaymentNotesStatusesEnum.INVOICE_CONFIRMED) {
			return baseStep + 3;
		}

		if (currentInvoice?.status === PaymentNotesStatusesEnum.PAID) {
			return baseStep;
		}

		return baseStep;
	}, [currentInvoice]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={currentStep}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{actionFor !== OrderTarget.customer &&
				[
					PaymentNotesStatusesEnum.UPLOADED_INVOICE,
					PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
				].includes(currentInvoice?.status as PaymentNotesStatusesEnum) && (
					<div className="px-10 pt-7 pb-3.5">
						<OrderDetailsInvoice
							currentStatus={currentInvoice?.status!}
							target={OrderTarget.admin}
							order={selectedOrder}
						/>
					</div>
				)}

			{!simpleOrder ? (
				<div className="px-10 pt-7 pb-3.5">
					<TruckDetailsMap selectedOrder={selectedOrder} />
				</div>
			) : null}

			<div className={`px-10 ${simpleOrder ? 'pt-7 pb-3.5' : 'py-3.5'}`}>
				{deliveryIncluded ? (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={3}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={simpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={simpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>

			<div className="px-10 py-3.5">
				{simpleOrder && deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={simpleOrder}
					/>
				) : !deliveryIncluded ? (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={simpleOrder}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				) : !simpleOrder && deliveryIncluded ? (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={false}
						simpleOrder={simpleOrder}
					/>
				) : (
					<PickUpDeliveryDetails
						selectedOrder={selectedOrder}
						target={selectedOrder?.target}
						isPickUp={true}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>

			<div className="px-10 pb-7 pt-3.5">
				{deliveryIncluded ? (
					<BaseOrderDetails
						selectedOrder={selectedOrder}
						isOpen={true}
						simpleOrder={simpleOrder}
						deliveryIncluded={deliveryIncluded!}
						target={selectedOrder?.target!}
					/>
				) : (
					<FulfilmentSchedule
						selectedOrder={selectedOrder}
						step={3}
						deliveryIncluded={deliveryIncluded!}
						simpleOrder={simpleOrder}
					/>
				)}
			</div>
		</>
	);
};

export default TransporterConfirmed;
