import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import { NotificationsService } from '../../../services/notifications.services';
import Emitter, { EventType } from '../../../services/events';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import {
	AdminOrderStatusesEnum,
	OrderStatesEnum,
	OrderStatusesEnum,
} from '../../../services/orders.services';
import AssignOrder from './components/Steps/AssignOrder';
import TransporterConfirmed from './components/Steps/TransporterConfirmed';
import AwaitingTransport from './components/Steps/AwaitingTransport';
import TruckConfirmed from './components/Steps/TruckConfirmed';
import Delivered from './components/Steps/Delivered';
import ToLoading from './components/Steps/ToLoading';
import Timeline from './components/Timeline';
import DocumentsTab from '../../../components/DocumentsTab/DocumentsTab';
import FillerStep from './components/Steps/FillerStep';
import PendingPayment from './components/Steps/PendingPayment';
import { OrderDetailsProps } from './adminOrder.interface';
import TabButton from '../../../components/OrderDetailsTabButton/OrderDetailsTabButton';
import { TabConfig } from '../../../components/OrderDetailsTabButton/OrderDetailsTabButton.interface';
import { BillingService } from '../../../services/billing.service';
import { OrderDetailsContext } from '../../../context/OrderDetailsContext/order-details.context';
import PaymentsTab from './components/PaymentsTab';

const TAB_CONFIG: Record<string, TabConfig> = {
	overview: {
		icon: 'Document Text',
		label: 'tabButton.general',
		translatedLabel: 'General',
	},
	documents: {
		icon: 'Folder',
		label: 'tabButton.documents',
		translatedLabel: 'Documents',
	},
	events: {
		icon: 'Bell',
		label: 'tabButton.events',
		translatedLabel: 'Events',
	},
	// payments: {
	// 	icon: 'Folder',
	// 	label: 'tabButton.payments',
	// 	translatedLabel: 'Payments',
	// },
} as const;

const OrderDetails: React.FC<OrderDetailsProps> = ({
	isOpen = false,
	selectedOrder,
	position = 'absolute',
}) => {
	const { setCustomerInvoice, setProducerInvoice, setCarrierInvoice } =
		useContext(OrderDetailsContext);
	const [order, setOrder] = useState<OrderInterface | undefined>(selectedOrder);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isInitialized, setIsInitialized] = useState<boolean>(false);
	const [currentTab, setCurrentTab] =
		useState<keyof typeof TAB_CONFIG>('overview');

	const notifications = useMemo(
		() => selectedOrder?.notifications?.items ?? [],
		[selectedOrder?.notifications?.items],
	);

	const getInvoice = useCallback(async () => {
		if (
			!selectedOrder?.id ||
			![
				OrderStatusesEnum.PAID,
				OrderStatusesEnum.PENDING_PAYMENT,
				OrderStatusesEnum.DELIVERED,
				AdminOrderStatusesEnum.TRANSPORTER_CONFIRMED,
				AdminOrderStatusesEnum.AWAITING_CONFIRMATION,
			].includes(selectedOrder.status)
		) {
			setIsLoading(false);
			setIsInitialized(true);
			return;
		}

		try {
			const invoicePromises = [
				BillingService.getInvoiceForAdmin(
					OrderTarget.customer,
					selectedOrder.id,
				),
				BillingService.getInvoiceForAdmin(
					OrderTarget.producer,
					selectedOrder.id,
				),
			];

			if (selectedOrder.deliveryIncluded) {
				invoicePromises.push(
					BillingService.getInvoiceForAdmin(
						OrderTarget.transporter,
						selectedOrder.id,
					),
				);
			}

			const results = await Promise.allSettled(invoicePromises);

			if (results[0].status === 'fulfilled') {
				setCustomerInvoice(results[0].value.data);
			}

			if (results[1].status === 'fulfilled') {
				setProducerInvoice(results[1].value.data);
			}

			if (
				selectedOrder.deliveryIncluded &&
				results[2]?.status === 'fulfilled'
			) {
				setCarrierInvoice(results[2].value.data);
			}
		} catch (error) {
		} finally {
			setIsLoading(false);
			setIsInitialized(true);
		}
	}, [
		selectedOrder,
		setCustomerInvoice,
		setCarrierInvoice,
		setProducerInvoice,
	]);

	const markReadNotifications = useCallback(async () => {
		const notificationIds = selectedOrder?.notifications?.items
			?.filter((r) => !r.readAt)
			?.map((r) => r.id);
		try {
			if (notificationIds?.length) {
				await NotificationsService.readNotify(notificationIds);
				Emitter.emit(EventType.NOTIFICATION_COUNT_DECREASE, 1);
			}
			Emitter.emit(
				EventType.ORDER_NOTIFICATION_COUNT_DECREASE,
				selectedOrder?.id!,
			);
		} catch (e) {}
	}, [selectedOrder]);

	const selectOrder = (order: OrderInterface) => {
		setOrder(order);
	};

	useEffect(() => {
		setOrder(selectedOrder);
		setCurrentTab('overview');
	}, [selectedOrder]);

	useEffect(() => {
		if (isOpen) {
			getInvoice().then(() => {});
		}
	}, [getInvoice, isOpen]);

	useEffect(() => {
		const refreshHandler = () => getInvoice();
		Emitter.on(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		return () => {
			Emitter.off(EventType.PAYMENT_NOTE_REFRESH, refreshHandler);
		};
	}, [getInvoice]);

	useEffect(() => {
		if (selectedOrder?.id) {
			markReadNotifications().then(() => {});
		}
	}, [markReadNotifications, selectedOrder?.id]);

	const renderSimpleSteps = (order: OrderInterface) => {
		const { state, status } = order;

		if (status === OrderStatusesEnum.PAID) {
			return <Delivered selectedOrder={order} />;
		}

		if (
			order?.trucks?.[0].driver &&
			state === OrderStatesEnum.TRANSPORTER_CONFIRMED
		) {
			return <TruckConfirmed selectedOrder={order} />;
		}

		switch (state) {
			case OrderStatesEnum.TO_DO:
				return <AssignOrder selectedOrder={order} setOrder={selectOrder} />;
			case OrderStatesEnum.AWAITING_CONFIRMATION:
				return <AwaitingTransport selectedOrder={order} />;
			case OrderStatesEnum.TRANSPORTER_CONFIRMED:
				return <TransporterConfirmed selectedOrder={order} />;
			case OrderStatesEnum.TRUCK_CONFIRMED:
				return <TruckConfirmed selectedOrder={order} />;
			case OrderStatesEnum.TO_LOADING:
				return <ToLoading selectedOrder={order} />;
			case OrderStatesEnum.ARRIVED_TO_LOADING:
			case OrderStatesEnum.LOADING:
				return <FillerStep selectedOrder={order} currentStep={6} />;
			case OrderStatesEnum.LOADED:
			case OrderStatesEnum.TO_UNLOADING:
				return <FillerStep selectedOrder={order} currentStep={7} />;
			case OrderStatesEnum.ARRIVED_TO_UNLOADING:
				return <FillerStep selectedOrder={order} currentStep={8} />;
			case OrderStatesEnum.UNLOADING:
				return <FillerStep selectedOrder={order} currentStep={9} />;
			case OrderStatesEnum.UNLOADED:
				return <FillerStep selectedOrder={order} currentStep={10} />;
			case OrderStatesEnum.COMPLETED:
				return <PendingPayment selectedOrder={order} />;
			default:
				return null;
		}
	};

	const renderMultipleSteps = (order: OrderInterface) => {
		const { state, status } = order;

		const isStateInRange = [
			OrderStatesEnum.TRUCK_CONFIRMED,
			OrderStatesEnum.TO_LOADING,
			OrderStatesEnum.ARRIVED_TO_LOADING,
			OrderStatesEnum.LOADING,
			OrderStatesEnum.LOADED,
			OrderStatesEnum.TO_UNLOADING,
			OrderStatesEnum.ARRIVED_TO_UNLOADING,
			OrderStatesEnum.UNLOADING,
			OrderStatesEnum.UNLOADED,
			OrderStatesEnum.COMPLETED,
		].includes(state);

		if (state === OrderStatesEnum.COMPLETED) {
			if (status === AdminOrderStatusesEnum.DELIVERED) {
				return <PendingPayment selectedOrder={order} />;
			} else if (status === OrderStatusesEnum.PAID) {
				return <Delivered selectedOrder={order} />;
			}
		}

		if (
			isStateInRange &&
			status === AdminOrderStatusesEnum.AWAITING_CONFIRMATION
		) {
			return <TransporterConfirmed selectedOrder={order} />;
		}

		switch (status) {
			case AdminOrderStatusesEnum.TO_DO:
				return <AssignOrder selectedOrder={order} setOrder={selectOrder} />;
			case AdminOrderStatusesEnum.AWAITING_CONFIRMATION:
				return <AwaitingTransport selectedOrder={order} />;
			case AdminOrderStatusesEnum.TRANSPORTER_CONFIRMED:
			case AdminOrderStatusesEnum.TRUCK_CONFIRMED:
			case AdminOrderStatusesEnum.IN_PROGRESS:
			case AdminOrderStatusesEnum.TO_LOADING:
			case AdminOrderStatusesEnum.TO_UNLOADING:
				return <TransporterConfirmed selectedOrder={order} />;

			default:
				return null;
		}
	};

	const renderSimpleExcluded = (order: OrderInterface) => {
		const statusComponentMap: { [key: string]: JSX.Element } = {
			[AdminOrderStatusesEnum.TO_DO]: (
				<AssignOrder selectedOrder={order} setOrder={selectOrder} />
			),
			[AdminOrderStatusesEnum.AWAITING_CONFIRMATION]: (
				<AwaitingTransport selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.IN_PROGRESS]: (
				<TransporterConfirmed selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.TO_LOADING]: (
				<TruckConfirmed selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.DELIVERED]: (
				<PendingPayment selectedOrder={order} />
			),
			[OrderStatusesEnum.PAID]: <Delivered selectedOrder={order} />,
		};

		return statusComponentMap[order.status] || null;
	};

	const renderMultipleExcluded = (order: OrderInterface) => {
		const statusComponentMap: { [key: string]: JSX.Element } = {
			[AdminOrderStatusesEnum.TO_DO]: (
				<AssignOrder selectedOrder={order} setOrder={selectOrder} />
			),
			[AdminOrderStatusesEnum.AWAITING_CONFIRMATION]: (
				<AwaitingTransport selectedOrder={order} />
			),
			[AdminOrderStatusesEnum.IN_PROGRESS]: (
				<FillerStep selectedOrder={order} currentStep={3} />
			),
			[AdminOrderStatusesEnum.TO_LOADING]: (
				<FillerStep selectedOrder={order} currentStep={3} />
			),
			[AdminOrderStatusesEnum.DELIVERED]: (
				<FillerStep selectedOrder={order} currentStep={3} />
			),
		};

		const hasProducerLoadDetails = order?.trucks?.every(
			(obj) => 'producerLoadDetails' in obj,
		);
		const isPaid = order?.status === OrderStatusesEnum.PAID;

		if (hasProducerLoadDetails) {
			return isPaid ? (
				<Delivered selectedOrder={order} />
			) : (
				<PendingPayment selectedOrder={order} />
			);
		}

		return statusComponentMap[order.status] || null;
	};

	const renderOverviewTab = (order: OrderInterface | undefined) => {
		if (!order) return null;
		const { trucks, deliveryIncluded } = order;
		const simpleOrder = trucks?.length === 1;

		return simpleOrder
			? deliveryIncluded
				? renderSimpleSteps(order)
				: renderSimpleExcluded(order)
			: deliveryIncluded
			? renderMultipleSteps(order)
			: renderMultipleExcluded(order);
	};

	return isOpen && isInitialized && !isLoading ? (
		<aside
			className={`${position} top-0 right-0 w-[716px] h-full flex flex-col bg-alabaster shadow-order-details z-[100] overflow-y-auto scroll-smooth rounded-2xl`}
		>
			<nav className="px-10 py-7 flex gap-[3px]">
				{Object.keys(TAB_CONFIG).map((tab) => (
					<TabButton
						key={tab}
						tab={tab}
						isActive={currentTab === tab}
						config={TAB_CONFIG[tab]}
						notificationCount={
							tab === 'events' ? notifications.length : undefined
						}
						onClick={setCurrentTab}
					/>
				))}
			</nav>

			<main className="flex flex-col h-full overflow-x-hidden overflow-y-scroll sypac-scrollbar">
				{currentTab === 'overview' && renderOverviewTab(order)}

				{currentTab === 'events' && <Timeline selectedOrder={selectedOrder} />}

				{currentTab === 'documents' && (
					<section className="flex flex-col gap-8 pt-8 border-0 border-solid border-t-[1px] border-gray-10">
						<DocumentsTab
							selectedOrder={selectedOrder}
							target={OrderTarget.admin}
						/>
					</section>
				)}

				{currentTab === 'payments' && (
					<PaymentsTab selectedOrder={selectedOrder} />
				)}
			</main>
		</aside>
	) : null;
};

export default OrderDetails;
