import React from 'react';
import QuantityCounter from '../QuantityCounter/QuantityCounter';
import { QuantityPickerProps } from './QuantityPicker.interface';

const QuantityPicker: React.FC<QuantityPickerProps> = ({
	counters,
	availableQuantity,
	unlimited,
}) => {
	return (
		<div className="max-w-[534px] h-[344px] xl-2xl:h-[400px] overflow-y-scroll overflow-x-hidden pr-2 sm:mb-[26px] sypac-scrollbar">
			<div className="flex flex-col gap-6.25">
				{counters.map((counter) => (
					<QuantityCounter
						key={counter.id}
						counter={counter}
						availableQuantity={availableQuantity}
						unlimited={unlimited}
					/>
				))}
			</div>
		</div>
	);
};

export default QuantityPicker;
