import React, { useState, useContext, useCallback } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import Close from '../../../../assets/Close';
import { useNavigate } from 'react-router-dom';
import { useGetCategories } from '../../../../hooks/use-get-categories';
import { StepProps } from '../addNewProduct.interface';
import {
	ProductContext,
	ProductProps,
} from '../../../../context/DatabaseProductContext/database-product.context';
import NewAddPlus from '../../../../assets/NewAddPlus';
import AddEditGroupCategory from '../AddEditGroupCategory';
import { Category } from '../../../../interfaces/category.interface';
import EditPenIcon from '../../../../assets/EditPenIcon';
import { Tooltip } from 'react-tooltip';
import NewTrash from '../../../../assets/NewTrash';
import ModalDeleteProduct from '../../ProductDatabase/components/ProductDetailsDatabase/components/ModalDeleteProduct';
import { CategoriesService } from '../../../../services/categories.services';

const ProductGroupStep: React.FC<StepProps> = ({ setStep }) => {
	const { t } = useTranslate();
	const navigate = useNavigate();
	const { product, setProduct } = useContext(ProductContext);
	const [refresh, setRefresh] = useState<number>(0);
	const [groups] = useGetCategories(
		{ countryCode: product?.countryCode },
		'admin',
		refresh,
	);
	const [openNewGroup, setOpenNewGroup] = useState<boolean>(false);
	const [activeGroup, setActiveGroup] = useState<Category | undefined>(
		undefined,
	);
	const [editGroup, setEditGroup] = useState<boolean>(false);
	const [openDelete, setOpenDelete] = useState<boolean>(false);

	const refreshCategories = useCallback(() => {
		setRefresh((prev) => prev + 1);
	}, []);

	const handleNextStep = () => {
		setProduct({
			...product,
			productGroup: activeGroup?.id,
		} as ProductProps);

		setStep((prevStep) => prevStep + 1);
	};

	const handleClose = () => {
		refreshCategories();
		setOpenNewGroup(false);
		setEditGroup(false);
		setActiveGroup(undefined);
	};

	const handleEditGroup = (group: Category) => {
		setOpenNewGroup(true);
		setEditGroup(true);
		setActiveGroup(group);
	};

	const handleDeleteGroup = (group: Category) => {
		setOpenDelete(true);
		setActiveGroup(group);
	};

	const deleteGroup = async (group: Category) => {
		try {
			await CategoriesService.deleteCategory(group.id);
			handleClose();
		} catch (e) {}
	};

	return (
		<>
			<div className="w-full h-full flex items-center justify-center">
				<main className="min-w-[450px] flex flex-col gap-6">
					<section className="flex justify-between items-center">
						<SypacText variant="body-normal-medium">
							<p className="text-xl text-gray-80">
								<T keyName="productGroup.manageProduct">
									Manage product groups and categories
								</T>
							</p>
						</SypacText>
						<SypacButton variant="secondary" size="medium">
							<button
								onClick={() => navigate('/product-database')}
								className="p-2 border border-solid border-gray-10 transition hover:border-primary-violet"
							>
								<Close />
							</button>
						</SypacButton>
					</section>

					<section className="flex items-center justify-between">
						<SypacText variant="body-normal-medium">
							<p className="text-gray-60">
								<T keyName="selectProductStore.selectGroup">
									Select a group to edit.
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex gap-3 items-center p-0 bg-transparent group"
								onClick={() => setOpenNewGroup(true)}
							>
								<NewAddPlus hoverEffect={true} />
								<SypacText variant="body-regular-medium">
									<p className="text-primary-violet mt-[2px] transition group-hover:text-primary-violet/70">
										<T keyName="selectProductGroup.createNewGroup">
											Create new group
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</section>
					<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

					{!openNewGroup ? (
						<div>
							<div
								className={`h-[300px] xl:h-auto grid gap-[20px] grid-cols-${
									groups?.items.length! < 3 ? groups?.items.length : 3
								} pr-3 xl:pr-0 overflow-x-hidden overflow-y-scroll xl:overflow-visible`}
							>
								{groups?.items
									.sort((a, b) => a.name.localeCompare(b.name))
									.map((group) => (
										<div
											key={group.id}
											className={`w-fit flex flex-col gap-3 p-[10px] border border-solid rounded-xl cursor-pointer transition ${
												activeGroup?.id === group.id
													? 'border-cornflower-blue'
													: 'border-gray-10 hover:border-gray-30'
											}`}
											onClick={() => setActiveGroup(group)}
										>
											<div
												style={{
													backgroundImage: `url(${group.imageUrl})`,
												}}
												className="relative w-[204px] h-[128px] xl:w-[248px] xl:h-[174px] bg-no-repeat bg-cover bg-center border border-solid border-gray-10 rounded-10 box-border"
												role="img"
												aria-label={group.name}
											/>

											<section className="flex flex-col gap-3">
												<span className="flex items-center justify-between">
													<SypacText variant="body-regular-medium">
														<p className="w-[126px] xl:w-[184px] truncate text-gray-80">
															{group.name}
														</p>
													</SypacText>

													<div className="flex justify-center gap-[10px]">
														<SypacButton variant="secondary" size="small">
															<button
																type="button"
																className="w-[24px] h-[24px] flex justify-between items-center border-0 bg-gray-10 rounded-lg m-0 p-0 transition hover:bg-gray-20"
																onClick={() => handleEditGroup(group)}
																data-tooltip-id="group-card"
																data-tooltip-content={t(
																	'productGroup.editGroup',
																	'Edit group',
																)}
															>
																<span className="flex scale-[0.8] ml-[1px]">
																	<EditPenIcon color="#454545" />
																</span>
															</button>
														</SypacButton>
														<SypacButton variant="secondary" size="small">
															<button
																type="button"
																className="w-[24px] h-[24px] flex justify-between items-center border-0 bg-red rounded-lg m-0 p-0 transition hover:bg-red/60"
																onClick={() => handleDeleteGroup(group)}
																data-tooltip-id="group-card"
																data-tooltip-content={t(
																	'productGroup.deleteGroup',
																	'Delete group',
																)}
															>
																<span className="flex ml-[3.5px]">
																	<NewTrash color="#FFFFFF" />
																</span>
															</button>
														</SypacButton>
													</div>
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-40">
														{group.countSubcategories}{' '}
														<T keyName="clientSelectGroups.categoriesInThisGroup">
															categories in this group
														</T>
													</p>
												</SypacText>
											</section>
										</div>
									))}
							</div>

							<nav className="flex justify-center w-full mt-3 xl:mt-[40px] gap-3">
								<SypacButton
									variant="secondary"
									size="small"
									className="w-full"
								>
									<button
										type="button"
										className="w-full px-6 py-[10px] rounded-lg"
										onClick={() => setStep((prevStep) => prevStep - 1)}
									>
										<SypacText variant="body-regular-medium">
											<p>
												<T keyName="companyDetails.back">Back</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
								<SypacButton variant="primary" size="small" className="w-full">
									<button
										type="button"
										className="w-full px-6 py-[11px] rounded-lg"
										onClick={handleNextStep}
										disabled={!activeGroup}
									>
										<SypacText
											variant="body-regular-medium"
											className="flex items-center gap-4"
										>
											<p>
												<T keyName="clientSelectQuantity.next">Next</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</nav>
						</div>
					) : (
						<AddEditGroupCategory
							current={editGroup ? activeGroup : undefined}
							type="group"
							onClose={handleClose}
						/>
					)}
				</main>
			</div>
			<ModalDeleteProduct
				isOpen={openDelete}
				onClose={() => setOpenDelete(false)}
				handleDelete={() => {
					deleteGroup(activeGroup!).then(() => {});
				}}
				group={activeGroup}
			/>
			<Tooltip
				place="top"
				id="group-card"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
				}}
			/>
		</>
	);
};
export default ProductGroupStep;
