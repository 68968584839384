import React from 'react';
import Flag from 'react-world-flags';

interface DropDownOptionProps {
	innerProps: React.HTMLProps<HTMLDivElement>;
	innerRef: React.Ref<HTMLDivElement>;
	data: {
		value: string;
		label: string;
	};
}

export const DropDownOption: React.FC<
	DropDownOptionProps & { current?: string }
> = ({ innerProps, innerRef, data, current }) => {
	return (
		<div
			ref={innerRef}
			{...innerProps}
			className="flex flex-col h-14 w-[calc(100%-24px)]"
		>
			<div
				className={`w-full min-w-full flex h-6 bg-white hover:bg-gray-10/50 justify-start items-center p-3 my-1 mx-3 rounded-md cursor-pointer ${
					data.value === current ? 'bg-gray-10' : ''
				}`}
			>
				<Flag code={data.value} className="rounded" width={48} height={32} />
				<div className="ml-2 text-base text-gray-100 truncate">
					{data.label}
				</div>
			</div>
		</div>
	);
};
