import React, { useContext } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { OrderTarget } from '../../interfaces/OrderStatus.interface';
import {
	adminTexts,
	carrierTexts,
	customerTexts,
	producerTexts,
} from './no-results.constants';
import { AuthContext } from '../../../../context/context';
import { useGetFleet } from '../../../../hooks/use-get-fleet';
import { useGetProducts } from '../../../../hooks/use-get-products';
import { StackThin } from '../../../../assets/StackThin';
import { useNavigate } from 'react-router-dom';

interface EmptyPlaceholderProps {
	currentTab: string;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({ currentTab }) => {
	const { user } = useContext(AuthContext);
	const userIndustry = user?.company.industries[0];
	const navigate = useNavigate();

	const [fleet] =
		userIndustry === OrderTarget.transporter
			? // eslint-disable-next-line react-hooks/rules-of-hooks
			  useGetFleet({ companyId: +user?.company.id! })
			: [{ count: undefined }];

	const [products] =
		userIndustry === OrderTarget.producer
			? // eslint-disable-next-line react-hooks/rules-of-hooks
			  useGetProducts(
					'all',
					undefined,
					100,
					0,
					'producer',
					user?.profile?.countryCode!,
			  )
			: [{ count: undefined }];

	const getTexts = (target: string, currentTab: string) => {
		let mainText = '';
		let subText = '';

		switch (target) {
			case OrderTarget.transporter:
				mainText =
					fleet?.count === 0
						? carrierTexts.main.noFleet
						: carrierTexts.main[currentTab as keyof typeof carrierTexts.main];
				subText =
					fleet?.count === 0
						? carrierTexts.sub.noFleet
						: carrierTexts.sub[currentTab as keyof typeof carrierTexts.sub];
				break;
			case OrderTarget.producer:
				mainText =
					products?.count === 0
						? producerTexts.main.noProducts
						: producerTexts.main[currentTab as keyof typeof producerTexts.main];
				subText =
					products?.count === 0
						? producerTexts.sub.noProducts
						: producerTexts.sub[currentTab as keyof typeof producerTexts.sub];
				break;
			case OrderTarget.customer:
				mainText =
					customerTexts.main[currentTab as keyof typeof customerTexts.main];
				subText =
					customerTexts.sub[currentTab as keyof typeof customerTexts.sub];
				break;
			default:
				mainText = adminTexts.main[currentTab as keyof typeof adminTexts.main];
				break;
		}

		return { mainText, subText };
	};
	const { mainText, subText } = getTexts(
		user?.company.industries[0]!,
		currentTab!,
	);

	return (
		<div className="h-full flex flex-col gap-[60px] justify-center items-center">
			<StackThin />

			<div
				className={`flex flex-col gap-6 ${
					user?.company.industries[0] === OrderTarget.producer
						? 'w-[423px]'
						: user?.company.industries[0] === OrderTarget.customer &&
						  currentTab === 'all'
						? 'w-[339px]'
						: 'w-fit'
				}`}
			>
				<div className="flex flex-col gap-6 items-center">
					<SypacText variant="body-regular-medium">
						<p className="text-2xl leading-7 text-gray-80">
							<T keyName={`ordersTable.${mainText}`}>{mainText}</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p
							className={`text-sm text-gray-40 ${
								['producer', 'customer'].includes(user?.company.industries[0]!)
									? 'text-center'
									: ''
							} ${
								user?.company.industries[0] === OrderTarget.customer
									? currentTab === 'all'
										? 'w-[266px]'
										: currentTab === 'preparing'
										? 'w-[500px]'
										: currentTab === 'delivered'
										? 'w-[460px]'
										: ''
									: ''
							}`}
							style={{
								whiteSpace: ['producer', 'customer'].includes(
									user?.company.industries[0]!,
								)
									? 'break-spaces'
									: 'normal',
							}}
						>
							{subText && <T keyName={`ordersTable.${subText}`}>{subText}</T>}
						</p>
					</SypacText>
				</div>

				{user?.company.industries[0] === OrderTarget.transporter &&
				fleet?.count === 0 ? (
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className="w-full flex py-[11px] border-none rounded-lg bg-primary-violet transition hover:bg-primary-violet/80"
							onClick={() => navigate('/fleet')}
						>
							<SypacText variant="body-normal-medium">
								<p className="text-white">
									<T keyName="noResults.addTrucks">Add trucks</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				) : user?.company.industries[0] === OrderTarget.producer &&
				  products?.count === 0 ? (
					<>
						<div className="flex justify-center items-center px-3 py-[11px] border border-solid border-gray-10 rounded-10 bg-gray-10-opacity-50">
							<SypacText variant="body-normal-small">
								<p
									className="text-gray-40 text-center"
									style={{
										whiteSpace: 'break-spaces',
									}}
								>
									<T keyName="ordersTable.noteAllProductsWillBeReviewed">
										Note: All added products will be reviewed by the SYPAC team
										before they’re displayed in the store.
									</T>
								</p>
							</SypacText>
						</div>

						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className="w-full flex py-[11px] border-none rounded-lg bg-primary-violet transition hover:bg-primary-violet/80"
								onClick={() => navigate('/stores')}
							>
								<SypacText variant="body-normal-medium">
									<p className="text-white">
										<T keyName="ordersTable.addNewProduct">Add new product</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</>
				) : null}
				{user?.company.industries[0] === OrderTarget.customer &&
				currentTab === 'all' ? (
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							className="w-full flex py-[11px] border-none rounded-lg bg-primary-violet transition hover:bg-primary-violet/80"
							onClick={() => navigate('/store')}
						>
							<SypacText variant="body-normal-medium">
								<p className="text-white">
									<T keyName="ordersTable.viewStoreCustomer">View store</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				) : null}
			</div>
		</div>
	);
};

export default EmptyPlaceholder;
