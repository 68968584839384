import React, { useState } from 'react';
import Flag from 'react-world-flags';
import {
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { T, useTranslate } from '@tolgee/react';
import { SearchLocationProps } from '../clientSelectLocation.interface';
import { Geo } from '../../../../interfaces/geo.interface';

const SearchLocation: React.FC<SearchLocationProps> = ({
	onChange,
	locations,
	selectLocation,
	inputName,
}) => {
	const { t } = useTranslate();
	const [expandResults, setExpandResults] = useState<boolean>(false);

	const inputRef = useDetectClickOutside({
		onTriggered: () => setExpandResults(false),
	});

	const handleLocationSelect = (location: Geo) => {
		selectLocation(location);
		setExpandResults(false);
	};

	const LocationItem = ({
		location,
		onSelect,
	}: {
		location: Geo;
		onSelect: (location: Geo) => void;
	}) => (
		<div
			className="flex items-center bg-white border border-solid border-transparent rounded-lg cursor-pointer hover:bg-gray-10-opacity-50 hover:border-gray-10 mr-[23px] 3xl:mr-2"
			onClick={() => onSelect(location)}
		>
			<span className="mx-5">
				<Flag
					className="object-cover rounded border border-solid border-gray-10"
					code={location.countryCode.toUpperCase()}
					width={22}
					height={16}
				/>
			</span>
			<SypacText variant="body-normal-medium" className="my-[15px]">
				<span>
					{location.address?.city}, {location.address?.street} str.
					{location.address?.houseNumber &&
						` ${location.address.houseNumber},`}{' '}
					Zip {location.address?.postalCode}
				</span>
			</SypacText>
		</div>
	);

	return (
		<div className="relative w-full group" ref={inputRef}>
			<SypacInput>
				<input
					name={inputName}
					autoComplete="off"
					type="text"
					className="w-full h-[42px] 2xl:h-[60px] outline-none border border-gray-10 rounded-10 pl-[54px] pr-3 truncate text-gray-80 placeholder:text-gray-40 transition hover:border-gray-30 focus:border-cornflower-blue"
					placeholder={t(
						'searchLocation.enterThePlaceWhere',
						'Enter the place where you would like to receive your products',
					)}
					onFocus={() => setExpandResults(true)}
					onChange={(e) => onChange(e.target.value)}
				/>
			</SypacInput>

			<SypacIcon
				icon-name="Map Point"
				className="absolute top-1 2xl:top-3 left-3 text-gray-40"
				size="custom"
				width="30px"
				height="30px"
			/>

			{expandResults && (
				<div
					className={`flex-col absolute top-[62px] md:top-[52px] 2xl:top-[70px] w-full max-h-72 bg-white border border-solid border-gray-10 rounded-lg overflow-hidden z-50 box-border shadow-drop-shadow-expanded ${
						expandResults ? 'flex' : 'hidden'
					}`}
				>
					{locations?.length ? (
						<nav className="flex flex-col w-full p-3 shadow-drop-shadow-expanded overflow-y-scroll">
							{locations.map((location) => (
								<LocationItem
									key={location.locationId}
									location={location}
									onSelect={handleLocationSelect}
								/>
							))}
						</nav>
					) : (
						<div className="flex items-center rounded-lg px-5">
							<SypacText variant="body-normal-medium" className="my-[15px]">
								<p>
									<T keyName="searchLocationInput.noResults">
										No results found
									</T>
								</p>
							</SypacText>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default SearchLocation;
