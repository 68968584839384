import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SypacText } from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import GroupCard from '../../../components/GroupCard/GroupCard';
import ClientStoreBreadcrumbs from '../../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { useGetCategories } from '../../../hooks/use-get-categories';
import { GeoService } from '../../../services/geo.services';
import { CompanyService } from '../../../services/company.services';
import { toastVariant } from '../../../components/ToastVariant/toastVariant';
import '../select-product.scss';

const ClientSelectGroups: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { order } = useContext(OrderContext);

	const [coordinates, setCoordinates] = useState<number[]>([]);
	const [userCountry, setUserCountry] = useState<string>();
	const [activeGroup, setActiveGroup] = useState<string>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [groups, _, isLoading] = useGetCategories({
		coordinates,
		countryCode: userCountry,
	});

	const [categories] = useGetCategories({
		parentIds: [activeGroup],
		coordinates,
		countryCode: userCountry,
	});

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			const countryCodeMap: Record<string, string> = {
				MD: 'MDA',
				PL: 'POL',
			};
			setUserCountry(countryCodeMap[data.countryCode] || '');
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	const getLocation = useCallback(async (locationId: string) => {
		if (locationId) {
			const { data } = await GeoService.getLocationDetails({
				locationid: locationId,
			});
			setCoordinates([data.Longitude, data.Latitude]);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	useEffect(() => {
		if (order?.location?.locationId) {
			getLocation(order?.location?.locationId).then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.location?.locationId]);

	useEffect(() => {
		if (activeGroup && categories?.items?.length) {
			navigate(`${activeGroup}/categories/${categories.items[0].id}/products`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeGroup, categories?.items]);

	return (
		<main className="w-full flex flex-col lg:gap-4 gap-x-2.5 h-[calc(100vh-60px)] overflow-x-hidden xl:overflow-x-auto mt-[68px] sm:mt-0">
			<header className="mx-3 lg:ml-5 xl:ml-0 my-3 lg:my-0 lg:w-[1132px] xl:self-center flex justify-between items-center xl:mb-4">
				<ClientStoreBreadcrumbs />
			</header>
			<div className="border-0 border-solid border-t-[1px] border-gray-10" />

			<section className="ml-3 lg:ml-5 xl:ml-0 w-[1132px] xl:self-center flex flex-col gap-4">
				<SypacText
					variant="body-regular-medium"
					className="hidden lg:flex mt-4"
				>
					<p className="text-xl">
						<T keyName="clientSelectGroups.selectProductGroup">
							Select product group:
						</T>
					</p>
				</SypacText>

				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center mt-16">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							visible={true}
						/>
					</div>
				) : (
					<div className="mt-3 grid gap-6 md:grid-cols-[repeat(3,minmax(0,261px))] xl:grid-cols-4">
						{groups?.items?.map((item) => (
							<GroupCard
								key={item.id}
								imageUrl={item.imageUrl}
								title={item.name}
								description={`${item.countSubcategories} ${t(
									'clientSelectGroups.categoriesInThisGroup',
									'categories in this group',
								)}`}
								onClick={() => setActiveGroup(item.id)}
							/>
						))}
					</div>
				)}
			</section>
		</main>
	);
};

export default ClientSelectGroups;
