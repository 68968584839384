export const StatusColors: {
	[key: string]: { color: string; label: string };
} = {
	canceled: { color: 'red', label: 'statuses.canceled' },
	accepted: { color: 'yellow', label: 'statuses.inProgress' },
	sent_to_driver: { color: 'yellow', label: 'statuses.inProgress' },
	preparing: { color: 'yellow', label: 'statuses.inProgress' },
	created: { color: 'blue', label: 'statuses.reviewing' },
	requested: { color: 'red', label: 'statuses.toDo' },
	shipped: { color: 'orange', label: 'statuses.shipped' },
	arrived_to_unloading: { color: 'yellow', label: 'statuses.inProgress' },
	to_do: { color: 'red', label: 'statuses.toDo' },
	completed: { color: 'violet', label: 'statuses.completed' },
	paid: { color: 'violet', label: 'statuses.completed' },
	in_progress: { color: 'yellow', label: 'statuses.inProgress' },
	loaded: { color: 'yellow', label: 'statuses.inProgress' },
	confirm_pick_up: { color: 'yellow', label: 'statuses.inProgress' },
	loading: { color: 'yellow', label: 'statuses.inProgress' },
	awaiting_confirmation: { color: 'yellow', label: 'statuses.inProgress' },
	transporter_confirmed: { color: 'yellow', label: 'statuses.inProgress' },
	truck_confirmed: { color: 'yellow', label: 'statuses.inProgress' },
	to_loading: { color: 'yellow', label: 'statuses.inProgress' },
	to_unloading: { color: 'yellow', label: 'statuses.inProgress' },
	pick_up: { color: 'yellow', label: 'statuses.inProgress' },
	delivering: { color: 'yellow', label: 'statuses.inProgress' },
	delivered: { color: 'green', label: 'statuses.delivered' },
	available: { color: 'green', label: 'statuses.available' },
	pending_payment: { color: 'green', label: 'statuses.delivered' },
	approved: { color: 'green', label: 'statuses.approved' },
	pending: { color: 'yellow', label: 'statuses.pending' },
	rejected: { color: 'red', label: 'statuses.rejected' },
	pending_invoice: { color: 'yellow', label: 'statuses.pendingInvoice' },
	uploaded_invoice: { color: 'yellow', label: 'statuses.uploadedInvoice' },
	invoice_confirmed: { color: 'yellow', label: 'statuses.pendingPayment' },
};

export const StateColors: {
	[key: string]: { color: string; label: string };
} = {
	transporter_confirmed: { color: 'red', label: 'statuses.toDo' },
	truck_confirmed: { color: 'yellow', label: 'statuses.inProgress' },
	arrived_to_loading: { color: 'yellow', label: 'statuses.inProgress' },
	loading: { color: 'yellow', label: 'statuses.inProgress' },
	loaded: { color: 'green', label: 'statuses.delivered' },
	to_unloading: { color: 'green', label: 'statuses.delivered' },
	arrived_to_unloading: { color: 'green', label: 'statuses.delivered' },
	unloading: { color: 'green', label: 'statuses.delivered' },
	unloaded: { color: 'green', label: 'statuses.delivered' },
	completed: { color: 'violet', label: 'statuses.completed' },
	paid: { color: 'violet', label: 'statuses.completed' },
};

export const StatusMultiple: {
	[key: string]: { color: string; label: string };
} = {
	requested: { color: 'yellow', label: 'orderStepper.pendingStartTrip' },
	accepted: { color: 'yellow', label: 'orderStepper.pendingStartTrip' },
	to_loading: {
		color: 'yellow',
		label: 'orderStepper.pendingArrivalAtTheLoadingPlace',
	},
	arrived_to_loading: {
		color: 'yellow',
		label: 'orderStepper.pendingLoadingOfGoods',
	},
	loading: { color: 'yellow', label: 'orderStepper.pendingLoadingOfGoods' },
	loaded: {
		color: 'yellow',
		label: 'orderStepper.pendingArrivalAtTheDestination',
	},
	to_unloading: {
		color: 'yellow',
		label: 'orderStepper.pendingArrivalAtTheDestination',
	},
	arrived_to_unloading: {
		color: 'yellow',
		label: 'orderStepper.pendingUnloadingOfGoods',
	},
	unloading: { color: 'yellow', label: 'orderStepper.pendingUnloadingOfGoods' },
	unloaded: { color: 'yellow', label: 'statuses.unloaded' },
	pending_payment: { color: 'green', label: 'statuses.delivered' },
	completed: { color: 'green', label: 'statuses.delivered' },
};

export const StatusMultipleExcluded: {
	[key: string]: { color: string; label: string };
} = {
	to_do: { color: 'gray', label: 'statuses.toDo' },
	in_progress: { color: 'yellow', label: 'statuses.inProgress' },
	confirm_pick_up: { color: 'yellow', label: 'statuses.inProgress' },
	pending_payment: { color: 'green', label: 'statuses.loaded' },
	paid: { color: 'green', label: 'statuses.loaded' },
};

export const StatusTruckColors: {
	[key: string]: { color: string; label: string };
} = {
	available: { color: 'green', label: 'statuses.available' },
	not_available: { color: 'yellow', label: 'statuses.inProgress' },
};

export const StatusInvoiceColors: {
	[key: string]: { color: string; label: string };
} = {
	pending: { color: 'yellow', label: 'statuses.pendingPayment' },
	pending_invoice: { color: 'yellow', label: 'statuses.pendingInvoiceAdmin' },
	uploaded_invoice: { color: 'yellow', label: 'statuses.uploadedInvoice' },
	invoice_confirmed: { color: 'yellow', label: 'statuses.pendingPayment' },
	paid: { color: 'green', label: 'statuses.paid' },
};

export const StatusIssuesColors: {
	[key: string]: { color: string; label: string };
} = {
	created: { color: 'yellow', label: 'statuses.pendingResolution' },
	ignore: { color: 'green', label: 'statuses.solved' },
	resolve: { color: 'green', label: 'statuses.solved' },
};
