import React, { useCallback, useEffect, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import ProductTabs from './components/ProductTabs/ProductTabs';
import { AvatarItem } from '../../../components/AvatarDropDown/AvatarDropDown.interface';
import AvatarDropDown from '../../../components/AvatarDropDown/AvatarDropDown';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { ProductsTable } from '../../../components/ProductsTable/ProductsTable';
import { MutatingDots } from 'react-loader-spinner';
import Pagination from '../../../components/Pagination/Pagination';
import {
	ProductInterface,
	ProductStatusEnum,
} from '../../../interfaces/product.interface';
import { LIMIT } from '../../../constants';
import {
	EditProduct,
	ProductService,
} from '../../../services/product.services';
import CompanyDetails from '../ComanyDetails';
import ProductDetails from './components/ProductDetails/ProductDetails';
import { UsersServices } from '../../../services/users.services';
import ModalDeleteProduct from '../../../components/ModalDeleteProduct/ModalDeleteProduct';
import ModalStoreProduct from '../../../components/ModalStoreProduct/ModalStoreProduct';
import useDebounce from '../../../hooks/useDebounce';
import { SypacText } from '@sypac/component-library-react';
import CountryBookTabs from '../../../components/CountryBookTabs/CountryBookTabs';
import { Sorting } from '../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';
import { CountryTab } from '../../../components/CountryBookTabs/CountryBookTabs.interface';
import { ProductTab } from './components/ProductTabs/ProductTabs.interface';

const initialTabs = [
	{ label: 'All', color: 'white', count: '0', value: 'all' },
	{ label: 'Approved', color: 'green', count: '0', value: 'approved' },
	{ label: 'Pending approve', color: 'yellow', count: '0', value: 'pending' },
	{ label: 'Rejected', color: 'gray', count: '0', value: 'rejected' },
];
const sortOptions = [
	{ title: 'Sort by ID descending', value: 'id DESC' },
	{ title: 'Sort by ID ascending', value: 'id ASC' },
];

const COUNTRY_TABS: Record<string, CountryTab> = {
	all: {
		key: 'all',
		label: 'adminProducts.allCountries',
		translatedLabel: 'All countries',
	},
	PL: {
		key: 'POL',
		label: 'adminProducts.poland',
		translatedLabel: 'Poland',
		flag: 'PL',
	},
	MD: {
		key: 'MDA',
		label: 'adminProducts.moldova',
		translatedLabel: 'Moldova',
		flag: 'MD',
	},
} as const;

const Products: React.FC = () => {
	const { t } = useTranslate();
	const refs = useRef<(HTMLDivElement | null)[]>([]);

	const [statusTab, setStatusTab] = useState<string>('all');
	const [avatars, setAvatars] = useState<AvatarItem[]>([]);
	const [products, setProducts] = useState<ProductInterface[]>([]);
	const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
		undefined,
	);
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [tabs, setTabs] = useState<ProductTab[]>(initialTabs);
	const [assigneeId, setAssignee] = useState<string>();
	const [page, setPage] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState<string>();
	const [count, setCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<
		ProductInterface | undefined
	>(undefined);
	const [showDetailsProduct, setShowDetailsProduct] = useState<boolean>(false);
	const [showRejectProduct, setShowRejectProduct] = useState<boolean>(false);
	const [showOnStore, setShowOnProduct] = useState<boolean>(false);
	const [countryTab, setCountryTab] = useState<string>('all');
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [productLanguage, setProductLanguage] = useState<string>('en');

	const search = useDebounce(searchQuery, 500);

	const getProductList = useCallback(async () => {
		setLoading(true);
		try {
			const sort = sorting?.value?.split(' ');
			const [sortBy, sortDirection] = sort || [];

			const { data } = await ProductService.getListAdmin({
				status: statusTab,
				limit: LIMIT,
				offset: page * LIMIT,
				verifiedBy: assigneeId,
				countryCode: countryTab,
				search,
				sortDirection,
				sortBy,
			});

			setProducts(data.items);
			setCount(data.count);
		} catch (error) {
			console.error('Failed to fetch products:', error);
		} finally {
			setLoading(false);
		}
	}, [assigneeId, statusTab, page, search, countryTab, sorting]);

	const getStatistics = useCallback(async () => {
		setLoading(true);
		try {
			const statuses = ['all', 'approved', 'pending', 'rejected'];
			const results = await Promise.all(
				statuses.map((status) =>
					ProductService.getListAdmin({
						status,
						limit: 0,
						countryCode: countryTab,
					}),
				),
			);

			const newTabs = initialTabs.map((tab, index) => ({
				...tab,
				count: results[index]?.data?.count?.toString() ?? '0',
			}));

			setTabs(newTabs);
		} finally {
			setLoading(false);
		}
	}, [countryTab]);

	const getUsers = useCallback(async () => {
		try {
			const { data } = await UsersServices.getUsers({
				limit: 10,
				skip: 0,
				companyId: 'self',
			});

			const newAvatars = data?.items
				.map((user) => ({
					id: user.uid,
					fullName: user?.firstName
						? `${user?.firstName} ${user?.lastName}`
						: user?.name,
				}))
				.filter((r) => r.fullName);

			setAvatars(newAvatars);
		} catch (error) {
			console.error('Failed to fetch users:', error);
		}
	}, []);

	const handleProductAction = async (action: 'approve' | 'reject') => {
		if (!selectedProduct?.id) return;

		setLoading(true);
		try {
			await ProductService.actionProduct(selectedProduct.id, action);
			const updatedProduct = {
				...selectedProduct,
				status:
					action === 'approve'
						? ProductStatusEnum.APPROVED
						: ProductStatusEnum.REJECTED,
			};
			setSelectedProduct(updatedProduct);

			setProducts((prev) =>
				prev.map((p) => (p.id === selectedProduct.id ? updatedProduct : p)),
			);

			await getStatistics();
			await getProductList();
		} finally {
			setLoading(false);
		}
	};

	const handleEditProduct = async (data: EditProduct) => {
		if (!selectedProduct?.id) return;

		setLoading(true);
		try {
			const { data: updatedProduct } = await ProductService.editProduct(
				selectedProduct.id,
				data,
			);

			const newProduct = {
				...selectedProduct,
				...updatedProduct,
				pricePerUnitWithProcent:
					updatedProduct.pricePerUnit +
					(updatedProduct.pricePerUnit * updatedProduct.markup || 0) / 100,
			} as unknown as ProductInterface;

			setSelectedProduct(newProduct);
			setProducts((prev) =>
				prev.map((p) => (p.id === selectedProduct.id ? newProduct : p)),
			);

			await getStatistics();
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		Promise.all([getStatistics(), getUsers(), getProductList()]).then(() => {});
	}, [getStatistics, getUsers, getProductList]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const mountedRefs = refs.current.filter(Boolean);
			const isOutside = mountedRefs.every(
				(ref) => !ref?.contains(event.target as Node),
			);

			if (isOutside) {
				setSelectedProduct(undefined);
				setSelectedCompany(undefined);
				setShowDetailsProduct(false);
				setShowDetails(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<main className="relative flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
			<header className="hidden xl:flex flex-col gap-4 mx-7.5">
				<SypacText variant="heading-4">
					<p className="text-gray-80">
						<T keyName="adminProducts.productList">Product list</T>
					</p>
				</SypacText>
				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">
						<T keyName="adminProducts.productListDescription">
							Ut enim ad minim veniam, quis nostrud exercitation.
						</T>
					</p>
				</SypacText>
			</header>

			<CountryBookTabs
				tabs={COUNTRY_TABS}
				activeTab={countryTab}
				onTabChange={setCountryTab}
			/>

			<section className="flex gap-3 xl:gap-0 justify-between ml-5 mr-7.5 xl:mx-7.5">
				<div className="flex items-center gap-3 xl:gap-7.5">
					<ProductTabs
						data={tabs}
						callback={(tab) => {
							setStatusTab(tab);
							setPage(0);
						}}
						activeTab={statusTab}
					/>
					{avatars.length > 0 && (
						<div className="flex w-fit h-[44px]">
							<AvatarDropDown
								items={avatars}
								onChange={(uid) => {
									setAssignee((prev) => (prev === uid ? undefined : uid));
									setPage(0);
								}}
							/>
						</div>
					)}
				</div>

				<div className="flex items-center gap-3 xl:gap-[42px]">
					<Sorting options={sortOptions} action={(item) => setSorting(item)} />
					<SearchBar
						placeholder={t(
							'adminProducts.searchOrderByIdName',
							'Search order by product ID and product name',
						)}
						onChange={setSearchQuery}
						showButton={false}
						classNames="xl:w-[384px]"
					/>
				</div>
			</section>

			<section className="w-full h-[calc(100%-119px)] xl:h-[calc(100%-213px)]">
				{loading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							visible={true}
						/>
					</div>
				) : (
					<article className="h-full relative ml-5 mr-7.5 xl:mx-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<div
							className="w-full h-[calc(100%-52px)] overflow-y-auto scroll-smooth pr-[5px]"
							ref={(el) => (refs.current[0] = el)}
						>
							<ProductsTable
								rows={products}
								rowClick={(product) => {
									const selectedProduct = products.find(
										(r) => r.id === product.id,
									);
									setSelectedProduct(selectedProduct);
									setShowDetails(false);
									setShowDetailsProduct(true);
								}}
								search={searchQuery}
								openCompany={(id) => {
									setSelectedCompany(id);
									setShowDetailsProduct(false);
									setShowDetails(true);
								}}
								clickedOutside={!showDetailsProduct && !showDetails}
								setProductLanguage={setProductLanguage}
							/>
						</div>
						<footer className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
							<div className="flex justify-between items-center h-[51px] px-3">
								<Pagination
									showText={true}
									count={count}
									page={page}
									onClick={(item) => setPage(item)}
								/>
							</div>
						</footer>
					</article>
				)}
			</section>

			{selectedCompany !== undefined && showDetails && (
				<div ref={(el) => (refs.current[1] = el)}>
					<CompanyDetails
						onClose={() => setShowDetails(false)}
						companyId={selectedCompany}
						onVerification={() => {
							setShowDetails(false);
						}}
					/>
				</div>
			)}
			{selectedProduct && showDetailsProduct && (
				<div ref={(el) => (refs.current[2] = el)}>
					<ProductDetails
						onClose={() => setShowDetailsProduct(false)}
						product={selectedProduct}
						approveProduct={() => handleProductAction('approve')}
						rejectProduct={() => setShowRejectProduct(true)}
						viewOnStore={() => setShowOnProduct(true)}
						editProduct={handleEditProduct}
						productLanguage={productLanguage}
					/>
				</div>
			)}
			{showRejectProduct && (
				<div ref={(el) => (refs.current[3] = el)}>
					<ModalDeleteProduct
						isOpen={showRejectProduct}
						onClose={() => setShowRejectProduct(false)}
						onSubmit={() => handleProductAction('reject')}
					/>
				</div>
			)}
			{showOnStore && (
				<div ref={(el) => (refs.current[4] = el)}>
					<ModalStoreProduct
						isOpen={showOnStore}
						onClose={() => setShowOnProduct(false)}
					/>
				</div>
			)}
		</main>
	);
};

export default Products;
