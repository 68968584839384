import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ModalConfirmationCode from '../ModalConfirmationCode/ModalConfirmationCode';
import ModalConfirmPayment from '../ModalConfirmPayment/ModalConfirmPayment';
import {
	ConfirmPaymentProps,
	TableData,
} from '../ModalConfirmPayment/ModalConfirmPayment.interface';
import ModalConfirmPaymentStatus from '../ModalConfirmPayment/ModalConfirmPaymentStatus';
import { BillingService } from '../../services/billing.service';
import { ConfirmPaymentForClientProps } from './ConfirmPaymentForClient.interface';

const ConfirmPaymentForClient: React.FC<ConfirmPaymentForClientProps> = ({
	isOpen,
	onClose,
	onSubmit,
	selectedInvoice,
}) => {
	const [step, setStep] = useState<string>('step_1');
	const [tableData, setTableData] = useState<TableData[]>([]);
	const [payment, setPayment] = useState<ConfirmPaymentProps>();
	const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);

	const closeFirstModal = () => {
		setStep('step_1');
		onClose();
	};

	const successPayment = () => {
		setStep('step_1');
		onSubmit();
	};

	const goToThirdStep = (value: ConfirmPaymentProps) => {
		setPayment(value);
		setStep('step_3');
	};

	const goToFourthStep = async () => {
		setInvoiceLoading(true);
		try {
			if (selectedInvoice.id && payment) {
				await BillingService.acceptInvoice('customer', selectedInvoice.id, {
					paymentAmount: parseFloat(payment?.amount)!,
					paymentDate: payment?.date!,
				});
				successPayment();
				//setStep('step_4');
			}
		} catch (e) {
		} finally {
			setInvoiceLoading(false);
		}
	};

	const getOrder = useCallback(async () => {
		try {
			const lines = selectedInvoice?.invoiceLines?.map((l) => ({
				price: l?.lineTotalWIthVat
					? `${l?.lineTotalWIthVat.toFixed(2)} ${
							selectedInvoice.countryCode === 'MD' ? 'MDL' : 'PLN'
					  }`
					: `${l?.lineTotal.toFixed(2)} ${
							selectedInvoice.countryCode === 'MD' ? 'MDL' : 'PLN'
					  }`,
				quantity:
					l?.unit === 'ton'
						? `${l.quantity?.toString()!} tons`
						: l.quantity?.toString()!,
				product: l.description === 'N/A' ? 'Transportation' : l.description,
				order: l.orderId,
			}));
			setTableData(lines);
		} catch (e) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInvoice?.invoiceLines]);

	useEffect(() => {
		getOrder().then(() => {});
	}, [getOrder]);

	return isOpen ? (
		<>
			<div className="bg-nero-72 blur-sm fixed inset-0 w-screen h-screen" />
			<ModalConfirmationCode
				isOpen={step === 'step_1'}
				onClose={closeFirstModal}
				onSubmit={() => setStep('step_2')}
			/>
			{step === 'step_2' ? (
				<ModalConfirmPayment
					data={{
						companyName: selectedInvoice.company?.name || 'Not Set',
						companyId: selectedInvoice.company?.id!,
						invoiceName: `${selectedInvoice.id}-${dayjs(
							selectedInvoice.invoiceDate,
						).format('DD.MM.YYYY')}`,
						amount: selectedInvoice.balanceDue,
						tableData,
						invoice: selectedInvoice,
					}}
					isOpen={step === 'step_2'}
					error={false}
					onClose={closeFirstModal}
					onSubmit={goToThirdStep}
				/>
			) : null}
			<ModalConfirmPaymentStatus
				data={{
					companyName: selectedInvoice.company?.name || 'Not set',
					companyId: selectedInvoice.company?.id!,
					invoiceName: `${selectedInvoice.id}-${dayjs(
						selectedInvoice.invoiceDate,
					).format('DD.MM.YYYY')}`,
					amount: selectedInvoice.balanceDue,
					tableData,
					paidData: payment!,
					invoice: selectedInvoice,
				}}
				isOpen={step === 'step_3'}
				onClose={closeFirstModal}
				onSubmit={goToFourthStep}
				companyType="customer"
				invoiceLoading={invoiceLoading}
			/>
		</>
	) : null;
};

export default ConfirmPaymentForClient;
