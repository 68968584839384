import React, { useContext, useMemo, useState } from 'react';
import { TransporterOrderProps } from '../../../transporterOrder.interface';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import TruckSchedule from '../../AdditionalDetails/TruckSchedule';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import { PaymentNotesStatusesEnum } from '../../../../../../services/billing.service';
import { OrderDetailsContext } from '../../../../../../context/OrderDetailsContext/order-details.context';
import OrderDetailsInvoice from '../../../../../../components/OrderDetailsInvoice/OrderDetailsInvoice';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';

const PendingAndPaid: React.FC<TransporterOrderProps> = ({
	selectedOrder,
	currentStep,
}) => {
	const { carrierInvoice } = useContext(OrderDetailsContext);
	const [stepperMargin, setStepperMargin] = useState<number>(0);

	const activeStep = useMemo(() => {
		if (currentStep! < 10) return currentStep;

		if (carrierInvoice?.status === PaymentNotesStatusesEnum.PENDING_INVOICE) {
			return currentStep;
		}

		if (carrierInvoice?.status === PaymentNotesStatusesEnum.UPLOADED_INVOICE) {
			return currentStep! + 1;
		}

		if (carrierInvoice?.status === PaymentNotesStatusesEnum.INVOICE_CONFIRMED) {
			return currentStep! + 2;
		}

		if (carrierInvoice?.status === PaymentNotesStatusesEnum.PAID) {
			return currentStep;
		}

		return currentStep;
	}, [carrierInvoice?.status, currentStep]);

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={activeStep!}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					isFinalStep={carrierInvoice?.status === PaymentNotesStatusesEnum.PAID}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{[
				PaymentNotesStatusesEnum.PENDING_INVOICE,
				PaymentNotesStatusesEnum.UPLOADED_INVOICE,
				PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
			].includes(carrierInvoice?.status as PaymentNotesStatusesEnum) && (
				<div className="px-10 pt-7 pb-3.5">
					<OrderDetailsInvoice
						currentStatus={carrierInvoice?.status!}
						target={OrderTarget.transporter}
					/>
				</div>
			)}

			<div
				className={`px-10 ${
					[
						PaymentNotesStatusesEnum.PENDING_INVOICE,
						PaymentNotesStatusesEnum.UPLOADED_INVOICE,
						PaymentNotesStatusesEnum.INVOICE_CONFIRMED,
					].includes(carrierInvoice?.status as PaymentNotesStatusesEnum)
						? 'py-3.5'
						: 'pt-7 pb-3.5'
				}`}
			>
				<BaseOrderDetails
					selectedOrder={selectedOrder}
					isOpen={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					deliveryIncluded={selectedOrder?.deliveryIncluded!}
					target={selectedOrder?.target!}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={false}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 py-3.5">
				<PickUpDeliveryDetails
					selectedOrder={selectedOrder}
					target={selectedOrder?.target}
					isPickUp={true}
					simpleOrder={selectedOrder?.trucks?.length === 1}
				/>
			</div>

			<div className="px-10 pb-7 pt-3.5">
				<TruckSchedule selectedOrder={selectedOrder} />
			</div>
		</>
	);
};

export default PendingAndPaid;
