import React, { useState, useEffect, useCallback } from 'react';
import { SypacText, SypacTabs } from '@sypac/component-library-react';
import { MutatingDots } from 'react-loader-spinner';
import { T, useTranslate } from '@tolgee/react';
import CompaniesTable from '../../../components/CompaniesTable/CompaniesTable';
import {
	CompanyResponse,
	CompanyService,
	CompanyShort,
	RequestCompanyList,
} from '../../../services/company.services';
import CompanyDetails from '../ComanyDetails';
import { useGetCompanyAdminStats } from '../../../hooks/use-get-stats';
import { camelToSnakeCase } from '../../../utils/string.util';
import CompanyVerification from '../../../components/CompanyVerification';
import CountryBookTabs from '../../../components/CountryBookTabs/CountryBookTabs';
import { TabsButton } from '../../../components/TabsButton/TabsButton';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Pagination from '../../../components/Pagination/Pagination';
import { LIMIT } from '../../../constants';
import useDebounce from '../../../hooks/useDebounce';
import { Sorting } from '../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';
import { CountryTab } from '../../../components/CountryBookTabs/CountryBookTabs.interface';

const COUNTRY_TABS: Record<string, CountryTab> = {
	all: {
		key: 'all',
		label: 'adminProducts.allCountries',
		translatedLabel: 'All countries',
	},
	PL: {
		key: 'PL',
		label: 'adminProducts.poland',
		translatedLabel: 'Poland',
		flag: 'PL',
	},
	MD: {
		key: 'MD',
		label: 'adminProducts.moldova',
		translatedLabel: 'Moldova',
		flag: 'MD',
	},
} as const;

const sortOptions = [
	{
		title: 'Buyer',
		value: 'customer',
	},
	{
		title: 'Seller',
		value: 'producer',
	},
	{
		title: 'Carrier',
		value: 'transporter',
	},
	{
		title: 'Seller & Carrier',
		value: 'producer transporter',
	},
];

const Dashboard: React.FC = () => {
	const { t } = useTranslate();
	const [count, setCount] = useState<number>(0);
	const [companies, setCompanies] = useState<CompanyShort[]>([]);
	const [status, setStatus] = useState<string>('all');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [refresh, setRefresh] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
		undefined,
	);
	const [showVerification, setShowVerification] = useState<boolean>(false);
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [countryTab, setCountryTab] = useState<string>('all');
	const [sorting, setSorting] = useState<SortingItemInterface>();
	const [companyTypes, setCompanyTypes] = useState<string[]>();
	const search = useDebounce(searchQuery, 500);
	const [statsParams, setStatsParams] = useState({
		status: status !== 'all' ? status : undefined,
		companyTypes,
		search: search.length ? search : undefined,
		countryTab: countryTab !== 'all' ? countryTab : undefined,
		refresh,
	});

	const [stats] = useGetCompanyAdminStats(
		undefined,
		statsParams.companyTypes,
		statsParams.search,
		statsParams.countryTab,
		statsParams.refresh,
	);

	// const useOnChange = (e: any) => {
	// 	setPage(1);
	// 	return useDebounce(setSearchQuery(e.currentTarget.value));
	// };

	const getCompaniesCallback = useCallback(async () => {
		setIsLoading(true);
		try {
			const query: RequestCompanyList = {
				status: camelToSnakeCase(status),
				limit: LIMIT + 2,
				offset: page * (LIMIT + 2),
				search,
				companyTypes,
			};

			if (countryTab !== 'all') {
				query.countryCode = countryTab;
			}

			const { data } = await CompanyService.getCompanies(query);
			setCount(data.count);
			setCompanies(data.items);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	}, [companyTypes, countryTab, page, search, status]);

	const openCompany = (company: CompanyResponse) => {
		setSelectedCompany(company.id);
		setShowVerification(false);
		setShowDetails(true);
	};

	const completeVerification = () => {
		setShowDetails(true);
		setShowVerification(false);
		setRefresh(refresh + 1);
		getCompaniesCallback().then(() => {});
	};

	const setTab = (status: string) => {
		setStatus(status);
		setPage(0);
		setRefresh(refresh + 1);
	};

	const handleCountryChange = (item: string) => {
		setCountryTab(item);
		setRefresh(refresh + 1);
	};

	const handleSorting = (sort: SortingItemInterface) => {
		setSorting(sort);

		const companyTypes = sort?.value?.trim().includes(' ')
			? sort.value.split(' ')
			: [sort.value!];

		setRefresh(refresh + 1);
		setCompanyTypes(companyTypes);
	};

	useEffect(() => {
		getCompaniesCallback().then(() => {});
	}, [getCompaniesCallback]);

	useEffect(() => {
		setStatsParams({
			status: status !== 'all' ? status : undefined,
			companyTypes,
			search: search.length ? search : undefined,
			countryTab: countryTab !== 'all' ? countryTab : undefined,
			refresh,
		});
	}, [status, companyTypes, search, countryTab, refresh]);

	const companyTabs = [
		{
			label: t('acceptedStep.tableTabAll', 'All'),
			value: stats?.all || '0',
			status: 'all',
		},
		{
			label: t(
				'companiesDashboard.registrationInProgress',
				'Registration in progress',
			),
			value: stats?.registrationInProgress || '0',
			status: 'registration_in_progress',
			icon: 'registrationInProgress',
		},
		{
			label: t(
				'companiesDashboard.verificationPending',
				'Verification pending',
			),
			value: stats?.verificationPending || '0',
			status: 'verification_pending',
			icon: 'verificationPending',
		},
		{
			label: t('companiesDashboard.verifiedTab', 'Verified'),
			value: stats?.verified || '0',
			status: 'verified',
			icon: 'verified',
		},
	];

	return (
		<main className="flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full relative">
			<div className="hidden xl:flex flex-col gap-4">
				<div className="flex flex-col gap-4 h-[78px] ml-[30px]">
					<SypacText variant="body-regular-large">
						<p className="text-gray-80 text-3xl tracking-tighter">
							<T keyName="companiesDashboard.companiesList">Companies list</T>
						</p>
					</SypacText>
				</div>

				<CountryBookTabs
					tabs={COUNTRY_TABS}
					activeTab={countryTab}
					onTabChange={(item) => handleCountryChange(item)}
				/>
			</div>

			<div className="flex items-center gap-6 2xl:gap-12 ml-[30px] mr-7.5">
				<SypacTabs className="flex w-fit p-[3px] gap-[4px]">
					{companyTabs.map(({ label, value, status: selectedStatus, icon }) => (
						<TabsButton
							key={selectedStatus}
							label={label}
							buttonId={selectedStatus}
							onClick={() => setTab(selectedStatus)}
							isSelected={status === selectedStatus}
							notification={+value}
							color="#E8E8E8"
							icon={icon}
						/>
					))}
				</SypacTabs>

				<Sorting
					options={sortOptions}
					action={(item) => handleSorting(item)}
					current={sorting}
					label={{ title: 'Company type:', key: 'companyType' }}
					customDefault={{
						title: 'All',
						key: 'all',
					}}
				/>

				<div className="ml-auto">
					<SearchBar
						placeholder={t('dashboard.search', 'Search')}
						onChange={setSearchQuery}
						showButton={false}
						classNames="w-[320px]"
					/>
				</div>
			</div>

			{/* <div className="flex flex-col gap-2.5 w-[500px] ml-5">
				<div className="border border-solid relative border-gray-40 rounded-xl py-3 px-5 flex items-center">
					<div className="flex w-full">
						<button
							className="flex items-center border-0 p-0 bg-transparent cursor-pointer"
							onClick={() => {
								setTimeout(() => {
									getUserCallback().then(() => {});
								}, 400);
							}}
						>
							<SypacIcon
								icon-name="Magnifer"
								className="text-gray-40"
								size="custom"
								width="20px"
								height="20px"
							/>
						</button>
						<input
							className="flex items-center text-base placeholder:text-gray-40 py-0 px-2.5 w-full outline-none border-0 bg-transparent"
							placeholder={t('dashboard.search', 'Search')}
							type="text"
							value={searchQuery}
							onChange={useOnChange}
						/>
					</div>
					<button
						className="flex items-center border-0 p-0 bg-transparent cursor-pointer"
						onClick={() => {
							setIsOpen(!isOpen);
						}}
					>
						<SypacIcon
							icon-name="Tuning 2"
							className="text-gray-40"
							size="custom"
							width="22px"
							height="22px"
						/>
					</button>
					<div
						className={`${
							isOpen ? 'flex' : 'hidden'
						} absolute left-0 top-full mt-3 bg-white rounded-xl p-5 border border-gray-10 flex-wrap gap-4`}
					>
						<SypacInput className="w-full" size="md">
							<label>
								<T keyName="dashboard.country">Country</T>
							</label>
							<input
								className="w-full outline-none border border-gray-10 px-5 py-1.5"
								placeholder={t('dashboard.country', 'Country')}
							/>
						</SypacInput>
						<div className="flex gap-x-3">
							<SypacInput size="md">
								<label>
									<T keyName="dashboard.startRegistrationDate">
										Start registration date
									</T>
								</label>
								<input
									className="w-full outline-none border border-gray-10 px-5 py-1.5"
									placeholder={t('dashboard.startRegDate', 'Start reg. date')}
								/>
							</SypacInput>
							<SypacInput size="md">
								<label>
									<T keyName="dashboard.endRegistrationDate">
										End registration date
									</T>
								</label>
								<input
									className="w-full outline-none border border-gray-10 px-5 py-1.5"
									placeholder={t('dashboard.endRegDate', 'End reg. date')}
								/>
							</SypacInput>
						</div>
						<SypacInput className="w-full" size="md">
							<label>
								<T keyName="dashboard.user">User</T>
							</label>
							<input
								className="w-full outline-none border border-gray-10 px-5 py-1.5"
								placeholder={t('dashboard.selectUser', 'Select user')}
							/>
						</SypacInput>
					</div>
				</div>
				<div className="border border-solid border-gray-10 rounded-xl h-full">
					<LeftSideMenu
						stats={[
							{ value: stats?.all || '0', status: 'all', label: 'All' },
							{
								value: stats?.registrationInProgress || '0',
								status: 'registrationInProgress',
								label: 'Registration (in progress)',
							},
							{
								value: stats?.verificationPending || '0',
								status: 'verificationPending',
								label: 'Verification (pending)',
							},
							{
								value: stats?.verified || '0',
								status: 'verified',
								label: 'Verified',
							},
						]}
						setStatus={(status) => setTab(status)}
						selectedStatus={status}
					/>
				</div>
			</div> */}

			<section className="w-full h-full">
				{isLoading ? (
					<div className="flex w-full h-full items-center justify-center">
						<MutatingDots
							height="100"
							width="100"
							color="#7693F4"
							secondaryColor="#494C83"
							radius="12.5"
							ariaLabel="mutating-dots-loading"
							visible={true}
						/>
					</div>
				) : (
					<article className="h-full relative ml-7.5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
						<div className="w-full h-[calc(100%-52px)] overflow-y-auto scroll-smooth pr-[5px]">
							<CompaniesTable
								rows={companies}
								onClick={openCompany}
								selectedCompany={selectedCompany}
								search={searchQuery}
								status={status}
							/>
						</div>
						<footer className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
							<div className="flex justify-between items-center h-[51px] px-3">
								<Pagination
									showText={true}
									count={count}
									page={page}
									onClick={(item) => setPage(item)}
									customLimit={LIMIT + 2}
								/>
							</div>
						</footer>
					</article>
				)}
			</section>

			{selectedCompany !== undefined && showDetails ? (
				<CompanyDetails
					onClose={() => {
						setShowDetails(false);
						setSelectedCompany(undefined);
					}}
					companyId={selectedCompany}
					onVerification={() => {
						setShowVerification(true);
						setShowDetails(false);
					}}
				/>
			) : null}
			{selectedCompany !== undefined && showVerification ? (
				<CompanyVerification
					isOpen={showVerification}
					closeModal={completeVerification}
					companyId={selectedCompany}
				/>
			) : null}
		</main>
	);
};

export default Dashboard;
