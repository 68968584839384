import React, { useContext, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import NewTrash from '../../../../../../assets/NewTrash';
import AlertSmall from '../../../../../../assets/AlertSmall';
import { ProductContext } from '../../../../../../context/DatabaseProductContext/database-product.context';
import { ModalDeleteProductProps } from '../../../productDatabase.interface';

const ModalDeleteProduct: React.FC<ModalDeleteProductProps> = ({
	isOpen,
	onClose,
	handleDelete,
	product,
	group,
}) => {
	const { setAdditional } = useContext(ProductContext);
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			modalRefs: [...prev.modalRefs, modalRef],
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				className="modal-inside outline-none w-[516px]"
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div ref={modalRef} className="h-full flex flex-col">
					<main className="flex flex-col gap-[28px] px-3 pt-3">
						<span className="flex -ml-1">
							<NewTrash width={60} height={60} strokeWidth="0.5" />
						</span>

						<SypacText variant="body-regular-medium">
							<p className="text-2xl text-gray-80 w-[400px]">
								<T
									keyName={
										group
											? group.parentId
												? 'modalDeleteProduct.deleteThisCategory'
												: 'modalDeleteProduct.deleteThisGroup'
											: 'modalDeleteProduct.areYouSure'
									}
								>
									{group
										? group.parentId
											? 'Are you sure you want to delete this category?'
											: 'Are you sure you want to delete this group?'
										: 'Are you sure you want to delete this product type?'}
								</T>
							</p>
						</SypacText>

						{!group && (
							<ul className="list-disc text-gray-40 space-y-1.5 -ml-[14px]">
								<li>
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-40">
											<T keyName="modalDeleteProduct.thisActionWillRemove">
												This action will remove the product type from the SYPAC
												(country) store.
											</T>
										</p>
									</SypacText>
								</li>
								<li>
									<div className="grid grid-cols-[144px,auto]">
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40">
												<T keyName="modalDeleteProduct.activeOrders">
													Active Orders:
												</T>
											</p>
										</SypacText>

										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80">
												{product?.totalOrders || 0}
											</p>
										</SypacText>
									</div>
								</li>
								<li>
									<div className="grid grid-cols-[144px,auto]">
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40">
												<T keyName="modalDeleteProduct.listedProducts">
													Listed Products:
												</T>
											</p>
										</SypacText>

										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80">5</p>
										</SypacText>
									</div>
								</li>
							</ul>
						)}
						<div className="flex flex-col gap-3 mb-3">
							<div className="flex flex-col border border-solid border-gray-10 rounded-10">
								<div className="flex gap-3 px-3 py-[15px]">
									<AlertSmall color="#F44A77" />

									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-80">
											<T keyName="modalDeleteProduct.importantNotes">
												Important notes:
											</T>
										</p>
									</SypacText>
								</div>
								<div className="border-0 border-solid border-t-[1px] border-gray-10" />
								<div className="flex p-3">
									<ul className="list-disc text-gray-40 m-0 -ml-[14px]">
										<li>
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-40">
													<T keyName="modalDeleteProduct.sellersWillNoLonger">
														Sellers will no longer be able to list products
														under this type;
													</T>
												</p>
											</SypacText>
										</li>
										<li>
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-40">
													<T keyName="modalDeleteProduct.ongoingOrders">
														Ongoing orders linked to this product type will
														continue to be delivered as scheduled.
													</T>
												</p>
											</SypacText>
										</li>
									</ul>
								</div>
							</div>
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-40">
									<T keyName="modalDeleteProduct.pleaseConfirmToProceed">
										Please confirm to proceed with the deletion.
									</T>
								</p>
							</SypacText>
						</div>
					</main>

					<nav className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[42px] flex items-center justify-center rounded-lg transition"
								onClick={onClose}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="modalDeleteProduct.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[42px] flex items-center justify-center bg-red border-0 rounded-lg transition hover:bg-red/70"
								onClick={handleDelete}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-white">
										<T keyName="modalDeleteProduct.delete">Delete</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</nav>
				</div>
			</Modal>
		</>
	);
};

export default ModalDeleteProduct;
