import { StylesConfig } from 'react-select';

export const settingsCountrySelect: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		boxShadow: 'none',
		borderRadius: '8px',
		cursor: 'text',
	}),
	valueContainer: (styles) => ({
		...styles,
		padding: '6px 12px',
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: '14px',
		color: '#D3D4D5',
	}),
	menu: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		borderRadius: '8px',
		boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
		border: '1px solid #E8E8E8',
		overflow: 'hidden',
		zIndex: 10,
	}),
	menuList: (styles) => ({
		...styles,
		padding: 0,
		paddingRight: '25px',
	}),
	indicatorSeparator: (styles) => ({
		...styles,
		visibility: 'hidden',
	}),
};

export const settingsCountrySelectError: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		':focus': { borderColor: 'rgb(247, 72, 39)' },
		':hover': { borderColor: 'rgb(247, 72, 39)' },
		':focus-visible': { borderColor: 'rgb(247, 72, 39)' },
		':focus-within': { borderColor: 'rgb(247, 72, 39)' },
		boxShadow: 'none',
		borderColor: 'rgb(247, 72, 39)',
		borderRadius: '8px',
	}),
};
