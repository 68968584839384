import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { useNavigate } from 'react-router-dom';
import {
	OrderContext,
	OrderProps,
} from '../../../context/OrderContext/order.context';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import { Geo } from '../../../interfaces/geo.interface';
import { MapMarker, PinVariant } from '../../../components/Map/Map.interface';
import { GeoService } from '../../../services/geo.services';
import { FileService } from '../../../services/file.service';
import { OrdersService } from '../../../services/orders.services';
import { T } from '@tolgee/react';
import useDebounce from '../../../hooks/useDebounce';
import SearchLocation from './components/SearchLocation';
import UploadLocationPhoto from './components/UploadLocationPhoto';
import Map from '../../../components/Map/Map';
import { MutatingDots } from 'react-loader-spinner';
import Flag from 'react-world-flags';
import { AuthContext } from '../../../context/context';

const ClientSelectLocation: React.FC = () => {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const { order, setOrder } = useContext(OrderContext);
	const { setInputQuantity, setTotalQuantityCounter } = useContext(
		QuantityPickerContext,
	);
	const [search, setSearch] = useState<string>('');
	const [locations, setLocations] = useState<Geo[]>([]);
	const [localRecentOrders, setLocalRecentOrders] = useState<OrderProps[]>([]);
	const [showUpload, setUploadModal] = useState<boolean>(false);
	const [currentTab, setCurrentTab] = useState<string>('orders');
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	// const [markerHovered, setMarkerHovered] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const searchQuery = useDebounce(search, 500);

	const MemoizedMap = React.memo(Map);

	const getRecentOrders = async () => {
		setIsLoading(true);
		try {
			const { data } = await OrdersService.getRecentOrdersByProducts({
				limit: 5,
				offset: 0,
			});
			if (!data.count) {
				setIsLoading(false);
				return;
			}

			const newMarkers = await Promise.all(
				data.items.map(async (order) => {
					const { data: locationData } = await GeoService.getLocationDetails({
						locationid: order?.location?.locationId!,
					});
					return {
						latitude: locationData.Latitude,
						longitude: locationData.Longitude,
						variant: PinVariant.pickup,
						orderProduct: order.product,
					};
				}),
			);

			setLocalRecentOrders(data.items || []);
			setMarkers(newMarkers);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	const handleLocationSearch = useCallback(async () => {
		if (!searchQuery.length) {
			setLocations([]);
			return;
		}

		try {
			const { data } = await GeoService.getLocations({
				phrase: searchQuery,
				countryCode: user?.profile?.countryCode!,
				maxResults: '10',
			});
			setLocations(data);
		} catch (e) {
			console.error('Error searching locations:', e);
		}
	}, [searchQuery, user?.profile?.countryCode]);

	const handleLocationSelect = (location: Geo) => {
		setOrder({ ...order, location });
		setUploadModal(true);
	};

	const selectRecentOrder = (order: OrderProps) => {
		setOrder({ ...order });
		navigate(
			`/store/groups/${order?.group?.id}/categories/${order?.category?.id}/products/${order?.product?.id}/quantity`,
		);
	};

	const selectRecentAddress = (location: any) => {
		setOrder({ location: location });
		navigate('/store/groups');
	};

	const handlePhotoUpload = async (file?: File) => {
		try {
			if (!file) {
				setOrder({ ...order, locationPhoto: undefined });
				setUploadModal(false);
				navigate('/store/groups');
				return;
			}

			const { data } = await FileService.uploadFiles([file]);
			setOrder({ ...order, locationPhoto: data.items?.[0]?.path });
			setUploadModal(false);
			navigate('/store/groups');
		} catch (e) {}
	};

	useEffect(() => {
		handleLocationSearch().then(() => {});
	}, [searchQuery, handleLocationSearch]);

	useEffect(() => {
		getRecentOrders().then(() => {});
		setOrder(undefined);
		setInputQuantity(0);
		setTotalQuantityCounter(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const RecentOrderCard = ({ order }: { order: OrderProps }) => (
		<button
			className="w-full h-[68px] flex items-center bg-transparent border border-solid border-gray-10 rounded-10 px-3 transition hover:border-primary-violet hover:shadow-dropdown cursor-pointer"
			onClick={() => selectRecentOrder(order)}
			// onMouseEnter={() => setMarkerHovered(order?.product?.name)}
			// onMouseLeave={() => setMarkerHovered(undefined)}
		>
			<div
				style={{ backgroundImage: `url(${order?.product?.photoUrl})` }}
				className="flex flex-none w-[44px] h-[44px] bg-no-repeat bg-cover rounded-[8px] border border-solid border-gray-10"
				role="img"
			/>
			<div className="flex flex-col ml-5 items-start">
				<SypacText variant="body-normal-medium">
					<p
						className={`text-base text-gray-80 ${
							!order?.product?.type && !order?.product?.size ? 'pb-[20px]' : ''
						}`}
					>
						{order?.product?.name}
					</p>
				</SypacText>
				{(order?.product?.type || order?.product?.size) && (
					<SypacText variant="body-normal-medium">
						<p className="text-sm text-gray-40">
							{order?.product?.type}{' '}
							{order?.product?.size && `(${order?.product?.size})`}
						</p>
					</SypacText>
				)}
			</div>
		</button>
	);

	const renderRecentAddresses = () => {
		const addressCounts = localRecentOrders.reduce<
			Record<
				string,
				{
					address: string;
					count: number;
					countryCode?: string;
					locationId: string;
				}
			>
		>((acc, order) => {
			const locationId = order?.location?.locationId;
			if (!locationId) return acc;

			const address = order?.location
				? [
						[
							order.location?.address?.street,
							order.location?.address?.houseNumber,
						]
							.filter(Boolean)
							.join(' '),
						order.location?.address?.postalCode,
						order.location?.address?.city,
						order.location?.address?.country,
				  ]
						.filter(Boolean)
						.join(', ')
				: '';

			if (!address) return acc;
			if (acc[locationId]) {
				acc[locationId].count += 1;
			} else {
				acc[locationId] = {
					address,
					count: 1,
					countryCode: order.location?.countryCode,
					locationId,
				};
			}

			return acc;
		}, {} as Record<string, { address: string; count: number; countryCode?: string; locationId: string }>);

		return Object.values(addressCounts).map(
			({ address, count, countryCode, locationId }) => (
				<button
					key={address}
					type="button"
					onClick={() =>
						selectRecentAddress(
							localRecentOrders.find(
								(l) => l.location?.locationId === locationId,
							)?.location,
						)
					}
					className="w-full h-[48px] flex items-center bg-transparent border border-solid border-gray-10 rounded-10 px-3 transition hover:border-primary-violet hover:shadow-dropdown cursor-pointer"
				>
					<div className="w-full flex gap-3 items-start">
						<span className="mb-auto pt-[2px]">
							<Flag
								className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
								code={countryCode}
								width={24}
								height={16}
							/>
						</span>
						<SypacText variant="body-regular-medium">
							<p className="text-left text-gray-80">{address}</p>
						</SypacText>

						{count > 1 ? (
							<SypacText variant="body-regular-medium" className="ml-auto">
								<p className="text-gray-40">{count}</p>
							</SypacText>
						) : null}
					</div>
				</button>
			),
		);
	};

	const EmptyState = ({ type }: { type: 'orders' | 'addresses' }) => (
		<section className="h-full flex justify-center items-center">
			<div className="w-[339px] h-[150px] flex flex-col justify-between text-center">
				<div className="flex flex-col gap-4">
					<SypacText variant="body-regular-large">
						<p className="text-xl text-gray-80">
							<T
								keyName={
									type === 'orders'
										? 'clientSelectLocation.noRecentOrdersYet'
										: 'selectLocation.noRecentAddresses'
								}
							>
								{type === 'orders'
									? 'No recent orders yet'
									: 'No recent addresses'}
							</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-large">
						<p className="text-base text-gray-40">
							<T
								keyName={
									type === 'orders'
										? 'selectLocation.youHaveNoRecentOrders'
										: 'selectLocation.youHaveNoRecentAddresses'
								}
							>
								{type === 'orders'
									? 'You have no recent orders. Your previous orders will show up here for convenient reordering.'
									: 'You have no recent addresses. Your previous addresses will show up here for convenient reordering.'}
							</T>
						</p>
					</SypacText>
				</div>
			</div>
		</section>
	);

	return (
		<main className="flex lg:w-[calc(100vw-87px)] xl-2xl:w-[calc(100vw-104px)] h-full xl-2xl:justify-center 2xl:items-center mt-[68px] lg:mt-0">
			<section className="w-[556px] h-[calc(100vh-80px)] lg:h-auto 2xl:h-[738px] flex flex-col gap-6 xl:gap-[32px] xl-2xl:gap-10 2xl:gap-[60px] lg:px-5 xl-2xl:p-0 xl-2xl:pr-5">
				<header>
					<SypacText variant="body-regular-large" className="px-3 lg:p-0">
						<p className="text-2xl xl:text-[32px] xl-2xl:text-[40px] leading-[42px] tracking-tighter text-gray-80 mt-6 lg:mt-0">
							<T keyName="clientSelectLocation.welcomeToTheStore">
								Welcome to the Sypac store!
							</T>
						</p>
					</SypacText>
				</header>

				<div className="h-full flex flex-col gap-3 xl:gap-[32px] xl-2xl:gap-10">
					<section className="flex flex-col gap-4 xl:gap-6 px-3 lg:p-0">
						<SypacText variant="body-regular-large">
							<p className="text-base xl:text-xl text-gray-80">
								<T keyName="clientSelectLocation.whereToDeliverYourGoods">
									Where to deliver your goods?
								</T>
							</p>
						</SypacText>
						<SearchLocation
							inputName="search_location"
							locations={locations}
							onChange={setSearch}
							selectLocation={handleLocationSelect}
						/>
					</section>

					<section className="w-full flex flex-col h-[calc(100%-106px)] 2xl:h-[480px] border border-solid border-gray-10 lg:rounded-10 bg-white overflow-hidden box-border">
						<nav className="flex gap-1 p-3 rounded-t-10 border border-solid border-gray-10 border-t-0 border-l-0 border-r-0 bg-white">
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									type="button"
									className={`w-full h-[42px] lg:h-[32px] rounded-lg flex gap-2 items-center border-none transition group
                    ${
											currentTab === 'orders'
												? 'bg-primary-violet text-white'
												: 'hover:bg-gray-10 text-gray-80'
										}`}
									onClick={() => setCurrentTab('orders')}
								>
									<SypacIcon
										iconName="Clock Circle"
										size="custom"
										width="24px"
										height="24px"
										className="mb-[2px]"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="clientSelectLocation.myRecentOrders">
												My recent orders
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									type="button"
									className={`w-full h-[32px] rounded-lg flex gap-2 items-center border-none transition group ${
										currentTab === 'addresses'
											? 'bg-primary-violet text-white'
											: 'hover:bg-gray-10 text-gray-80'
									}`}
									onClick={() => setCurrentTab('addresses')}
								>
									<SypacIcon
										iconName="Clock Circle"
										size="custom"
										width="24px"
										height="24px"
										className="mb-[2px]"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="clientSelectLocation.recentAddresses">
												Recent addresses
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</nav>

						{currentTab === 'orders' ? (
							isLoading ? (
								<div className="flex h-full items-center justify-center">
									<MutatingDots
										height="100"
										width="100"
										color="#7693F4"
										secondaryColor="#494C83"
										radius="12.5"
										ariaLabel="mutating-dots-loading"
										visible={true}
									/>
								</div>
							) : localRecentOrders.length !== 0 ? (
								<div
									className={`h-full flex flex-col gap-3 p-3 lg:p-[10px] ${
										localRecentOrders.length > 5
											? 'overflow-y-scroll scroll-smooth'
											: ''
									}`}
								>
									{localRecentOrders.map((order: OrderProps) => (
										<div key={order?.product?.id}>
											<RecentOrderCard order={order} />
										</div>
									))}
								</div>
							) : (
								<EmptyState type="orders" />
							)
						) : null}

						{currentTab === 'addresses' ? (
							localRecentOrders.length !== 0 ? (
								<div className="w-full h-full overflow-y-scroll scroll-smooth flex flex-col p-3">
									<div
										className="flex flex-col gap-3 mr-[11px]"
										key={order?.product?.id}
									>
										{renderRecentAddresses()}
									</div>
								</div>
							) : (
								<EmptyState type="addresses" />
							)
						) : null}
					</section>
				</div>
			</section>

			<section className="hidden lg:flex w-[701px] 2xl:h-[738px]">
				<MemoizedMap
					polygons={[]}
					markers={markers}
					target="customer"
					//markerHovered={markerHovered}
				/>
			</section>

			<UploadLocationPhoto
				isOpen={showUpload}
				onClose={() => setUploadModal(false)}
				onSave={handlePhotoUpload}
			/>
		</main>
	);
};

export default ClientSelectLocation;
