import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { T, useTranslate } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '../../../assets/PlusIcon';
import Map from '../../../components/Map/Map';
import { toastVariant } from '../../../components/ToastVariant/toastVariant';
import {
	ProductService,
	ProductStoreInterface,
} from '../../../services/product.services';
import { LIMIT } from '../../../constants';
import NewStoreCreate from './components/NewStoreCreate';
import { Geo } from '../../../interfaces/geo.interface';
import { GeoService } from '../../../services/geo.services';
import useDebounce from '../../../hooks/useDebounce';
import { MapMarker, PinVariant } from '../../../components/Map/Map.interface';
import Flag from 'react-world-flags';
import { formatDate } from '../../../utils/time.util';
import DotsDropdown from '../../../components/DotsDropdown/DotsDropdown';
import {
	ProductStoresInterface,
	StoreCardProps,
} from './productStores.interface';
import { AuthContext } from '../../../context/context';

const ProductStores: React.FC = () => {
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const divRef = useRef<HTMLDivElement>(null);

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingMap, setLoadingMap] = useState<boolean>(false);
	const [myStores, setMyStores] = useState<ProductStoresInterface>();
	const [openCreate, setOpenCreate] = useState<boolean>(false);
	const [showList, setShowList] = useState<boolean>(true);
	const [isScrollable, setIsScrollable] = useState<boolean>(false);
	const [locations, setLocations] = useState<Geo[]>([]);
	const [searchLocation, setSearchLocation] = useState<string>('');
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [storeLocation, setStoreLocation] = useState<Geo>();
	const [storeName, setStoreName] = useState<string>();
	const [selectedStore, setSelectedStore] =
		useState<ProductStoreInterface | null>(null);
	const [updatedStore, setUpdatedStore] = useState<MapMarker>();

	const searchQuery = useDebounce(searchLocation, 500);

	const MemoizedMap = React.memo(Map);

	const getStoresCallback = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await ProductService.getProductStores({
				limit: LIMIT,
				offset: 0,
			});

			if (!selectedStore) {
				setLoadingMap(true);
				const newMarkers = data?.items?.map((item) => ({
					latitude: item.location.coordinates[1],
					longitude: item.location.coordinates[0],
					variant: PinVariant.product_store,
					store: {
						name: item.name,
						countryCode: item.countryCode,
						productCount: item.productCount?.toString(),
					},
				}));
				setMarkers(newMarkers);
				setTimeout(() => setLoadingMap(false), 300);
			}

			setMyStores(data);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	}, [selectedStore]);

	const getSearchLocation = useCallback(async () => {
		if (!searchQuery.length) {
			setLocations([]);
			return;
		}

		try {
			const { data } = await GeoService.getLocations({
				phrase: searchQuery,
				countryCode: user?.profile?.countryCode,
				maxResults: '10',
			});
			setLocations(data);
		} catch (error) {
			console.error(error);
		}
	}, [searchQuery, user?.profile?.countryCode]);

	const getStoreCoords = useCallback(
		async (locationId: string) => {
			if (!locationId) return;

			setLoadingMap(true);
			try {
				const { data } = await GeoService.getLocationDetails({
					locationid: locationId,
				});
				const newMarker = {
					latitude: data.Latitude,
					longitude: data.Longitude,
					variant: PinVariant.product_store,
					store: {
						name: storeName,
						countryCode: data.address?.countryCode,
					},
				};
				setMarkers([newMarker]);
				setUpdatedStore(newMarker);
			} catch (error) {
				toastVariant(`Something went wrong. ${error?.toString()!}`, true);
			} finally {
				setLoadingMap(false);
			}
		},
		[storeName],
	);

	const handleClose = () => {
		setOpenCreate(false);
		setShowList(true);
		setSelectedStore(null);
		setStoreName(undefined);
		setStoreLocation(undefined);
		getStoresCallback().then(() => {});
	};

	const editStore = (store: ProductStoreInterface) => {
		setLoadingMap(true);
		setSelectedStore(store);
		setStoreName(store.name);

		const selectedMarker = {
			latitude: store.location.coordinates[1],
			longitude: store.location.coordinates[0],
			variant: PinVariant.product_store,
			store: {
				name: store.name,
				countryCode: store.countryCode,
				productCount: store.productCount?.toString(),
			},
		};
		setMarkers([selectedMarker]);

		setOpenCreate(true);
		setShowList(false);
		setTimeout(() => setLoadingMap(false), 300);
	};

	const StoreCard = ({ store, onEdit, onClick }: StoreCardProps) => (
		<article
			className="w-[432px] xl-2xl:w-[544px] flex flex-col border border-solid border-gray-10 rounded-10 cursor-pointer transition hover:border-[#AFB0C8] box-border"
			onClick={onClick}
		>
			<header className="flex items-center p-[10px]">
				<SypacText variant="body-regular-medium">
					<p className="text-xs text-gray-40">
						<T keyName="baseOrderDetails.created">Created:</T>{' '}
						{formatDate(store.createdAt)}
					</p>
				</SypacText>
				<div className="ml-auto" onClick={(e) => e.stopPropagation()}>
					<DotsDropdown
						options={[
							{
								id: 1,
								title: t('productStores.editStore', 'Edit store'),
								action: () => onEdit(store),
							},
						]}
					/>
				</div>
			</header>
			<div className="border-0 border-solid border-t-[1px] border-gray-10" />
			<div className="flex flex-col gap-3 p-[10px]">
				<div className="flex items-center justify-between">
					<SypacText variant="body-regular-medium">
						<p className="text-xl text-gray-90">{store.name}</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80">
							{store.productCount}{' '}
							<T keyName="productStores.products">products</T>
						</p>
					</SypacText>
				</div>
				<div className="flex gap-3">
					<Flag
						className="object-cover border border-solid border-gray-10 rounded mt-[2px]"
						code={store.countryCode}
						width={22}
						height={16}
					/>
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-80 mt-[2px]">{store.address}</p>
					</SypacText>
				</div>
			</div>
		</article>
	);

	useEffect(() => {
		getStoresCallback().then(() => {});
	}, [getStoresCallback]);

	useEffect(() => {
		getSearchLocation().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	useEffect(() => {
		if (storeLocation?.locationId) {
			getStoreCoords(storeLocation.locationId).then(() => {});
		}
	}, [storeLocation, storeName, getStoreCoords]);

	useEffect(() => {
		const checkIfOverViewport = () => {
			if (divRef.current) {
				const { bottom } = divRef.current.getBoundingClientRect();
				setIsScrollable(bottom > window.innerHeight);
			}
		};
		const timeoutId = setTimeout(checkIfOverViewport, 1500);
		return () => clearTimeout(timeoutId);
	}, []);

	const renderEmptyState = () => (
		<section className="w-[544px] h-full flex justify-center items-center">
			<div className="w-[339px] flex flex-col text-center gap-[40px] box-border">
				<div className="flex flex-col gap-3">
					<SypacText variant="body-regular-medium">
						<p className="text-2xl text-gray-80">
							<T keyName="productStores.noStoresYet">
								You don't have any stores yet
							</T>
						</p>
					</SypacText>
					{/* <SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-40">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt.
						</p>
					</SypacText> */}
				</div>
				<SypacButton variant="secondary" size="small">
					<button
						type="button"
						className="py-[9px] border-gray-10 rounded-lg transition hover:border-gray-40"
						onClick={() => {
							setOpenCreate(true);
							setShowList(false);
						}}
					>
						<SypacText variant="body-regular-medium">
							<p>
								<T keyName="productStores.createStore">Create store</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
			</div>
		</section>
	);

	return (
		<main className="flex w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-104px)] h-full">
			<section
				className={`relative flex flex-col gap-4 ${loading ? 'w-[849px]' : ''}`}
			>
				{showList && (
					<>
						<header className="flex flex-col gap-4 px-3 xl-2xl:px-5">
							<div className="flex items-center justify-between">
								<SypacText variant="heading-4">
									<p className="text-gray-90">
										<T keyName="productStores.myStores">My stores</T>
									</p>
								</SypacText>
								{myStores?.count! > 0 && (
									<SypacButton variant="subTitle">
										<button
											type="button"
											className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-mountain-meadow rounded-lg transition hover:bg-mountain-meadow/80"
											onClick={() => {
												setSelectedStore(null);
												setOpenCreate(true);
												setShowList(false);
											}}
										>
											<PlusIcon color="white" />
										</button>
									</SypacButton>
								)}
							</div>
						</header>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />
						{loading ? (
							<div className="w-[584px] h-full flex justify-center items-center z-[1000]">
								<MutatingDots
									height="100"
									width="100"
									color="#7693F4"
									secondaryColor="#494C83"
									radius="12.5"
									ariaLabel="mutating-dots-loading"
									visible={true}
								/>
							</div>
						) : (
							<div
								ref={divRef}
								className={`flex flex-col gap-3 px-3 xl-2xl:px-5 ${
									isScrollable
										? 'overflow-y-scroll overflow-x-hidden sypac-scrollbar mr-[2px]'
										: ''
								} ${!myStores?.items ? 'h-full justify-center' : ''}`}
							>
								{myStores?.count! > 0
									? myStores?.items?.map((store: ProductStoreInterface) => (
											<StoreCard
												key={store.name}
												store={store}
												onEdit={editStore}
												onClick={() => navigate(`/products/${store.id}`)}
											/>
									  ))
									: renderEmptyState()}
							</div>
						)}
					</>
				)}
			</section>

			{openCreate && (
				<NewStoreCreate
					locations={locations}
					onClose={handleClose}
					onChange={setSearchLocation}
					setStoreLocation={setStoreLocation}
					storeLocation={updatedStore!}
					setStoreName={setStoreName}
					isEditing={!!selectedStore}
					storeData={selectedStore!}
				/>
			)}

			<section className="w-full border border-solid border-gray-10 rounded-xl">
				{!loadingMap && <MemoizedMap markers={markers || []} />}
			</section>
		</main>
	);
};

export default ProductStores;
