import React, { useEffect, useState } from 'react';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import Flag from 'react-world-flags';
import { formatDate } from '../../../../../../utils/time.util';
import { InfoRowProps } from '../../../../Products/components/ProductDetails/ProductDetails.interface';
import { COUNTRIES } from '../../../../../../components/DropDownOption/countries.constant';
import Edit from './Edit';
import { ProductDetailsDatabaseProps } from '../../../productDatabase.interface';
import NewTrash from '../../../../../../assets/NewTrash';
import ModalDeleteProduct from './ModalDeleteProduct';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import EditProducer from './EditProducer';

const Overview: React.FC<ProductDetailsDatabaseProps> = ({
	product,
	deleteProduct,
	target,
	//onClose,
	activeLanguage,
}) => {
	const [readOnly, setReadOnly] = useState<boolean>(true);
	const [openDelete, setOpenDelete] = useState<boolean>(false);

	const InfoRow = ({ label, value, className = '' }: InfoRowProps) => (
		<section
			className={className || 'grid grid-cols-[185px,auto] gap-3 items-center'}
		>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-80">{value}</p>
			</SypacText>
		</section>
	);

	const ProductTypeSize = ({ type, size }: { type: string; size: string }) => {
		if (!type && !size) {
			return (
				<SypacText variant="body-regular-medium">
					<span className="text-gray-80">—</span>
				</SypacText>
			);
		}

		return (
			<div className="flex gap-4 items-center">
				{type && (
					<SypacText variant="body-regular-medium">
						<span className="text-gray-80">{type}</span>
					</SypacText>
				)}
				{type && size && (
					<span className="w-[2px] h-[2px] bg-gray-90 rounded-full" />
				)}
				{size && (
					<SypacText variant="body-regular-medium">
						<span className="text-gray-80">{size}</span>
					</SypacText>
				)}
			</div>
		);
	};

	const CountryDisplay = ({ countryCode }: { countryCode: string }) => (
		<div className="h-[22px] flex items-center">
			<div className="flex items-center gap-4">
				<Flag
					className="mb-[2px] object-cover border border-solid border-gray-10 rounded"
					code={countryCode}
					width={20}
					height={14}
				/>
				<SypacText variant="body-regular-medium">
					<span className="max-w-[262px] truncate">
						{
							COUNTRIES.find((country) => country['alpha-3'] === countryCode)
								?.label
						}
					</span>
				</SypacText>
			</div>
		</div>
	);

	useEffect(() => {
		setReadOnly(true);
	}, [product]);

	const handleDelete = () => deleteProduct?.(product?.id!);

	const renderActions = () => (
		<div className="flex gap-6">
			{/* <SypacButton variant="secondary" size="small">
				<button
					type="button"
					className="h-[28px] p-0 gap-3 border-0 group"
					onClick={() => setOpenDelete(true)}
				>
					<span className="flex scale-[1.3]">
						<NewTrash hoverEffect={true} />
					</span>
					<SypacText variant="body-regular-medium">
						<p className="text-primary-violet transition group-hover:text-primary-violet/70 mt-1">
							<T keyName="productDetails.delete">Delete</T>
						</p>
					</SypacText>
				</button>
			</SypacButton> */}

			<SypacButton variant="secondary" size="small">
				<button
					type="button"
					className="p-0 border-0 group"
					onClick={() => setReadOnly(false)}
				>
					<span className="flex m-0 scale-[1.3] mr-[2px]">
						<SypacIcon
							iconName="Document Add"
							size="custom"
							width="32px"
							height="32px"
							className="text-primary-violet transition group-hover:text-primary-violet/70"
						/>
					</span>
					<SypacText variant="body-regular-medium">
						<p className="text-primary-violet transition group-hover:text-primary-violet/70">
							<T keyName="productDetails.edit">Edit</T>
						</p>
					</SypacText>
				</button>
			</SypacButton>
		</div>
	);

	const renderGeneralInfo = () => (
		<section className="flex flex-col gap-[14px] px-10 pb-5">
			<SypacText variant="body-regular-medium" className="mb-[6px]">
				<p className="text-gray-80">
					<T keyName="productDetails.generalInformation">General information</T>
				</p>
			</SypacText>

			{target === OrderTarget.producer ? (
				<>
					<InfoRow
						label={<T keyName="productDetails.group">Group:</T>}
						value={product?.category?.parent?.name}
					/>
					<InfoRow
						label={<T keyName="productDetails.category">Category:</T>}
						value={product?.category?.name}
					/>
					<InfoRow
						label={<T keyName="productDetails.format">Format:</T>}
						value={
							product?.unit === 'ton'
								? product.unit.charAt(0).toUpperCase() + product.unit.slice(1)
								: product?.unit
						}
					/>
					<InfoRow
						label={
							<T keyName="productDetails.productTypeSize">
								Product type & size:
							</T>
						}
						value={
							<ProductTypeSize type={product?.type!} size={product?.size!} />
						}
					/>
					<InfoRow
						label={<T keyName="productDetails.productName">Product name:</T>}
						value={
							product?.translations?.find(
								(locale) => locale.language === activeLanguage,
							)?.name || product?.name
						}
					/>
					<InfoRow
						label={<T keyName="productDetails.created">Created:</T>}
						value={formatDate(product?.createdAt)}
					/>
				</>
			) : (
				<>
					<InfoRow
						label={<T keyName="productDetails.countryStore">Country store:</T>}
						value={<CountryDisplay countryCode={product?.countryCode!} />}
					/>
					<InfoRow
						label={<T keyName="productDetails.databaseId">Database ID:</T>}
						value={product?.id}
					/>
					<InfoRow
						label={<T keyName="productDetails.group">Group:</T>}
						value={product?.category?.parent?.name}
					/>
					<InfoRow
						label={<T keyName="productDetails.category">Category:</T>}
						value={product?.category?.name}
					/>
					<InfoRow
						label={<T keyName="productDetails.format">Format:</T>}
						value={
							product?.unit === 'ton'
								? product.unit.charAt(0).toUpperCase() + product.unit.slice(1)
								: product?.unit
						}
					/>
					<InfoRow
						label={
							<T keyName="productDetails.productTypeSize">
								Product type & size:
							</T>
						}
						value={
							<ProductTypeSize type={product?.type!} size={product?.size!} />
						}
					/>
					<InfoRow
						label={<T keyName="productDetails.productName">Product name:</T>}
						value={
							product?.translations?.find(
								(locale) => locale.language === activeLanguage,
							)?.name || product?.name
						}
					/>
					<InfoRow
						label={<T keyName="productDetails.created">Created:</T>}
						value={formatDate(product?.createdAt)}
					/>
					<InfoRow
						label={
							<T keyName="productDetails.productDescription">
								Product description:
							</T>
						}
						{...(product?.description && {
							className: 'flex flex-col items-start gap-3',
						})}
						value={product?.description || '—'}
					/>
				</>
			)}
		</section>
	);

	const renderPriceAvailability = () => (
		<>
			<div className="border-0 border-solid border-t-[1px] border-gray-10" />
			<section className="flex flex-col gap-[14px] px-10 pb-5">
				<SypacText variant="body-regular-medium" className="mb-[6px]">
					<p className="text-gray-80">
						<T keyName="productDetails.priceAvailability">
							Price & availability
						</T>
					</p>
				</SypacText>

				<InfoRow
					label={
						<T keyName="productDetails.availableStock">Available stock:</T>
					}
					value={
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{product?.availableQuantity! > 0 ? (
									<>
										{product.availableQuantity}{' '}
										<T keyName="productDetails.tons">tons</T>
									</>
								) : (
									'—'
								)}
							</p>
						</SypacText>
					}
				/>
				<InfoRow
					label={
						<T keyName="productDetails.minimumOrderCapacity">
							Minimum order capacity per day:
						</T>
					}
					value={
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{product.minOrderQuantity}{' '}
								<T keyName="productDetails.tons">tons</T>
							</p>
						</SypacText>
					}
				/>
				<InfoRow
					label={
						<T keyName="productDetails.maximumOrderCapacity">
							Maximum order capacity per day:
						</T>
					}
					value={
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{product.maxOrderCapacity}{' '}
								<T keyName="productDetails.tons">tons</T>
							</p>
						</SypacText>
					}
				/>
				<InfoRow
					label={
						<T keyName="productDetails.netPricePerTon">
							Net price per ton (excluding VAT):
						</T>
					}
					value={
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{product.pricePerUnit}{' '}
								{product.countryCode === 'MDA' ? 'MDL' : 'PLN'}
							</p>
						</SypacText>
					}
				/>
			</section>
		</>
	);

	if (!readOnly) {
		return target === OrderTarget.producer ? (
			<EditProducer onClose={() => setReadOnly(true)} product={product} />
		) : (
			<Edit onClose={() => setReadOnly(true)} product={product} />
		);
	}

	return (
		<>
			<article className="flex flex-col gap-5">
				<header className="flex border-0 border-b border-t border-solid border-gray-10">
					<div className="w-full flex items-center justify-between px-10 py-[10px]">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								<T keyName="productDetails.productInformation">
									Product information
								</T>
							</p>
						</SypacText>
						{renderActions()}
					</div>
				</header>

				{renderGeneralInfo()}
				{target === OrderTarget.producer && renderPriceAvailability()}
			</article>

			<ModalDeleteProduct
				isOpen={openDelete}
				onClose={() => setOpenDelete(false)}
				handleDelete={handleDelete}
				product={product}
			/>
		</>
	);
};

export default Overview;
