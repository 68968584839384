import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
	SypacBadge,
	SypacBox,
	SypacBreadcrumbs,
	SypacButton,
	SypacIcon,
	SypacStepper,
	SypacStepperStep,
	SypacText,
} from '@sypac/component-library-react';
import Flag from 'react-world-flags';

import Close from '../../../assets/Close';

import '../select-product.scss';
import { QuantityPickerContext } from '../../../context/QuantityPickerContext/quantity-picker.context';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { LineStroke } from '../../../assets/LineStroke';
import { COUNTERS } from '../constants';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { OrdersService, Prices } from '../../../services/orders.services';
import { GeoService } from '../../../services/geo.services';
import { T, useTranslate } from '@tolgee/react';

// TODO implement products list infinite scroll in client store
const OrderConfirmation: React.FC = () => {
	const { t } = useTranslate();
	const carPlaceholder = '/car-quantity.png';
	const navigate = useNavigate();
	const { order } = useContext(OrderContext);
	const { totalQuantityCounter, inputQuantity } = useContext(
		QuantityPickerContext,
	);
	const [prices, setPrices] = useState<Prices>({
		productPriceForClient: 0,
		productPriceForClientWithVat: 0,
		transportPriceForClient: 0,
		transportPriceForClientWithVat: 0,
	});
	const [pricesAvailable, setPricesAvailable] = useState(true);

	const currentProduct = useMemo(() => order?.product, [order?.product]);

	const currentCategory = useMemo(() => order?.category, [order?.category]);
	const currentGroup = useMemo(() => order?.group, [order?.group]);

	const address = useMemo(
		() =>
			order?.location
				? [
						[
							order?.location?.address?.street,
							order?.location?.address?.houseNumber,
						].join(' ') || '',
						order?.location?.address?.postalCode || '',
						order?.location?.address?.city || '',
						order?.location?.address?.country || '',
				  ]
						.filter((r) => r)
						.join(', ')
				: '',
		[order?.location],
	);

	const getPrices = useCallback(async () => {
		try {
			let quantity = 0;
			let trucksNr = 1;
			if (totalQuantityCounter?.length) {
				quantity = totalQuantityCounter?.reduce(
					(total, item) => total + item.payload * item.quantity,
					0,
				);
				trucksNr = totalQuantityCounter?.reduce(
					(total, item) => total + item.quantity,
					0,
				);
			}
			if (inputQuantity) {
				quantity = inputQuantity;
			}
			let latDelivery = 0;
			let longDelivery = 0;
			if (order?.location?.locationId) {
				const resp = await GeoService.getLocationDetails({
					locationid: order?.location?.locationId,
				});
				latDelivery = resp?.data?.Latitude;
				longDelivery = resp?.data?.Longitude;
			}
			const { data } = await OrdersService.calculatePrice({
				quantity,
				trucksNr,
				latPickup: order?.product?.location?.coordinates?.length
					? order?.product?.location?.coordinates[1]
					: 47.04014,
				longPickup: order?.product?.location?.coordinates.length
					? order?.product?.location?.coordinates[0]
					: 28.80058,
				latDelivery,
				longDelivery,
				productId: currentProduct?.id!,
			});
			setPrices(data);
			setPricesAvailable(false);
		} catch (e) {
			setPricesAvailable(true);
		}
	}, [
		currentProduct?.id,
		inputQuantity,
		order?.location?.locationId,
		order?.product?.location?.coordinates,
		totalQuantityCounter,
	]);

	useEffect(() => {
		getPrices();
	}, [getPrices]);

	const next = () => {
		navigate('/store/payment');
	};

	return (
		<section className="client-select-product">
			<SearchBar
				placeholder={t('orderConfirmation.searchPlacehoder', 'Search Products')}
			/>
			<div className="mt-6">
				<SypacText variant="body-regular-medium">
					<p className="align-middle text-gray-80">
						<T keyName="clientOrderConfirmation.deliveryAddress">
							Delivery address:
						</T>
						<span className="flag">
							<Flag
								code={order?.location?.countryCode.toUpperCase()}
								width={24}
								height={16}
							/>
						</span>
						{address}
						<SypacButton
							variant="secondary"
							size="medium"
							className="inline-block mx-6"
						>
							<a href="/store" className="close">
								<Close width="8" height="8" />
							</a>
						</SypacButton>
					</p>
				</SypacText>
			</div>
			<SypacBreadcrumbs className="my-7">
				<div
					className="cursor-pointer text-cornflower-blue"
					onClick={() => navigate('/store/groups')}
				>
					<T keyName="orderConfirmation.store">Store</T>
				</div>
				<div
					className="cursor-pointer text-cornflower-blue"
					onClick={() =>
						navigate(`/store/groups/${currentGroup?.id}/categories`)
					}
				>
					{currentGroup?.name}
				</div>
				<div
					className="cursor-pointer text-cornflower-blue"
					onClick={() =>
						navigate(
							`/store/groups/${currentGroup?.id}/categories/${order?.category?.id}/products`,
						)
					}
				>
					{currentCategory?.name}
				</div>
				<div className="text-cornflower-blue">{currentProduct?.name}</div>
			</SypacBreadcrumbs>
			<div className="overflow-x-auto w-full">
				<SypacStepper>
					<SypacStepperStep>
						<SypacText variant="body-regular-small">
							<span>
								<T keyName="orderConfirmation.selectQuantity">
									Select quantity
								</T>
							</span>
						</SypacText>
					</SypacStepperStep>
					<SypacStepperStep selected={true}>
						<SypacText variant="body-regular-small">
							<span>
								<T keyName="orderConfirmation.enterDeliveryDate">
									Enter delivery date
								</T>
							</span>
						</SypacText>
					</SypacStepperStep>
					<SypacStepperStep selected={true}>
						<SypacText variant="body-regular-small">
							<span>
								<T keyName="orderConfirmation.orderConfirmation">
									Order confirmation
								</T>
							</span>
						</SypacText>
					</SypacStepperStep>
					<SypacStepperStep>
						<SypacText variant="body-regular-small">
							<span>
								<T keyName="orderConfirmation.selectPaymentMethod">
									Select payment method
								</T>
							</span>
						</SypacText>
					</SypacStepperStep>
				</SypacStepper>
			</div>
			<div className="my-7 flex">
				<div
					style={{ backgroundImage: `url(${currentProduct?.photoUrl})` }}
					className="product-image"
					role="img"
				/>
				<div className="ml-6">
					<SypacText variant="heading-5">
						<h5>{currentProduct?.name}</h5>
					</SypacText>
					<SypacText variant="body-regular-small" className="mt-3">
						<span>
							{currentProduct?.type}{' '}
							{currentProduct?.size ? `(${currentProduct?.size})` : ''}
						</span>
					</SypacText>
				</div>
			</div>
			<div className="flex">
				<SypacBox
					className="px-6 pb-6 pt-4 flex flex-col items-center box-border"
					box-shadow="xs"
				>
					<div className="flex flex-row w-full">
						<SypacText variant="body-regular-medium" className="mb-4">
							<p className="text-gray-90">
								<T keyName="orderConfirmation.orderConfirmation">
									Order confirmation
								</T>
							</p>
						</SypacText>
					</div>
					<div className="relative overflow-hidden w-full">
						<LineStroke width={[900, 900, 908]} />
					</div>
					<div className="flex flex-col my-6 w-full">
						<SypacText variant="body-regular-medium" className="mb-6">
							<p className="text-gray-80">
								<T keyName="orderConfirmation.deliveryDateAddress">
									Delivery date & address:
								</T>
							</p>
						</SypacText>
						<div className="flex flex-row">
							<div className="flex flex-col">
								<SypacText variant="body-regular-medium" className="mb-4">
									<p className="text-gray-40">
										<T keyName="orderConfirmation.date">Date:</T>
									</p>
								</SypacText>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-40">
										<T keyName="orderConfirmation.address">Address:</T>
									</p>
								</SypacText>
							</div>
							<div className="flex flex-col ml-11">
								{/*<SypacText variant="body-regular-medium" className="ml-5 mb-4">
									<p className="text-gray-80">
										From {dayjs(order?.dateFrom).format('DD MMM, YYYY')} —{' '}
										{dayjs(order?.dateTo).format('DD MMM, YYYY')}
									</p>
								</SypacText>*/}
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<span className="flag">
											<Flag
												code={order?.location?.countryCode.toUpperCase()}
												width={24}
												height={16}
											/>
										</span>
										{address}
									</p>
								</SypacText>
							</div>
						</div>
					</div>
					<div className="relative overflow-hidden w-full">
						<LineStroke width={[900, 900, 908]} />
					</div>
					<div className="flex flex-col my-6 w-full">
						<SypacText variant="body-regular-medium" className="mb-6">
							<p className="text-gray-80">
								<T keyName="orderConfirmation.priceQuantity">
									Price & quantity:
								</T>
							</p>
						</SypacText>
						{inputQuantity ? (
							<div className="flex flex-col w-full">
								<div className="flex flex-row justify-between mb-4 items-end">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-40 whitespace-nowrap">
											<T keyName="orderConfirmation.customQuantity">
												Custom quantity
											</T>
										</p>
									</SypacText>
									<div className="border-b border-gray-10 border-dashed w-full mx-5 relative top-[-4px]" />
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80 whitespace-nowrap">
											{inputQuantity}{' '}
											<T keyName="orderConfirmation.tons">tons</T>
										</p>
									</SypacText>
								</div>
								<div className="relative overflow-hidden flex flex-row justify-center w-full my-6">
									<LineStroke width={[900, 900, 908]} />
								</div>
							</div>
						) : (
							<div className="flex flex-col">
								{totalQuantityCounter?.map((item) => {
									const car = COUNTERS.find((r) => r.id === item.type);
									return (
										<div className="flex flex-row justify-between mb-6 items-end">
											<div className="flex flex-row">
												<div
													style={{
														backgroundImage: `url(${
															car?.car ? car?.car : carPlaceholder
														})`,
													}}
													className="flex justify-center items-center w-[64px] sm:w-[100px] h-[55px] border border-solid border-gray-10 rounded-10 box-border"
													role="img"
												/>
												<div className="flex flex-col ml-6">
													<SypacText variant="body-regular-large">
														<p className="text-gray-90">{car?.name}</p>
													</SypacText>
													<SypacText
														variant="body-regular-medium"
														className="mt-2"
													>
														<p className="text-gray-90">
															<T keyName="orderConfirmation.payload">
																Payload:
															</T>{' '}
															{car?.payload}{' '}
															<T keyName="orderConfirmation.tons">tons</T>
														</p>
													</SypacText>
												</div>
											</div>
											<div className="flex flex-row items-center">
												<SypacText variant="body-regular-small">
													<p className="text-gray-40">
														<T keyName="orderConfirmation.totalSelectedQuantity">
															Total selected quantity:
														</T>{' '}
														{item.payload}{' '}
														<T keyName="orderConfirmation.tons">tons</T>
													</p>
												</SypacText>

												<SypacBadge className="ml-6">
													<div className="border border-solid border-gray-10 bg-transparent w-7 h-8 flex flex-row justify-center items-center">
														<SypacText variant="body-regular-medium">
															<p className="text-gray-90">{item.quantity}</p>
														</SypacText>
													</div>
												</SypacBadge>
											</div>
										</div>
									);
								})}
							</div>
						)}
						<div className="flex flex-col">
							<div className="flex flex-row justify-between mb-4 items-end">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-40 whitespace-nowrap">
										<T keyName="orderConfirmation.shippingVatExcl">
											Shipping cost (excl. VAT)
										</T>
									</p>
								</SypacText>
								<div className="border-b border-gray-10 border-dashed w-full mx-5 relative top-[-4px]" />
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 whitespace-nowrap">
										{prices.transportPriceForClient.toFixed(2)} PLN
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row justify-between mb-4 items-end">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-40 whitespace-nowrap">
										<T
											keyName="orderConfirmation.shippingVatIncl"
											params={{ vat: 23 }}
										>
											Shipping cost (incl. VAT)
										</T>
									</p>
								</SypacText>
								<div className="border-b border-gray-10 border-dashed w-full mx-5 relative top-[-4px]" />
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 whitespace-nowrap">
										{prices.transportPriceForClientWithVat.toFixed(2)} PLN
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row justify-between mb-4 items-end">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-40 whitespace-nowrap">
										<T keyName="orderConfirmation.productVatExcl">
											Product (excl. VAT)
										</T>
									</p>
								</SypacText>
								<div className="border-b border-gray-10 border-dashed w-full mx-5 relative top-[-4px]" />
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 whitespace-nowrap">
										{prices.productPriceForClient.toFixed(2)} PLN
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row justify-between mb-4 items-end">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-40 whitespace-nowrap">
										<T
											keyName="orderConfirmation.productVatIncl"
											params={{ vat: order?.product?.vat || 0 }}
										>
											Product (incl. VAT)
										</T>
									</p>
								</SypacText>
								<div className="border-b border-gray-10 border-dashed w-full mx-5 relative top-[-4px]" />
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80 whitespace-nowrap">
										{prices.productPriceForClientWithVat.toFixed(2)} PLN
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row justify-between items-end">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-90 whitespace-nowrap font-bold">
										<T keyName="orderConfirmation.totalVatExcl">
											Total price (excl. VAT)
										</T>
									</p>
								</SypacText>
								<div className="border-b border-gray-10 border-dashed w-full mx-5 relative top-[-4px]" />
								<SypacText variant="body-regular-medium">
									<p className="text-gray-90 whitespace-nowrap font-bold">
										{(
											prices.productPriceForClient +
											prices.transportPriceForClient
										).toFixed(2)}{' '}
										PLN
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row justify-between items-end">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-90 whitespace-nowrap font-bold">
										<T keyName="orderConfirmation.totalVatIncl">
											Total price (incl. VAT)
										</T>
									</p>
								</SypacText>
								<div className="border-b border-gray-10 border-dashed w-full mx-5 relative top-[-4px]" />
								<SypacText variant="body-regular-medium">
									<p className="text-gray-90 whitespace-nowrap font-bold">
										{(
											prices.productPriceForClientWithVat +
											prices.transportPriceForClientWithVat
										).toFixed(2)}{' '}
										PLN
									</p>
								</SypacText>
							</div>
						</div>
					</div>
				</SypacBox>
			</div>
			<div className="flex flex-row gap-5 justify-center my-20">
				<SypacButton
					variant="secondary"
					size="small"
					onClick={() => {
						window.history.back();
					}}
				>
					<button className="flex flex-row gap-6 px-6 py-[13px]">
						<SypacIcon
							size="custom"
							width="10px"
							height="12px"
							icon-name="Alt Arrow Left"
						/>
						<SypacText variant="body-normal-medium">
							<p>
								<T keyName="orderConfirmation.back">Back</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
				<SypacButton variant="secondary" size="small">
					<button
						className="flex flex-row gap-6 px-6 py-[13px]"
						onClick={next}
						disabled={pricesAvailable}
					>
						<SypacText variant="body-normal-medium">
							<p>
								<T keyName="orderConfirmation.next">Next</T>
							</p>
						</SypacText>
						<SypacIcon
							size="custom"
							width="10px"
							height="12px"
							icon-name="Alt Arrow Right"
						/>
					</button>
				</SypacButton>
			</div>
		</section>
	);
};

export default OrderConfirmation;
