import { FileInterface } from '../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import {
	BankDetailsInterface,
	ContactCompany,
} from '../services/company.services';

export interface PhoneDto {
	phoneNumber: string;
	isViber: boolean;
	isTelegram: boolean;
	isWhatsapp: boolean;
}

export interface ProfileInterface {
	id: string;
	uid: string;
	firstName: string;
	lastName: string;
	name?: string;
	email: string;
	phoneNumber?: PhoneDto;
	secondaryPhoneNumber?: PhoneDto;
	countryCode: string;
	secondaryEmail?: string;
	photoUrl?: string;
	role?: string;
}

export interface UsersInterface {
	status: UserStatus | string;
	uid: string;
	country?: string;
	registered?: string;
	name: string;
	seen?: string;
	assigned?: string;
	avatarUrl?: string;
	email: string;
	company?: Company;
	role?: string;
	countryCode?: string;
	firstName?: string;
	id?: string;
	lastName?: string;
	phoneNumber?: PhoneDto;
	secondaryPhoneNumber?: PhoneDto;
	secondaryEmail?: string;
	profile?: ProfileInterface;
	photoUrl?: string;
}

export interface UsersList {
	items: UsersInterface[];
	count: number;
}

export interface Company {
	id?: string;
	name?: string;
	industry?: string;
	alowExcludedTransporter?: boolean;
	alowUploadInvoice?: boolean;
	bankDetails?: BankDetailsInterface;
	billingAddress?: string;
	companyTypes?: string[];
	contact?: ContactCompany;
	countryCode?: string;
	createdAt?: string;
	establishedDate?: string;
	files?: FileInterface[];
	logoUrl?: string;
	nextInvoicingDate?: string;
	paymentTerm?: number;
	registrationNumber?: string;
	registryAddress?: string;
	status?: string;
	taxNumber?: string;
	updatedAt?: string;
	vatId?: string;
	walletId?: number;
	website?: string;
}

export enum UserStatus {
	total = 'total',
	AWAIT_EMAIL_CONFIRMATION = 'awaitEmailConfirmation',
	APPROVED = 'approved',
	DISABLED = 'disabled',
	ASSIGNED = 'assigned',
	UNASSIGNED = 'unassigned',
}
