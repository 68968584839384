import React, { useMemo, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	CommonNotificationsEnums,
	OrderInterface,
} from '../../../../../components/OrdersTable/interfaces/Order.interface';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { Calendar } from '../../../../../assets/Calendar';
import TooltipCircle from '../../../../../assets/TooltipCircle';
import ModalTruckDetails from '../ModalTruckDetails';
import { Tooltip } from 'react-tooltip';
import Clock from '../../../../../assets/Clock';
import { convertToTimeZone, formatDate } from '../../../../../utils/time.util';
import StatusBadge from '../../../../../components/StatusBadge/StatusBadge';
import { PlusIcon } from '../../../../../assets/PlusIcon';
import TruckIcon from '../../../../../components/TruckIcon/TruckIcon';
import { numberTwoDecimal } from '../../../../../utils/number.util';
import { InfoRowProps } from '../../../../../components/BaseOrderDetails/BaseOrderDetails.interface';

interface DeliveryScheduleProps {
	selectedOrder: OrderInterface | undefined;
	deliveryIncluded: boolean;
	step: number;
	simpleOrder: boolean;
}

const DeliverySchedule: React.FC<DeliveryScheduleProps> = (props) => {
	const { t } = useTranslate();
	const { selectedOrder, deliveryIncluded, step, simpleOrder } = props;
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [orderTruckId, setOrderTruckId] = useState<number>(0);

	const orderTrucks = useMemo(() => {
		return selectedOrder?.trucks?.slice().sort((a, b) => a.id - b.id);
	}, [selectedOrder?.trucks]);

	const variationPercent = (weight: number, quantity: number) => {
		const result = ((weight - quantity) / quantity) * 100 || 0;
		const percent = Number.isInteger(result)
			? result
			: parseFloat(result.toFixed(2));
		return result > 0 ? `+${percent}` : `${percent}`;
	};

	const openModal = (id: number) => {
		setOrderTruckId(id);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const InfoRow = ({ label, value, children }: InfoRowProps) => (
		<div className="grid grid-cols-[250px,auto] items-start">
			<SypacText variant="body-regular-medium">
				<p className="text-gray-40">{label}</p>
			</SypacText>
			<SypacText variant="body-regular-medium">
				<p className="text-gray-80">{children || value}</p>
			</SypacText>
		</div>
	);

	const referenceFormatter = (id: string) => {
		return id.substring(0, 3) + ' ' + id.substring(3);
	};

	const isPickUpDate = (status: string) => {
		const pickUpDateStatuses = [
			'requested',
			'accepted',
			'to_loading',
			'arrived_to_loading',
			'loading',
		];
		return pickUpDateStatuses.includes(status);
	};

	return (
		<>
			<div className="bg-white border border-solid border-gray-10 rounded-xl">
				<SypacText variant="body-regular-medium" className="px-5 pt-4 mb-4">
					<p className="text-gray-60">
						{deliveryIncluded ? (
							<T keyName="deliverySchedule.deliverySchedule">
								Delivery schedule
							</T>
						) : (
							<T keyName="deliverySchedule.pickUpSchedule">Pick-up schedule</T>
						)}
					</p>
				</SypacText>

				<div className="flex flex-col gap-4 pb-4">
					{orderTrucks?.map((orderTruck, index) => (
						<div className="flex flex-col gap-4" key={orderTruck?.id}>
							<div className="flex gap-4 px-5">
								<span className="w-[98px] h-[53px] flex items-center justify-center bg-alabaster border border-solid border-gray-10 rounded-10">
									<TruckIcon type={orderTruck?.truckType} />
								</span>
								<div className="flex flex-col gap-[5px] justify-center">
									<div className="flex gap-4">
										<SypacText variant="body-regular-medium">
											<p className="leading-[26px] text-gray-90">
												<T keyName={`quantityPicker.${orderTruck?.truckType}`}>
													{orderTruck?.truckType?.charAt(0).toUpperCase()! +
														orderTruck?.truckType?.slice(1)}
												</T>
											</p>
										</SypacText>

										{!simpleOrder ? (
											<div className="flex items-center w-fit h-[22px] bg-gray-10 rounded-md px-[14px]">
												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-90">
														<T keyName="requestedStep.payloadTruck">Truck</T>{' '}
														{index + 1}
													</p>
												</SypacText>
											</div>
										) : null}
									</div>
									<SypacText variant="body-regular-small">
										<p className="text-gray-40">
											<T keyName="quantityPicker.payload">Payload:</T>{' '}
											{orderTruck?.payload}{' '}
											<T keyName="quantityCounter.tons">tons</T>
										</p>
									</SypacText>
								</div>

								<div className="h-[22px] ml-auto">
									<StatusBadge
										status={orderTruck?.orderStatus!}
										isTruckStatus={true}
										deliveryIncluded={deliveryIncluded}
									/>
								</div>
							</div>

							<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							<div className="flex flex-col gap-3 px-5">
								{deliveryIncluded &&
								selectedOrder?.notifications?.items?.some(
									(notify) =>
										notify.category ===
											CommonNotificationsEnums.ORDER_COMPLETE &&
										notify.content?.driverId === orderTruck?.driver?.uid,
								) ? (
									<>
										<div
											className={`grid grid-cols-[250px,auto] items-start ${
												simpleOrder ? 'mb-4' : ''
											}`}
										>
											<SypacText variant="body-regular-medium">
												<p className="text-gray-40">
													{deliveryIncluded ? (
														<T keyName="basicDetails.deliveryDate">
															Delivery date:
														</T>
													) : (
														<T keyName="deliverySchedule.loadingconfirmationDate">
															Loading confirmation date:
														</T>
													)}
												</p>
											</SypacText>

											<div className="flex flex-col gap-3">
												<div className="flex gap-6 [&_p]:text-gray-80">
													<div className="flex gap-3">
														<span className="scale-[1.1]">
															<Calendar />
														</span>
														<SypacText variant="body-regular-medium">
															<p>
																{formatDate(
																	selectedOrder?.notifications?.items?.find(
																		(notify) =>
																			notify.category ===
																				CommonNotificationsEnums.ORDER_COMPLETE &&
																			notify.content?.driverId ===
																				orderTruck?.driver?.uid,
																	)?.createdAt,
																)}
															</p>
														</SypacText>
													</div>

													<div className="flex gap-3">
														<span className="scale-[1.1]">
															<Clock />
														</span>
														<SypacText variant="body-regular-medium">
															<p>
																{convertToTimeZone(
																	selectedOrder?.notifications?.items?.find(
																		(notify) =>
																			notify.category ===
																				CommonNotificationsEnums.ORDER_COMPLETE &&
																			notify.content?.driverId ===
																				orderTruck?.driver?.uid,
																	)?.createdAt!,
																)}
															</p>
														</SypacText>
													</div>
												</div>
											</div>
										</div>
										{!simpleOrder && (
											<div className="grid grid-cols-[250px,auto] items-start mb-4">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-40">
														<T keyName="deliverySchedule.truckArrivalConfirmation">
															Truck arrival confirmation:
														</T>
													</p>
												</SypacText>

												<div className="flex flex-col gap-3">
													<div className="flex gap-6 [&_p]:text-gray-80">
														<div className="flex gap-3">
															<span className="scale-[1.1]">
																<Calendar />
															</span>
															<SypacText variant="body-regular-medium">
																<p>
																	{formatDate(
																		selectedOrder?.notifications?.items?.find(
																			(notify) =>
																				notify.category ===
																					CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS &&
																				notify.content?.driverId ===
																					orderTruck?.driver?.uid,
																		)?.createdAt,
																	)}
																</p>
															</SypacText>
														</div>

														<div className="flex gap-3">
															<span className="scale-[1.1]">
																<Clock />
															</span>
															<SypacText variant="body-regular-medium">
																<p>
																	{convertToTimeZone(
																		selectedOrder?.notifications?.items?.find(
																			(notify) =>
																				notify.category ===
																					CommonNotificationsEnums.ORDER_START_UNLOADING_GOODS &&
																				notify.content?.driverId ===
																					orderTruck?.driver?.uid,
																		)?.createdAt!,
																	)}
																</p>
															</SypacText>
														</div>
													</div>
												</div>
											</div>
										)}
									</>
								) : (
									<div className="grid grid-cols-[250px,auto] items-start mb-4">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-40">
												{!deliveryIncluded ||
												isPickUpDate(orderTruck.orderStatus) ? (
													<T keyName="clientDeliveryDetails.scheduledPickUpDate">
														Scheduled pick-up date:
													</T>
												) : (
													<T keyName="clientDeliveryDetails.deliveryDate">
														Scheduled delivery date:
													</T>
												)}
											</p>
										</SypacText>
										<div className="flex flex-col gap-3">
											<div className="flex gap-3 [&_p]:text-gray-80">
												<span className="scale-[1.1]">
													<Calendar />
												</span>
												<SypacText variant="body-regular-medium">
													<p>
														{formatDate(orderTruck?.deliveryFrom, '', 'DD.MM')}{' '}
														- {formatDate(orderTruck?.deliveryTo)}
													</p>
												</SypacText>
											</div>

											<div className="flex gap-3 [&_p]:text-gray-80">
												<span className="scale-[1.1]">
													<Clock />
												</span>
												<div className="flex gap-5 items-center">
													{selectedOrder?.trucks ? (
														convertToTimeZone(orderTruck.deliveryTimeFrom) ===
															'0:00' &&
														convertToTimeZone(orderTruck.deliveryTimeTo) ===
															'23:59' ? (
															<SypacText variant="body-regular-medium">
																<p>Full Time</p>
															</SypacText>
														) : (
															<>
																<SypacText variant="body-regular-medium">
																	<p>
																		{convertToTimeZone(
																			orderTruck.deliveryTimeFrom,
																		)}
																	</p>
																</SypacText>
																<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
																<SypacText variant="body-regular-medium">
																	<p>
																		{convertToTimeZone(
																			orderTruck.deliveryTimeTo,
																		)}
																	</p>
																</SypacText>
															</>
														)
													) : null}
												</div>
											</div>
										</div>
									</div>
								)}

								{deliveryIncluded ? (
									<InfoRow
										label={
											orderTruck.driver
												? t('assignOrder.referenceId', 'Reference ID:')
												: t(
														'clientDeliveryDetails.deliveryReferenceId',
														'Delivery reference ID:',
												  )
										}
									>
										<div className="flex items-center gap-3">
											<SypacText variant="body-regular-medium">
												{orderTruck.driver ? (
													<p className="text-cornflower-blue font-bold">
														{referenceFormatter(orderTruck.referenceId!)}
													</p>
												) : (
													<p className="text-gray-80">
														<T keyName="deliverySchedule.pendingAllocation">
															Pending allocation
														</T>
													</p>
												)}
											</SypacText>
											<span
												data-tooltip-id="delivery-schedule"
												data-tooltip-content={t(
													'truckSchedule.referenceId',
													'Truck Reference ID: A unique code',
												)}
												className="p-0 m-0 mb-[2px] flex scale-[1.1]"
											>
												<TooltipCircle />
											</span>
										</div>
									</InfoRow>
								) : (
									<>
										<InfoRow
											label={t(
												'deliverySchedule.pickUpId:',
												'Pick-up reference ID:',
											)}
										>
											<div className="flex items-center gap-3">
												<SypacText variant="body-regular-medium">
													{step >= 2 || (!simpleOrder && orderTruck.driver) ? (
														<p className="text-cornflower-blue font-bold">
															{referenceFormatter(orderTruck.referenceId!)}
														</p>
													) : (
														<p className="text-gray-80">
															<T keyName="deliverySchedule.pendingAllocation">
																Pending allocation
															</T>
														</p>
													)}
												</SypacText>
												<span
													data-tooltip-id="delivery-schedule"
													data-tooltip-content={t(
														'truckSchedule.referenceId',
														'Truck Reference ID: A unique code',
													)}
													className="p-0 m-0 mb-[2px] flex scale-[1.1]"
												>
													<TooltipCircle />
												</span>
											</div>
										</InfoRow>
										<div className="grid grid-cols-[250px,auto] items-start justify-start">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-40">
													<T keyName="deliverySchedule.driverName">
														Driver name:
													</T>
												</p>
											</SypacText>
											{orderTruck.driver ? (
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">
														{orderTruck.driver.driverFullName || '-'}
													</p>
												</SypacText>
											) : step >= 3 ? (
												<span>-</span>
											) : (
												<SypacButton variant="secondary" size="small">
													<button
														className="h-[22px] w-full border-none bg-transparent p-0 group"
														type="button"
														onClick={() => openModal(orderTruck?.id)}
													>
														<span className="scale-[1.1] mr-3">
															<PlusIcon />
														</span>
														<SypacText variant="body-normal-medium">
															<p>
																<T keyName="deliverySchedule.addTruckDetailsOptional">
																	Add truck details (optional)
																</T>
															</p>
														</SypacText>
													</button>
												</SypacButton>
											)}
										</div>
										<div className="grid grid-cols-[250px,auto] items-start justify-start">
											<SypacText variant="body-regular-medium">
												<p className="text-gray-40">
													<T keyName="deliverySchedule.licensePlates">
														License plates:
													</T>
												</p>
											</SypacText>
											{orderTruck.driver ? (
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">
														{orderTruck.driver.licensePlates || '-'}
													</p>
												</SypacText>
											) : step >= 3 ? (
												<span>-</span>
											) : (
												<SypacButton variant="secondary" size="small">
													<button
														className="h-[22px] w-full border-none bg-transparent p-0 group"
														type="button"
														onClick={() => openModal(orderTruck?.id)}
													>
														<span className="scale-[1.1] mr-3">
															<PlusIcon />
														</span>
														<SypacText variant="body-normal-medium">
															<p>
																<T keyName="deliverySchedule.addTruckDetailsOptional">
																	Add truck details (optional)
																</T>
															</p>
														</SypacText>
													</button>
												</SypacButton>
											)}
										</div>
									</>
								)}

								{orderTruck.driver && deliveryIncluded ? (
									<>
										<InfoRow
											label={t('deliverySchedule.driverName', 'Driver name:')}
											value={orderTruck.driver?.driverFullName || '-'}
										/>
										<InfoRow
											label={t(
												'deliverySchedule.licensePlates',
												'License plates:',
											)}
											value={orderTruck.driver?.licensePlates || '-'}
										/>
										{orderTruck?.loadedQuantity && (
											<InfoRow
												label={t(
													'baseOrderDetails.loadedQuantity',
													'Loaded quantity:',
												)}
											>
												<div className="flex gap-3">
													<SypacText variant="body-regular-medium">
														<p>
															{numberTwoDecimal(orderTruck?.loadedQuantity)}{' '}
															<T keyName="basicDetails.tons">tons</T>
														</p>
													</SypacText>
													<span className="flex items-center bg-gray-10 rounded px-1">
														<SypacText variant="body-regular-medium">
															<p className="text-xs leading-none">
																{variationPercent(
																	orderTruck.loadedQuantity,
																	orderTruck.payload,
																)}
																%
															</p>
														</SypacText>
													</span>
													<SypacText
														variant="body-regular-medium"
														className="self-center"
													>
														<p className="text-sm text-gray-40 leading-none">
															<T keyName="baseOrderDetails.quantityDifference">
																Quantity difference
															</T>
														</p>
													</SypacText>
												</div>
											</InfoRow>
										)}
									</>
								) : null}
							</div>

							{index !== orderTrucks.length - 1 ? (
								<div className="border-0 border-solid border-t-[1px] border-gray-10" />
							) : null}
						</div>
					))}
				</div>
			</div>
			<ModalTruckDetails
				isOpen={isModalOpen}
				onClose={closeModal}
				orderId={selectedOrder?.id!}
				truckId={orderTruckId}
			/>
			<Tooltip
				place="top"
				id="delivery-schedule"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
					whiteSpace: 'normal',
					maxWidth: '248px',
				}}
			/>
		</>
	);
};

export default DeliverySchedule;
