import React, { DragEvent, useContext, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useFormik } from 'formik';
import { toastVariant } from '../../../components/ToastVariant/toastVariant';
import { FileInterface } from '../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { FileService } from '../../../services/file.service';
import {
	CategoriesService,
	CategoryBody,
} from '../../../services/categories.services';
import { ProductContext } from '../../../context/DatabaseProductContext/database-product.context';
import Flag from 'react-world-flags';
import * as Yup from 'yup';
import { AddEditGroupCategoryProps, Language } from './addNewProduct.interface';
import { useGetCategories } from '../../../hooks/use-get-categories';
import { ChevronRightIcon } from '../../../assets/ChevronRightIcon';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { MaxFileSizeMB } from '../../../constants';

const SUPPORTED_LANGUAGES: Language[] = [
	{ code: 'en', name: 'English', fieldName: 'entityName', flagCode: 'GBR' },
	{ code: 'pl', name: 'Polish', fieldName: 'entityNamePL', flagCode: 'POL' },
	{ code: 'ro', name: 'Romanian', fieldName: 'entityNameRO', flagCode: 'ROU' },
	{ code: 'ru', name: 'Russian', fieldName: 'entityNameRU', flagCode: 'RUS' },
];

const AddEditGroupCategory: React.FC<AddEditGroupCategoryProps> = ({
	current,
	onClose,
	type,
}) => {
	const { t } = useTranslate();
	const { product } = useContext(ProductContext);
	const [groups] = useGetCategories({}, 'admin');
	const [file, setFile] = useState<FileInterface>();
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const getInitialValue = (langCode: string) =>
		current?.translations?.find(
			(locale: { language: string }) => locale.language === langCode,
		)?.name || '';

	const formik = useFormik({
		initialValues: {
			entityName: getInitialValue('en'),
			entityNameRO: getInitialValue('ro'),
			entityNameRU: getInitialValue('ru'),
			entityNamePL: getInitialValue('pl'),
			...(type === 'category' && { parentId: product?.productGroup }),
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			const translations = SUPPORTED_LANGUAGES.map((lang) => ({
				language: lang.code,
				name: values[lang.fieldName as keyof typeof values] || '',
			})).filter((translation) => translation.name);

			const body: CategoryBody = {
				name: values.entityName,
				countryCode: product?.countryCode!,
				...(type === 'category' && { parentId: values.parentId }),
				...(file && { imageUrl: file?.file?.name }),
				translations,
			};

			if (current) {
				await CategoriesService.updateCategory(current.id, body);
			} else {
				await CategoriesService.createCategory(body);
			}
			handleReset();
		},
		validationSchema: Yup.object({
			entityName: Yup.string().required('English name is required'),
			entityNamePL: Yup.string(),
			entityNameRO: Yup.string(),
			entityNameRU: Yup.string(),
			...(type === 'category' && {
				parentId: Yup.string().required('Category group is required'),
			}),
		}),
	});

	const handleFileUpload = async (uploadedFile: File) => {
		if (uploadedFile.size / (1024 * 1024) > MaxFileSizeMB.IMAGE) {
			toastVariant(
				`File size exceeds ${MaxFileSizeMB.IMAGE} MB. Please upload a smaller file.`,
				true,
			);
			return;
		}

		try {
			const { data } = await FileService.uploadFiles([uploadedFile]);
			const newFile = data.items[0];
			const [name, type] = newFile.originalName?.split('.') || [];
			const mimeType =
				newFile.type?.split('/').pop()?.toUpperCase() ||
				type?.toUpperCase() ||
				'';
			const size = `${(uploadedFile.size / (1024 * 1024)).toFixed(1)} MB`;

			setFile({ name, mimeType, size, file: newFile });
		} catch (e) {
			toastVariant(
				t('modalAddNewGroup.fileUploadError', 'Error uploading file'),
				true,
			);
		}
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const droppedFile = e.dataTransfer.files?.[0];
		if (droppedFile) handleFileUpload(droppedFile).then(() => {});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files?.[0];
		if (selectedFile) handleFileUpload(selectedFile).then(() => {});
	};

	const handleReset = () => {
		formik.resetForm();
		setFile(undefined);
		onClose();
	};

	const renderLanguageInput = (language: Language) => (
		<div
			key={language.code}
			className="relative flex flex-col p-[10px] border border-dashed border-gray-10 rounded-10 bg-alabaster"
		>
			<SypacInput
				error={
					!!(
						formik.touched[language.fieldName as keyof typeof formik.touched] &&
						formik.errors[language.fieldName as keyof typeof formik.errors]
					)
				}
			>
				<SypacText className="mb-1" variant="overline-normal-large">
					<p>
						<T
							keyName={`modalAddNewGroup.${type}Name${language.code.toUpperCase()}`}
						>
							{`${language.name} language ${type} name`}
						</T>
						{language.code === 'en' && <span className="text-red">*</span>}
					</p>
				</SypacText>
				<input
					className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
					name={language.fieldName}
					type="text"
					placeholder={t(
						`modalAddNewGroup.enter${
							type.charAt(0).toUpperCase() + type.slice(1)
						}Name`,
						`Enter ${type} name`,
					)}
					value={
						formik.values[language.fieldName as keyof typeof formik.values]
					}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
				{formik.touched[language.fieldName as keyof typeof formik.touched] &&
					formik.errors[language.fieldName as keyof typeof formik.errors] && (
						<span className="bottom-helper">
							{String(
								formik.errors[language.fieldName as keyof typeof formik.errors],
							)}
						</span>
					)}
			</SypacInput>
			<div className="absolute flex top-[44px] right-[24px]">
				<Flag
					className="object-cover border border-solid border-gray-10 rounded"
					code={language.flagCode}
					width={20}
					height={14}
				/>
			</div>
		</div>
	);

	const handleOutsideClick = () => {
		if (isDropdownOpen && !formik.values.parentId) {
			formik.setFieldTouched('parentId', true);
			formik.validateField('parentId');
		}
		setIsDropdownOpen(false);
	};

	const catGroupRef = useDetectClickOutside({
		onTriggered: handleOutsideClick,
	});

	return (
		<div className="h-full flex flex-col border border-solid border-gray-10 rounded-xl">
			<form onSubmit={formik.handleSubmit} className="h-full flex flex-col">
				<div
					className="w-[824px] h-[180px] flex flex-col m-[10px] p-10 bg-no-repeat bg-cover bg-center rounded-10 box-border"
					style={{
						backgroundImage: `url(${file ? file.file.url : current?.imageUrl})`,
					}}
					role="img"
					aria-label={file?.name}
				>
					{!current && (
						<div
							onDragEnter={(e) => e.preventDefault()}
							onDragLeave={(e) => e.preventDefault()}
							onDragOver={(e) => e.preventDefault()}
							onDrop={handleDrop}
							className="flex px-5 py-[25px] bg-white/80 border border-dashed border-gray-40 rounded-lg cursor-pointer transition hover:border-gray-50"
						>
							<input
								type="file"
								id="input-file-upload"
								className="hidden"
								multiple={true}
								accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
								onChange={handleChange}
							/>
							<label
								htmlFor="input-file-upload"
								className="w-full flex gap-6 items-center justify-center cursor-pointer"
							>
								<section className="flex flex-col text-center gap-[10px]">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T keyName="userSettings.uploadNewPhoto">
												Upload new photo
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-xs text-nevada">
											<T
												keyName="modalAddProduct.weSupport"
												params={{ size: MaxFileSizeMB.IMAGE }}
											>
												We support JPEG, or PNG, files. Max file size: 2 MB.
											</T>
										</p>
									</SypacText>
								</section>
							</label>
						</div>
					)}
				</div>

				{type === 'category' && (
					<div className="relative flex flex-col p-[10px] m-[10px] border border-dashed border-gray-10 rounded-10 bg-alabaster">
						<SypacText className="mb-1" variant="overline-normal-large">
							<p className="text-xs">
								<T keyName="modalAddNewGroup.categoryGroup">Category group</T>
								<span className="text-red">*</span>
							</p>
						</SypacText>

						<div ref={catGroupRef} className="relative">
							<div
								className={`flex justify-between items-center h-[46px] box-border border border-solid border-gray-10 bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
									isDropdownOpen
										? 'border-cornflower-blue'
										: 'hover:border-gray-30'
								} ${
									formik.touched.parentId && formik.errors.parentId
										? 'border-red-orange'
										: 'border-gray-10'
								}`}
								onClick={() => setIsDropdownOpen(true)}
							>
								<SypacText variant="body-normal-small">
									<p
										className={`text-base ${
											formik.values.parentId ? 'text-gray-80' : 'text-gray-40'
										}`}
									>
										{formik.values.parentId ? (
											groups?.items.find(
												(group) => group.id === formik.values.parentId,
											)?.name
										) : (
											<T keyName="modalAddNewGroup.selectCategoryGroup">
												Select category group
											</T>
										)}
									</p>
								</SypacText>

								<span
									className={`flex my-auto transition-all mr-[2px] ${
										isDropdownOpen ? 'transform rotate-90' : ''
									}`}
								>
									<ChevronRightIcon />
								</span>
							</div>

							{isDropdownOpen && (
								<div className="absolute -top-[292px] xl-2xl:top-[52px] w-full h-fit xl-2xl:h-[162px] 3xl:h-fit overflow-x-hidden overflow-y-scroll 3xl:overflow-auto flex flex-col p-3 rounded-lg shadow-dropdown bg-white z-30 box-border">
									{groups?.items.map((group) => (
										<div
											key={group.id}
											className="no-underline flex hover:bg-gray-10-opacity-50 bg-white border-none pt-4 pb-[17px] px-5 rounded-md cursor-pointer"
											onClick={() => {
												formik.setFieldValue('parentId', group.id);
												setIsDropdownOpen(false);
											}}
										>
											<SypacText
												variant="body-regular-medium"
												className="mr-auto"
											>
												<p className="text-gray-80 leading-[19px]">
													{group.name}
												</p>
											</SypacText>
										</div>
									))}
								</div>
							)}

							{formik.touched.parentId && formik.errors.parentId ? (
								<span className="input-error">{formik.errors.parentId}</span>
							) : null}
						</div>
					</div>
				)}
				<hr className="w-full h-[1px] border-0 bg-gray-10 my-[2px]" />
				<div
					className={`${
						current || type === 'category'
							? 'grid grid-cols-2'
							: 'flex flex-col'
					} gap-3 m-[10px]`}
				>
					{SUPPORTED_LANGUAGES.map(renderLanguageInput)}
				</div>

				<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
							onClick={handleReset}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">
									<T keyName="selectProduct.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="submit"
							className="w-full h-[44px] flex items-center justify-center bg-primary-violet border-0 rounded-lg transition hover:bg-primary-violet/70"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									{current ? (
										<T keyName="userCard.save">Save</T>
									) : (
										<T
											keyName={`modalAddNewGroup.create${
												type[0].toUpperCase() + type.slice(1)
											}`}
										>
											{`Create ${type}`}
										</T>
									)}
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>
		</div>
	);
};

export default AddEditGroupCategory;
