import React, { useContext, useEffect, useRef } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import NewTrash from '../../../../assets/NewTrash';
import AlertSmall from '../../../../assets/AlertSmall';
import ClockSmall from '../../../../assets/ClockSmall';
import ArrowToRight from '../../../../assets/ArrowToRight';
import { ModalDeleteProductProps } from '../adminBilling.interface';
import { OrderContext } from '../../../../context/OrderContext/order.context';
import StatusDocument from '../../../../assets/StatusDocument';
import { BillingService } from '../../../../services/billing.service';
import { toastVariant } from '../../../../components/ToastVariant/toastVariant';
import Emitter, { EventType } from '../../../../services/events';

const ModalRejectInvoice: React.FC<ModalDeleteProductProps> = ({
	isOpen,
	onClose,
	invoice,
}) => {
	const { setAdditional } = useContext(OrderContext);
	const modalRef = useRef<HTMLDivElement>(null);

	const handleReject = async () => {
		try {
			await BillingService.rejectInvoiceAdmin(invoice.companyType, invoice.id);
			toastVariant('Invoices rejected successfully.', false);
			Emitter.emit(EventType.PAYMENT_NOTE_REFRESH);
			onClose();
		} catch (error) {}
	};

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={onClose}>
			<div
				className="w-[516px] bg-white rounded-xl"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="h-full flex flex-col">
					<section className="flex flex-col gap-6 p-3">
						<span className="flex -ml-1">
							<NewTrash width={60} height={60} strokeWidth="0.5" />
						</span>
						<SypacText variant="body-regular-medium">
							<p className="text-2xl text-gray-80">
								<T keyName="modalRejectInvoice.areYouSure">
									Are you sure you want to reject this file?
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="modalRejectInvoice.areYouSureDescription">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</T>
							</p>
						</SypacText>

						<div className="flex flex-col border border-solid border-gray-10 rounded-10">
							<header className="flex gap-3 px-3 py-[15px]">
								<AlertSmall color="#F44A77" />
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-80">
										<T keyName="modalDeleteProduct.importantNotes">
											Important notes:
										</T>
									</p>
								</SypacText>
							</header>
							<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

							<div className="flex flex-col gap-3 p-3">
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40">
										<T keyName="modalDeleteProduct.importantNotesDescription">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua.
										</T>
									</p>
								</SypacText>
								<div className="flex items-center gap-3">
									<ClockSmall color="#FFB352" secondColor="#454545" />
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-80">
											<T keyName="paymentStatus.uploaded_invoice">
												Pending invoice confirmation
											</T>
										</p>
									</SypacText>
									<ArrowToRight color="#A2A2A2" width={39} />
									<StatusDocument width={20} height={18} />
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-80">
											<T keyName="paymentStatus.pending_invoice">
												Pending invoice
											</T>
										</p>
									</SypacText>
								</div>
							</div>
						</div>
					</section>

					<footer className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[42px] flex items-center justify-center rounded-lg transition"
								onClick={onClose}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="modalDeleteProduct.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[42px] flex items-center justify-center bg-red border-0 rounded-lg transition hover:bg-red/70"
								onClick={handleReject}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-white">
										<T keyName="modalPendingInvoiceConfirmation.reject">
											Reject
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</footer>
				</div>
			</div>
		</div>
	) : null;
};

export default ModalRejectInvoice;
