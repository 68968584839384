import React, { useContext, useState } from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import Close from '../../assets/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import LoadingSpinner from '../../assets/LoadingSpinner';
import Modal from 'react-modal';
import { AuthContext } from '../../context/context';
import { ContactService, ContactUsBody } from '../../services/contact.services';
import { toastVariant } from '../ToastVariant/toastVariant';
import { PREFERRED_PHONE_COUNTIES } from '../../constants';
import { CompanyResponse } from '../../services/company.services';

interface ModalContactUsProps {
	isOpen: boolean;
	onClose: () => void;
	isEditable?: boolean;
	company: CompanyResponse;
	target: string;
}

const ModalContactUs: React.FC<ModalContactUsProps> = ({
	isOpen,
	onClose,
	isEditable = false,
	company,
	target,
}) => {
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const [charCount, setCharCount] = useState<number>(0);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: user?.profile?.firstName || '',
			lastName: user?.profile?.lastName || '',
			email: user?.profile?.email || '',
			phoneNumber: user?.profile?.phoneNumber?.phoneNumber || '',
			info: '',
		},
		onSubmit: async (values) => {
			try {
				const body: ContactUsBody = {
					name: `${values.firstName} ${values.lastName}`,
					email: values.email,
					companyName: company?.name,
					phone: values.phoneNumber,
					message: values.info,
				};

				await ContactService.sendContactUs(target, {
					...body,
				});

				toastVariant(
					'Your message has been sent successfully! We will get back to you soon.',
					false,
				);
				onClose();
			} catch (error) {}
		},
		validationSchema: Yup.object({
			firstName: Yup.string().required('First name is required'),
			lastName: Yup.string().required('Last name is required'),
			email: Yup.string()
				.email('Email address must be an email')
				.required('Email is required'),
			phoneNumber: Yup.string().required('Phone number is required'),
			info: Yup.string().required('The message is required'),
		}),
	});

	const changePhone = (key: string, value: string) => {
		formik?.setFieldValue(key, value);
	};

	const handleTextareaChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		const text = event.target.value;
		formik.handleChange(event);
		setCharCount(text.length);
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="w-[660px] modal-inside outline-none box-border"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<form onSubmit={formik.handleSubmit} className="flex flex-col">
				<header className="flex items-center justify-between p-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="modalContactUs.contactUs">Contact us</T>
						</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</header>
				<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

				<main className="flex flex-col gap-3 p-3">
					<div className="flex gap-3">
						<SypacInput
							className="w-full"
							error={!!(formik.touched.firstName && formik.errors.firstName)}
							disabled={!isEditable}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="modalManageMember.firstName">First name</T>{' '}
									{isEditable && <span className="text-red">*</span>}
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="firstName"
								type="text"
								placeholder={t(
									'modalManageMember.enterUserFirstName',
									'Enter user first name',
								)}
								value={formik?.values.firstName}
								onChange={(event) => formik?.handleChange(event)}
								disabled={!isEditable}
							/>
							{formik.touched.firstName && formik.errors.firstName ? (
								<span className="input-error">{formik.errors.firstName}</span>
							) : null}
						</SypacInput>

						<SypacInput
							className="w-full"
							error={!!(formik.touched.lastName && formik.errors.lastName)}
							disabled={!isEditable}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="modalManageMember.lastName">Last name</T>{' '}
									{isEditable && <span className="text-red">*</span>}
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="lastName"
								type="text"
								placeholder={t(
									'modalManageMember.enterUserLastName',
									'Enter user last name',
								)}
								value={formik?.values.lastName}
								onChange={(event) => formik?.handleChange(event)}
								disabled={!isEditable}
							/>
							{formik.touched.lastName && formik.errors.lastName ? (
								<span className="input-error">{formik.errors.lastName}</span>
							) : null}
						</SypacInput>
					</div>
					<hr className="w-full h-[1px] border-0 bg-gray-10 m-0" />

					<div className="flex gap-3">
						<SypacInput
							className="w-full"
							error={!!(formik.touched.email && formik.errors.email)}
							disabled={!isEditable}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="modalManageMember.emailAddress">E-mail address</T>{' '}
									{isEditable && <span className="text-red">*</span>}
								</p>
							</SypacText>
							<input
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name="email"
								type="text"
								placeholder={t(
									'modalManageMember.enterEmailAddress',
									'Enter e-mail address',
								)}
								value={formik?.values.email}
								onChange={(event) => formik?.handleChange(event)}
								disabled={!isEditable}
							/>
							{formik.touched.email && formik.errors.email ? (
								<span className="input-error">{formik.errors.email}</span>
							) : null}
						</SypacInput>

						<SypacInput
							className="w-full"
							error={
								!!(formik.touched.phoneNumber && formik.errors.phoneNumber)
							}
							disabled={!isEditable}
						>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									<T keyName="userSettings.phoneNumber">Phone number</T>{' '}
									{isEditable && <span className="text-red">*</span>}
								</p>
							</SypacText>
							<PhoneInput
								containerClass={`border border-solid border-gray-10 rounded-lg focus-within:border-cornflower-blue focus-within:hover:border-cornflower-blue ${
									isEditable ? 'hover:border-gray-30' : ''
								}`}
								placeholder={t(
									'userSettings.enterPhoneNumber',
									'Enter Phone number',
								)}
								value={formik?.values.phoneNumber}
								onChange={(phone) => changePhone('phoneNumber', phone)}
								preferredCountries={PREFERRED_PHONE_COUNTIES}
								country="pl"
								disabled={!isEditable}
							/>
							{formik.touched.phoneNumber && formik.errors.phoneNumber ? (
								<span className="input-error">{formik.errors.phoneNumber}</span>
							) : null}
						</SypacInput>
					</div>
					<SypacInput
						className="w-full flex"
						error={!!(formik.touched.info && formik.errors.info)}
					>
						<SypacText variant="overline-normal-large" className="mb-1">
							<p className="text-xs">
								<T keyName="modalContactUs.message">Message</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<div className="w-full flex flex-col gap-3">
							<textarea
								name="info"
								className={`w-full box-border h-[180px] font-sans py-3.5 pl-3 border border-solid rounded-xl placeholder:text-gray-40 placeholder:text-base resize-none outline-none ${
									!!(formik.touched.info && formik.errors.info)
										? 'border-red'
										: 'border-gray-10 hover:border-gray-40/75 focus:border-[#5682fa]'
								}`}
								rows={3}
								maxLength={1500}
								placeholder={t(
									'modalContactUs.enterYourMessage',
									'Enter your message',
								)}
								onChange={handleTextareaChange}
								onBlur={formik.handleBlur}
								value={formik.values.info}
							/>
							<div className="w-full flex items-center justify-between">
								{formik.touched.info && formik.errors.info ? (
									<span className="text-xs text-red-orange">
										{formik.errors.info}
									</span>
								) : null}
								<div className="w-fit flex bg-gray-10 text-xs text-gray-40 rounded-2xl px-2 py-[0.5px] ml-auto">{`${charCount}/1500`}</div>
							</div>
						</div>
					</SypacInput>
				</main>

				<footer className="flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl mt-auto">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full pl-2.5 xl-2xl:pl-3"
					>
						<button
							type="reset"
							className="w-full py-2.5 rounded-lg"
							onClick={onClose}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalChangeRequest.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="primary"
						size="small"
						className="w-full pr-2.5 xl-2xl:pr-3"
					>
						<button
							type="submit"
							className={`w-full py-2.75 rounded-lg ${
								formik.isSubmitting ? 'bg-primary-violet/80 text-gray-500' : ''
							}`}
							disabled={formik.isSubmitting}
						>
							<SypacText variant="body-regular-medium">
								<p className="flex text-white gap-3">
									<T keyName="modalContactUs.sendMessage">Send message</T>
									{formik.isSubmitting && <LoadingSpinner />}
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</footer>
			</form>
		</Modal>
	);
};

export default ModalContactUs;
