import EventEmitter from 'eventemitter3';

export enum EventType {
	NOTIFICATION_COUNT = 'notification_count',
	NOTIFICATION_COUNT_DECREASE = 'notification_count_decrease',
	ORDER_NOTIFICATION_COUNT_DECREASE = 'order_notification_count_decrease',
	ORDER_LIST_REFRESH = 'order_list_refresh',
	TRUCK_UPDATE_LOCATION = 'truck_update_location',
	UPDATE_EXPIRED_NOTIFICATION = 'update_expired_notification',
	ORDER_REFRESH = 'order_refresh',
	PRODUCT_DATABASE_REFRESH = 'product_database_refresh',
	PAYMENT_NOTE_REFRESH = 'payment_note_refresh',
}

type EventHandler<T = unknown> = (payload: T) => void;

const eventEmitter = new EventEmitter();

const Emitter = {
	on: <T = unknown>(event: EventType | string, fn: EventHandler<T>) =>
		eventEmitter.on(event, fn),
	once: <T = unknown>(event: EventType | string, fn: EventHandler<T>) =>
		eventEmitter.once(event, fn),
	off: <T = unknown>(event: EventType | string, fn?: EventHandler<T>) =>
		eventEmitter.off(event, fn),
	emit: <T = unknown>(event: EventType | string, payload?: T) =>
		eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
