import React from 'react';
import { SypacBox, SypacIcon, SypacText } from '@sypac/component-library-react';
import { Line } from '../../assets/Line';
import { LogoBlack } from '../../assets/LogoBlack';
import SearchInput from '../SearchInput/SearchInput';
import './top-bar.css';
import { useTranslate } from '@tolgee/react';

interface TopBarProps {
	showSearch?: boolean;
	title?: string;
}

const TopBar: React.FC<TopBarProps> = ({ showSearch, title }) => {
	const { t } = useTranslate();
	const defaultTitle = title || t('topBar.forProducers', 'For Sellers');

	return (
		<SypacBox
			className="flex justify-between cursor-auto w-auto h-auto p-4 top-bar-border rounded-10 bg-gray-1"
			hover={false}
			box-style="none"
		>
			<div className="flex gap-4 items-center">
				<a href="/">
					<LogoBlack />
				</a>
				<Line />
				<SypacText variant="body-regular-small">
					<p className="text-gray-80">{defaultTitle}</p>
				</SypacText>
			</div>

			{showSearch ? (
				<div className="flex min-w-[400px] orders">
					<SearchInput
						placeholder={t(
							'topBar.searchByOrderIdProductType',
							'Search by Order ID & Product type',
						)}
					/>
				</div>
			) : (
				<div className="flex gap-4 items-center text-gray-80">
					<SypacIcon
						icon-name="Bell"
						color="currentColor"
						size="custom"
						width="22px"
						height="22px"
					/>
					<SypacIcon
						icon-name="Question Circle"
						color="currentColor"
						size="custom"
						width="22px"
						height="22px"
					/>
				</div>
			)}
		</SypacBox>
	);
};

export default TopBar;
