import Api from '../axios/api';
import { UsersInterface } from '../interfaces/Users.interface';
import { CompanyBankDetails } from '../components/CompanyVerification/components/BankDetails/BankDetails.interface';
import { CompanyContact } from '../components/CompanyVerification/components/UserDetails/UserDetails.interface';
import { CompanyProps } from '../components/CompanyVerification/components/CompanyDetails/CompanyDetails.interface';
import { FileInterface } from '../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';

export interface CompanyAdminStats {
	all: string;
	registrationInProgress: string;
	verificationPending: string;
	verified: string;
}

export interface PhoneInterface {
	phoneNumber?: string;
	isViber?: boolean;
	isTelegram?: boolean;
	isWhatsapp?: boolean;
}

export interface ContactCompany {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	secondaryEmail?: string;
	phone?: PhoneInterface;
	secondaryPhone?: PhoneInterface;
	createdAt: Date;
	updatedAt: Date;
}

export interface ProfileCompany {
	uid: string;
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	secondaryEmail?: string;
	phoneNumber?: PhoneInterface;
	secondaryPhoneNumber?: PhoneInterface;
	createdAt: Date;
	updatedAt: Date;
	countryCode: string;
	photoUrl?: string;
}

export interface Point {
	type: 'Point';
	coordinates: number[];
}

export interface BankDetailsInterface {
	id?: number;
	bankAccountNumber: string;
	swift: string;
	bankName: string;
	iban: string;
	creditLimit?: number;
	paymentTerm?: number;
	createdAt?: string;
	updatedAt?: string;
}

export interface CompanyStats {
	'paymentTerm': number;
	'creditLimit': string;
	'nextInvoicingDate': string;
	'amount': string;
	'count': {
		'all': string;
		'invoiceConfirmed': string;
		'overdue': string;
		'paid': string;
		'pending': string;
		'pendingInvoice': string;
		'uploadedInvoice': string;
	};
	'countOrders': {
		'all': string;
		'paid': string;
		'dispute': string;
		'inProgress': string;
	};
	'amountTotal': {
		'all': string;
		'invoiceConfirmed': string;
		'overdue': string;
		'paid': string;
		'pending': string;
		'pendingInvoice': string;
		'uploadedInvoice': string;
	};
}

export interface CompanyResponse {
	id?: number;
	name: string;
	registrationNumber: string;
	taxNumber: string;
	vatId: string;
	countryCode: string;
	contact?: ContactCompany;
	userOwner?: ProfileCompany;
	walletId: number;
	paymentTerm?: number;
	location?: Point;
	registryAddress?: string;
	billingAddress?: string;
	status: string;
	nextInvoicingDate?: Date | string;
	establishedDate?: Date;
	bankDetails?: BankDetailsInterface;
	files?: FileInterface[];
	assignees?: Assignee[];
	createdAt?: string;
	updatedAt?: string;
	companyTypes: string[];
	alowExcludedTransporter: boolean;
	alowUploadInvoice: boolean;
	termsAccepted: boolean;
	logoUrl?: string;
	website?: string;
}

export interface GetCompanyResponse {
	data: CompanyResponse;
}

export interface Assignee {
	id: number;
	assigneeUid: string;
	companyId: number;
	company?: CompanyResponse[];
	assigneeUser?: UsersInterface;
	createdAt: string;
	updatedAt: string;
}

export interface CompanyShort {
	id: number;
	name: string;
	countryCode: string;
	status: string;
	assignees?: Assignee[];
	createdAt?: string;
	updatedAt?: string;
}

export interface RequestCompanyList {
	status?: string;
	companyTypes?: string[];
	search?: string;
	limit?: number;
	offset?: number;
	countryCode?: string;
}

export interface ResponseCompanyList {
	items: CompanyShort[];
	count: number;
}

export interface GetCompaniesResponse {
	data: ResponseCompanyList;
}

export interface UpdateCompany {
	name?: string;
	companyTypes?: string[];
	registrationNumber?: string;
	taxNumber?: string;
	vatId?: string;
	countryCode?: string;
	paymentTerm?: number;
	registryAddress?: string;
	billingAddress?: string;
	nextInvoicingDate?: Date | string;
	establishedDate?: Date | string;
	email?: string;
	phone?: PhoneInterface;
	secondaryEmail?: string;
	secondaryPhone?: PhoneInterface;
	lat?: number;
	long?: number;
	bankAccountNumber?: string;
	swift?: string;
	bankName?: string;
	iban?: string;
	creditLimit?: string;
	files?: FileInterface[];
	alowExcludedTransporter?: boolean;
	alowUploadInvoice?: boolean;
}

export interface ResponseUpdate {
	data: CompanyResponse;
}

export interface UpdateMyCompany {
	phone: PhoneInterface;
	registryAddress: string;
	logoUrl: string;
	lat: number;
	long: number;
	billingAddress?: string;
	companyTypes?: string[];
	registrationNumber?: string;
	vat?: number;
	establishedDate?: string;
	website?: string;
}

export interface ChangeRequest {
	newEmail: string;
	newName: string;
}

export class CompanyService {
	static getMeCompany(): Promise<GetCompanyResponse> {
		return Api.get('/v1/companies/my');
	}

	static getCompanies(
		query: RequestCompanyList,
	): Promise<GetCompaniesResponse> {
		const params = {
			limit: query.limit,
			offset: query.offset,
			status: query.status !== 'all' ? query.status : undefined,
			companyTypes: query.companyTypes || undefined,
			search: query.search || undefined,
			sortBy: 'id',
			sortDirection: 'DESC',
			countryCode: query.countryCode,
		};
		return Api.get('/v1/admin/companies', { params });
	}

	static companyVerificationStep1(
		companyId: number,
		body: CompanyProps,
	): Promise<void> {
		return Api.post(`/v1/admin/companies/${companyId}/verify/step-1`, {
			...body,
		});
	}

	static companyVerificationStep2(
		companyId: number,
		body: { files?: FileInterface[] },
	): Promise<ResponseUpdate> {
		return Api.post(`/v1/admin/companies/${companyId}/verify/step-2`, {
			...body,
		});
	}

	static companyVerificationStep3(
		companyId: number,
		body: CompanyContact,
	): Promise<void> {
		return Api.post(`/v1/admin/companies/${companyId}/verify/step-3`, {
			...body,
		});
	}

	static companyVerificationStep4(
		companyId: number,
		body: CompanyBankDetails,
	): Promise<void> {
		return Api.post(`/v1/admin/companies/${companyId}/verify/step-4`, {
			...body,
		});
	}

	static assignManagerToCompany(
		companyId: number,
		userId: string,
	): Promise<void> {
		return Api.post(`/v1/admin/companies/${companyId}/assign/${userId}`);
	}

	static unassignManagerToCompany(
		companyId: number,
		userId: string,
	): Promise<void> {
		return Api.delete(`/v1/admin/companies/${companyId}/assign/${userId}`);
	}

	static updateCompany(companyId: number, body: UpdateCompany): Promise<void> {
		return Api.patch(`/v1/admin/companies/${companyId}`, body);
	}

	static updateMyCompany(target: string, body: UpdateMyCompany): Promise<void> {
		return Api.patch(`/v1/${target}/companies/update`, body);
	}

	static myCompanyChangeRequest(
		target: string,
		body: ChangeRequest,
	): Promise<void> {
		return Api.patch(`/v1/${target}/companies/change-request`, body);
	}
}
