import React, { useContext, useEffect, useRef } from 'react';
import {
	SypacAvatar,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { TransportSolutionProps } from '../../adminOrder.interface';
import { OrderContext } from '../../../../../context/OrderContext/order.context';
import Close from '../../../../../assets/Close';
import Flag from 'react-world-flags';
import BorderLine from '../../../../../assets/BorderLine';

const TransportSolution: React.FC<TransportSolutionProps> = ({
	isOpen,
	onClose,
	solutions,
}) => {
	const { setAdditional } = useContext(OrderContext);
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isOpen ? (
		<div className="modal-overlay" onClick={() => onClose()}>
			<div
				className="absolute bg-white outline-none rounded-xl"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<div className="flex flex-col w-[492px] h-[588px] overflow-hidden">
					<div className="flex items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="transportSolution.transportSolutions">
									Transport solutions
								</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<BorderLine />

					<div className="h-full flex flex-col gap-3 px-3 py-[10px] overflow-y-scroll scroll-smooth -mr-[15px]">
						{solutions?.map(
							({
								name,
								phone,
								secondaryPhone,
								email,
								avatarUrl,
								countryCode,
								address,
							}) => {
								return (
									<div
										key={name}
										className="w-[468px] flex flex-col border border-solid border-gray-10 bg-alabaster rounded-xl box-border cursor-pointer transition hover:border-primary-violet hover:shadow-dropdown-avatar"
									>
										<div className="flex gap-[14px] p-[10px]">
											<SypacAvatar size="md" initials={name}>
												{avatarUrl ? (
													<img
														src={avatarUrl}
														alt={name}
														className="w-full h-full object-cover rounded-full"
													/>
												) : null}
											</SypacAvatar>

											<div className="flex flex-col">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80 font-bold">{name}</p>
												</SypacText>

												<div className="flex items-center gap-[10px]">
													<span className="-ml-[2px] mt-[2px]">
														<Flag
															className="object-cover border border-solid border-gray-10 rounded"
															code={countryCode}
															width={20}
															height={14}
														/>
													</span>
													<SypacText variant="body-regular-medium">
														<p className="text-xs text-gray-40 w-[296px] truncate">
															{address}
														</p>
													</SypacText>
												</div>
											</div>
										</div>
										<BorderLine />

										<div className="flex flex-col gap-3 p-[10px]">
											<div className="grid grid-cols-[90px,auto]">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-40">
														<T keyName="transportSolution.phone">Phone:</T>
													</p>
												</SypacText>

												<div className="flex items-center gap-[10px]">
													<span className="-ml-[2px] mt-[2px]">
														<Flag
															className="object-cover border border-solid border-gray-10 rounded"
															code={countryCode}
															width={20}
															height={14}
														/>
													</span>
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">
															{phone?.phoneNumber ||
																secondaryPhone?.phoneNumber}
														</p>
													</SypacText>
												</div>
											</div>

											<div className="grid grid-cols-[90px,auto]">
												<SypacText variant="body-regular-medium">
													<p className="text-gray-40">
														<T keyName="transportSolution.emailAddress">
															Email:
														</T>
													</p>
												</SypacText>

												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">{email}</p>
												</SypacText>
											</div>
										</div>
									</div>
								);
							},
						)}
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default TransportSolution;
