import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
	SypacButton,
	SypacDropdown,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import 'react-phone-input-2/lib/style.css';
import Select, { StylesConfig } from 'react-select';
import { ModalAddTruckProps } from './ModalAddTruck.interface';
import './modal-add-truck.css';
import { TruckService } from '../../services/truck.services';
import { T, useTranslate } from '@tolgee/react';
import { useGetTruckOptions } from '../../hooks/use-get-truck-options';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { COLORS } from '../../constants/truck-colors.constant';
import { TYPE_TRUCK } from '../../constants/truck-types.constant';
import Close from '../../assets/Close';
import { PREFERRED_PHONE_COUNTIES } from '../../constants';
import { colourErrorStyles } from '../DropDownOption/DropDownStyles';

const colourStyles: StylesConfig<any, true> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		boxShadow: 'none',
		padding: '0px 2px',
		maxHeight: '42px',
		height: '42px',
		borderRadius: '6px',
	}),
	input: (styles) => ({
		...styles,
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '0.01em',
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.01em',
		color: '#D3D4D5',
	}),
	menuList: (styles) => ({
		...styles,
		margin: '12px',
	}),
	option: (styles, state) => ({
		...styles,
		color: 'rgb(23, 23, 23)',
		padding: '20px 16px',
		borderRadius: '4px',
		backgroundColor: state.isSelected
			? 'rgba(232, 232, 232, 1)'
			: 'transparent',
		'&:hover': {
			backgroundColor: 'rgba(232, 232, 232, 0.5)',
		},
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: data.background,
			borderRadius: '6px',
			// fontSize: '12px',
			// lineHeight: '16px',
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color,
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: data.background,
			color: 'white',
		},
	}),
};

interface DropdownEventDetail {
	value: string;
}

const ModalAddTruck: React.FC<ModalAddTruckProps> = ({
	isOpen,
	onClose,
	onSuccess,
	truck,
	company,
	fleet,
}) => {
	const { t } = useTranslate();
	const [openOtherFields, setOpenOtherFields] = useState<boolean>(false);
	const lang = useMemo(() => localStorage.getItem('lang') || 'pl', []);

	const formik = useFormik({
		initialValues: {
			name: truck?.name || '',
			type: truck?.type || '',
			truckModel: truck?.truckModel || '',
			truckYear: truck?.truckYear,
			truckColor: truck?.truckColor || '',
			truckNumber: truck?.truckNumber || '',
			truckBrand: truck?.truckBrand || '',
			truckPhoto: truck?.truckPhoto || '',
			licensePlates: truck?.licensePlates || '',
			emissionType: truck?.emissionType || '',
			truckPayload: truck?.truckPayload || '',
			driverFirstName: truck?.driverFullName.length
				? truck?.driverFullName?.substring(
						0,
						truck?.driverFullName?.indexOf(' '),
				  )
				: '',
			driverLastName: truck?.driverFullName.length
				? truck?.driverFullName?.substring(
						truck?.driverFullName?.indexOf(' ') + 1,
				  )
				: '',
			driverEmail: truck?.driverEmail || 'example@email.com',
			driverAvatar: truck?.driverAvatar || '',
			driverPhone: truck?.driverPhone || '',
		},
		onSubmit: async (values) => {
			const createParams = {
				...values,

				driverFullName: `${values.driverFirstName} ${values.driverLastName}`,
				emissionType: values.emissionType || undefined,
				truckModel: values.truckModel || undefined,
				truckColor: values.truckColor || undefined,
				truckBrand: values.truckBrand || undefined,
				truckYear: values.truckYear
					? (typeof values.truckYear === 'string'
							? dayjs(values.truckYear).year()
							: values.truckYear)!
					: undefined,
			};
			if (truck && truck.id) {
				await TruckService.editTruck(truck.id, createParams);
				onSuccess();
				formik.resetForm();
			} else {
				const { data } = await TruckService.createTruck(createParams);
				onSuccess(data);
				formik.resetForm();
			}
		},
		validationSchema: Yup.object({
			type: Yup.string().required(
				t('modalAddTruck.truckTypeRequired', 'Truck type is required'),
			),
			licensePlates: Yup.string().required(
				t('modalAddTruck.licensePlatesRequired', 'License plates is required'),
			),
			name: Yup.string()
				.required(
					t('modalAddTruck.truckNameRequired', 'Truck name is required'),
				)
				.test(
					'unique-name',
					t(
						'modalAddTruck.truckNameUnique',
						'This truck name is already in use. Try another one',
					),
					function (value) {
						if (!value || !Array.isArray(fleet)) return true;
						return !fleet.some((truck) => truck.name === value);
					},
				),
			truckPayload: Yup.string().required(
				t('modalAddTruck.payloadRequired', 'Payload is required'),
			),
			driverFirstName: Yup.string().required(
				t(
					'modalAddTruck.driverFirstNameRequired',
					'Driver first name is required',
				),
			),
			driverLastName: Yup.string().required(
				t(
					'modalAddTruck.driverLastNameRequired',
					'Driver last name is required',
				),
			),
			driverPhone: Yup.string().required(
				t('modalAddTruck.phoneNumberRequired', 'Phone number is required'),
			),
		}),
	});

	const typeOptions = useMemo(
		() => ({
			items: TYPE_TRUCK[lang],
			count: TYPE_TRUCK[lang]?.length,
		}),
		[lang],
	);

	const [brandOptions] = useGetTruckOptions({
		optionType: 'brands',
		type: formik.values.type,
	});
	const [modelOptions] = useGetTruckOptions({
		optionType: 'models',
		brand: formik.values.truckBrand,
		type: formik.values.type,
	});
	const [yearsOptions] = useGetTruckOptions({
		optionType: 'years',
		brand: formik.values.truckBrand,
		model: formik.values.truckModel,
		type: formik.values.type,
	});

	const emissionTypes = [
		'EURO 1',
		'EURO 2',
		'EURO 3',
		'EURO 4',
		'EURO 5',
		'EURO 6',
	];

	const onSelectDropdown = (
		event: CustomEvent<DropdownEventDetail>,
		id: string,
	) => {
		formik.setFieldValue(`${id}`, event?.detail?.value);
		if (id === 'type') {
			formik.setFieldValue('truckYear', '');
			formik.setFieldValue('truckModel', '');
			formik.setFieldValue('truckBrand', '');
		}
		if (id === 'truckBrand') {
			formik.setFieldValue('truckModel', '');
			formik.setFieldValue('truckYear', '');
		}
		if (id === 'truckModel') {
			formik.setFieldValue('truckYear', '');
		}
	};

	const CustomDropdownEvent = (
		value: DropdownEventDetail,
	): CustomEvent<DropdownEventDetail> => {
		return new CustomEvent('select', {
			detail: value,
			bubbles: true,
			cancelable: true,
			composed: true,
		});
	};

	const onSelectColor = (event: CustomEvent<DropdownEventDetail>) => {
		formik.setFieldValue('truckColor', event?.detail?.value);
	};

	const changePhone = (key: 'driverPhone', value: string) => {
		formik?.setFieldValue(key, value);
	};

	const changeName = useCallback(() => {
		const { licensePlates, truckPayload } = formik?.values;

		let name = licensePlates;
		if (truckPayload) {
			name = `${licensePlates} - ${truckPayload}t`;
		}
		if (name !== formik?.values.name) {
			formik.setFieldValue('name', name);
		}
	}, [formik]);

	const handleModalClose = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={handleModalClose}
			className="modal-inside outline-none"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<form onSubmit={formik.handleSubmit}>
				<div className="flex flex-col">
					<div className="flex items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								{truck && truck.id ? (
									<T keyName="modalAddTruck.editTruck">Edit truck</T>
								) : (
									<T keyName="modalAddTruck.addNewTruck">Add new truck</T>
								)}
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={() => handleModalClose()}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div className="p-3">
						<div className="bg-alabaster border border-solid border-gray-10 rounded-10 flex flex-col">
							<div className="p-3 flex flex-col">
								<SypacText variant="body-regular-small">
									<p className="text-gray-40">
										<T keyName="modalAddTruck.mandatoryTruckInfo">
											Mandatory truck info:
										</T>
									</p>
								</SypacText>
								<div className="grid grid-cols-2 gap-x-3 gap-y-4 mt-4">
									<div
										className={classNames('w-full relative', {
											'pointer-events-none': !typeOptions?.count,
										})}
									>
										<SypacText
											variant="overline-regular-large"
											className="mb-1"
										>
											<p className="-tracking-[0.01em] text-primary-dark-gray">
												<T keyName="modalAddTruck.truckType">Truck type</T>
												<span className="text-red">*</span>
											</p>
										</SypacText>
										<SypacDropdown
											name="type"
											placeholder={t(
												'modalAddTruck.selectTruckType',
												'Select truck type',
											)}
											callback={(e) => onSelectDropdown(e, 'type')}
											className={classNames({
												'border_required': !!(
													formik.touched.type && formik.errors.type
												),
											})}
										>
											{typeOptions?.items?.map((type: any) => {
												return (
													// @ts-ignore
													<sypac-dropdown-option
														key={Math.random()}
														value={type.value}
														label={type.label}
														selected={type.value === formik.values.type}
													/>
												);
											})}
											{formik.touched.type && formik.errors.type ? (
												<span className="input-error">
													{formik.errors.type}
												</span>
											) : null}
										</SypacDropdown>
									</div>
									<SypacInput
										error={
											!!(
												formik.touched.licensePlates &&
												formik.errors.licensePlates
											)
										}
									>
										<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em] font-medium">
											<T keyName="modalAddTruck.licensePlates">
												License plates
											</T>

											<span className="text-red">*</span>
										</label>
										<input
											className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
											name="licensePlates"
											type="text"
											placeholder={t(
												'modalAddTruck.enterLicensePlates',
												'Enter license plates',
											)}
											value={formik?.values.licensePlates}
											onChange={(event) => formik?.handleChange(event)}
											onBlur={changeName}
										/>
										{formik.touched.licensePlates &&
										formik.errors.licensePlates ? (
											<span className="bottom-helper">
												{formik.errors.licensePlates}
											</span>
										) : null}
									</SypacInput>

									<SypacInput
										error={
											!!(
												formik.touched.truckPayload &&
												formik.errors.truckPayload
											)
										}
									>
										<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em] font-medium">
											<T keyName="modalAddTruck.truckPayload">Payload (tons)</T>
											<span className="text-red">*</span>
										</label>
										<input
											className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
											name="truckPayload"
											type="number"
											placeholder={t(
												'modalAddTruck.enterTruckPayload',
												'Enter truck payload',
											)}
											value={formik?.values.truckPayload}
											onChange={(event) => formik?.handleChange(event)}
											onBlur={changeName}
										/>
										{formik.touched.truckPayload &&
										formik.errors.truckPayload ? (
											<span className="bottom-helper">
												{formik.errors.truckPayload}
											</span>
										) : null}
									</SypacInput>

									<SypacInput
										error={!!(formik.touched.name && formik.errors.name)}
									>
										<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em] font-medium">
											<T keyName="modalAddTruck.truckName">Truck name</T>
											<span className="text-red">*</span>
										</label>
										<input
											className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
											name="name"
											type="text"
											placeholder={t(
												'modalAddTruck.enterTruckName',
												'Enter truck name',
											)}
											value={formik?.values.name}
											onChange={(event) => formik?.handleChange(event)}
										/>
										{formik.touched.name && formik.errors.name ? (
											<span className="bottom-helper">
												{formik.errors.name}
											</span>
										) : null}
									</SypacInput>
								</div>
							</div>

							{openOtherFields ? (
								<div className="border-0 border-t border-solid border-gray-10 p-3 flex flex-col">
									<SypacText variant="body-regular-small">
										<p className="text-gray-40">
											<T keyName="modalAddTruck.optionalInfo">Optional info:</T>
										</p>
									</SypacText>
									<div className="grid grid-cols-2 gap-x-3 gap-y-4 mt-4">
										<div
											className={classNames('w-full relative', {
												'pointer-events-none': !brandOptions?.count,
											})}
										>
											<SypacInput
												error={
													!!(
														formik.touched.truckBrand &&
														formik.errors.truckBrand
													)
												}
												className="mb-1"
											>
												<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em] font-medium">
													<T keyName="modalAddTruck.vehicleBrand">
														Vehicle brand:
													</T>
												</label>
												<Select
													closeMenuOnSelect={true}
													value={
														formik.values.truckBrand
															? {
																	value: formik.values.truckBrand,
																	label: formik.values.truckBrand,
															  }
															: ''
													}
													options={brandOptions?.items.map((r) => ({
														value: r,
														label: r,
													}))}
													onChange={(value: any) => {
														onSelectDropdown(
															CustomDropdownEvent({ value }),
															'truckBrand',
														);
													}}
													placeholder={t(
														'modalAddTruck.selectTruckBrand',
														'Select truck brand',
													)}
													name="truckBrand"
													onBlur={formik.handleBlur}
													menuPlacement={'auto'}
													classNamePrefix="dropdown"
													styles={
														formik.touched.truckBrand &&
														formik.errors.truckBrand
															? { ...colourStyles, ...colourErrorStyles }
															: colourStyles
													}
												/>
												{formik.touched.truckBrand &&
												formik.errors.truckBrand ? (
													<span className="input-error">
														{formik.errors.truckBrand}
													</span>
												) : null}
											</SypacInput>
										</div>
										<div
											className={classNames('w-full relative', {
												'pointer-events-none': !modelOptions?.count,
											})}
										>
											<SypacText
												variant="overline-regular-large"
												className="mb-1"
											>
												<p className="-tracking-[0.01em] text-primary-dark-gray">
													<T keyName="modalAddTruck.truckModel">Truck model</T>
												</p>
											</SypacText>
											<SypacDropdown
												name="truckModel"
												placeholder={t(
													'modalAddTruck.selectTruckModel',
													'Select truck model',
												)}
												callback={(e) => onSelectDropdown(e, 'truckModel')}
											>
												{modelOptions?.items?.map((model: any) => {
													return (
														// @ts-ignore
														<sypac-dropdown-option
															key={Math.random()}
															value={model}
															label={model}
															selected={model === formik.values.truckModel}
														/>
													);
												})}
												{formik.touched.truckModel &&
												formik.errors.truckModel ? (
													<span className="input-error">
														{formik.errors.truckModel}
													</span>
												) : null}
											</SypacDropdown>
										</div>
									</div>
									<div className="grid grid-cols-3 gap-x-3 gap-y-4 mt-4">
										<div className={classNames('w-full relative')}>
											<SypacText
												variant="overline-regular-large"
												className="mb-1"
											>
												<p className="-tracking-[0.01em] text-primary-dark-gray">
													<T keyName="modalAddTruck.truckColor">Truck color</T>
												</p>
											</SypacText>
											<SypacDropdown
												name="truckColor"
												placeholder={t(
													'modalAddTruck.enterTruckColor',
													'Enter truck color',
												)}
												callback={(e) => onSelectColor(e)}
											>
												{Object.keys(COLORS)?.map((key: string) => {
													return (
														// @ts-ignore
														<sypac-dropdown-option
															key={Math.random()}
															value={key}
															label={COLORS[key][lang]}
															selected={key === formik.values.truckColor}
														/>
													);
												})}
												{formik.touched.truckColor &&
												formik.errors.truckColor ? (
													<span className="input-error">
														{formik.errors.truckColor}
													</span>
												) : null}
											</SypacDropdown>
										</div>
										<div
											className={classNames('w-full relative', {
												'pointer-events-none': !(
													yearsOptions?.count &&
													formik.values.truckModel &&
													formik.values.type
												),
											})}
										>
											<SypacText
												variant="overline-regular-large"
												className="mb-1"
											>
												<p className="-tracking-[0.01em] text-primary-dark-gray">
													<T keyName="modalAddTruck.yearOfProduction">
														Year of production
													</T>
												</p>
											</SypacText>
											<SypacDropdown
												name="truckYear"
												placeholder={t(
													'modalAddTruck.selectProductionYear',
													'Select production year',
												)}
												callback={(e) => onSelectDropdown(e, 'truckYear')}
											>
												{yearsOptions?.items?.map((year: any) => {
													const truckYear =
														typeof formik.values.truckYear === 'string'
															? parseInt(formik.values.truckYear)
															: formik.values.truckYear;

													return (
														// @ts-ignore
														<sypac-dropdown-option
															key={Math.random()}
															value={year}
															label={year}
															selected={year === truckYear}
														/>
													);
												})}
												{formik.touched.truckYear && formik.errors.type ? (
													<span className="input-error">
														{formik.errors.truckYear}
													</span>
												) : null}
											</SypacDropdown>
										</div>
										<div className="w-full relative">
											<SypacText
												variant="overline-regular-large"
												className="mb-1"
											>
												<p className="-tracking-[0.01em] text-primary-dark-gray">
													<T keyName="modalAddTruck.emissionType">
														Emission type
													</T>
												</p>
											</SypacText>
											<SypacDropdown
												name="emissionType"
												placeholder={t(
													'modalAddTruck.selectEmissionType',
													'Select emission type',
												)}
												callback={(e) => onSelectDropdown(e, 'emissionType')}
											>
												{emissionTypes?.map((emissionType: any) => {
													return (
														// @ts-ignore
														<sypac-dropdown-option
															key={Math.random()}
															value={emissionType}
															label={emissionType}
															selected={
																emissionType === formik.values.emissionType
															}
														/>
													);
												})}
												{formik.touched.emissionType &&
												formik.errors.emissionType ? (
													<span className="input-error">
														{formik.errors.emissionType}
													</span>
												) : null}
											</SypacDropdown>
										</div>
									</div>
								</div>
							) : null}

							<div className="flex mt-1 px-3 pb-3">
								<SypacButton variant="subtitle" size="small">
									<button
										className="bg-transparent p-0"
										type="reset"
										onClick={() => {
											setOpenOtherFields(!openOtherFields);
										}}
									>
										<SypacIcon
											iconName={openOtherFields ? 'Minus Square' : 'Add Square'}
										/>
										<SypacText variant="body-regular-medium">
											<p>
												{openOtherFields ? (
													<T keyName="modalAddTruck.hide">Hide</T>
												) : (
													<T keyName="modalAddTruck.addMoreDetails">
														Add more details
													</T>
												)}
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</div>
						</div>
						<div className="bg-alabaster border border-solid border-gray-10 rounded-10 flex flex-col mt-3 p-3">
							<SypacText variant="body-regular-small">
								<p className="text-gray-40">
									<T keyName="modalAddTruck.driverInformation">
										Driver information:
									</T>
								</p>
							</SypacText>
							<div className="grid grid-cols-3 gap-x-3 gap-y-4 mt-4">
								<SypacInput
									error={
										!!(
											formik.touched.driverFirstName &&
											formik.errors.driverFirstName
										)
									}
								>
									<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em] font-medium">
										<T keyName="modalAddTruck.driverFirstName">
											Driver First Name
										</T>
										<span className="text-red">*</span>
									</label>
									<input
										className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
										name="driverFirstName"
										type="text"
										placeholder={t(
											'modalAddTruck.enterFirstName',
											'Enter First Name',
										)}
										value={formik?.values.driverFirstName}
										onChange={(event) => formik?.handleChange(event)}
									/>
									{formik.touched.driverFirstName &&
									formik.errors.driverFirstName ? (
										<span className="bottom-helper">
											{formik.errors.driverFirstName}
										</span>
									) : null}
								</SypacInput>
								<SypacInput
									error={
										!!(
											formik.touched.driverLastName &&
											formik.errors.driverLastName
										)
									}
								>
									<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em] font-medium">
										<T keyName="modalAddTruck.driverLastName">
											Driver Last Name
										</T>
										<span className="text-red">*</span>
									</label>
									<input
										className="py-2.5 pl-3 border rounded-md placeholder:text-gray-22"
										name="driverLastName"
										type="text"
										placeholder={t(
											'modalAddTruck.enterLastName',
											'Enter Last Name',
										)}
										value={formik?.values.driverLastName}
										onChange={(event) => formik?.handleChange(event)}
									/>
									{formik.touched.driverLastName &&
									formik.errors.driverLastName ? (
										<span className="bottom-helper">
											{formik.errors.driverLastName}
										</span>
									) : null}
								</SypacInput>
								<SypacInput
									error={
										!!(formik.touched.driverPhone && formik.errors.driverPhone)
									}
								>
									<label className="text-xs leading-4 text-primary-dark-gray -tracking-[0.01em] font-medium">
										<T keyName="modalAddTruck.phoneNumber">Phone number</T>
										<span className="text-red">*</span>
									</label>
									<PhoneInput
										containerClass={`border border-solid rounded-lg ${
											!!(
												formik.touched.driverPhone && formik.errors.driverPhone
											)
												? 'border-red'
												: 'border-gray-20 hover:border-gray-30 focus-within:border-cornflower-blue focus-within:hover:border-cornflower-blue'
										}`}
										value={formik.values.driverPhone}
										placeholder={t(
											'modalAddTruck.enterPhoneNumber',
											'Enter Phone number',
										)}
										country={company?.countryCode.toLowerCase()}
										onChange={(phone) => changePhone('driverPhone', phone)}
										preferredCountries={PREFERRED_PHONE_COUNTIES}
									/>
									{formik.touched.driverPhone && formik.errors.driverPhone ? (
										<span className="bottom-helper">
											{formik.errors.driverPhone}
										</span>
									) : null}
								</SypacInput>
							</div>
						</div>
					</div>

					<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
								onClick={() => handleModalClose()}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="modalAddTruck.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="submit"
								className="w-full h-[44px] flex items-center justify-center bg-primary-violet border-0 rounded-lg transition hover:bg-primary-violet/70"
								disabled={formik.isSubmitting || !formik.isValid}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-white">
										{truck && truck.id ? (
											<T keyName="modalAddTruck.save">Save</T>
										) : (
											<T keyName="modalAddTruck.nextStep">Next step</T>
										)}
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ModalAddTruck;
