import React from 'react';
import {
	SypacBadge,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { PaymentStatusColor } from './TableStatus.constants';
import { TableStatusProps } from './TableStatus.interface';
import { T } from '@tolgee/react';
import AlertSmall from '../../assets/AlertSmall';
import ClockSmall from '../../assets/ClockSmall';
import CheckmarkSmall from '../../assets/CheckmarkSmall';
import { PaymentNotesStatusesEnum } from '../../services/billing.service';
import StatusDocument from '../../assets/StatusDocument';
import StatusPendingPayment from '../../assets/StatusPendingPayment';

const TableStatus: React.FC<TableStatusProps> = ({
	state,
	days,
	isPartial,
	insideOut,
}) => {
	return !insideOut ? (
		<SypacBadge
			color={PaymentStatusColor[state]}
			size="large"
			className="w-full"
		>
			{['pending', 'rejected'].includes(state) ? (
				<div className="flex gap-1 items-center w-fit justify-center cursor-auto">
					<SypacIcon
						iconName="Clock White"
						size="custom"
						width="12px"
						height="12px"
					/>
					<SypacText variant="overline-regular-large">
						<p className="text-gray-80">
							<T keyName="paymentStatus.pending">Pending</T>
						</p>
					</SypacText>
					{days && (
						<SypacBadge
							color={PaymentStatusColor[state]}
							size="small"
							className="ml-3 cursor-auto"
						>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{days}d.</p>
							</SypacText>
						</SypacBadge>
					)}
					{isPartial && (
						<SypacBadge
							color={PaymentStatusColor[state]}
							size="small"
							className="ml-3 w-fit"
						>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80 min-w-max">
									<T keyName="paymentStatus.partialPayment">Partial payment</T>
								</p>
							</SypacText>
						</SypacBadge>
					)}
				</div>
			) : ['overdue', 'rejected'].includes(state) ? (
				<div className="flex gap-1 items-center text-red w-fit justify-center cursor-auto">
					<SypacIcon
						iconName="Danger Filled"
						size="custom"
						width="12px"
						height="11px"
					/>
					<SypacText variant="overline-regular-large">
						<p className="text-white">
							<T keyName="paymentStatus.overdue">Overdue</T>
						</p>
					</SypacText>
					{days && (
						<SypacBadge
							color={PaymentStatusColor[state]}
							size="small"
							className="ml-3 cursor-auto"
						>
							<SypacText variant="overline-regular-large">
								<p className="text-white">{days}d.</p>
							</SypacText>
						</SypacBadge>
					)}
				</div>
			) : ['paid', 'approved'].includes(state) ? (
				<div className="flex gap-1 text-white w-fit justify-center cursor-auto">
					<SypacIcon
						iconName="Unread"
						size="custom"
						width="19px"
						height="16px"
					/>
					<SypacText variant="overline-regular-large">
						<p className="text-white">
							<T keyName="paymentStatus.paid">Paid</T>
						</p>
					</SypacText>
					{isPartial && (
						<SypacBadge
							color={PaymentStatusColor[state]}
							size="small"
							className="ml-3 w-fit cursor-auto"
						>
							<SypacText variant="overline-regular-large">
								<p className="text-white min-w-max">
									<T keyName="paymentStatus.inInstalments">In instalments</T>
								</p>
							</SypacText>
						</SypacBadge>
					)}
				</div>
			) : null}
		</SypacBadge>
	) : (
		<div className="flex gap-3 items-center">
			{['pending', PaymentNotesStatusesEnum.UPLOADED_INVOICE].includes(
				state,
			) ? (
				<ClockSmall color="#FFB352" secondColor="#454545" />
			) : ['overdue', 'rejected'].includes(state) ? (
				<AlertSmall color="#F44A77" />
			) : ['paid', 'approved'].includes(state) ? (
				<CheckmarkSmall color="#20AC93" />
			) : null}

			{state === PaymentNotesStatusesEnum.PENDING_INVOICE && <StatusDocument />}

			{state === PaymentNotesStatusesEnum.INVOICE_CONFIRMED && (
				<StatusPendingPayment />
			)}

			<SypacText variant="overline-regular-large">
				<p className="text-base text-gray-80 mt-[2px]">
					<T keyName={`paymentStatus.${state}`}>
						{state === 'rejected'
							? 'Rejected'
							: state.charAt(0).toUpperCase() + state.slice(1)}
					</T>
				</p>
			</SypacText>
			{days! > 0 && ['pending', 'overdue', 'rejected'].includes(state) && (
				<div
					className={`flex rounded-full py-[1px] px-[7px] ${
						state === 'pending'
							? 'bg-texas-rose'
							: ['overdue', 'rejected'].includes(state)
							? 'bg-red'
							: ['paid', 'approved'].includes(state)
							? 'bg-mountain-meadow'
							: 'bg-gray-10'
					}`}
				>
					<SypacText variant="overline-regular-large">
						<p
							className={`text-xs ${
								['overdue', 'rejected'].includes(state)
									? 'text-white'
									: 'text-gray-80'
							}`}
						>
							{days}d.
						</p>
					</SypacText>
				</div>
			)}
			{isPartial && ['pending', 'paid', 'approved'].includes(state) && (
				<div
					className={`flex rounded-full py-[1px] px-[7px] ${
						state === 'pending'
							? 'bg-texas-rose'
							: ['paid', 'approved'].includes(state)
							? 'bg-mountain-meadow'
							: 'bg-gray-10'
					}`}
				>
					<SypacText variant="overline-regular-large">
						<p
							className={`text-xs ${
								['paid', 'approved'].includes(state)
									? 'text-white'
									: 'text-gray-80'
							}`}
						>
							<T keyName="paymentStatus.inInstalments">In instalments</T>
						</p>
					</SypacText>
				</div>
			)}
		</div>
	);
};

export default TableStatus;
