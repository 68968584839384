import React, { DragEvent, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	SypacButton,
	SypacCheckbox,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { FileInterface } from '../../../../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import { FileService } from '../../../../../../services/file.service';
import { toastVariant } from '../../../../../../components/ToastVariant/toastVariant';
import { ProductDetailsDatabaseProps } from '../../../productDatabase.interface';
import { PhotoFrame } from '../../../../../../assets/PhotoFrame';
import NewTrash from '../../../../../../assets/NewTrash';
import ModalDeletePhoto from './ModalDeletePhoto';
import { ChevronRightIcon } from '../../../../../../assets/ChevronRightIcon';
import { ProductService } from '../../../../../../services/product.services';
import Emitter, { EventType } from '../../../../../../services/events';
import { MaxFileSizeMB } from '../../../../../../constants';

const EditProducer: React.FC<ProductDetailsDatabaseProps> = ({
	onClose,
	product,
}) => {
	const { t } = useTranslate();
	const [maxStockAvailability, setStockAvailability] =
		useState<number>(1000000);
	const [file, setFile] = useState<FileInterface | undefined>({
		fileUrl: product?.photoUrl,
	});
	const [fileUpdated, setFileUpdated] = useState<boolean>(false);
	const [photoHover, setPhotoHover] = useState<boolean>(false);
	const [deletePhoto, setDeletePhoto] = useState<boolean>(false);
	const [formEdited, setFormEdited] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			available: product.availableQuantity,
			availableQuantity: product.availableQuantity,
			unlimited: product.unlimited,
			minOrderQuantity: product.minOrderQuantity,
			maxOrderCapacity: product.maxOrderCapacity,
			pricePerUnit: product.pricePerUnit,
		},
		onSubmit: async (values) => {
			const body = {
				...(fileUpdated && { photoUrl: file?.file?.name }),
				pricePerUnit: values.pricePerUnit,
				minOrderQuantity: values.minOrderQuantity,
				availableQuantity: values.available,
				maxOrderCapacity: values.maxOrderCapacity,
				unlimited: values.unlimited,
			};
			await ProductService.editMeProduct(product?.id!, body);
			Emitter.emit(EventType.PRODUCT_DATABASE_REFRESH, product);
			onClose && onClose();
			setFormEdited(false);
		},
		validateOnChange: true,
		validationSchema: Yup.object({
			available: Yup.number()
				.positive(
					t(
						'selectProduct.minimumProductStock',
						'Minimum product stock is 0.1',
					),
				)
				.when(['availableQuantity', 'unlimited'], {
					is: (a: number, b: boolean) => {
						return a !== undefined || !b;
					},
					then: Yup.number().required(
						t(
							'selectProduct.productStockIsRequired',
							'Product stock is required',
						),
					),
				}),
			minOrderQuantity: Yup.number()
				.positive(
					t('selectProduct.minimumCapacityValue', 'Minimum capacity is 0.1'),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maxMinimumCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.minimumCapacityIsRequired',
						'Minimum capacity is required',
					),
				),
			maxOrderCapacity: Yup.number()
				.positive(
					t(
						'selectProduct.minimumMaxCapacityValue',
						'Minimum max order capacity is 0.1',
					),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maximumMaxCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.maximumCapacityIsRequired',
						'Maximum capacity is required',
					),
				),
			pricePerUnit: Yup.number()
				.positive(
					t('selectProduct.netPriceMinimumValue', 'Minimum Net price is 0.1'),
				)
				.required(
					t('selectProduct.netPriceIsRequired', 'Net price is required'),
				),
		}),
	});

	const getDataFiles = async (file: File) => {
		try {
			const { data } = await FileService.uploadFiles([file]);
			const newFile = data.items[0];
			const [name, type] = newFile.originalName?.split('.') || [];
			const mimeType =
				newFile.type?.split('/').pop()?.toUpperCase() ||
				type?.toUpperCase() ||
				'';
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

			setFile({ name, mimeType, size, file: newFile });
			setFormEdited(true);
			setFileUpdated(true);
		} catch (e) {}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFile = e.dataTransfer.files[0];
			if (droppedFile.size / (1024 * 1024) > MaxFileSizeMB.IMAGE) {
				toastVariant(
					`File size exceeds ${MaxFileSizeMB.IMAGE} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(droppedFile).then(() => {});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFile = e.target.files[0];
			if (selectedFile.size / (1024 * 1024) > MaxFileSizeMB.IMAGE) {
				toastVariant(
					`File size exceeds ${MaxFileSizeMB.IMAGE} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(selectedFile).then(() => {});
		}
	};

	const handleDeleteFile = () => {
		setFile(undefined);
		setDeletePhoto(false);
	};

	const changeAvailableStock = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setStockAvailability(
			formik.values.unlimited ? 1000000 : parseFloat(e.target.value) || 100000,
		);
		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
		setFormEdited(true);
	};

	const changeUnlimited = (checked?: boolean) => {
		const isChecked =
			checked !== undefined ? checked : !formik?.values.unlimited;

		formik.setFieldValue('unlimited', isChecked);
		setStockAvailability(
			isChecked ? 1000000 : formik.values.available || 100000,
		);

		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ maxOrderCapacity: true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ minOrderQuantity: true });
		}
		setFormEdited(true);
	};

	const changeMinOrderQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (!(value && value > 0.1 && value < maxStockAvailability)) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
		setFormEdited(true);
	};

	const changeMaxOrderCapacity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (!(value && value > 0.1 && value < maxStockAvailability)) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		setFormEdited(true);
	};

	const changePricePerUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setFormEdited(true);
	};

	return (
		<>
			<form
				onSubmit={formik.handleSubmit}
				className={`${formEdited ? 'mb-[100px]' : ''}`}
			>
				<div className="flex border-0 border-b border-t border-solid border-gray-10">
					<div className="w-full flex items-center justify-between px-10 py-[15px]">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								<T keyName="productDetails.productInformation">
									Product information
								</T>
							</p>
						</SypacText>
					</div>
				</div>

				<div className="flex border-0 border-b border-solid border-gray-10">
					<div className="flex flex-col gap-5 px-10 py-5">
						<SypacText variant="overline-normal-large">
							<p>
								<T keyName="modalAddProduct.productPhoto">Product photo</T>
							</p>
						</SypacText>

						<div className="flex gap-5">
							{file ? (
								<div
									style={{
										backgroundImage: `url(${file.fileUrl || file.file.url})`,
									}}
									className="relative w-[120px] h-[120px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
									onClick={() => setDeletePhoto(true)}
									onMouseEnter={() => setPhotoHover(true)}
									onMouseLeave={() => setPhotoHover(false)}
									role="img"
								>
									{photoHover ? (
										<div className="absolute top-[8px] right-[8px] w-[24px] h-[24px] flex justify-center items-center bg-red rounded-md z-10">
											<NewTrash color="#FFFFFF" />
										</div>
									) : null}
									{photoHover ? (
										<div className="absolute w-[119px] h-[119px] flex bg-transparent/20 z-0 rounded-10 box-border" />
									) : null}
								</div>
							) : null}

							<div
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}
								className="flex gap-[10px] w-[120px] h-[120px] border border-solid border-gray-10 bg-alabaster p-0 rounded-10 transition hover:border-gray-40 box-border"
							>
								<input
									type="file"
									id="input-file-upload"
									className="hidden"
									multiple={true}
									accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
									onChange={handleChange}
								/>
								<label
									htmlFor="input-file-upload"
									className="w-full h-full flex flex-col gap-[10px] items-center justify-center cursor-pointer"
								>
									<span className="flex scale-[1.1]">
										<PhotoFrame color="#454545" />
									</span>
									<SypacText variant="overline-normal-large">
										<p className="text-sm text-gray-80">
											<T keyName="edit.editPhoto">Edit photo</T>
										</p>
									</SypacText>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-5 mt-6 px-10">
					<div className="flex flex-col gap-2">
						<SypacInput
							error={!!(formik.touched.available && formik.errors.available)}
						>
							<SypacText className="mb-1" variant="overline-normal-large">
								<p>
									<T keyName="selectProduct.productStockAvailability">
										Product stock availability (tons)
									</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
									formik.touched.available && formik.errors.available
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
								} ${
									formik?.values.unlimited
										? 'bg-gray-1 pointer-events-none'
										: ''
								}`}
								name="available"
								type="number"
								placeholder={t(
									'selectProduct.enterAvailableProductStock',
									'Enter available product stock',
								)}
								value={formik?.values.available}
								onChange={changeAvailableStock}
								disabled={formik?.values.unlimited}
							/>
							{formik.touched.available && formik.errors.available ? (
								<span className="input-error">
									<T keyName="selectProduct.errorAvailable">
										{formik.errors.available}
									</T>
								</span>
							) : null}
						</SypacInput>

						<SypacButton
							variant="secondary"
							size="small"
							className="self-start"
						>
							<button
								type="button"
								className="border-none bg-transparent p-0 gap-2"
								onClick={() => changeUnlimited(!formik?.values.unlimited)}
							>
								<SypacCheckbox size="md" className="mt-[1px]">
									<input
										type="checkbox"
										name="unlimited"
										checked={formik?.values.unlimited}
										className="cursor-pointer"
										onChange={(e) => changeUnlimited(e.target.checked)}
									/>
									{formik.touched.unlimited && formik.errors.unlimited ? (
										<span className="bottom-helper">
											<T keyName="selectProduct.errorUnlimited">
												{formik.errors.unlimited}
											</T>
										</span>
									) : null}
								</SypacCheckbox>
								<SypacText variant="overline-normal-large">
									<p className="text-gray-hover-2 pt-1">
										<T keyName="selectProduct.unlimitedQuantity">
											Unlimited quantity
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
					<SypacInput
						error={
							!!(
								formik.touched.minOrderQuantity &&
								formik.errors.minOrderQuantity
							)
						}
					>
						<SypacText className="mb-1" variant="overline-normal-large">
							<p>
								<T keyName="selectProduct.minimumOrderCapacity">
									Minimum order capacity per day (tons)
								</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<input
							className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
								formik.touched.minOrderQuantity &&
								formik.errors.minOrderQuantity
									? 'border-red-orange'
									: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
							}`}
							type="number"
							name="minOrderQuantity"
							placeholder={t(
								'selectProduct.enterMinimumPerTon',
								'Enter minimum per ton',
							)}
							value={formik?.values.minOrderQuantity}
							onChange={changeMinOrderQuantity}
						/>
						{formik.touched.minOrderQuantity &&
						formik.errors.minOrderQuantity ? (
							<span className="input-error">
								<T keyName="selectProduct.errorMinOrderQuantity">
									{formik.errors.minOrderQuantity}
								</T>
							</span>
						) : null}
					</SypacInput>
					<SypacInput
						error={
							!!(
								formik.touched.maxOrderCapacity &&
								formik.errors.maxOrderCapacity
							)
						}
					>
						<SypacText className="mb-1" variant="overline-normal-large">
							<p>
								<T keyName="selectProduct.maximumOrderCapacity">
									Maximum order capacity per day (tons)
								</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<input
							className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
								formik.touched.maxOrderCapacity &&
								formik.errors.maxOrderCapacity
									? 'border-red-orange'
									: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
							}`}
							type="number"
							name="maxOrderCapacity"
							placeholder={t(
								'selectProduct.enterMaximumPerTon',
								'Enter maximum per ton',
							)}
							value={formik?.values.maxOrderCapacity}
							onChange={changeMaxOrderCapacity}
						/>
						{formik.touched.maxOrderCapacity &&
						formik.errors.maxOrderCapacity ? (
							<span className="input-error">
								<T keyName="selectProduct.errorMaxOrderCapacity">
									{formik.errors.maxOrderCapacity}
								</T>
							</span>
						) : null}
					</SypacInput>
					<div className="w-full">
						<SypacText className="mb-1" variant="overline-normal-large">
							<p>
								<T keyName="selectProduct.netPricePerTon">
									Net price per ton (excluding VAT)
								</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>

						<div className="flex flex-col">
							<SypacInput
								error={
									!!(formik.touched.pricePerUnit && formik.errors.pricePerUnit)
								}
								className={`relative flex border border-solid transition rounded-lg ${
									!!(formik.touched.pricePerUnit && formik.errors.pricePerUnit)
										? 'border-red-orange'
										: 'border-gray-10 focus-within:border-cornflower-blue hover:border-gray-30 focus-within:hover:border-cornflower-blue'
								}`}
							>
								<input
									className="border-0 py-[11px] pl-3 rounded-lg placeholder:text-gray-22 outline-none"
									type="number"
									step="0.01"
									name="pricePerUnit"
									placeholder={t(
										'selectProduct.enterProductPricePerTon',
										'Enter product price per ton',
									)}
									value={formik?.values.pricePerUnit}
									onChange={changePricePerUnit}
								/>

								<div className="absolute right-0 flex h-[40px] gap-3 px-[10px] justify-center items-center bg-white border-0 border-solid border-l-[1px] border-gray-10 rounded-r-lg">
									<SypacText variant="overline-normal-large">
										<p className="text-base text-gray-80 mt-[2px]">
											{product.countryCode === 'MDA' ? 'MDL' : 'PLN'}
										</p>
									</SypacText>
									<span className="flex my-auto transition-all mr-[2px]">
										<ChevronRightIcon />
									</span>
								</div>
							</SypacInput>
							{formik.touched.pricePerUnit && formik.errors.pricePerUnit ? (
								<span className="text-xs text-red-orange mt-[2px]">
									<T keyName="selectProduct.errorPricePerUnit">
										{formik.errors.pricePerUnit}
									</T>
								</span>
							) : null}
						</div>
					</div>
				</div>

				{formEdited ? (
					<div className="w-[714px] fixed bottom-0 px-10 py-5 bg-white border-0 border-t border-solid border-t-gray-10 animate-slide-up box-border">
						<div className="flex gap-3">
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									className="w-full border-red text-red px-5 py-2 rounded-lg"
									onClick={onClose}
								>
									<SypacIcon
										iconName="Close Circle"
										size="custom"
										width="24px"
										height="24px"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="productDetails.cancel">Cancel</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton variant="secondary" size="small" className="w-full">
								<button
									type="submit"
									className="w-full border-mountain-meadow text-mountain-meadow px-5 py-2 rounded-lg"
								>
									<SypacIcon
										iconName="Check Circle"
										size="custom"
										width="24px"
										height="24px"
									/>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="productDetails.save">Save</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
				) : null}
			</form>
			<ModalDeletePhoto
				isOpen={deletePhoto}
				onClose={() => setDeletePhoto(false)}
				handleDelete={handleDeleteFile}
				url={product.photoUrl!}
			/>
		</>
	);
};

export default EditProducer;
