import React, { useState, useEffect } from 'react';
import { SypacText } from '@sypac/component-library-react';

interface LoadingBarProps {
	isLoading?: boolean;
	onComplete?: () => void;
	simulationDuration?: number;
	className?: string;
}

const LoadingBar: React.FC<LoadingBarProps> = ({
	isLoading = true,
	onComplete,
	simulationDuration = 6000,
	className = '',
}) => {
	const [progress, setProgress] = useState(0);
	const [show, setShow] = useState(isLoading);

	useEffect(() => {
		if (isLoading) {
			setShow(true);
			const quickStart = setTimeout(() => {
				setProgress(20);
			}, 100);

			const initialBoost = setTimeout(() => {
				setProgress(30);
			}, 200);

			const progressInterval = setInterval(() => {
				setProgress((prev) => {
					if (prev >= 95) return 95;
					if (prev >= 90) return Math.min(95, prev + 0.05);
					if (prev >= 80) return Math.min(95, prev + 0.15);
					if (prev >= 60) return Math.min(95, prev + 0.25);
					return Math.min(95, prev + 0.5);
				});
			}, simulationDuration / 400);

			return () => {
				clearTimeout(quickStart);
				clearTimeout(initialBoost);
				clearInterval(progressInterval);
			};
		} else if (show) {
			const currentProgress = progress;
			const remainingProgress = 100 - currentProgress;
			const duration = 800;
			const startTime = Date.now();

			const completeAnimation = () => {
				const elapsed = Date.now() - startTime;
				const progress = Math.min(1, elapsed / duration);

				const eased =
					progress === 1
						? 1
						: 1 -
						  Math.pow(2, -10 * progress) * Math.cos(progress * Math.PI * 0.5);

				setProgress(Math.min(100, currentProgress + remainingProgress * eased));

				if (progress < 1) {
					requestAnimationFrame(completeAnimation);
				} else {
					setProgress(100);
					setTimeout(() => {
						setShow(false);
						onComplete?.();
					}, 400);
				}
			};

			requestAnimationFrame(completeAnimation);
		}
	}, [isLoading, onComplete, progress, show, simulationDuration]);

	if (!show) return null;

	return (
		<div className={`${className}`}>
			<div className="w-full flex flex-col gap-5 items-center">
				<div className="w-full bg-gray-10 rounded-lg p-[2px]">
					<div className="w-full h-[6px] rounded-lg overflow-hidden">
						<div
							className="h-full bg-gradient-primary rounded-lg transition-all duration-500 ease-out"
							style={{ width: `${progress}%` }}
						/>
					</div>
				</div>
				<SypacText variant="body-regular-medium">
					<p className="text-lg leading-[16px] text-gray-90">
						{`${Math.round(progress)}%`}
					</p>
				</SypacText>
			</div>
		</div>
	);
};

export default LoadingBar;
